import React from 'react';
import { Box, Paper } from '@mui/material';
import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import {
  BrowseProductFamiliesQuery,
  useBrowseProductFamiliesQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { dateTimeColumn, stringColumn } from '../../../../../util/dataGridColumns';
import { ProductFamiliesDialogButton } from './ProductFamiliesDialogButton';

type Row = BrowseProductFamiliesQuery['productFamilies'][0];

const columns: GridEnrichedColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <ProductFamiliesDialogButton id={params.row.id}/>,
  },
  {
    ...stringColumn('pType'),
    headerName: 'PType',
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('name'),
    headerName: 'Product Family name',
    width: 500,
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified',
  },
];

export function ProductFamiliesTable() {
  const pageSize = 100;
  const {
    data, error, loading, previousData,
  } = useBrowseProductFamiliesQuery();

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}/>
      <DataGrid
       density="compact"
       paginationMode="server"
       loading={loading}
       pageSize={pageSize}
       pagination
       columns={columns}
       rowCount={(data || previousData)?.productFamiliesAggregate.count || 0}
       rows={(data || previousData)?.productFamilies || []}
       />
    </Box>
  );
}
