import { TextField } from '@mui/material';
import React from 'react';

interface Props {
  onChange: (value: number) => void;
  defaultValue: number;
}

/**
 * Text input field for structure week "YYYYMM"
 */
export function StructureWeekInput(props: Props) {
  return (
    <TextField
      label=""
      variant="standard"
      className="font-monospace"
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(parseInt(e.target.value, 10))}
    />
  );
}
