import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import { dateFormat } from '../../../util/date';
import {
  UserWebhookDetailsFragment,
  AdminWebhookDetailsFragment,
} from '../../../generated/graphql';
import { webhookLabel } from './webhook.consts';
import { UserNotFound } from '../../common/UserNotFound';
import { WebhookDebugButton } from './WebhookDebugButton';
import { WebhookDeleteButton } from './WebhookDeleteButton';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';
import { getComparator } from '../../common/sortTables/comparators';

type Webhook = UserWebhookDetailsFragment | AdminWebhookDetailsFragment;

interface Props {
  webhooks: Webhook[],
  refresh: () => void,
}

const hasIntegration = (webhook: Webhook): webhook is AdminWebhookDetailsFragment => webhook.hasOwnProperty('application');

export function RegisteredWebhooksTable(props: Props) {
  const { webhooks, refresh } = props;
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof Webhook>('createdAt');

  if (webhooks.length === 0) {
    return (
      <Alert severity="warning">
        You have not registered any webhooks yet
      </Alert>
    );
  }

  const sortableRows = webhooks.slice().sort(
    getComparator<Webhook>(
      order,
      orderBy,
    ),
  );
  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(property, direction) => {
              setOrder(direction);
              setOrderBy(property);
            }}
            refetch={refresh}
            headCells={headCells}
          />
          <TableBody>
            {sortableRows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {hasIntegration(row) && (
                  <TableCell>
                    {row.application?.teamName} <br/> {row.application?.appName}
                  </TableCell>
                )}
                <TableCell>
                  <Tooltip title={webhookLabel(row.eventType)}>
                    <em><strong>{row.eventType}</strong></em>
                  </Tooltip>
                  <br/>
                  {row.environment}
                </TableCell>
                <TableCell>
                  <Tooltip title={row.url}>
                    <Box sx={{
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '200px',
                    }}>
                      <em>{row.url}</em>
                    </Box>
                  </Tooltip>
                  {row.description}
                </TableCell>
                <TableCell>
                  {row.debug && (
                    <WebhookDebugButton
                      debug={row.debug}
                      lastTriggered={row.lastTriggered}
                      failed={row.failed}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {row.createdByUser?.name || <UserNotFound/>}<br/>
                  {dateFormat(row.createdAt)}
                </TableCell>
                <TableCell padding="none" align="right">
                  <WebhookDeleteButton id={row.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'app.teamName',
    label: 'App',
    isSortable: true,
  },
  {
    id: 'eventType',
    label: 'Event Type & Environment',
    isSortable: true,
  },
  {
    id: 'description',
    label: 'Url & Description',
    isSortable: true,
  },
  {
    id: 'lastTriggered',
    label: 'Last Triggered',
    isSortable: true,
  },
  {
    id: 'createdBy',
    label: 'Created by',
    isSortable: true,
  },
];
