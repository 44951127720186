import { Warning } from '@mui/icons-material';
import React from 'react';

export function UserNotFound() {
  return (
    <>
      <span>
        <em>User not found</em>
      </span>
      <Warning color='warning'/>
    </>
  );
}
