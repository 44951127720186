import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import React from 'react';
import { PartDialogQuery } from '../../../../../../generated/graphql';
import { PartUsagesTable } from './PartUsagesTable';
import { ConsumptionRecordsTable } from './ConsumptionRecordsTable';

type PartDialogPart = PartDialogQuery['parts'][0];

interface Props {
  part?: PartDialogPart;
}

export function UsagesInfoPanel(props: Props) {
  const { part } = props;
  const allConsumptionRecords = part?.partUsages
    .flatMap((partUsage) => partUsage.consumptionRecords) || [];

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>
            Part Usages - EBOM
          </Typography>
          {part?.partUsages.length === 0 ? (
            <Alert severity="info">This has no part usages</Alert>
          ) : (
            <PartUsagesTable part={part} />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>
            Consumption Records - MBOM
          </Typography>
          {allConsumptionRecords.length > 0 ? (
            <ConsumptionRecordsTable consumptionRecords={allConsumptionRecords} />
          ) : (
            <Alert severity="info">
              This part does not have any consumption records.
            </Alert>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
