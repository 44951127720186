/* eslint-disable max-len */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { UsageRuleDialog_CpvFragment } from '../../../../../../generated/graphql';
import { ValueText, ValueTooltip } from './valueText';

interface Props {
  cpvs?: UsageRuleDialog_CpvFragment[]
}

export function CPVTable(props: Props) {
  const { cpvs } = props;

  if (!cpvs) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>AltCode</TableCell>
            <TableCell>name</TableCell>
            <TableCell>Configuration Parameter</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cpvs.map((cpv, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}
            >
              <TableCell>{ValueText(cpv.code)}</TableCell>
              <TableCell>{ValueText(cpv.altCode)}</TableCell>
              <TableCell>{ValueText(cpv.name)}</TableCell>
              <TableCell>
                <ValueTooltip title="name" placement="right" value={cpv.configurationParameter.name}/><br/>
                <ValueTooltip title="altCode" placement="right" value={cpv.configurationParameter.code}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
