import React from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { MfgCoiAssemblyPlaceholderInfoFragment } from '../../../../../../generated/graphql';
import { TableCellWithValue } from '../../parts/PartDialog/valueText';

interface Props {
  rows: MfgCoiAssemblyPlaceholderInfoFragment[]
}

export function MfgCoiAssemblyPlaceholderTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>name</TableCell>
            <TableCell>productId</TableCell>
            <TableCell>sapPlant</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow><TableCell colSpan={99} align="left">None</TableCell></TableRow>
          )}
          {rows?.map((value, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, whiteSpace: 'nowrap' }}
            >
              <TableCellWithValue value={value.name}/>
              <TableCellWithValue value={value.productId}/>
              <TableCellWithValue value={value.sapPlant}/>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
