import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { PendingUserConnectionRequests } from '../admin/app-approval/PendingUserConnectionRequests';
import { PendingApplicationCreationRequests } from '../admin/app-approval/PendingApplicationCreationRequests';
import { SimpleCardLayout } from '../layout/SimpleCardLayout';
import { AdminOverview } from '../admin/admin-overview/AdminOverview';
import { Section } from '../layout/Section';

export function AdminPage() {
  const { data: authData } = useAuth();

  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  return (
    <>
      <Section maxWidth={1400}>
        <PendingApplicationCreationRequests/>
      </Section>
      <Section maxWidth={1400}>
        <PendingUserConnectionRequests/>
      </Section>
      <Section mb={10} maxWidth={1400}>
        <AdminOverview/>
      </Section>
    </>
  );
}
