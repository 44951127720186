import React from 'react';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

interface Props {
  onClick: () => void,
  loading?: boolean,
}

export function CardRefreshAction(props: Props) {
  return (
    <IconButton
      size="small"
      disabled={props.loading}
      onClick={() => props.onClick()}
    >
      {props.loading ? <MoreHorizIcon/> : <RefreshIcon/>}
    </IconButton>
  );
}
