import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

interface Props {
  children: ReactNode,
}

export function SimpleCardLayout(props: Props) {
  return <Box sx={{
    height: '100vh',
    minWidth: 280,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  }}>
    <Card>
      <CardContent>
        {props.children}
      </CardContent>
    </Card>
  </Box>;
}
