import React, { useEffect, useState } from 'react';
import { Avatar, Skeleton } from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';

export function CurrentUserAvatar() {
  const { data } = useAuth();
  const [img, setImage] = useState<Blob | null>(null);

  useEffect(() => {
    if (data) {
      fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: {
          'Content-Type': 'image/jpg',
          Authorization: `Bearer ${data.accessToken}`,
        },
      }).then((r) => {
        r.blob().then(setImage).catch(() => {});
      }).catch(() => {});
    }
  }, [data]);

  if (!img) {
    return <Skeleton variant='circular'><Avatar/></Skeleton>;
  }

  const url = window.URL || window.webkitURL;
  const blobUrl = url.createObjectURL(img);

  return <Avatar src={blobUrl}/>;
}
