/* eslint-disable no-param-reassign,@typescript-eslint/no-explicit-any */
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { OperationTypeDistributionByTeamResult } from '../../../generated/graphql';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { BarGraph } from './Graph/BarGraph';
import { Section } from '../../layout/Section';

interface QueryUsageProps {
  operationDistribution: OperationTypeDistributionByTeamResult[]
}

export function QueryUsage(props: QueryUsageProps) {
  const preprocess = (
    dataRows: OperationTypeDistributionByTeamResult[],
  ): { [name: string]: any }[] => {
    return Object.values(dataRows.reduce((r, a) => {
      if (a.operationType) {
        r[a.teamName] = r[a.teamName] || { teamName: a.teamName };
        r[a.teamName][a.operationType] = a.count;
      }
      return r;
    }, Object.create({})));
  };

  return (
    <Card {...props}>
      <SectionCardHeader
        avatar={<ArrowUpward color='secondary'/>}
        title='Request Distribution'
      />
      <CardContent>
        <Section>
          <BarGraph
            data={preprocess(props.operationDistribution)}
            xDataKey='teamName'
            yDataKey={['query', 'mutation']}
          />
        </Section>
      </CardContent>
    </Card>
  );
}
