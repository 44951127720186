import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { Monospaced } from '../../common/text/Monospaced';
import { QuantityQualityCheckErrors } from '../../../generated/graphql';

export function QualityCheckResultTable(props: { errors: QuantityQualityCheckErrors[] }) {
  const sortedErrors = [...props.errors].sort((a, b) => {
    return a.number.localeCompare(b.number);
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableCell>PA <br />Number</TableCell>
          <TableCell>PA <br />Name</TableCell>
          <TableCell align="right">Part Usages</TableCell>
          <TableCell align="right">Expected Qty</TableCell>
          <TableCell>
            Deviation Rule Key <br />
            (if Part address not using default value)
          </TableCell>
        </TableHead>
        {sortedErrors.map((pu, i) => (
          <TableRow key={i}>
            <TableCell>{pu.number}</TableCell>
            <TableCell>{pu.name}</TableCell>
            <TableCell align="right"><Monospaced>{pu.usageCount}</Monospaced></TableCell>
            <TableCell align="right"><Monospaced>{pu.expectedQty}</Monospaced></TableCell>
            <TableCell sx={{
              wordBreak: 'break-word',
              width: 320,
            }}>
              {pu.ruleKey || '-'}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
}
