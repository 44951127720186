import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import {
  ConfigurationDictionariesTree,
} from '../../documentation/pages/browser/configuration-dictionaries/ConfigurationDictionariesTree';

export function ConfigurationDictionariesBrowserPage() {
  return (
    <Box mt={2}>
      <Card variant="outlined">
        <Box mb={1} mx={1}>
          <ConfigurationDictionariesTree/>
        </Box>
      </Card>
    </Box>
  );
}
