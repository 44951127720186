import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowseSparePartInfoQuery, SortDirection,
  SparePartInfoWhere,
  useBrowseSparePartInfoQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn, dateTimeColumn, stringColumn,
} from '../../../../../util/dataGridColumns';
import { SparePartInfoDialogButton } from './SparePartInfoDialogButton';

type Row = BrowseSparePartInfoQuery['sparePartInfos'][0];

const columns: GridEnrichedColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    // eslint-disable-next-line max-len
    renderCell: (params) => <SparePartInfoDialogButton sparePartInfoWhere={{ id: params.row.id }} />,
  },
  {
    ...stringColumn('bpsr'),
    headerName: 'BPSR',
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('dangerousGoods'),
    width: 210,
  },
  {
    ...stringColumn('funcGroup'),
    headerName: 'Function Group',
    width: 150,
  },
  {
    ...stringColumn('infoToSupplyPlanner'),
    headerName: 'Info to Supply Planner',
    width: 180,
  },
  {
    ...stringColumn('isSoldSeparately'),
    headerName: 'Sold Separately',
    width: 150,
  },
  {
    ...stringColumn('packagingComp'),
    headerName: 'Packaging Comp',
    width: 150,
  },
  {
    ...stringColumn('sort'),
    headerName: 'Sort',
    cellClassName: 'font-monospace',
    width: 120,
  },
  {
    ...stringColumn('sparePart'),
    headerName: 'Spare Part',
    width: 90,
  },
  {
    ...stringColumn('status'),
    headerName: 'Status',
    width: 90,
  },
  {
    ...aggregateColumn('partUseInsteadAggregate'),
    headerName: 'Use Instead',
    width: 90,
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified',
    width: 170,
  },
];

export function SparePartInfoTable() {
  const pageSize = 100;
  const [page, setPage] = useState<number>(0);
  const [where, setWhere] = useState<SparePartInfoWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseSparePartInfoQuery({
    variables: {
      where,
      options: {
        limit: pageSize,
        offset: page * pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        pageSize={pageSize}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        rowsPerPageOptions={[]}
        onPageChange={(p) => setPage(p)}
        columns={columns}
        rowCount={(data || previousData)?.sparePartInfosAggregate.count || 0}
        rows={(data || previousData)?.sparePartInfos || []}
      />
    </Box>
  );
}
