import React from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useDeleteRegisteredWebhooksMutation } from '../../../generated/graphql';

interface Props {
  id: string;
}

export function WebhookDeleteButton(props: Props) {
  const { id } = props;
  const [deleteWebhook] = useDeleteRegisteredWebhooksMutation();
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleYes = () => {
    deleteWebhook({
      variables: {
        id,
      },
      refetchQueries: 'all',
    }).then(() => {
      enqueueSnackbar(`
         Deregistered webhook
      `, { variant: 'success' });
    });

    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="error">
        <DeleteIcon/>
      </IconButton>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          Are you sure you want to delete this webhook registration? <br />
          This action can not be undone.
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
