import { Dayjs } from 'dayjs';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export function MyDatePicker(props: { value: Dayjs, setValue: (value: Dayjs) => void }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={props.value}
        onChange={(newValue: Dayjs | null) => {
          if (newValue) {
            props.setValue(newValue);
          }
        }}
        slotProps={{ textField: { size: 'small' } }}
        format="YYYY-MM-DD" // Format displayed in the input
        views={['year', 'month', 'day']} // Show only date selection (no time)
      />
    </LocalizationProvider>
  );
}
