import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { Typography } from '@mui/material';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { Section } from '../../layout/Section';
import { DataValidationUpload } from './DataQualityUpload';
import { DataQualityType } from '../../../generated/graphql';

export function UploadDataValidation() {
  return (
      <>
        <Section>
          <Card>
            <SectionCardHeader
              avatar={<ArrowUpward color="secondary"/>}
              title="Upload EBOM Validation Data(TC)"
            />
            <CardContent>
              <Typography color="text.secondary" mb={2} variant="body2">
                This will check Arts, FunctionGroups,
                FgPoses and PartAddresses and partUsage <br/>
                Note that this does not update any records <br/>
              </Typography>
              <DataValidationUpload
                type={DataQualityType.Structure}
                buttonText="Upload Structure EBOM"
              />
            </CardContent>
          </Card>
        </Section>
        <Section>
          <Card>
            <SectionCardHeader
              avatar={<ArrowUpward color="secondary"/>}
              title="Upload MBOM Validation Data(TC)"
            />
            <CardContent>
              <Typography color="text.secondary" mb={2} variant="body2">
                This will check PartAddresses and ConsumptionRecord <br/>
                Note that this does not update any records <br/>
              </Typography>
              <DataValidationUpload
                type={DataQualityType.Mbom}
                buttonText="Upload Structure MBOM"
              />
            </CardContent>
          </Card>
          </Section>
      </>
  );
}
