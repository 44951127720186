import {
  IntrospectionField,
  IntrospectionObjectType,
  IntrospectionOutputTypeRef,
} from 'graphql/utilities/getIntrospectionQuery';
import React, {
  memo, PropsWithChildren, useMemo, useState,
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  CircularProgress, debounce, FormControlLabel, Switch, TextareaAutosize,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {
  WikiEntry,
  WikiEntryUpdate,
  useMeQuery,
  useUpdateWikiEntryMutation,
} from '../../../../generated/graphql';
import ErrorMessage from '../../../common/ErrorMessage';
import { colors } from '../../../../services/mui-theme';

interface Props {
  type: IntrospectionObjectType,
  wikiEntries: { [key: string]: WikiEntry } | null
}

export function TypeTable(props: Props) {
  const { type, wikiEntries } = props;
  const { data: userData, error: meQueryError } = useMeQuery();
  const isUpstream = userData?.user.me.roles.includes('upstream') || false;
  const [editorEnabled, setEditorEnabled] = useState(false);

  const getFieldKey = useMemo(() => (field: IntrospectionField) => `${type.name}.${field.name}`, [type.name]);

  const findEntry = useMemo(() => (field: IntrospectionField) => (
    wikiEntries ? wikiEntries[getFieldKey(field)] : null
  ), [wikiEntries, getFieldKey]);

  const showTcCol = useMemo(
    () => !!(
      editorEnabled || (wikiEntries && type.fields.some((f) => findEntry(f)?.tcDescription))),
    [editorEnabled, wikiEntries, type.fields, findEntry],
  );

  const showKdpCol = useMemo(
    () => !!(
      editorEnabled || (wikiEntries && type.fields.some((f) => findEntry(f)?.kdpDescription))),
    [editorEnabled, wikiEntries, type.fields, findEntry],
  );

  return (
    <>
      <a id={`${type.name}`} style={{ position: 'relative', top: -90 }} />
      <ErrorMessage error={meQueryError} />
      <Stack spacing={2} direction="row" my={2} alignItems="center">
        <Typography variant="h2">{type.name}</Typography>
        {isUpstream && (
          <FormControlLabel
            control={<Switch onChange={(e) => setEditorEnabled(e.target.checked)} />}
            disabled={wikiEntries === null} // Wait until wiki entries are loaded
            label="Edit fields"
          />
        )}
      </Stack>
      {type.description && <Typography mb={2} variant="body2">{type.description}</Typography>}
      <TableContainer component={Paper} sx={{ mb: 6 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Notes</TableCell>
              {showTcCol && <TableCell>TC</TableCell>}
              {showKdpCol && <TableCell>KDP</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {type.fields.map((field) => {
              return (
                <FieldRow
                  key={field.name}
                  field={field}
                  fieldKey={getFieldKey(field)}
                  wikiEntry={findEntry(field)}
                  editMode={editorEnabled}
                  showTcCol={showTcCol}
                  showKdpCol={showKdpCol}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

interface FieldRowProps {
  editMode: boolean,
  field: IntrospectionField,
  fieldKey: string,
  wikiEntry: WikiEntry | null
  showTcCol: boolean
  showKdpCol: boolean
}

function EmptyCell() {
  return <Box sx={{ opacity: 0.3 }}>-</Box>;
}

// eslint-disable-next-line react/display-name
const FieldRow = memo((props: FieldRowProps) => {
  const {
    field, wikiEntry, editMode, fieldKey, showTcCol, showKdpCol,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [update, { loading }] = useUpdateWikiEntryMutation({
    onError: (e) => enqueueSnackbar(`Unable to update field: ${e}`),
  });
  const updateEntry = (f: WikiEntryUpdate) => update({ variables: { entry: f } });

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        position: 'relative',
        '& td, & th': { fontSize: '0.8rem' },
      }}
    >
      <TableCell
        component="th"
        scope="row"
      >
        <Box sx={field.isDeprecated ? { textDecoration: 'line-through', opacity: 0.5 } : {}}>
          {field.name}
        </Box>
        {field.deprecationReason && (
          <Typography variant="body2" fontSize="0.7rem" color="error">{field.deprecationReason}</Typography>
        )}
        {loading && <RowLoader />}
      </TableCell>
      <TableCell>{typeCell(field.type)}</TableCell>
      <TableCell>{field.description || <EmptyCell />}</TableCell>
      <TableCell>
        {editMode ? (
          <EditableCell
            defaultValue={wikiEntry?.notes || ''}
            onChange={(v) => updateEntry({ key: fieldKey, notes: v })}
          />
        ) : <>{wikiEntry?.notes || <EmptyCell />}</>}
      </TableCell>
      {showTcCol && (
        <TableCell>
          {editMode ? (
            <EditableCell
              defaultValue={wikiEntry?.tcDescription || ''}
              onChange={(v) => updateEntry({ key: fieldKey, tcDescription: v })}
            />
          ) : <>{wikiEntry?.tcDescription || <EmptyCell />}</>}
        </TableCell>
      )}
      {showKdpCol && (
        <TableCell>
          {editMode ? (
            <EditableCell
              defaultValue={wikiEntry?.kdpDescription || ''}
              onChange={(v) => updateEntry({ key: fieldKey, kdpDescription: v })}
            />
          ) : <>{wikiEntry?.kdpDescription || <EmptyCell />}</>}
        </TableCell>
      )}
    </TableRow>
  );
});

function EditableCell(props: { defaultValue: string, onChange: (v: string) => void }) {
  return (
    <TextareaAutosize
      style={{
        border: 'none', maxWidth: 300, minWidth: 100, background: '#f8f8f8', padding: 4,
      }}
      defaultValue={props.defaultValue}
      onChange={debounce((e) => props.onChange(e.target.value), 1000)}
    />
  );
}

function typeCell(type: IntrospectionOutputTypeRef) {
  if (type.kind === 'NON_NULL') {
    return <TypeWrapper color={colors.brand.red2}>{typeCell(type.ofType)}!</TypeWrapper>;
  }
  if (type.kind === 'LIST') {
    return <TypeWrapper color={colors.brand.orange2}>[{typeCell(type.ofType)}]</TypeWrapper>;
  }
  if (type.kind === 'OBJECT') {
    return <TypeWrapper>
      <Link href={`#${type.name}`} style={{ textDecoration: 'none' }}>{type.name}</Link>
    </TypeWrapper>;
  }
  if (type.kind === 'SCALAR') {
    return <TypeWrapper color={colors.brand.gray1}>{type.name}</TypeWrapper>;
  }

  return <TypeWrapper color={colors.brand.green2}>{type.kind}</TypeWrapper>;
}

function TypeWrapper(props: PropsWithChildren<{ color?: string }>) {
  return (
    <Typography
      component="span"
      sx={{ color: props.color, fontSize: '0.8rem' }}
    >{props.children}</Typography>
  );
}

function RowLoader() {
  return <Box sx={{
    position: 'absolute',
    top: 8,
    right: 10,
  }}><CircularProgress size={20} /></Box>;
}
