import React from 'react';
import { PartDialogQuery } from '../../../../../../generated/graphql';
import { DenseFieldList, DenseFieldListRow } from '../../common/DenseFieldList';
import {
  FieldBoolean, FieldDatetime, FieldEnum, FieldString,
} from '../../common/FieldValues';

type Part = PartDialogQuery['parts'][0];

interface Props {
  part?: Part
}

export function FieldList({ part }: Props) {
  return (
    <DenseFieldList>
      <DenseFieldListRow name="partNumber">
        <FieldString value={part?.partNumber} />
      </DenseFieldListRow>
      <DenseFieldListRow name="name">
        <FieldString value={part?.name} />
      </DenseFieldListRow>
      <DenseFieldListRow name="assignmentNumber">
        <FieldString value={part?.assignmentNumber} />
      </DenseFieldListRow>
      <DenseFieldListRow name="project">
        <FieldString value={part?.project} />
      </DenseFieldListRow>
      <DenseFieldListRow name="docDuty">
        <FieldString value={part?.docDuty} />
      </DenseFieldListRow>
      <DenseFieldListRow name="kuResponsible">
        <FieldString value={part?.kuResponsible} />
      </DenseFieldListRow>
      <DenseFieldListRow name="taskNumber">
        <FieldString value={part?.taskNumber} />
      </DenseFieldListRow>
      <DenseFieldListRow name="taskStatus">
        <FieldString value={part?.taskStatus} />
      </DenseFieldListRow>
      <DenseFieldListRow name="costCarrier">
        <FieldString value={part?.costCarrier} />
      </DenseFieldListRow>
      <DenseFieldListRow name="designOwner">
        <FieldString value={part?.designOwner} />
      </DenseFieldListRow>
      <DenseFieldListRow name="description">
        <FieldString value={part?.description} />
      </DenseFieldListRow>
      <DenseFieldListRow name="partType">
        <FieldString value={part?.partType} />
      </DenseFieldListRow>
      <DenseFieldListRow name="partTypeDescription">
        <FieldString value={part?.partTypeDescription} />
      </DenseFieldListRow>
      <DenseFieldListRow name="unit">
        <FieldString value={part?.unit} />
      </DenseFieldListRow>
      <DenseFieldListRow name="aWeight">
        <FieldString value={part?.aWeight} />
      </DenseFieldListRow>
      <DenseFieldListRow name="weightType">
        <FieldString value={part?.weightType} />
      </DenseFieldListRow>
      <DenseFieldListRow name="iWeight">
        <FieldString value={part?.iWeight} />
      </DenseFieldListRow>
      <DenseFieldListRow name="quantityWeighed">
        <FieldString value={part?.quantityWeighed} />
      </DenseFieldListRow>
      <DenseFieldListRow name="typeApproved">
        <FieldString value={part?.typeApproved} />
      </DenseFieldListRow>
      <DenseFieldListRow name="cccMarking">
        <FieldString value={part?.cccMarking} />
      </DenseFieldListRow>
      <DenseFieldListRow name="reqCode">
        <FieldString value={part?.reqCode} />
      </DenseFieldListRow>
      <DenseFieldListRow name="healthRegulations">
        <FieldBoolean value={part?.healthRegulations} />
      </DenseFieldListRow>
      <DenseFieldListRow name="homologationNo">
        <FieldString value={part?.homologationNo} />
      </DenseFieldListRow>
      <DenseFieldListRow name="masteredBy">
        <FieldString value={part?.masteredBy} />
      </DenseFieldListRow>
      <DenseFieldListRow name="sparePart">
        <FieldString value={part?.sparePart} />
      </DenseFieldListRow>
      <DenseFieldListRow name="alternateId">
        <FieldString value={part?.alternateId} />
      </DenseFieldListRow>
      <DenseFieldListRow name="colourCode">
        <FieldString value={part?.colourCode} />
      </DenseFieldListRow>
      <DenseFieldListRow name="colour">
        <FieldString value={part?.colour} />
      </DenseFieldListRow>
      <DenseFieldListRow name="colourStatus">
        <FieldEnum value={part?.colourStatus} />
      </DenseFieldListRow>
      <DenseFieldListRow name="createdAt">
        <FieldDatetime value={part?.createdAt} />
      </DenseFieldListRow>
      <DenseFieldListRow name="lastModified">
        <FieldDatetime value={part?.lastModified} />
      </DenseFieldListRow>
    </DenseFieldList>
  );
}
