import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ErrorMessage from '../../common/ErrorMessage';
import { useQuantityQualityCheck2LazyQuery } from '../../../generated/graphql';
import { QualityCheckResultTable } from './QuantityCheckResultTable';
import { Input, QuantityCheckForm } from './QuantityCheckForm';

interface Props {
  defaultValues: Input;
}
export function QuantityCheck(props: Props) {
  const [input, setInput] = React.useState<Input>(props.defaultValues);

  const [call, {
    data, error, loading, variables,
  }] = useQuantityQualityCheck2LazyQuery({});

  return (
    <>

      <Grid container spacing={1} wrap="nowrap">
        <Grid item sx={{ minWidth: 160 }}>
          <QuantityCheckForm
            values={input}
            onChange={(v) => setInput({ ...input, ...v })}
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          {!objectsAreEqual(input, variables) && (
            <Box p={3} pt={0} textAlign="center">
              <LoadingButton variant="contained" onClick={() => call({
                variables: input,
              })} loading={loading}>
                Run quantity quality check
              </LoadingButton>
            </Box>
          )}
          <Paper variant="outlined">
            {loading && <LinearProgress/>}
            <ErrorMessage error={error}></ErrorMessage>

            {data && (
              <QualityCheckResultTable errors={data.quantityQualityCheck2.errors}/>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function objectsAreEqual(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}
