import React from 'react';
import Box from '@mui/material/Box';
import { ReactMarkdownWithSyntaxHighlighting } from '../../../common/ReactMarkdownWithSyntaxHighlighting';

const Md = ReactMarkdownWithSyntaxHighlighting;

export function RevokedUsagesPage() {
  return (
    <>
      <h1>Revoked Usages</h1>

      <Box mb={5}>

        <Md content={`

**Data cleansing**
 
 Part Usages that have been marked as revoked and have had no consumption records for more than 4 weeks will be deleted.


*Last modified: 2024-09-04*

        `} />

      </Box>
    </>
  );
}
