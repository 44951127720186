/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { LinearProgress } from '@mui/material';
import {
  CartesianGrid,
  Legend,
  Line, LineChart,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { formatXAxis, randomColor } from './graph.utils';

interface LineGraphProps {
  data: any[];
  loading?: boolean;
  error?: any;
  xDataKey: string;
  yDataKey: string[];
  period: string
  limit?: number
}

export function MultiLineGraph(props: LineGraphProps) {
  if (props.loading) {
    return (
      <ResponsiveContainer width='100%' aspect={8.0 / 3.0}>
        <LineChart data={[]}>
          <XAxis dataKey={props.xDataKey} tickFormatter={() => ''}/>
          <YAxis/>
          <LinearProgress></LinearProgress>
          <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
          <Legend></Legend>
        </LineChart>
      </ResponsiveContainer>
    );
  }

  if (props.error || !props.data) {
    return <div> error </div>;
  }

  return (
    <ResponsiveContainer width='100%' aspect={8.0 / 3.0}>
      <LineChart data={props.data}>
        <XAxis dataKey={props.xDataKey} tickFormatter={(tick) => formatXAxis(tick)}/>
        <YAxis/>
        <CartesianGrid stroke='#eee' strokeDasharray='5 5'/>
        {props.yDataKey.map((key, i) => (
          <Line type="monotone" key={key} stroke={randomColor(i)} dataKey={key}/>
        ))}
        <Tooltip></Tooltip>
        <Legend></Legend>
      </LineChart>
    </ResponsiveContainer>
  );
}
