export function downloadStringAsFile(content: string, filename: string, type = 'text/csv') {
  const myBlob = new Blob([content], { type });

  const url = window.URL.createObjectURL(myBlob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;

  anchor.click();
  window.URL.revokeObjectURL(url);
  anchor.remove();
}
