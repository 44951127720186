/* eslint-disable */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { SwBaselinePartUsageInfoFragment } from '../../../../../../generated/graphql';
import { ValueText } from '../../parts/PartDialog/valueText';
import { LargePartDialogButton } from '../../common/dialog-buttons/PartDialogButton';

interface Props {
  baselinePartUsages?: SwBaselinePartUsageInfoFragment[]
}

export function SwBaselinePartUsageTable(props: Props) {
  const { baselinePartUsages } = props;

  if (!baselinePartUsages) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="none"></TableCell>
            <TableCell>Part version</TableCell>
            <TableCell>Software part</TableCell>
            <TableCell>Usage rules</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {baselinePartUsages.map((bpu, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="none" sx={{ pl: 1 }}>
              </TableCell>
              <TableCell>{ValueText(bpu.partVersion.versionId)}</TableCell>
              <LargePartDialogButton
              part={bpu.partVersion.softwarePart}
              showName
            />
              <TableCell>
                {bpu.usageRules && bpu.usageRules.length > 0 ? (
                  bpu.usageRules.map((ur, index) => (
                    <div key={index}>
                      {ValueText(ur.varCodes)}
                    </div>
                  ))
                ) : (
                  ValueText('This softwarePart has no usage rules')
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
