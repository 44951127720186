import React from 'react';
import { IntegrationList } from '../control-panel/IntegrationList';
import { IntegrationTabs } from '../control-panel/IntegrationTabs';
import { EasterEgg } from '../control-panel/EasterEgg';
import { HelpSection } from '../control-panel/HelpSection';
import { PreProdHelpSection } from '../control-panel/PreProdHelpSection';
import { Section } from '../layout/Section';

export function MyApplicationsPage() {
  return (
    <>
      <PreProdHelpSection />
      <Section>
        <HelpSection/>
      </Section>
      <IntegrationList/>
      <Section>
        <IntegrationTabs />
      </Section>
      <EasterEgg/>
    </>
  );
}
