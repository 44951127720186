/* eslint-disable no-param-reassign */
import React, { PropsWithChildren } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  OperationTypeDistributionByTeamResult,
  OperationStatusDistributionResult,
} from '../../../generated/graphql';
import { colors } from '../../../services/mui-theme';

interface UsageMetricProps {
  period: { l: string, v: string }
  teamOperationDistribution?: OperationTypeDistributionByTeamResult[]
  operationStatusDistribution?: OperationStatusDistributionResult[]
}

export default function UsageMetric(props: UsageMetricProps) {
  const activeIntegrations = (props.teamOperationDistribution || []).reduce((r, a) => {
    r[a.teamName] = r[a.teamName] || 0;
    r[a.teamName] += a.count;
    return r;
  }, Object.create({}));

  const activeIntegrationsCount = Object.keys(activeIntegrations).length;
  const requestResolvedEvent = props.operationStatusDistribution?.find((x) => x.eventName === 'RequestResolved');
  const requestErrorEvent = props.operationStatusDistribution?.find((x) => x.eventName === 'RequestError');

  return (
    <Grid
      container
      my={2}
      spacing={2}
    >
      <Grid item sm={4}>
        <ColoredBox color={colors.brand.orange1}>
          <LargeNumber number={activeIntegrationsCount} />
          <Typography>Active apps <br/>in past {props.period.l}</Typography>
        </ColoredBox>
      </Grid>
      <Grid item sm={4}>
        <ColoredBox color={colors.brand.green2}>
          <LargeNumber number={requestResolvedEvent?.count || 0} />
          <Typography>Successful requests <br/>in past {props.period.l}</Typography>
        </ColoredBox>
      </Grid>
      <Grid item sm={4}>
        <ColoredBox color={colors.brand.red2}>
          <LargeNumber number={requestErrorEvent?.count || 0} />
          <Typography>Request errors <br/>in past {props.period.l}</Typography>
        </ColoredBox>
      </Grid>
    </Grid>
  );
}

function ColoredBox(props: PropsWithChildren<{ color: string }>) {
  return (
    <Box
      textAlign="right"
      p={2}
      height="100%"
      sx={{
        background: props.color,
        fontWeight: 'bold',
        color: 'white',
      }}
    >
      {props.children}
    </Box>
  );
}

function LargeNumber(props: { number: number }) {
  return (
    <Typography
      fontSize={60}
      lineHeight={1.2}
    >{props.number.toLocaleString('sv-SE')}</Typography>
  );
}
