/* eslint-disable no-underscore-dangle */

import * as React from 'react';
import { Voyager } from 'graphql-voyager';
import Alert from '@mui/material/Alert';
import { useSelectedEnvironment } from '../../../hooks/useSelectedEnvironment';
import { filterIntrospection } from '../../../util/filterIntrospection';

import 'graphql-voyager/dist/voyager.css';
import { currentAuth } from '../../../hooks/useAuth';

export function GraphExplorer() {
  const [selectedEnv] = useSelectedEnvironment();
  const [error, setError] = React.useState<Error | unknown | null>();
  const auth = currentAuth();

  const introspectionProvider = async (query: string) => {
    try {
      const result = await fetch(selectedEnv.graphQLEndpoint, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          authorization: auth ? `Bearer ${auth.idToken}` : '',
        },
        body: JSON.stringify({ query }),
      }).then((response) => response.json());

      if (result.errors && result.errors[0].message) {
        setError(result.errors[0].message);
        return null;
      }

      setError(null);

      return {
        data: {
          __schema: filterIntrospection(result.data.__schema),
        },
      };
    } catch (e: unknown) {
      setError(e);
      return null;
    }
  };

  return (
    <>
      {error && <Alert severity="error">{`${error}`}</Alert>}
      <Voyager
        displayOptions={{
          hideRoot: true,
        }}
        introspection={introspectionProvider}
      />
    </>
  );
}
