import React from 'react';
import { Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import {
  FieldBoolean, FieldDatetime, FieldEnum, FieldFloat, FieldInteger, FieldString,
} from './FieldValues';

export function FieldStringWithTooltip(props: {
  value: string | null | undefined,
  title: string,
  placement?: TooltipProps['placement']
}) {
  return (
    <Tooltip title={props.title} placement={props.placement}>
      <span><FieldString value={props.value} /></span>
    </Tooltip>
  );
}

export function FieldIntegerWithTooltip(props: {
  value: number | null | undefined,
  title: string,
  placement?: TooltipProps['placement']
}) {
  return (
    <Tooltip title={props.title} placement={props.placement}>
      <span><FieldInteger value={props.value} /></span>
    </Tooltip>
  );
}

export function FieldFloatWithTooltip(props: {
  value: number | null | undefined,
  title: string,
  placement?: TooltipProps['placement']
}) {
  return (
    <Tooltip title={props.title} placement={props.placement}>
      <span><FieldFloat value={props.value} /></span>
    </Tooltip>
  );
}

export function FieldDatetimeWithTooltip(props: {
  value: string | null | undefined,
  title: string,
  placement?: TooltipProps['placement']
}) {
  return (
    <Tooltip title={props.title} placement={props.placement}>
      <span><FieldDatetime value={props.value} /></span>
    </Tooltip>
  );
}

export function FieldBooleanWithTooltip(props: {
  value: boolean | null | undefined,
  title: string,
  placement?: TooltipProps['placement']
}) {
  return (
    <Tooltip title={props.title} placement={props.placement}>
      <span><FieldBoolean value={props.value} /></span>
    </Tooltip>
  );
}

export function FieldEnumWithTooltip(props: {
  value: string | null | undefined,
  title: string,
  placement?: TooltipProps['placement']
}) {
  return (
    <Tooltip title={props.title} placement={props.placement}>
      <span><FieldEnum value={props.value} /></span>
    </Tooltip>
  );
}
