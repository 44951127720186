import Box from '@mui/material/Box';
import React from 'react';
import { MfgEffectivityInfoFragment } from '../../../../../../generated/graphql';

export function EffectivityList(props: {
  effectivities: Array<MfgEffectivityInfoFragment>
}) {
  return (
    <Box sx={{
      '& ul': {
        paddingLeft: 2,
        margin: 0,
        fontSize: '0.875rem',
      },
    }} className="font-monospace">
      <ul>
        {props.effectivities.map((effectivity, i) => (
          <li key={i}>
            <Box component="span">
              {effectivity.configurationElement.endItemId}
              &nbsp;
            </Box>
            <Box component="span" color="info.dark">
              {effectivity.effectiveIn}&nbsp;{effectivity.issueNumberAdd ? `(${effectivity.issueNumberAdd})` : ''}
              &nbsp;
            </Box>
            -&nbsp;
            <Box component="span" color="error.dark">
              {effectivity.effectiveOut}&nbsp;{effectivity.issueNumberDel ? `(${effectivity.issueNumberDel})` : ''}
            </Box>
          </li>
        ))}
      </ul>
    </Box>
  );
}
