/* eslint-disable max-len */
import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useSelectedEnvironment } from '../../hooks/useSelectedEnvironment';
import { CodeWrapper } from '../common/CodeWrapper';
import { RoutedButton, RoutedLink } from '../common/Links';

export function GettingStartedText() {
  return (
    <>
      Start with creating an <em>Application Group</em> for your <em>Application Keys</em>.
      If someone else in your team has already created an <em>Application Group</em> you
      can request join that <em>Application Group</em>. <br/>
      If not then you should request for a new <em>Application Group</em> to be created.
      The PRINS team then will review and approve the requests.
    </>
  );
}

export function EnvironmentsText() {
  return (
    <>
      The data in all environments are separated.
      You need to create an <em>Application Group</em> and <em>Application Key</em> for each environment that you want to use.
    </>
  );
}

export function DocsLink() {
  return (
    <>
      <Box display="flex" justifyContent="center">
        <RoutedButton to="/documentation">
          Learn more about GraphHub and GraphQL in our documentation
        </RoutedButton>
      </Box>
    </>
  );
}

export function ManageYourKeysText() {
  return (
    <>
      When the your account has been added to the <em>Application Group</em>,
      it will be listed under <em>My Application Group</em>. <br/>

      Click on the integration to expand the view and display your keys.
      To create a new key, click on “CREATE NEW APP KEY” and give it a description.
      Make sure to store your key in a secure place
      since it will not be able to see it again.
    </>
  );
}

export function UseYourKeyText() {
  const [env] = useSelectedEnvironment();
  const { graphQLEndpoint } = env;

  return (
    <>
      Once you have created a key you can use it to query the GraphQL API at “<em>{graphQLEndpoint}</em>” <br/>
      together with an authorization header
      <CodeWrapper>Authorization: <strong>AppKey XXXXXXXXXXXXXXXXXXXXXXXXX:XXXXXXXXXXXXXXXXXXXXXX</strong></CodeWrapper>
      You can try this out and play around with the API
      in <Link
        href={`https://studio.apollographql.com/sandbox?endpoint=${encodeURI(graphQLEndpoint)}`}
        target='_blank'
      >Apollo Studio</Link>
      <br/>
      <br/>
      More info on how to use the api can be found in the <RoutedLink
      to="/documentation">PRINS GraphHub API Documentation</RoutedLink>
    </>
  );
}
