import { useEffect, useState } from 'react';

export function useStaticFile(file: string) {
  const [content, setContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    fetch(file).then(async (result) => {
      setContent(await result.text());
      setLoading(false);
    }).catch(() => {
      setError('Unable to load content');
    });
  }, [file]);

  return {
    content,
    loading,
    error,
  };
}
