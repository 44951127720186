import { TypographyOptions } from '@mui/material/styles/createTypography';

export const BOLD = 500;
export const REGULAR = 'normal';
export const SEMILIGHT = 300;

export const typography: TypographyOptions = {
  // https://design.volvocars.com/type/principles/
  h1: {
    fontSize: 40,
    fontWeight: BOLD,
    lineHeight: 1.125,
  },
  h2: {
    fontSize: 24,
    fontWeight: REGULAR,
    lineHeight: 1.125,
  },
  h3: {
    fontSize: 20,
    fontWeight: REGULAR,
    lineHeight: 1.125,
  },
  h4: {
    fontSize: 18,
    fontWeight: REGULAR,
    lineHeight: 1.125,
  },
  h5: {
    fontSize: 16,
    fontWeight: REGULAR,
    lineHeight: 1.125,
  },
  body1: {
    fontWeight: SEMILIGHT,
    lineHeight: 1.5,
  },
  body2: {
    fontWeight: SEMILIGHT,
    lineHeight: 1.5,
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '0.9em',
  },
  subtitle2: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: BOLD,
  },
  fontFamily: "'Volvo Novum 2', Arial, sans-serif",
};
