/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Collapse from '@mui/material/Collapse';
import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';

interface DataTreeRowProps {
  header: React.ReactNode | string
  suffix?: React.ReactNode | string
  defaultExpanded?: boolean
}

export function DataTreeRow(props: PropsWithChildren<DataTreeRowProps>) {
  const { header, children, suffix } = props;
  const [expanded, setExpanded] = useState(!!props.defaultExpanded);

  return (
    <Box
      className="DataTreeRow"
      sx={{
        position: 'relative',
        '& .DataTreeRow:before': {
          display: 'block',
          content: '""',
          width: 15,
          position: 'absolute',
          top: 15,
          left: -10,
          borderTop: '1px dashed #ccc',
        },
      }}
    >
      <ButtonBase onClick={() => setExpanded(!expanded)} sx={{
        px: 1,
        py: 0.5,
        width: '100%',
        justifyContent: 'left',
      }}>
        {!isEmpty(children) ? (
          <>{expanded ? <ExpandMoreIcon/> : <ChevronRightIcon/>}</>
        ) : (
          <>{<ChevronRightIcon color="disabled"/>}</>
        )}
        {header}
        <Box sx={{ flexGrow: 1 }}/>
        {suffix && (<Box sx={{ opacity: 0.5, ml: 1 }}>{suffix}</Box>)}
      </ButtonBase>
      <Collapse in={expanded && !isEmpty(children)} unmountOnExit>
        <Box sx={{
          ml: 2,
          pl: 2,
          mb: 1,
          position: 'relative',
          '&:before': {
            display: 'block',
            content: '""',
            bottom: 15,
            position: 'absolute',
            top: 0,
            left: 3,
            borderLeft: '1px dashed #ccc',
          },
        }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
}
