import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Warning from '@mui/icons-material/Warning';
import dayjs, { Dayjs } from 'dayjs';
import { useDataIssuesQuery } from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { Section } from '../../layout/Section';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { DataIssueTable } from './DataIssue/DataIssuesTable';
import { MyDatePicker } from './DatePicker';
import { BomInstanceSelect } from '../../documentation/pages/browser/bom/common/BomInstanceSelect';

export function DataIssuesDashboard() {
  const [topNode, setTopNode] = React.useState<string | undefined>(undefined);
  const [from, setFrom] = React.useState<Dayjs>(
    dayjs().subtract(7, 'day').startOf('day'),
  );

  const { data, loading, error } = useDataIssuesQuery({
    variables: {
      from: from?.toISOString(),
      statuses: [
        'New',
        'Ignore',
        'Resolved',
        'WIP',
      ],
      topNode: topNode || undefined,
    },
  });

  return (
    <>
      <Section maxWidth={1920}>
        <Card>
          <SectionCardHeader
            avatar={<Warning color="primary"/>}
            title="Data issues"
            action={(
              <div>
              <Stack direction="row" spacing={1} sx={{
                '& > *': {
                  minWidth: 170,
                },
                '& .MuiInputBase-root': {
                  background: 'white',
                },
              }}>
                <BomInstanceSelect onSelect={(v) => setTopNode(v || undefined)} size="small" />
                <MyDatePicker value={from} setValue={setFrom} />
              </Stack>
              </div>
            )}
          />
          {loading ? <LinearProgress /> : null}
          <ErrorMessage error={error} />
          <DataIssueTable data={data?.user.dataIssues} />
        </Card>
      </Section>
    </>
  );
}
