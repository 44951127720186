/* eslint-disable */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { UsedInAssembliesInfoFragment, PartInfoFragment } from '../../../../../../generated/graphql';
import { FieldInteger, FieldString, FieldTypename } from "../../common/FieldValues";
import { FieldCoi } from "../../common/FieldCoi";
import { LargePartDialogButton } from "../../common/dialog-buttons/PartDialogButton";

// type Part = { PartDialogQuery['parts'][0] | SoftwarePartDialogQuery['softwareParts'][0] };

interface Props {
  part?: UsedInAssembliesInfoFragment | null
}

export function UsedInAssembliesTable(props: Props) {
  const { part } = props;

  if (!part) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">POST</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>hardwareOrSoftwareAssembly</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {part.usedInAssemblies.map((c, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <FieldInteger alignRight value={c.POST} />
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={c.coiNumberAdd}
                  coiNumberTooltip="usedInAssemblies.coiNumberAdd"
                  week={c.effectiveInWeek}
                  weekTooltip="usedInAssemblies.effectiveInWeek"
                />
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={c.coiNumberDel}
                  coiNumberTooltip="usedInAssemblies.coiNumberDel"
                  week={c.effectiveOutWeek}
                  weekTooltip="usedInAssemblies.effectiveOutWeek"
                />
              </TableCell>
              <TableCell>
                <LargePartDialogButton
                  part={c.hardwareOrSoftwareAssembly}
                  showName
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
