import React from 'react';
import { Paper } from '@mui/material';

export function ColoredPaper(props: {
  borderColor: string,
  backgroundColor?: string,
  children: React.ReactNode
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        px: 2,
        py: 1,
        mb: 1.5,
        mt: 0.5,
        borderColor: props.borderColor,
        backgroundColor: props.backgroundColor,
        borderBottomWidth: 4,
        borderRightWidth: 2,
        borderRadius: '8px',
      }}
    >
      {props.children}
    </Paper>
  );
}
