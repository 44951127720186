/* eslint-disable @typescript-eslint/no-explicit-any */
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React from 'react';

const prettyJson = (v: any) => JSON.stringify(v, null, 2);

export function PrettyJson(props: { data: any }) {
  return (
    <SyntaxHighlighter
      children={String(prettyJson(props.data)).replace(/\n$/, '')}
      language="json"
      style={materialLight}
      PreTag="div"
    />
  );
}
