// Edit here: https://mermaid.live/edit

export const paginationSequenceDiagramWebhooks = `
sequenceDiagram
participant C as Client app
participant S as GraphQL server

C->>S: query PaginatedParts(offset=0, limit=20)
S-->>C: Returns the first 20 list elements

C->>S: query PaginatedParts(offset=20, limit=10)
S-->>C: Returns the next 10 list elements

`;
