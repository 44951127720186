import React from 'react';
import {
  Menu, MenuItem, Tooltip,
} from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import DangerousIcon from '@mui/icons-material/Dangerous';
import PendingIcon from '@mui/icons-material/Pending';
import IconButton from '@mui/material/IconButton';
import {
  AdminApplicationDetailsFragment, useAdminApplicationSetRoleMutation, ValidAppRole,
} from '../../../generated/graphql';
import { EnvironmentOption } from '../../../util/envrionments';

interface Props {
  application: AdminApplicationDetailsFragment;
  onComplete: () => void;
  env: EnvironmentOption;
}

export function ToggleRole(props: Props) {
  const [update] = useAdminApplicationSetRoleMutation();

  const setRole = (role: ValidAppRole) => {
    handleClose();
    update({
      variables: {
        appId: props.application.appId,
        role,
        environment: props.env.environment,
      },
    }).then(() => props.onComplete());
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentRole = props.application.roles.find((r) => {
    return r.environment === props.env.environment;
  });

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        {currentRole?.role === 'upstream' && (
          <Tooltip title={`${props.env.label} | Upstream`}>
            <ArrowCircleUpIcon color="warning"/>
          </Tooltip>
        )}
        {currentRole?.role === 'downstream' && (
          <Tooltip title={`${props.env.label} | Downstream`}>
            <ArrowCircleDownIcon color="primary"/>
          </Tooltip>
        )}
        {currentRole?.role === 'disabled' && (
          <Tooltip title={`${props.env.label} | Disabled`}>
            <DangerousIcon color="error"/>
          </Tooltip>
        )}
        {!currentRole && (
          <Tooltip title={`${props.env.label} | Not set`}>
            <PendingIcon color="disabled"/>
          </Tooltip>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setRole(ValidAppRole.Upstream)}>Upstream</MenuItem>
        <MenuItem onClick={() => setRole(ValidAppRole.Downstream)}>Downstream</MenuItem>
        <MenuItem onClick={() => setRole(ValidAppRole.Disabled)}>Disabled</MenuItem>
      </Menu>
    </>
  );
}
