import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  PartUsageEbomDataFragment,
} from '../../../../../../generated/graphql';
import { FieldCoi } from '../../common/FieldCoi';
import { FieldInteger } from '../../common/FieldValues';
import { LargePartDialogButton } from '../../common/dialog-buttons/PartDialogButton';

interface PartUsageTableProps {
  partUsages: PartUsageEbomDataFragment[]
}

export function PartUsageTable(props: PartUsageTableProps) {
  const { partUsages } = props;

  return (
    <Box mb={1}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">POST</TableCell>
              <TableCell align="right">quantity</TableCell>
              <TableCell>part</TableCell>
              <TableCell>add</TableCell>
              <TableCell>del</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partUsages.map((pu, index) => (
              <TableRow
                key={index}
                className={pu.isRevoked ? 'revoked' : ''}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  // Strikethrough and change opacity when isRevoked
                  '&.revoked': {
                    opacity: 0.5,
                    textDecoration: 'line-through',
                  },
                }}
              >
                <TableCell>
                  <FieldInteger value={pu.POST} alignRight />
                </TableCell>
                <TableCell>
                  <FieldInteger value={pu.quantity} alignRight />
                </TableCell>
                <TableCell>
                  <LargePartDialogButton part={pu.hardwareOrSoftwarePart || pu.deliveryModule} />
                </TableCell>
                <TableCell>
                  <FieldCoi
                    coiNumber={pu.coiNumberAdd}
                    coiNumberTooltip={'.coiNumberAdd'}
                    week={pu.effectiveInWeek}
                    weekTooltip={'.effectiveInWeek'}
                  />
                </TableCell>
                <TableCell>
                  <FieldCoi
                    coiNumber={pu.coiNumberDel}
                    coiNumberTooltip={'.coiNumberDel'}
                    week={pu.effectiveOutWeek}
                    weekTooltip={'.effectiveOutWeek'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
