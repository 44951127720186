import { Chip, Tooltip } from '@mui/material';
import React from 'react';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

export function ValueText(value: string | number | null | undefined | boolean) {
  if (value === undefined) {
    return <span>...</span>; // loading
  }

  if (value === '') {
    return <Chip label="empty string" size="small" variant="outlined" sx={{ borderRadius: '4px' }}/>;
  }

  if (value === null) {
    return <Chip label="NULL" size="small" variant="outlined" sx={{ borderRadius: '4px' }} color="warning"/>;
  }

  if (value === true) {
    return <Chip label="true" size="small" variant="outlined" sx={{ borderRadius: '4px' }} color="success"/>;
  }

  if (value === false) {
    return <Chip label="false" size="small" variant="outlined" sx={{ borderRadius: '4px' }} color="error"/>;
  }

  // Need some element for a wrapping tooltip to work
  return <span>{value}</span>;
}

interface ValueTooltipProps {
  value: string | number | null | undefined | boolean,
  title: string,
  placement: TooltipProps['placement']
}

export function ValueTooltip(props: ValueTooltipProps) {
  return (
    <Tooltip title={props.title} placement={props.placement}>{ValueText(props.value)}</Tooltip>
  );
}
