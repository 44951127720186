import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { StoredCpvSetsTable } from '../../documentation/pages/browser/cpv-sets/CpvSetsTable';
import { StoredCpvSetWhere } from '../../../generated/graphql';
import { StoredCpvSetFilter } from '../../documentation/pages/browser/cpv-sets/StoredCpvSetsFilter';

export function StoredCpvSetsBrowserPage() {
  const [where, setWhere] = useState<StoredCpvSetWhere>({});

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <StoredCpvSetFilter onChange={setWhere}/>
      <Box flexGrow={1}>
        <StoredCpvSetsTable where={where}/>
      </Box>
    </Box>
  );
}
