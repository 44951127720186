import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { getIntrospectionQuery } from 'graphql';
import {
  IntrospectionObjectType,
  IntrospectionQuery,
  IntrospectionType,
} from 'graphql/utilities/getIntrospectionQuery';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { filterIntrospection } from '../../util/filterIntrospection';
import { TypeTable } from '../documentation/pages/model/TypeTable';
import { useWikiEntriesQuery } from '../../generated/graphql';
import ErrorMessage from '../common/ErrorMessage';

export function FieldListPage() {
  const { data, error: error1 } = useQuery<IntrospectionQuery>(gql(getIntrospectionQuery()));
  const { data: wikiData, error: error2 } = useWikiEntriesQuery();

  if (!data) {
    return <Box m={4} textAlign="center"><CircularProgress/></Box>;
  }

  const wikiEntries = wikiData ? Object.assign({}, ...wikiData.wikiEntries.map((x) => ({
    [x.key]: x,
  }))) : null;

  const types = filterIntrospection(data.__schema)
    .types.filter(typesFilter) as IntrospectionObjectType[];

  return (
    <>
      <ErrorMessage error={error1}/>
      <ErrorMessage error={error2}/>
      {types.map((t, i) => (
        <Box key={i} my={4}>
          <TypeTable type={t} wikiEntries={wikiEntries}/>
        </Box>
      ))}
    </>
  );
}

function typesFilter(t: IntrospectionType) {
  if (t.kind !== 'OBJECT') {
    return false;
  }

  // Only show those that have id since these are the entities in PRINS
  if (!t.fields.find((f) => f.name === 'id')) {
    return false;
  }

  return true;
}
