import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  ButtonBase, Menu, MenuItem,
} from '@mui/material';
import { useMeQuery } from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { CurrentUserAvatar } from './CurrentUserAvatar';
import { useAuth } from '../../../hooks/useAuth';

export function CurrentUser() {
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        mx: 2,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <ButtonBase
        onClick={handleClick}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Box sx={{ display: { xs: 'none', sm: 'block' } }} pr={2} textAlign="right">
          <UserName/>
        </Box>
        <Box>
          <CurrentUserAvatar/>
        </Box>
      </ButtonBase>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ minWidth: 150 }} onClick={logout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}

function UserName() {
  const { data: userData } = useAuth();
  const { data, loading, error } = useMeQuery({
    skip: !userData,
  });

  if (error) {
    return <ErrorMessage error={error}/>;
  }

  if (!data || loading) {
    return (
      <div>Loading…</div>
    );
  }

  return (
    <>
      <Typography fontSize={16} component="div">
        {data.user?.me?.personalName}
      </Typography>
      <Typography fontSize={12} color="text.secondary">
        {data.user?.me?.username}
      </Typography>
    </>
  );
}
