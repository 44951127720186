import React from 'react';
import {
  TestSeriesConsumptionsTable,
} from '../../documentation/pages/browser/test-series-consumptions/TestSeriesConsumptionsTable';

export function TestSeriesConsumptionsBrowserPage() {
  return (
    <>
      <TestSeriesConsumptionsTable/>
    </>
  );
}
