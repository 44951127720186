import React from 'react';
import Box from '@mui/material/Box';
import { Alert, CircularProgress } from '@mui/material';
import { useAdminApplicationQuery } from '../../../generated/graphql';
import { ApplicationUsersTable } from '../../control-panel/IntegrationInfo/ApplicationUsersTable';

export function AdminApplicationUsersTable(props: { appId: string }) {
  const { data, loading, refetch } = useAdminApplicationQuery({
    variables: {
      appId: props.appId,
    },
  });

  if (loading) {
    return <CircularProgress/>;
  }

  const users = data?.admin.application?.users;

  if (!users?.length) {
    return <Alert severity="warning">This group has no users</Alert>;
  }

  return (
    <Box>
      <ApplicationUsersTable users={users} refresh={refetch}/>
    </Box>
  );
}
