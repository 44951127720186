import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React, { useState } from 'react';
import {
  ApplicationDetailsForOperationFragment,
  OperationDetailsFragment,
} from '../../../generated/graphql';
import { UserNotFound } from '../../common/UserNotFound';
import { dateTimeFormat } from '../../../util/date';
import { ApplicationOperationEditButton } from './ApplicationOperationEditButton';
import { ApplicationOperationDeleteButton } from './ApplicationOperationDeleteButton';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';
import { getComparator } from '../../common/sortTables/comparators';

interface Props {
  application: ApplicationDetailsForOperationFragment,
  operations: OperationDetailsFragment[],
  refresh: () => void,
}

export function ApplicationOperationsTable(props: Props) {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof OperationDetailsFragment>('lastModified');
  const { operations, refresh } = props;

  if (operations.length === 0) {
    return (
      <Alert severity="warning">
        You have not registered any operations yet
      </Alert>
    );
  }

  const sortedRows = operations.slice().sort(
    getComparator<OperationDetailsFragment>(order, orderBy),
  );

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(property, direction) => {
              setOrder(direction);
              setOrderBy(property);
            }}
            refetch={refresh}
            headCells={headCells}
          />
          <TableBody>
            {sortedRows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.impactLevel}
                </TableCell>
                <TableCell>
                  {row.modifiedByUser?.name || <UserNotFound/>}<br/>
                </TableCell>
                <TableCell>
                  {dateTimeFormat(row.lastModified)}
                </TableCell>
                <TableCell>
                  {row.draft ? 'Draft' : 'Active'}
                </TableCell>
                <TableCell padding="none" align="right" sx={{ whiteSpace: 'nowrap' }}>
                  <ApplicationOperationEditButton
                    application={props.application}
                    operation={row}
                    onComplete={refresh}
                  />
                  <ApplicationOperationDeleteButton id={row.id}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'impactLevel',
    label: 'Impact Level',
  },
  {
    id: 'modifiedByUser.name',
    label: 'Modified by',
  },
  {
    id: 'lastModified',
    label: 'Modified',
  },
  {
    id: '',
    label: '',
    isSortable: false,
  },
];
