import React from 'react';
import {
  Autocomplete,
  TextField, TextFieldProps,
} from '@mui/material';
import { ControllerFieldState, Noop, RefCallBack } from 'react-hook-form/dist/types';
import { config } from '../../../config/config';
import { EnvironmentOption } from '../../../util/envrionments';

interface Props {
  size?: 'small' | 'medium'
}

/**
 * Environment input element
 */

interface Props {
  onChange: (value: EnvironmentOption) => void,
  inputValue?: string,
  onBlur?: Noop; // From a controller
  value?: EnvironmentOption; // From a controller
  name?: string; // From a controller
  ref?: RefCallBack; // From a controller
  state?: ControllerFieldState
  textFieldProps?: TextFieldProps
}

export function EnvironmentSelect(props: Props) {
  return (
    <>
      <Autocomplete
        isOptionEqualToValue={(option, value) => option.environment === value.environment}
        {...props}
        onChange={(e, v) => props.onChange(v as EnvironmentOption)}
        disablePortal
        options={config.environments}
        renderInput={(params) => <TextField
          error={!!props.state?.error || false}
          {...props.textFieldProps}
          {...params}
          label="Environment"/>
        }
      />
    </>
  );
}
