import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import { createDialog } from '../../../../../hooks/useDialogs';
import { SoftwarePartDialog } from './SoftwarePartDialog';
import { PartWhere } from '../../../../../generated/graphql';

interface Props {
  id?: string,
  softwarePartWhere: PartWhere,
  size?: 'small' | 'medium' | 'large',
}

export function SoftwarePartDialogButton(props: Props) {
  return (
    <IconButton
      size={props.size || 'medium'}
      color="primary"

      onClick={(e) => {
        e.stopPropagation();
        /* eslint-disable-next-line max-len */
        createDialog((close) => <SoftwarePartDialog close={close} where={props.softwarePartWhere}/>);
      }}
    >
      <OpenInNewIcon />
    </IconButton>
  );
}
