export const exampleOrder = {
  pno12: '246K5170C110',
  color: '72300',
  upholstery: 'UA0000',
  plant: '21',
  structureWeek: '202312',
  options: [
    '000010',
    '000016',
    '000047',
    '000063',
    '000139',
    '000212',
    '000273',
    '000385',
    '000645',
    '000752',
    '000854',
    '000869',
    '000879',
    '001028',
    '001033',
    '001101',
    '001265',
  ],
};

export const exampleOrder2 = {
  pno12: '516NA650E119',
  color: '70700',
  upholstery: 'R10000',
  plant: '21',
  structureWeek: '202517',
  options: [],
};
