import { BehaviorSubject } from 'rxjs';
import React from 'react';
import { useBehaviorSubject } from './useSubject';

export const dialogSubject = new BehaviorSubject<React.ReactNode[]>([]);

export function useDialogSubject() {
  return useBehaviorSubject(dialogSubject);
}

function removeDialog(node: React.ReactNode) {
  const list = dialogSubject.getValue();
  const index = list.indexOf(node, 0);
  if (index > -1) {
    list.splice(index, 1);
  }
  dialogSubject.next([...list]);
}

function addDialog(node: React.ReactNode) {
  const list = dialogSubject.getValue();
  list.push(node);
  dialogSubject.next([...list]);
}

export function createDialog(renderCallback: (close: () => void) => React.ReactNode) {
  let component : React.ReactNode;
  const closeCallback = () => {
    removeDialog(component);
  };

  component = renderCallback(closeCallback);

  addDialog(component);
  return component;
}
