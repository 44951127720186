import React from 'react';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { IntegrationsOverviewTable } from './IntegrationsOverviewTable';
import { AddUserToIntegration } from './AddUserToIntegration';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { AdminWebhooks } from './AdminWebhooks';
import { AdminApplicationOperations } from './AdminApplicationOperations';
import { UsersTable } from './UsersTable';
import { KeysTable } from './KeysTable';
import { Offboarding, OrphanedSecretsTable } from './Offboarding';

export function AdminOverview() {
  return (
    <>
      <Card>
        <SectionCardHeader
          avatar={<AdminPanelSettingsIcon color='warning'/>}
          title="Admin overview"
        />
      </Card>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography data-testid="integrationName">Applications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IntegrationsOverviewTable/>
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography data-testid="integrationName">Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UsersTable/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography>Add user to Application</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddUserToIntegration/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography>Operations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdminApplicationOperations/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography>Webhooks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdminWebhooks/>
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography data-testid="integrationName">Secrets</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <KeysTable/>
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography>Offboarding list</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Offboarding/>
          <OrphanedSecretsTable/>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
