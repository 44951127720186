import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import {
  Noop, RefCallBack,
} from 'react-hook-form/dist/types';
import ErrorMessage from '../ErrorMessage';
import { useAdminListUsersQuery } from '../../../generated/graphql';

export interface SelectedUser {
  label: string
  username: string
}

interface Props {
  onChange: (value: SelectedUser) => void,
  inputValue?: string,
  onBlur?: Noop; // From a controller
  value?: SelectedUser; // From a controller
  name?: string; // From a controller
  ref?: RefCallBack; // From a controller
}

export function UserSelect(props: Props) {
  const { data, loading, error } = useAdminListUsersQuery();

  if (error) {
    return <ErrorMessage error={error}/>;
  }

  const options = data?.admin.users.map((user) => ({
    label: `${user.name} (${user.username})`,
    username: user.username,
  })) || [];

  return (
    <>
      <Autocomplete
      isOptionEqualToValue={(option, value) => option.username === value.username}
        {...props}
        onChange={(e, v) => props.onChange(v as SelectedUser)}
        loading={loading}
        disablePortal
        options={options}
        renderInput={(params) => <TextField {...params} label="User"/>}
      />
    </>
  );
}
