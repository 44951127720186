import Box from '@mui/material/Box';
import React from 'react';

export function VolvoLogo() {
  return (
    <Box padding={`${8 * 3}px`} textAlign="center">
      <img
        width="auto"
        height="8"
        alt="Volvo"
        src="https://www.volvocars.com/static/shared/images/volvo-wordmark-black.svg"
      />
    </Box>
  );
}
