import React, { useEffect } from 'react';
import mermaid from 'mermaid';
import { colors } from '../../services/mui-theme';

mermaid.mermaidAPI.initialize({
  startOnLoad: true,
  securityLevel: 'loose',
  logLevel: 5,
  theme: 'base',
  htmlLabels: true,
  themeVariables: {
    primaryColor: colors.brand.orange4,
    // primaryTextColor: colors.lightUi.dark1,
    // primaryBorderColor: '#8da3c0',
    secondaryColor: colors.brand.blue3,
    // tertiaryColor: colors.brand.green2,
  },
});

export interface MermaidProps {
  text: string;
}

export function Mermaid({ text }: MermaidProps) {
  useEffect(() => {
    mermaid.contentLoaded();
  }, [text]);

  return <div className="mermaid">{text}</div>;
}
