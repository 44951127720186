import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowseBopRoutingQuery,
  BopRoutingWhere,
  SortDirection,
  useBrowseBopRoutingQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn,
  dateTimeColumn, stringColumn,
} from '../../../../../util/dataGridColumns';
import { BopRoutingDialogButton } from './BopRoutingDialogButton';

type Row = BrowseBopRoutingQuery['bopRoutings'][0];

const columns: GridEnrichedColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <BopRoutingDialogButton id={params.row.id} />,
  },
  {
    ...stringColumn('name'),
    headerName: 'Name',
    width: 250,
  },
  {
    ...stringColumn('revision'),
    headerName: 'Revision',
    width: 90,
  },
  {
    ...stringColumn('catalogueId'),
    headerName: 'Catalogue Id',
    width: 120,
  },
  {
    ...aggregateColumn('compoundOperationsAggregate'),
    headerName: 'Compound Operations',
    width: 120,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'CreatedAt',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'LastModified',
  },
];

export function BopRoutingTable() {
  const pageSize = 100;
  const [page, setPage] = useState<number>(0);
  const [where, setWhere] = useState<BopRoutingWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseBopRoutingQuery({
    variables: {
      where,
      options: {
        limit: pageSize,
        offset: page * pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        pageSize={pageSize}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        rowsPerPageOptions={[]}
        onPageChange={(p) => setPage(p)}
        columns={columns}
        rowCount={(data || previousData)?.bopRoutingsAggregate.count || 0}
        rows={(data || previousData)?.bopRoutings || []}
      />
    </Box>
  );
}
