/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Info } from '@mui/icons-material';

interface Props {
  message: string | ReactElement
}

export function SimpleMessageDialog(props: Props) {
  const { message } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton size="medium" color="primary" onClick={handleClickOpen} >
        <Info fontSize="inherit" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent sx={{ minWidth: 200 }}>
          <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
