/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Dialog, DialogContent, DialogTitle, IconButton, Tooltip,
} from '@mui/material';
import { gql, isApolloError } from '@apollo/client';
import { CancelRounded } from '@mui/icons-material';
import { docsClient } from '../../../services/apollo';
import { createErrorMessage } from '../../common/GraphqlCodeBlock/apollo.util';

interface Props {
  query?: string;
  variables?: any;
}

export function ApplicationOperationRunButton(props: Props) {
  const { query, variables } = props;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [queryOk, setQueryOk] = useState<boolean>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRunUserQuery = async () => {
    setLoading(true);
    let vars: any = null;
    if (variables) {
      try {
        vars = JSON.parse(variables);
      } catch (e) {
        setErrorMessage(`Invalid JSON for variables: ${e}`);
        setQueryOk(false);
        setOpen(true);
        return;
      }
    }

    try {
      await docsClient.query({
        query: gql(query || ''),
        variables: vars,
        fetchPolicy: 'no-cache',
      });
      setQueryOk(true);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMessage(`${e}`);
      setQueryOk(false);

      if (isApolloError(e)) {
        setErrorMessage(createErrorMessage(e));
      }
    } finally {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  return (
    <>
      <Tooltip title='Click to see if your query will run' placement='top'>
        <LoadingButton variant='contained' onClick={handleRunUserQuery} loading={loading}>
          Try Query
        </LoadingButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}>
        <DialogTitle>Query Feedback
          <IconButton onClick={handleClose}>
            <CancelRounded color='error'/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {queryOk
            ? <Alert severity="success" className='success-message'>
              <Box display="inline-block">
                Query runnable!&nbsp;
              </Box>
            </Alert>
            : <Alert severity="error" className='error-message'>
              <Box>
                <strong>Query resulted in error:&nbsp;</strong>
                <br />{errorMessage}
              </Box>
            </Alert>}
        </DialogContent>
      </Dialog>
    </>
  );
}
