import React, { useRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ButtonGroup, Stack } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useDataQualityUploadMutation, DataQualityType } from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';

export interface UploadProps {
  type: DataQualityType
  buttonText: string,
}

export function DataValidationUpload(props: UploadProps) {
  const { type, buttonText } = props;
  const [callUpload, { data, loading, error }] = useDataQualityUploadMutation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = e.target;

    if (validity.valid && files) {
      callUpload({ variables: { file: files[0], type } }).then(() => {
        // reset the value so we can select the same file again
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      });
    }
  };

  return (
    <>
      <ErrorMessage error={error} />
      <Stack direction="row" spacing={1} marginTop={2}>
        <label htmlFor={`${type}-upload-input`}>
          <input
            ref={inputRef}
            style={{ display: 'none' }}
            accept=".csv"
            required
            id={`${type}-upload-input`}
            type="file"
            onChange={onChange}
          />
          <LoadingButton
            variant="contained"
            component="span"
            loading={loading}
          >
            {buttonText}
          </LoadingButton>
        </label>
        <ButtonGroup>
          <Button href={data?.dataQualityUpload?.inputFileUrl} disabled={!data} variant="outlined" startIcon={<DownloadIcon />}>
            Input File
          </Button>
          <Button href={data?.dataQualityUpload?.outputFileUrl} disabled={!data} variant="outlined" startIcon={<DownloadIcon />}>
            Report
          </Button>
        </ButtonGroup>
      </Stack>
    </>
  );
}
