import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';
import { ApplicationWithSecretsFragment, useApplicationSecretsQuery } from '../../../generated/graphql';
import { DeleteSecretDialog } from '../../control-panel/AppSecrets/DeleteSecretDialog';
import { UserNotFound } from '../../common/UserNotFound';
import { dateFormat } from '../../../util/date';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';
import { getComparator } from '../../common/sortTables/comparators';

export function KeysTable() {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof ApplicationWithSecretsFragment>('teamName');
  const {
    data, refetch, loading,
  } = useApplicationSecretsQuery({});

  const rows = data?.admin.approvedApplications;

  if (loading) {
    return <Paper variant="outlined"><CircularProgress /></Paper>;
  }

  if (!rows?.length) {
    return (
      <Alert severity="warning">
        There are no secrets
      </Alert>
    );
  }

  const sortableRows = rows.slice().sort(
    getComparator<ApplicationWithSecretsFragment>(
      order,
      orderBy,
    ),
  );

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(property, direction) => {
              setOrder(direction);
              setOrderBy(property);
            }}
            refetch={refetch}
            headCells={headCells}
          />
          <TableBody>
            {sortableRows?.map((row) => (
              row.secrets.map((secret) => (
                <TableRow
                  key={secret.secretId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{secret.environment}</TableCell>
                  <TableCell>{secret.secretId}</TableCell>
                  <TableCell>{secret.description}</TableCell>
                  <TableCell>{row.appName}</TableCell>
                  <TableCell>
                    {secret.createdByUser?.name || <UserNotFound />} <br />
                    {dateFormat(secret.createdAt)}
                  </TableCell>
                  <TableCell padding="checkbox">
                    <DeleteSecretDialog id={secret.id} />
                  </TableCell>
                </TableRow>
              ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'environment',
    label: 'Env',
    isSortable: true,
  },
  {
    id: 'secretId',
    label: 'Key',
    isSortable: true,
  },
  {
    id: 'desc',
    label: 'Description',
    isSortable: true,
  },
  {
    id: 'appName',
    label: 'Application',
    isSortable: true,
  },
  {
    id: 'created',
    label: 'Created',
    isSortable: true,
  },
];
