import React from 'react';
import { BomInstancesTree } from '../../documentation/pages/browser/bom/ebom/BomInstancesTree';

export function EbomBrowserPage() {
  return (
    <>
      <BomInstancesTree/>
    </>
  );
}
