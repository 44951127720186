import { BehaviorSubject } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-common';
import { useBehaviorSubject } from './useSubject';
import {
  getAuthData, logout, secondsLeftUntilTokenExpires,
} from '../services/msal';
import cache from '../services/cache';

export const authSubject = new BehaviorSubject<AuthenticationResult | null>(null);

getAuthData().then((e) => {
  authSubject.next(e);
});

export function useAuth() {
  const [data, update] = useBehaviorSubject(authSubject);

  return {
    data,
    timeUntilExpire: () => (data ? secondsLeftUntilTokenExpires(data) : 0),
    refresh: () => {
      getAuthData().then(update);
    },
    login: () => {
      getAuthData(true).then(update);
    },
    logout: async () => {
      if (data?.account?.homeAccountId) {
        cache.reset();
        await logout(data.account.homeAccountId);
      }
    },
  };
}

export function currentAuth() {
  return authSubject.value;
}
