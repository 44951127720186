import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowseCpvSetsQuery, StoredCpvSetWhere, SortDirection, useBrowseCpvSetsQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  dateTimeColumn, stringArrayColumn, stringColumn, subFieldColumn, weekColumn,
} from '../../../../../util/dataGridColumns';
import { CpvSetDialogButton } from './Dialog/CpvSetDialogButton';

type Row = BrowseCpvSetsQuery['storedCpvSets'][0];

const columns: GridEnrichedColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <CpvSetDialogButton id={params.row.id} />,
  },
  {
    ...stringColumn('revision'),
    headerName: 'Revision',
    sortable: false,
    width: 70,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('name'),
    headerName: 'Name',
    sortable: false,
    width: 200,
    cellClassName: 'font-monospace',
  },
  {
    ...subFieldColumn('plant.plantCode'),
    valueGetter: (v) => v.row.plant?.plantCode,
    headerName: 'Plant Code',
    sortable: false,
    filterable: false,
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('pno'),
    headerName: 'Pno',
    sortable: false,
    cellClassName: 'font-monospace',
    width: 600,
  },
  {
    ...stringColumn('externalPno'),
    headerName: 'ExternalPno',
    sortable: false,
    cellClassName: 'font-monospace',
    width: 400,
  },
  {
    ...stringArrayColumn('cpvSetTypes'),
    headerName: 'CpvSetTypes',
    sortable: false,
    width: 100,
  },
  {
    ...stringColumn('description'),
    headerName: 'Description',
    sortable: false,
    width: 300,
  },
  {
    ...stringColumn('modelYear'),
    headerName: 'ModelYear',
    sortable: false,
    width: 90,
  },
  {
    ...weekColumn('effectiveInWeek'),
    headerName: 'In Week',
    sortable: false,
    width: 100,
  },
  {
    ...weekColumn('effectiveOutWeek'),
    headerName: 'Out Week',
    width: 100,
  },
  {
    ...stringColumn('status'),
    headerName: 'Status',
    width: 100,
  },
  {
    ...stringColumn('preSeries'),
    headerName: 'PreSeries',
    width: 100,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'CreatedAt',
    width: 170,
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified At',
    width: 170,
  },
];

interface Props {
  where?: StoredCpvSetWhere
}

export function StoredCpvSetsTable(props: Props) {
  const { where } = props;
  const pageSize = 100;
  const [page, setPage] = useState<number>(0);
  const [whereFilter, setWhereFilter] = useState<StoredCpvSetWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseCpvSetsQuery({
    variables: {
      where: {
        ...where,
        ...whereFilter,
      },
      options: {
        limit: pageSize,
        offset: page * pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        pageSize={pageSize}
        pagination
        onFilterModelChange={(filter) => {
          setWhereFilter(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        rowsPerPageOptions={[]}
        onPageChange={(p) => setPage(p)}
        columns={columns}
        getRowClassName={(params) => (params.row.status as string).toLowerCase()}
        rowCount={(data || previousData)?.storedCpvSetsAggregate.count || 0}
        rows={(data || previousData)?.storedCpvSets || []}
      />
    </Box>
  );
}
