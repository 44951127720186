/* eslint-disable max-len */
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { SIPlusCaseI00568 } from './case-I00568/SIPlusCaseI00568';
import { SIPlusCaseI00569 } from './case-I00569/SIPlusCaseI00569';
import { SIPlusCaseI00570 } from './case-I00570/SIPlusCaseI00570';
import { SectionCardHeader } from '../../../../common/SectionCardHeader';
import { CaseAccordionSummary } from '../common/CaseAccordionSummary';

export function SIPlusCasePage() {
  const [expanded, setExpanded] = useState<number>();
  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  return (
    <>
      <Box my={3}>
        <Card>
          <CardContent>
            <Typography variant="h1" mb={1}>SI+</Typography>
            <Typography paragraph mb={3}>Application for VCC purchasing of direct material</Typography>
            <Button
              startIcon={<FolderIcon/>}
              variant="contained"
              target="_blank"
              href="https://intranet.volvocars.net/sites/SHLM/Shared%20Documents/Forms/AllItems.aspx?FolderCTID=0x012000106DF1FE7AC95E45891244A8051A2D01&id=%2Fsites%2FSHLM%2FShared%20Documents%2FSystem%5FIntegration%5FTC%2FApplications%20for%20integration%2FSI%20Plus"
            >Files</Button>
          </CardContent>
        </Card>
      </Box>
      <Card>
        <SectionCardHeader
          title="Existing KDP integrations"
        />
      </Card>
      <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
        <CaseAccordionSummary
          title="Assembly file to SIPLUS-SAP MM"
          sub="KDP name I00568, I00597"
          status="Implementing"
          statusColor="warning.main"
        />
        <AccordionDetails>
          <SIPlusCaseI00568/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 2} onChange={handleChange(2)}>
        <CaseAccordionSummary
          title="Delivery Module file to SIPLUS-SAP MM"
          sub="KDP name I00569, I00571, I00596"
          status="Blocked"
          statusColor="error.main"
        />
        <AccordionDetails>
          <SIPlusCaseI00569/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 3} onChange={handleChange(3)}>
        <CaseAccordionSummary
          title="Part file to SIPLUS-VSIM-SAP MM-FLIT"
          sub="KDP name I00570, I00572, I00591, I00595. Integrationcenter name INT2652"
          status="Implementing"
          statusColor="warning.main"
        />
        <AccordionDetails>
          <SIPlusCaseI00570/>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
