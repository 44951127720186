import React from 'react';

import Box from '@mui/material/Box';
import GraphView from '../../../../common/GraphqlCodeBlock/GraphView/GraphView';

const query = `
query BrowseMobilities {
  productLineMobilities {
    id
    name
    productFamilies {
      id
      name
      productContexts {
        id
        name
        typeCode
        typeDesignation
        configurationDictionary {
          id
          name
        }
        ebom(where: {
          NOT: { functionGroups_SOME: null }
        }) {
          id
          typeCode
          typeDesignation
        }
      }
    }
  }
}
`;

export function MobilitiesGraph() {
  return (
    <Box pt={2}>
      <Box sx={{ background: '#fff', position: 'relative', overflow: 'hidden' }}>
        <GraphView query={query} dagMode={false}/>
      </Box>
    </Box>
  );
}
