/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import {
  Collapse,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import {
  OperationTypeDistributionByTeamResult,
  useTopOperationMetricLazyQuery,
} from '../../../generated/graphql';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import ErrorMessage from '../../common/ErrorMessage';

interface TopQueryProps {
  recentTeams: OperationTypeDistributionByTeamResult[]
}

function Row(props: { name: string, count: number }) {
  const { name, count } = props;
  const [open, setOpen] = React.useState(false);
  const [callTopQueries, {
    data,
    loading,
    error,
  }] = useTopOperationMetricLazyQuery({
    variables: {
      period: 'P5D',
      teamName: name,
    },
  });

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'none !important' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              callTopQueries();
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell >{count}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper sx={{ marginBottom: 2 }}>
              {loading && <LinearProgress></LinearProgress>}
              {error && <ErrorMessage error={error}></ErrorMessage>}
              {data?.kpiMetric.topUsedOperations && !error && (
                <Table>
                  <TableHead>
                    <TableCell>Operation Name</TableCell>
                    <TableCell>Requests</TableCell>
                  </TableHead>
                  <TableBody>
                    {data.kpiMetric.topUsedOperations.map((row) => (
                      <TableRow key={row.operationName}>
                        <TableCell>{row.operationName}</TableCell>
                        <TableCell>{row.count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function TopQuery(props: TopQueryProps) {
  const [activeTeams, setActiveTeams] = useState<{ [key: string]: number; }>({});

  const activeIntegrations = (
    dataRow: OperationTypeDistributionByTeamResult[],
  ): { [key: string]: number } => {
    return dataRow.reduce((r, a) => {
      r[a.teamName] = r[a.teamName] || 0;
      r[a.teamName] += a.count;
      return r;
    }, Object.create({}));
  };

  useEffect(() => {
    setActiveTeams(activeIntegrations(props.recentTeams));
  }, [props.recentTeams]);

  return (
    <Card>
      <SectionCardHeader
        avatar={<ArrowUpward color='secondary'/>}
        title='Top Used Queries'
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>TeamName</TableCell>
                <TableCell>Requests</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(activeTeams).map((key) => (
                <Row
                  key={key}
                  name={key}
                  count={activeTeams[key]}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
