/* eslint-disable @typescript-eslint/no-explicit-any,no-restricted-syntax */
import { GraphData, LinkObject, NodeObject } from 'react-force-graph-2d';
import { findIndex, uniqBy } from 'lodash';
import { Query } from '../../../../generated/graphql';

export type GraphDataWithValues = GraphData & {
  nodes: NodeObjectWithValues[];
};

export type NodeObjectWithValues = NodeObject & {
  __typename: string;
  relCount: number;
  values: NodeObjectValue[];
};

export type NodeObjectValue = {
  key: string;
  value: string | number | boolean | null;
};

export const formatQueryResultForGraph = (data: Query | any): GraphDataWithValues => {
  const nodes: NodeObjectWithValues[] = [];
  const links: LinkObject[] = [];

  const processDocument = (document: Query | any) => {
    if (document === null) {
      return;
    }
    // Add node if it is an entity
    if (document.__typename && document.id) {
      addNode(document);
    }

    // Loop all the properties on the document
    for (const [key, value] of Object.entries(document)) {
      // If value is another document then process that
      // and add link to this document

      if (typeof value === 'object' && !Array.isArray(value)) {
        processDocument(value);
        createLinks(document, [value], key);
      }

      // Same as above but if it is an array of documents
      if (Array.isArray(value)
        && value.length > 0
        && typeof value[0] === 'object'
      ) {
        value.forEach(processDocument);
        createLinks(document, value, key);
      }
    }
  };

  const createLinks = (document: Query | any, children: any[], linkName: string) => {
    if (document
      && document.__typename && document.id
      && children[0]
      && children[0].__typename && children[0].id
    ) {
      children.forEach((child) => {
        links.push({
          source: document.id,
          target: child.id,
          linkText: linkName,
        } as LinkObject);
      });
    }
  };

  const addNode = (document: Query | any) => {
    // Find existing
    const index = findIndex(nodes, {
      __typename: document.__typename,
      id: document.id,
    });

    // if exists somewhere else in the result
    if (index !== -1) {
      nodes[index] = {
        id: document.id,
        relCount: 0,
        __typename: document.__typename,
        // merge node values with existing values
        values: uniqBy([
          ...nodes[index].values,
          ...scalarOnly(document),
        ], 'key'),
      };
    } else {
      nodes.push({
        id: document.id,
        relCount: 0,
        __typename: document.__typename,
        values: scalarOnly(document),
      });
    }
  };

  const scalarOnly = (document: Query | any) => {
    return Object.keys(document)
      .filter((key) => typeof document[key] !== 'object')
      .map((key) => {
        return {
          key,
          value: document[key],
        };
      });
  };

  if (data) {
    processDocument(data);
  }

  nodes.forEach((n) => {
    const rels = links.filter((l) => l.source === n.id || l.target === n.id);
    // eslint-disable-next-line no-param-reassign
    n.relCount = rels.length;
  });

  return {
    nodes,
    links,
  };
};
