import React from 'react';
import Typography from '@mui/material/Typography';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';

export function DataModelPage() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Current" value="1"/>
            <Tab label="Older versions" value="2"/>
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box mx="auto" maxWidth={960} mb={2}>
            <Alert severity="warning">
              This is the most current model that we are working towards.
              Some things, like property names, may differ. <br/>
              For an exact specification of the current model
              with correct names and types view the <Link to="/graph-navigator">Graph Navigator</Link> instead.
            </Alert>
          </Box>
          <ModelImage src="/images/print-data-model-2024-10-30.png" label="2024-10-30" width={2200}/>
        </TabPanel>
        <TabPanel value="2">
          <ModelImage src="/images/prins-data-model-2024-09-05.png" label="2024-09-05" width={2200}/>
          <ModelImage src="/images/prins-data-model-2024-05-20.png" label="2024-05-20" width={2200}/>
          <ModelImage src="/images/prins-data-model-2024-01-22.png" label="2024-01-22" width={2200}/>
          <ModelImage src="/images/prins-data-model-2023-10-27.png" label="2023-10-27" width={2200}/>
          <ModelImage src="/images/prins-data-model-2023-03-24.png" label="2023-03-24" width={2200}/>
          <ModelImage src="/images/prins-data-model-2023-03-01.png" label="2023-03-01" width={2200}/>
          <ModelImage src="/images/prins-data-model-2023-01-18.png" label="2023-01-18" width={1800}/>
          <ModelImage src="/images/prins-data-model-2022-11-15.png" label="2022-11-15" width={900}/>
          <ModelImage src="/images/prins-data-model-2022-11-02.png" label="2022-11-02" width={900}/>
          <ModelImage src="/images/prins-data-model-2022-10-27.png" label="2022-10-27" width={900}/>
          <ModelImage src="/images/prins-data-model-2022-10-10.png" label="2022-10-10" width={900}/>
          <ModelImage src="/images/prins-data-model-2022-10-03.png" label="2022-10-03" width={900}/>
          <ModelImage src="/images/prins-data-model.png" label="Older version"/>
        </TabPanel>
      </TabContext>
    </>
  );
}

interface ModelImageProps {
  src: string,
  label: string,
  width?: number | string,
}

export function ModelImage({ src, label, width }: ModelImageProps) {
  return (
    <Box mb={4}>
      <Paper sx={{
        maxWidth: '100%',
        overflowX: 'auto',
      }} elevation={2}>
        <img
          src={src}
          alt=""
          style={{
            display: 'block',
            minWidth: '100%',
            maxWidth: 'none',
            width,
          }}
        />
      </Paper>
      <Typography textAlign="center" variant="subtitle1" mt={0.5}>{label}</Typography>
    </Box>
  );
}
