import React from 'react';
import { ConsumptionRecordsTable } from '../../documentation/pages/browser/consumption-records/ConsumptionRecordsTable';

export function ConsumptionRecordsBrowserPage() {
  return (
    <>
      <ConsumptionRecordsTable/>
    </>
  );
}
