import List from '@mui/material/List';
import React from 'react';
import { LmodDialogQuery } from '../../../../../../generated/graphql';
import { FieldListValue } from '../../common/FieldListValue';

type LMOD = LmodDialogQuery['deliveryModules'][0];

interface Props {
  lmod?: LMOD
}

export function FieldList({ lmod: part }: Props) {
  return (
    <List disablePadding>
      <FieldListValue name="partNumber" value={part?.partNumber}/>
      <FieldListValue name="name" value={part?.name}/>
      <FieldListValue name="assignmentNumber" value={part?.assignmentNumber}/>
      <FieldListValue name="description" value={part?.description}/>
      <FieldListValue name="unit" value={part?.unit}/>
      <FieldListValue name="masteredBy" value={part?.masteredBy}/>
      <FieldListValue name="createdAt" value={part?.createdAt}/>
      <FieldListValue name="lastModified" value={part?.lastModified}/>
    </List>
  );
}
