/* eslint-disable */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PartsInfoFragment } from '../../../../../../generated/graphql';
import { ValueText } from '../../parts/PartDialog/valueText';
import { PartDialogButton } from '../../parts/PartDialogButton';

interface Props {
  parts?: PartsInfoFragment[]
}

export function PartsTable(props: Props) {
  const { parts } = props;

  if (!parts) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="none"></TableCell>
            <TableCell>PartNumber</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>PartType</TableCell>
            <TableCell>PartTypeDescription</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parts.map((part, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="none" sx={{ pl: 1 }}>
                {part.__typename === 'Part' &&
                  <PartDialogButton where={{id: part.id }} size="small"/>}
              </TableCell>
              <TableCell>{ValueText(part.partNumber)}</TableCell>
              <TableCell>{ValueText(part.name)}</TableCell>
              <TableCell>{ValueText(part.project)}</TableCell>
              <TableCell>{ValueText(part.partType)}</TableCell>
              <TableCell>{ValueText(part.partTypeDescription)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
