import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CircularProgress, Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import WebhookIcon from '@mui/icons-material/Webhook';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import {
  ApplicationWithSecretsFragment,
  useAdminApplicationDeleteMutation,
  useAdminApplicationQuery,
  useAdminApprovedApplicationsQuery,
} from '../../../generated/graphql';
import { ToggleRole } from '../../control-panel/IntegrationInfo/ToggleRole';
import { IntegrationBadge } from '../../control-panel/IntegrationInfo/IntegrationBadge';
import { ModalWrapper } from '../../common/ModalWrapper';
import { AdminApplicationUsersTable } from '../app-details/AdminApplicationUsersTable';
import { EditIntegrationForm } from '../app-details/EditIntegrationForm';
import { ApplicationWebhooks } from '../../control-panel/IntegrationInfo/ApplicationWebhooks';
import { config } from '../../../config/config';
import { ApplicationKeys } from '../../control-panel/IntegrationInfo/ApplicationKeys';
import { ApplicationOperations } from '../../control-panel/IntegrationInfo/ApplicationOperations';
import { getComparator } from '../../common/sortTables/comparators';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';
import { dateTimeFormat } from '../../../util/date';

export function IntegrationsOverviewTable() {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof ApplicationWithSecretsFragment>('teamName');
  const {
    data, refetch, loading,
  } = useAdminApprovedApplicationsQuery({});

  const rows = data?.admin.approvedApplications;

  if (loading) {
    return <Paper variant="outlined"><CircularProgress /></Paper>;
  }

  if (!rows?.length) {
    return (
      <Alert severity="warning">
        There are no applications
      </Alert>
    );
  }

  const sortedRows = [...rows].sort(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getComparator<ApplicationWithSecretsFragment | any>(
      order,
      orderBy,
    ),
  );

  return (
    <Paper variant="outlined">
      <Alert severity="info">
        Note that you can´t create or delete secrets for application groups you don´t belong to
      </Alert>
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(property, direction) => {
              setOrder(direction);
              setOrderBy(property);
            }}
            refetch={refetch}
            headCells={headCells}
            />
          <TableBody>
            {sortedRows.map((row) => {
              return <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.teamName}</TableCell>
                <TableCell>{row.appName}</TableCell>
                <TableCell>{row.appId}</TableCell>
                <TableCell>{dateTimeFormat(row.createdAt)}</TableCell>
                {config.environments.map((env, i) => (
                  <TableCell key={i} padding="none" align="center">
                    <ToggleRole
                      application={row}
                      env={env} onComplete={refetch}
                    />
                  </TableCell>
                ))}
                <TableCell padding="none" align="right">
                  <Stack spacing={0} direction="row" display="flex">
                    <ModalWrapper
                      renderButton={(open) => (
                        <IconButton onClick={open}>
                          <IntegrationBadge
                            content={row.userCount}
                            icon={<PersonIcon />}
                          />
                        </IconButton>
                      )}
                      renderModal={() => (
                        <AdminApplicationUsersTable appId={row.appId} />
                      )}
                    />
                    <ModalWrapper
                      renderButton={(open) => (
                        <IconButton onClick={open}>
                          <IntegrationBadge
                            content={row.secretCount}
                            icon={<KeyIcon />}
                          />
                        </IconButton>
                      )}
                      renderModal={(_, isOpen) => (
                        <Box p={2} width={800}>
                          <ApplicationKeysController
                            appId={row.appId}
                            visible={isOpen}
                            refetch={refetch}
                          />
                        </Box>
                      )}
                    />
                    <ModalWrapper
                      renderButton={(open) => (
                        <IconButton onClick={open}>
                          <IntegrationBadge
                            content={row.operationCount}
                            icon={<PlayCircleOutlineIcon />}
                          />
                        </IconButton>
                      )}
                      renderModal={(_, isOpen) => (
                        <Box p={2} width={800}>
                          <ApplicationOperationsController
                            appId={row.appId}
                            visible={isOpen}
                            refetch={refetch}
                          />
                        </Box>
                      )}
                    />
                    <ModalWrapper
                      renderButton={(open) => (
                        <IconButton onClick={open}>
                          <IntegrationBadge
                            content={row.webhookCount}
                            icon={<WebhookIcon />}
                          />
                        </IconButton>
                      )}
                      renderModal={(_, isOpen) => (
                        <Box p={2} width={800}>
                          <ApplicationWebhooksController
                            appId={row.appId}
                            visible={isOpen}
                            refetch={refetch}
                          />
                        </Box>
                      )}
                    />
                    <ModalWrapper
                      renderButton={(open) => (
                        <IconButton onClick={open} color="primary">
                          <EditIcon />
                        </IconButton>
                      )}
                      renderModal={(close) => (
                        <Box p={2} width={320}>
                          <EditIntegrationForm application={row} close={close} />
                        </Box>
                      )}
                    />
                    <DeleteDialog appId={row.appId} onComplete={refetch} />
                  </Stack>
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function DeleteDialog(props: { appId: string, onComplete: () => void }) {
  const { appId, onComplete } = props;
  const [deleteIntegration] = useAdminApplicationDeleteMutation();
  const [open, setOpen] = useState(false);
  const handleYes = () => {
    deleteIntegration({
      variables: {
        appId,
      },
    }).then(() => onComplete());

    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="error">
        <DeleteIcon />
      </IconButton>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          Are you sure you want to delete this integration? <br />
          This action can not be undone.
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'teamName',
    label: 'Team',
    isSortable: true,
  },
  {
    id: 'appName',
    label: 'Application',
    isSortable: true,
  },
  {
    id: 'appId',
    label: 'App ID',
    isSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Created',
    isSortable: true,
  },
  ...config.environments.map((env) => (
    {
      id: `env-${env.environment}`,
      label: env.label,
    }
  )),
];

function ApplicationWebhooksController(props: {
  appId: string,
  visible: boolean,
  refetch: () => void,
}) {
  const {
    data, loading, error, refetch,
  } = useAdminApplicationQuery({
    skip: !props.visible,
    variables: {
      appId: props.appId,
    },
  });

  if (loading) {
    return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!data?.admin.application) {
    return null;
  }

  return (
    <ApplicationWebhooks application={data.admin.application} refresh={refetch} />
  );
}

function ApplicationOperationsController(props: {
  appId: string,
  visible: boolean,
  refetch: () => void,
}) {
  const {
    data, loading, error, refetch,
  } = useAdminApplicationQuery({
    skip: !props.visible,
    variables: {
      appId: props.appId,
    },
  });

  if (loading) {
    return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!data?.admin.application) {
    return null;
  }

  return (
    <ApplicationOperations application={data.admin.application} refresh={refetch} />
  );
}

function ApplicationKeysController(props: {
  appId: string,
  visible: boolean,
  refetch: () => void,
}) {
  const {
    data, loading, error, refetch,
  } = useAdminApplicationQuery({
    skip: !props.visible,
    variables: {
      appId: props.appId,
    },
  });

  if (loading) {
    return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!data?.admin.application) {
    return null;
  }

  return (
    <ApplicationKeys application={data.admin.application} refresh={refetch} />
  );
}
