/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller } from 'react-hook-form';
import {
  FormControl, FormHelperText, InputLabel, Select,
} from '@mui/material';
import { FormControlProps } from '@mui/material/FormControl/FormControl';
import * as React from 'react';

interface Props extends FormControlProps {
  name: string,
  label?: string,
  control: any,
  rules: any,
}

export function ReactHookFormSelect(props: Props) {
  const {
    name,
    label,
    control,
    defaultValue,
    children,
    rules,
    ...rest
  } = props;

  const labelId = `${name}-label`;
  return (
    <FormControl fullWidth {...rest as any}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field, fieldState }) => (
          <>
            <Select
              labelId={labelId}
              label={label}
              value={field.value || '' as any} // Forced controlled input
              onChange={field.onChange}
              error={!!fieldState.error}
            >
              {children}
            </Select>
            <FormHelperText error={true}>{fieldState.error?.message}</FormHelperText>
          </>
        )}
        name={name as any}
        control={control}
        rules={rules}
        defaultValue={defaultValue || null}
      />
    </FormControl>
  );
}
