/* eslint-disable */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { SwDeploymentContextInfoFragment } from '../../../../../../generated/graphql';
import { ValueText } from '../../parts/PartDialog/valueText';

interface Props {
  swDeploymentContexts?: SwDeploymentContextInfoFragment[]
}

export function DeploymentContextTable(props: Props) {
  const { swDeploymentContexts } = props;

  if (!swDeploymentContexts) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="none"></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>PlmItemId</TableCell>
            <TableCell>Version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {swDeploymentContexts.map((dc, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="none" sx={{ pl: 1 }}>
              </TableCell>
              <TableCell>{ValueText(dc.name)}</TableCell>
              <TableCell>{ValueText(dc.description)}</TableCell>
              <TableCell>{ValueText(dc.plmItemId)}</TableCell>
              <TableCell>{ValueText(dc.version)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
