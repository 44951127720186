import React, { ReactNode } from 'react';
import { Modal } from '@mui/material';
import Card from '@mui/material/Card';

interface Props {
  renderButton: (open: () => void) => ReactNode,
  renderModal: (close: () => void, isOpen: boolean) => ReactNode,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 200,
  maxWidth: '98vw',
  overflowX: 'auto',
};

export function ModalWrapper(props: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {props.renderButton(handleOpen)}
      <Modal
        open={open}
        onClose={handleClose}
        style={{ overflow: 'scroll' }}
      >
        <Card sx={style}>
          {props.renderModal(handleClose, open)}
        </Card>
      </Modal>
    </>
  );
}
