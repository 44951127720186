import { MarkChatRead, SmsFailed, Sms } from '@mui/icons-material';
import React from 'react';
import { Tooltip } from '@mui/material';

export function ApprovalStatus(props: { lastModified: string, approvedAt: string | null }) {
  if (!props.approvedAt) {
    return (
      <Tooltip title="Not approved">
        <Sms color='disabled' />
      </Tooltip>
    );
  }

  const approvedAt = new Date(props.approvedAt).getTime();
  const lastModified = new Date(props.lastModified).getTime();

  if (approvedAt > lastModified) {
    return (
      <Tooltip title="Approved">
        <MarkChatRead color='success' />
      </Tooltip>
    );
  }

  return (
      <Tooltip title="Changed after approval">
        <SmsFailed color='warning' />
      </Tooltip>
  );
}
