import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useCmpConsumptionRecordsLazyQuery } from '../../../generated/graphql';

interface NormalizedRow {
  consumptionRecordId: string;
  pmlId?: string | null;
  createdAt: string;
}

/**
 * Recreates the flow of fetching consumptions that Topas and CMP does
 */
export function CmpConsumptionsFetcher() {
  const [limit, setLimit] = useState<number>(1000);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rows, setRows] = useState<NormalizedRow[]>([]);

  const [call, { error, loading }] = useCmpConsumptionRecordsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // count the usage total usage rules
      const normalizedRows = data.consumptionRecords.reduce((acc, curr) => {
        return acc + curr.partUsage.usageRules.length;
      }, 0);

      const newRows = data.consumptionRecords.flatMap((cr) => {
        return cr.partUsage.usageRules.map((rule) => {
          return {
            consumptionRecordId: cr.id,
            createdAt: cr.createdAt,
            pmlId: rule.pmlId,
          };
        });
      }, 0);

      // increase the total
      setTotalCount(totalCount + normalizedRows);
      // add the new rows
      setRows([...rows, ...newRows]);

      if (data.consumptionRecords.length === limit) {
        call({
          variables: {
            offset: offset + limit,
            limit,
            plant: 22,
          },
        });
        setOffset(offset + limit);
      }
    },
  });

  return (
    <Box my={2}>
      <Paper>
        <Box p={2}>
          <TextField
            label="Limit"
            type="number"
            value={limit}
            onChange={(e) => setLimit(parseInt(e.target.value, 10))}
          />
          <TextField
            label="Offset"
            type="number"
            value={offset}
            onChange={(e) => setOffset(parseInt(e.target.value, 10))}
          />
          {}
          <Stack spacing={2} direction="row" my={1}>
            <LoadingButton
              onClick={() => call({
                variables: {
                  offset,
                  limit,
                  plant: 22,
                },
              })}
              loading={loading}
              variant="contained"
              color="primary"
            > Check </LoadingButton>
            <LoadingButton
              onClick={() => {
                setTotalCount(0);
                setOffset(0);
                setRows([]);
              }}
              loading={loading}
              variant="contained"
              color="secondary"
            > Reset </LoadingButton>
          </Stack>

          <>
            {error && <>Error: {error}</>}

            Total Count: {totalCount}
          </>

          <Box sx={{
            table: {
              width: '100%',
              borderCollapse: 'collapse',
              fontSize: '11px',
            },
            th: {
              border: '1px solid black',
              padding: '2px',
              textAlign: 'left',
            },
            td: {
              border: '1px solid black',
              padding: '2px',
              textAlign: 'left',
            },
          }}>
          <table>
            <thead>
              <tr>
                <th>Consumption Record ID</th>
                <th>PML ID</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>{row.consumptionRecordId}</td>
                  <td>{row.pmlId}</td>
                  <td>{row.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
