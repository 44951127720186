/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import {
  Chip,
  LinearProgress,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {
  BrowseConfigurationDictionariesQuery,
  useBrowseConfigurationDictionariesQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { DataTreeRow } from '../../../../common/data/DataTree';
import { ToggleAltCodes } from './ToggleAltCodes';
import { Cell } from './Cell';
import { colors } from '../../../../../services/mui-theme';

type CP = BrowseConfigurationDictionariesQuery['configurationDictionaries'][0]['cps'][0];
type CPV = BrowseConfigurationDictionariesQuery['configurationDictionaries'][0]['cps'][0]['cpvs'][0];

export function ConfigurationDictionariesTree() {
  const { data, loading, error } = useBrowseConfigurationDictionariesQuery();
  const [showAltCodes, setShowAltCodes] = React.useState(false);

  if (error) {
    return <ErrorMessage error={error}/>;
  }

  if (loading) {
    return <LinearProgress/>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <Stack direction="row" >
        <Typography variant="h3" sx={{ flexGrow: 1, m: 1 }}>
          Configuration Dictionaries
        </Typography>
        <ToggleAltCodes checked={showAltCodes} setVisibility={setShowAltCodes}/>
      </Stack>
      <Divider sx={{ mb: 1 }}/>
      {data.configurationDictionaries.map((cd, index) => (
        <DataTreeRow
          key={index}
          header={(
            <>
              <Cell value={cd.name} mono />
              <Cell value={cd.description}/>
            </>
          )}
          suffix={cd.__typename}
        >
          {cd.cps.map((cp, index) => (
            <DataTreeRow
              key={index}
              header={<CpHeader cp={cp} showAltCodes={showAltCodes} />}
              suffix={cp.__typename}
            >
              {cp.cpvs.map((cpv, index) => (
                <DataTreeRow
                  key={index}
                  header={<CpvHeader cpv={cpv} showAltCodes={showAltCodes} />}
                  suffix={cpv.__typename}
                >
                </DataTreeRow>
              ))}
            </DataTreeRow>
          ))}
        </DataTreeRow>
      ))}
    </>
  );
}

function CpHeader(props: { cp: CP, showAltCodes: boolean }) {
  const { cp, showAltCodes } = props;

  return (
    <>
      <Cell value={cp.code} mono/>
      {showAltCodes && (
        <Cell value={cp.altCode} color={colors.brand.orange2} mono/>
      )}
      <VariabilityDomain domain={cp.domain}/>
      <Cell value={cp.name}/>
      {showAltCodes && (
        <Cell value={cp.altName} color={colors.brand.orange2}/>
      )}
    </>
  );
}
function CpvHeader(props: { cpv: CPV, showAltCodes: boolean }) {
  const { cpv, showAltCodes } = props;

  return (
    <>
      <Cell value={cpv.code} mono/>
      {showAltCodes && (
        <Cell value={cpv.altCode} color={colors.brand.orange2} mono/>
      )}
      <Cell value={cpv.name}/>
      {showAltCodes && (
        <Cell value={cpv.altName} color={colors.brand.orange2}/>
      )}
    </>
  );
}

function VariabilityDomain(props: { domain: { code: string; name: string } | null | undefined }) {
  if (!props.domain) {
    return null;
  }

  return (
    <Chip
      label={`${props.domain?.code} - ${props.domain.name}`}
      variant="outlined"
      // color={releaseStatus === 'RELEASED' ? 'info' : 'warning'}
      size="small"
      sx={{
        mx: 2,
        opacity: 0.7,
      }}
    />
  );
}
