import React from 'react';
import Box from '@mui/material/Box';
import { ReactMarkdownWithSyntaxHighlighting } from '../../../common/ReactMarkdownWithSyntaxHighlighting';

const Md = ReactMarkdownWithSyntaxHighlighting;

export function MbomPage() {
  return (
    <>
      <Box mb={5}>
        <Md content={`

# MBOM

## ConsumptionRecord, UsageRule (PML) & plant
 
Usage Rule in PRINS represents one variant (Single PML) or combination variant (combination PML) or variant combination (multiple variants with AND) without any PML code as well.
Below examples are using  single PML for simplicity to explain concept but these are applicable in same way for combinations ( UsageRule with more than one variant)

![MBOM in PRINS](/images/MBOM-ConsumptionRecord-UsageRule-plant.jpg)

> This is also applicable for Test Series Consumptions

## LMOD (Delivery Module / Leveransmodul)

![MBOM in PRINS](/images/lmod.png)

[AC0693 LMOD – In TC PLM (PP)](https://mysite.volvocars.net/:p:/r/personal/tadolfs3_volvocars_com/_layouts/15/doc2.aspx?sourcedoc=%7B2C61C948-7B16-44AA-A5B3-038024A8773D%7D&file=AC0693%20LMOD%20-%20In%20TC%20PLM%20-%20Copy%2020230502.pptx&action=edit&mobileredirect=true&DefaultItemOpen=1&wdOrigin=TEAMS-ELECTRON.teams.search)

Contact Person: Adolfsson, Tobias

## Plant exceptions

[Plant exception use cases (PP)](https://intranet.volvocars.net/:p:/r/sites/SHLM/_layouts/15/Doc.aspx?sourcedoc=%7B42C6CFA8-6068-4127-87C1-020766B1A9DF%7D&file=Plant%20deviation%20usecases%2020231129.pptx&action=edit&mobileredirect=true)

Contact Person: Adolfsson, Tobias

*Last modified: 2024-10-07*

        `} />

      </Box>
    </>
  );
}
