/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { randomColor } from './graph.utils';

interface BarGraphProps {
  data: { [name: string]: any }[];
  xDataKey: string;
  yDataKey: string[];
  limit?: number;
}

export function BarGraph(props: BarGraphProps) {
  return (
    <div>
      <ResponsiveContainer width='100%' aspect={8.0 / 3.0}>
        <BarChart data={props.data.slice(0, props.limit)}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey={props.xDataKey}/>
          <YAxis/>
          <Tooltip/>
          <Legend/>
          {
          props.yDataKey.map((k, i) => (
            <Bar stackId="a" key={k} dataKey={k} fill={randomColor(i)}/>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
