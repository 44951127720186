/* eslint-disable max-len */
import React, { PropsWithChildren } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import PartFeatBreakUpQuery from './queries/PartFeatBreakUp.graphql';
import PartDetailsQuery from './queries/PartDetails.graphql';
import PartBreakUpQuery from './queries/PartBreakUp.graphql';
import PartWeeksQuery from './queries/PartWeeks.graphql';
import PartWeeks2Query from './queries/PartWeeks2.graphql';
import PSSGetQuery from './queries/PSSGet.graphql';

export function HerculesCasePage() {
  return (
    <>
      <Box my={3}>
        <Card>
          <CardContent>
            <Typography variant="h1">Hercules Case</Typography>
            <VolBreakDown/>
            <FeatParts/>
            <PartDetails/>
            <PartFeatBreakUp/>
            <PartBreakUp/>
            <PartWeeks/>
            <PSSGet/>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

function Item(props: PropsWithChildren<{ name: string }>) {
  return (
    <Box display="flex" sx={{
      display: {
        xs: 'block',
        sm: 'flex',
      },
      mb: 1,
    }}>
      <Box sx={{
        flexBasis: '190px',
        flexGrow: 0,
        flexShrink: 0,
        marginBottom: 0,
      }}>
        <Typography component="h3" sx={{
          fontStyle: 'italic',
        }}>
          {props.name}
        </Typography>
      </Box>
      <Box width="auto" flexGrow={1}>
        <Typography>
          {props.children}
        </Typography>
      </Box>
    </Box>
  );
}

function VolBreakDown() {
  return (
    <>
      <h2>VolBreakDown</h2>
      <Item name="Purpose">
        Break down PNO34+options to both features & parts. Automatic adjustment of any invalid PNO34+option specs.
      </Item>
      <Item name="Request">
        N/A
      </Item>
      <Item name="Response">
        Similar to previous break downs but result is returned in APL files (large data)
      </Item>
      <Item name="Current Source">
        CPAM
      </Item>
      <Item name="Temp. Source">
        CPAM / PRINS
      </Item>
      <Item name="Long Term Source">
        PNO Translator/Structure Check PRINS
      </Item>
      <Item name="Current API/Batch Jobs">
        batch service: VolBreakDown, VolBreakDown2wk
      </Item>
      <Stack my={4} spacing={1}>
        <Alert variant="outlined" severity="error">
          No solution at the moment.
        </Alert>
        <Alert variant="outlined" severity="info">
          This is essentially a proxy for CPAM and then FeatParts
        </Alert>
        <Alert variant="outlined" severity="warning">
          PRINS did a POC 2 years ago using CPAM web API and ElasticSearch.
          Got very little feedback and it seemed like it would not be used so it was removed.
        </Alert>
        <Alert variant="outlined" severity="warning">
          We could now do a similar solution with CPAM and Neo4j (See FeatParts below).
          However PRINS suggests that Hercules calls CPAM directly instead of going through PRINS.
        </Alert>
      </Stack>
    </>
  );
}
function FeatParts() {
  return (
    <>
      <h2>FeatParts</h2>
      <Item name="Purpose">
        Break down feature combination(s) to parts
      </Item>
      <Item name="Request">
        From week + to week + plant list + car type list + type code + feature combination list
      </Item>
      <Item name="Response">
        Out: List of part number + part description + function grp + pos+ occurence + valid from week + valid to week
      </Item>
      <Item name="Current Source">
        CPAM
      </Item>
      <Item name="Temp. Source">
        PRINS:GraphQL
      </Item>
      <Item name="Long Term Source">
        PRINS:GraphQL
      </Item>
      <Item name="Current API/Batch Jobs">
        Web service: FeatParts
      </Item>
      <Box my={4}/>
      <Stack my={4} spacing={1}>
        <Alert variant="outlined" severity="info">
          We have solution for this in PRINS using GraphQL or custom endpoint.
          <ul>
            <li><Link href="/documentation/breakdown-code-example">/documentation/breakdown-code-example</Link></li>
            <li><Link href="/tools/feature-breakdown-batch">/tools/feature-breakdown-batch</Link></li>
          </ul>
          There is also a third option to use blob storage files. Contact PRINS for more information
        </Alert>
        <Alert variant="outlined" severity="warning">
          From week + to week does not make sense. What happens if new parts are introduced during this period?
          <br/> Instead we should use structure week
        </Alert>
        <Alert variant="outlined" severity="warning">
          Prins will not supply part details. Result will instead contain a list of PartUsage TC IDs
        </Alert>
      </Stack>
    </>
  );
}

function PartDetails() {
  return (
    <>
      <h2>PartDetails</h2>
      <Item name="Purpose">
        Get Part Details (description and structure rows)
      </Item>
      <Item name="Request">
        In: Plant(factory) code or empty and part no
      </Item>
      <Item name="Response">
        Out 1. Part + Text  and 2. Type,  Factory Code, function group, pos, post, part number, flag , color, number, start week , end week and Features
      </Item>
      <Item name="Current Source">
        CPAM
      </Item>
      <Item name="Temp. Source">
        PRINS:GraphQL
      </Item>
      <Item name="Long Term Source">
        PRINS:GraphQL
      </Item>
      <Item name="Current API/Batch Jobs">
        Web service: PartDetails
      </Item>
      <Stack my={4} spacing={1}>
        <Alert variant="outlined" severity="info">
          We have a similar example of this on the part variant breakdown page. Here is the query:
        </Alert>
      </Stack>

      <GraphQLCodeFile
        file={PartDetailsQuery}
        variables={
          {
            plantCode: 21,
            partNumbers: ['32365771', '80006178', '32365770', '80006177'],
          }
        }
      />
    </>
  );
}

function PartFeatBreakUp() {
  return (
    <>
      <h2>PartFeatBreakUp</h2>
      <Item name="Purpose">
        Break up a part to feature combinations (Creating CC)
      </Item>
      <Item name="Request">
        In: Plant(factory) code, part no , start and end week
      </Item>
      <Item name="Response">
        Out Plant(factory) code, car models, type codes, feature 1 to 5
      </Item>
      <Item name="Current Source">
        CPAM
      </Item>
      <Item name="Temp. Source">
        PRINS:GraphQL? CPAM?
      </Item>
      <Item name="Long Term Source">
        ?
      </Item>
      <Item name="Current API/Batch Jobs">
        Web service: PartFeatBreakUp
      </Item>

      <GraphQLCodeFile
        file={PartFeatBreakUpQuery}
        variables={{
          plantCode: 21,
          in: 202916,
          out: 202352,
          partNumbers: ['32365771', '80006178', '32365770', '80006177'],
        }}
      />
    </>
  );
}

function PartBreakUp() {
  return (
    <>
      <h2>PartBreakUp</h2>
      <Item name="Purpose">
        Break up a part to PNO34+options
      </Item>
      <Item name="Request">
        In: User Id, Part structure version, part number , start and end week.
      </Item>
      <Item name="Response">
        Out: plant(factory) + pno23 + start week + end week + option codes
      </Item>
      <Item name="Current Source">
        CPAM
      </Item>
      <Item name="Temp. Source">
        PRINS (PRINS will call to CPAM)?
      </Item>
      <Item name="Long Term Source">
        PNO Translator
        PRINS (PRINS will call to TCPLM)?
      </Item>
      <Item name="Current API/Batch Jobs">
        Web service: PartBreakUp
      </Item>

      <Stack my={4} spacing={1}>
        <Alert variant="outlined" severity="warning">
          Only part number , start and end week as input
        </Alert>
        <GraphQLCodeFile
          file={PartBreakUpQuery}
          variables={{
            input: {
              partNumber: '32365770',
              start: 202352,
              end: 202916,
            },
          }}
        />
      </Stack>
    </>
  );
}

function PartWeeks() {
  return (
    <>
      <h2>PartWeeks</h2>
      <Item name="Purpose">
        Get parts valid weeks per plant (and car model)
      </Item>
      <Item name="Request">
        In: User Id, list of part numbers with plant code , car model (optional)
      </Item>
      <Item name="Response">
        Out: part numbers + plants(factory) + buyer code + start week + end week
      </Item>
      <Item name="Current Source">
        CPAM
      </Item>
      <Item name="Temp. Source">
        PRINS/CPAM?
      </Item>
      <Item name="Long Term Source">
        PRINS
      </Item>
      <Item name="Current API/Batch Jobs">
        Web service: PartWeeks
      </Item>

      <Stack my={4} spacing={1}>
        <Alert variant="outlined" severity="warning">
          We don´t have car type. Result will not contain buyer code.
        </Alert>
      </Stack>

      We have two different solutions. Note that the second argument is optional and can be skipped to include all.

      Individual Consumption records:

      <GraphQLCodeFile
        file={PartWeeksQuery}
        variables={{
          partNumbers: ['32365771', '80006178', '32365770', '80006177'],
          plantWhere: { plantCode: 21 },
        }}
      />

      Or grouped by part number:

      <GraphQLCodeFile
        file={PartWeeks2Query}
        variables={{
          partNumbers: ['32365771', '80006178', '32365770', '80006177'],
          consumptionRecordWhere: {
            plant: { plantCode: 21 },
          },
        }}
      />
    </>
  );
}

function PSSGet() {
  return (
    <>
      <h2>PSSGet</h2>
      <Item name="Purpose">
        Get PSS codes and descriptions
      </Item>
      <Item name="Request">
        In: User Id, PSScodes (Optional)
      </Item>
      <Item name="Response">
        Out: PSS codes and PSS Names
      </Item>
      <Item name="Current Source">
        CPAM
      </Item>
      <Item name="Temp. Source">
        PRINS?
      </Item>
      <Item name="Long Term Source">
        PRINS
      </Item>
      <Item name="Current API/Batch Jobs">
        Web service: PSSGet
      </Item>

      <Stack my={4} spacing={1}>
        <Alert variant="outlined" severity="info">
          Result contain duplicates when in multiple car models. Has to be filtered on client side.
        </Alert>
      </Stack>

      <GraphQLCodeFile
        file={PSSGetQuery}
        variables={{
        }}
      />
    </>
  );
}
