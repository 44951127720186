import React from 'react';
import { Button, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TimelineIcon from '@mui/icons-material/Timeline';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import GraphView from './GraphView';

interface Props {
  query: string;
  variables?: string;
  dagMode: boolean;
  label: string;
  toolTip: string;
}

export function GraphViewButton(props: Props) {
  const {
    query, dagMode, label, toolTip, variables,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Tooltip title={toolTip} placement="top">
        <Button
          onClick={() => setOpen(true)}
          startIcon={<TimelineIcon/>}
          color="inherit"
        >
          {label}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth={false}
        keepMounted={false}
        disablePortal={true}
      >
        <DialogContent sx={{ p: 0 }}>
          <GraphView query={query} variables={variables} dagMode={dagMode} />
        </DialogContent>
        <DialogActions>
          <Alert severity="info">Only nodes with the “id” property will be visible in the graph.</Alert>
          <Box flexGrow={1}></Box>
          <Button onClick={() => setOpen(false)} color="error" variant={'contained'}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
