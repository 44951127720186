import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { LmodDialog_ConsumptionRecordFragment } from '../../../../../../generated/graphql';
import { FieldCoi } from '../../common/FieldCoi';
import { FieldBoolean, FieldInteger, FieldString } from '../../common/FieldValues';
import { ConsumptionRecordDialogButton } from '../../consumption-records/ConsumptionRecordDialogButton';

interface Props {
  consumptionRecords: LmodDialog_ConsumptionRecordFragment[];
}

export function ConsumptionRecordsTable(props: Props) {
  const { consumptionRecords } = props;

  if (consumptionRecords.length === 0) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Type Code</TableCell>
            <TableCell align="right">POST</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>DU-marking</TableCell>
            <TableCell>Plant</TableCell>
            <TableCell>Is revoked</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consumptionRecords?.map((c) => (
            <TableRow
              key={c.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                ...(c.isRevoked ? {
                  opacity: 0.5,
                  textDecoration: 'line-through',
                } : {}),
              }}
            >
              <TableCell padding="checkbox">
                <ConsumptionRecordDialogButton id={c.id} />
              </TableCell>
              <TableCell>
                <FieldString value={c.mbom?.ebom?.productContext?.typeCode} />
              </TableCell>
              <TableCell>
                <FieldInteger value={c.POST} alignRight />
              </TableCell>
              <TableCell>
                <FieldString value={c.partUsage?.part?.partNumber} />
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={c.changeOrderIssueAdd?.coiNumber}
                  coiNumberTooltip="consumptionRecord.changeOrderIssueAdd.coiNumber"
                  week={c.effectiveInWeek}
                  weekTooltip="consumptionRecord.effectiveInWeek"
                />
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={c.changeOrderIssueDel?.coiNumber}
                  coiNumberTooltip="consumptionRecord.changeOrderIssueDel.coiNumber"
                  week={c.effectiveOutWeek}
                  weekTooltip="consumptionRecord.effectiveOutWeek"
                />
              </TableCell>
              <TableCell>
                <FieldString value={c.duMarking} />
              </TableCell>
              <TableCell>
                <FieldInteger value={c.plant?.plantCode} />
              </TableCell>
              <TableCell>
                <FieldBoolean value={c.isRevoked} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
