import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export function LoadingOverlay() {
  return <Box p={1} sx={{
    top: 0,
    left: 0,
    position: { md: 'absolute' },
    background: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  }}>
    <CircularProgress/>
  </Box>;
}
