/* eslint-disable max-len */
import React, { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { useWindowSize } from '@react-hook/window-size';
import { SlideUpTransition } from '../parts/PartDialog/SlideUpTransition';
import ErrorMessage from '../../../../common/ErrorMessage';
import {
  ChangeOrderIssueDialogDocument,
  ChangeOrderIssueDialogQuery,
  useChangeOrderIssueDialogQuery,
} from '../../../../../generated/graphql';
import { FieldListValue } from '../common/FieldListValue';
import { CoiPartVersionTable } from './coi-dialog/CoiPartVersionTable';
import { CoiPartUsageTable } from './coi-dialog/CoiPartUsageTable';
import { CoiPartConstituentTable } from './coi-dialog/CoiPartConstituentTable';
import { CoiConsumptionRecordTable } from './coi-dialog/CoiConsumptionRecordTable';
import { PrettyJson } from '../../../../common/data/PrettyJson';
import { PrettyQuery } from '../../../../common/data/PrettyQuery';
import { formatQueryResultForGraph } from '../../../../common/GraphqlCodeBlock/GraphView/graphview.util';
import { GraphViewResult } from '../../../../common/GraphqlCodeBlock/GraphView/GraphView';
import { DialogLoader } from '../../../../common/dialog/DialogLoader';

interface Props {
  id: string,
  close: () => void
}

export function ChangeOrderIssueDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = React.useState('1');
  const [width, height] = useWindowSize();

  const { data, loading, error } = useChangeOrderIssueDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const changeOrderIssue = data?.changeOrderIssues[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      TransitionComponent={SlideUpTransition}
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogTitle>
        <Stack justifyContent="space-between" alignItems="center" direction="row">
          <div>Change Order Issue {changeOrderIssue?.coiNumber}</div>
          <Button color="error" onClick={handleClose}>Close</Button>
        </Stack>
      </DialogTitle>
      {loading && <DialogLoader/>}
      <ErrorMessage error={error}/>
      <TabContext value={tab} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Preview" value="1" />
            <Tab label="Graph" value="2" />
            <Tab label="Json" value="3" />
            <Tab label="Example Query" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <DataTab data={data}></DataTab>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          {data && <GraphViewResult
            graphData={formatQueryResultForGraph(data)}
            width={Math.min(width - 64, 1536)}
            height={height - 32 - 156}
            displayTypename
            dagMode
          />}
        </TabPanel>
        <TabPanel value="3">
          {data && <PrettyJson data={data} />}
        </TabPanel>
        <TabPanel value="4">
          <PrettyQuery query={ChangeOrderIssueDialogDocument.loc?.source.body || ''}/>
        </TabPanel>
      </TabContext>

    </Dialog>
  );
}

function DataTab({ data }: { data?: ChangeOrderIssueDialogQuery }) {
  const changeOrderIssue = data?.changeOrderIssues[0];

  return (
    <>
      <Box p={2} mb={4}>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="coiNumber" value={changeOrderIssue?.coiNumber}/>
            <FieldListValue name="introductionWeek" value={changeOrderIssue?.introductionWeek}/>
            <FieldListValue name="createdAt" value={changeOrderIssue?.createdAt}/>
          </List>
        </Paper>
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>Change Order</Typography>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="coNumber" value={changeOrderIssue?.changeOrder?.coNumber}/>
            <FieldListValue name="name" value={changeOrderIssue?.changeOrder?.name}/>
            <FieldListValue name="description" value={changeOrderIssue?.changeOrder?.description}/>
            <FieldListValue name="createdAt" value={changeOrderIssue?.changeOrder?.createdAt}/>
          </List>
        </Paper>
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Part Versions&nbsp;
          <Typography color="info.dark" component="span" variant="inherit">Introduced</Typography>
        </Typography>
        <CoiPartVersionTable rows={changeOrderIssue?.partVersions || []}/>
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Part Usages&nbsp;
          <Typography color="info.dark" component="span" variant="inherit">Added</Typography>
        </Typography>
        <CoiPartUsageTable rows={changeOrderIssue?.partUsageAdds || []}/>
      </Box>
      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Part Usages&nbsp;
          <Typography color="error.dark" component="span" variant="inherit">Deleted</Typography>
        </Typography>
        <CoiPartUsageTable rows={changeOrderIssue?.partUsageDels || []}/>
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Part Constituents&nbsp;
          <Typography color="info.dark" component="span" variant="inherit">Added</Typography>
        </Typography>
        <CoiPartConstituentTable rows={changeOrderIssue?.PartConstituentAdds || []}/>
      </Box>
      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Part Constituents&nbsp;
          <Typography color="error.dark" component="span" variant="inherit">Deleted</Typography>
        </Typography>
        <CoiPartConstituentTable rows={changeOrderIssue?.PartConstituentDels || []}/>
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Consumption Records&nbsp;
          <Typography color="info.dark" component="span" variant="inherit">Added</Typography>
        </Typography>
        <CoiConsumptionRecordTable rows={changeOrderIssue?.ConsumptionRecordAdds || []}/>
      </Box>
      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Consumption Records&nbsp;
          <Typography color="error.dark" component="span" variant="inherit">Deleted</Typography>
        </Typography>
        <CoiConsumptionRecordTable rows={changeOrderIssue?.ConsumptionRecordDels || []}/>
      </Box>
    </>
  );
}
