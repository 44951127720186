import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { SimpleCardLayout } from '../layout/SimpleCardLayout';
import { UpstreamDashboard } from '../control-panel/Upstream/UpstreamDashboard';

export function UpstreamDashboardPage() {
  const { data: authData } = useAuth();

  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  return (
    <>
      <UpstreamDashboard/>
    </>
  );
}
