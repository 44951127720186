import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { Alert, MenuItem } from '@mui/material';
import {
  ApplicationDetailsFragment,
  useCreateRegisteredWebhookMutation,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { ReactHookFormSelect } from '../../common/ReactHookFormSelect';
import { useAuth } from '../../../hooks/useAuth';
import { webhooks } from './webhook.consts';
import { EnvironmentOption } from '../../../util/envrionments';
import { EnvironmentSelect } from '../../common/forms/EnvironmentSelect';

interface Props {
  application: ApplicationDetailsFragment;
}

interface IFormInput {
  description: string;
  url: string;
  eventType: string;
  environment: EnvironmentOption;
}

export function WebhookCreateButton(props: Props) {
  const { application } = props;
  const [call, { loading, error }] = useCreateRegisteredWebhookMutation();
  const [open, setOpen] = React.useState(false);
  const { data: authData } = useAuth();

  const {
    register, control, handleSubmit, watch,
  } = useForm<IFormInput>();

  const selectedEventType = watch('eventType');
  const selectedEventTypeDefinition = webhooks.find((w) => w.name === selectedEventType);

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    const idTokenClaims = authData?.account?.idTokenClaims as { sid?: string };
    if (!idTokenClaims?.sid) {
      return;
    }

    await call({
      variables: {
        input: {
          url: formData.url,
          description: formData.description,
          eventType: formData.eventType,
          appId: application.appId,
          environment: formData.environment.environment,
        },
      },
      refetchQueries: 'all',
    });

    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startIcon={<AddIcon/>}
      >
        Register a new webhook
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>New Webhook for {application.appName}</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Register an your webhook url here. <br/>
              PRINS GraphHub will call this url (POST request) when the selected event is triggered.
              <br/>
              Dont forget to add the entire url including the protocol (http:// or https://).
            </DialogContentText>
            <ErrorMessage error={error}/>
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              {...register('description', { required: true, minLength: 2 })}
            />
            <ReactHookFormSelect
              label="Event type"
              name="eventType"
              control={control}
              rules={{ required: true }}
              variant="outlined"
              margin="dense"
            >
              {webhooks.map((w, i) => <MenuItem key={i} value={w.name}>{w.label}</MenuItem>)}
            </ReactHookFormSelect>
            {selectedEventTypeDefinition && (
              <Alert severity="info" sx={{ mb: 2, fontSize: '0.8em' }}>
                {selectedEventTypeDefinition.description}
              </Alert>
            )}
            <TextField
              margin="dense"
              label="Url"
              fullWidth
              {...register('url', { required: true, minLength: 12 })}
            />
            <Controller
              render={({ field: { ref, ...rest }, fieldState }) => (
                <EnvironmentSelect
                  {...rest}
                  state={fieldState}
                  textFieldProps={{ margin: 'dense' }}
                  value={rest.value || null} // Forced controlled input
                />
              )}
              name={'environment'}
              rules={{
                required: true,
              }}
              control={control}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>Create</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
