import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
import { SimpleCardLayout } from '../../layout/SimpleCardLayout';
import { Section } from '../../layout/Section';
import { RunOperationsOverview } from './RunOperationsOverview';

export function RunOperationsPage() {
  const { data: authData } = useAuth();

  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  return (
    <>
      <Section mb={10} maxWidth={1200}>
        <RunOperationsOverview/>
      </Section>
    </>
  );
}
