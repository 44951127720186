import React from 'react';
import { LinearProgress } from '@mui/material';
import { yearWeekFormat } from '../../../../../../util/date';
import { QuantityCheck } from '../../../../../tools/quantity-check/QuantityCheck';

interface Props {
  typeCode: string;
  features: string[];
}

export function QuantityQualityCheckTab(props: Props) {
  const { typeCode, features } = props;

  if (!typeCode) {
    return <LinearProgress />; // In case it has not been loaded yet
  }

  return (
    <>
      <QuantityCheck
        defaultValues={{
          structureWeek: yearWeekFormat(),
          typeCode,
          features,
        }}
      />
    </>
  );
}
