import React from 'react';
import { Alert, LinearProgress } from '@mui/material';
import { useStaticFile } from '../../hooks/useStaticFile';
import { ReactMarkdownWithSyntaxHighlighting } from './ReactMarkdownWithSyntaxHighlighting';

import './Markdown.scss';

interface Props {
  file: string
}

export function MarkdownFile(props: Props) {
  const { file } = props;
  const { loading, error, content } = useStaticFile(file);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return <ReactMarkdownWithSyntaxHighlighting content={content} />;
}
