import { useEffect, useRef } from 'react';

function useInterval(callback: () => void, ms: number) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setInterval(() => savedCallback.current(), ms);

    return () => clearInterval(id);
  }, [ms]);
}

export default useInterval;
