import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import React from 'react';
import { FieldList } from './FieldList';
import { LmodDialogQuery } from '../../../../../../generated/graphql';
import { PartVersionsTable } from '../../parts/PartDialog/PartVersionsTable';
import { ConsumptionRecordsTable } from './LmodConsumptionRecordsTable';
import { LmodConstituentTable } from './LmodConstituentTable';

type LMOD = LmodDialogQuery['deliveryModules'][0];

interface Props {
  lmod?: LMOD;
}

export function LmodDialogInfoPanel(props: Props) {
  const { lmod } = props;
  const allConsumptionRecords = lmod?.consumptionRecords.map((c) => c) || [];

  return (
    <Grid container spacing={3} p={3}>

      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>
            Part Versions
          </Typography>
          <PartVersionsTable partVersions={lmod?.partVersions || []} />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>
            Delivery Module Constituents
          </Typography>
          {lmod?.dModuleConstituents.length === 0 ? (
            <Alert severity="info">
              This delivery module does not have any constituents
            </Alert>
          ) : (
            <LmodConstituentTable lmod={lmod}/>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper variant="outlined">
          <FieldList lmod={lmod} />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>
            Consumption Records
          </Typography>
          {allConsumptionRecords.length > 0 ? (
            <ConsumptionRecordsTable consumptionRecords={allConsumptionRecords} />
          ) : (
            <Alert severity="info">
              This delivery module does not have any consumption records.
            </Alert>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
