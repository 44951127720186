import React, { PropsWithChildren } from 'react';
import { Stack, StackProps } from '@mui/material';

export function FormFooter(props: PropsWithChildren<StackProps>) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      mt={2}
      {...props}
    />
  );
}
