import { Fetcher } from '@graphiql/toolkit/src/create-fetcher/types';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { useSelectedEnvironment } from '../../../hooks/useSelectedEnvironment';
import { useAuth } from '../../../hooks/useAuth';

let key = '';
let fetcher: Fetcher;

export function useFetcher() {
  const [selectedEnv] = useSelectedEnvironment();
  const auth = useAuth();

  // If one of these changes then we create a new fetcher
  const newKey = (auth?.data?.idToken || '') + selectedEnv.graphQLEndpoint;

  if (newKey !== key) {
    fetcher = createGraphiQLFetcher({
      url: selectedEnv.graphQLEndpoint,
      headers: {
        authorization: auth.data ? `Bearer ${auth.data.idToken}` : '',
      },
    });

    key = newKey;
  }

  // The same fetcher is now shared by all components using this hook
  return fetcher;
}
