/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridColDef } from '@mui/x-data-grid';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { dateTimeFormat, dateTimeFormatWithSeconds } from './date';

export function stringColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    type: 'string',
  };
}

export function stringArrayColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    filterable: false, // Don't have support for this (yet)
    type: 'string',
  };
}

export function numberColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    cellClassName: 'font-monospace',
    align: 'right',
    headerAlign: 'right',
    type: 'number',
  };
}

export function weekColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    ...numberColumn(field),
    valueFormatter: (g) => g.value, // no number formatting
  };
}

export function booleanColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    type: 'boolean',
  };
}

export function dateTimeColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    cellClassName: 'font-monospace',
    width: 170,
    type: 'dateTime',
    valueFormatter: (v) => dateTimeFormat(v.value),
  };
}
export function dateTimeWithSecondsColumn<R extends GridValidRowModel = any>(
  field: string,
): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    cellClassName: 'font-monospace',
    width: 220,
    type: 'dateTime',
    valueFormatter: (v) => dateTimeFormatWithSeconds(v.value),
  };
}

export function timeColumn<R extends GridValidRowModel = any>(
  field: string,
): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    cellClassName: 'font-monospace',
    width: 160,
    align: 'right',
    type: 'string',
    valueFormatter: (v) => formatTime(v.value),
  };
}

export function enumColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    width: 170,
    type: 'singleSelect',
    valueOptions: [],
  };
}

export function subFieldColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: field,
    headerName: field,
    sortable: false,
    filterable: false,
  };
}

export function aggregateColumn<R extends GridValidRowModel = any>(field: string): GridColDef<R> {
  return {
    field,
    description: `${field}.count`,
    headerName: field,
    valueGetter: (v) => v.value?.count || '-',
    sortable: false,
    filterable: false,
    align: 'right',
    headerAlign: 'right',
    width: 80,
    cellClassName: 'font-monospace',
  };
}

function formatTime(milliseconds: number): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const millis = milliseconds % 1000;

  const hoursDisplay = hours > 0 ? `${hours}h ` : '';
  const minutesDisplay = minutes > 0 ? `${minutes}m ` : '';
  const secondsDisplay = seconds > 0 ? `${seconds}s ` : '';
  const millisDisplay = millis > 0 ? `${millis}ms` : '';

  return `${hoursDisplay}${minutesDisplay}${secondsDisplay}${millisDisplay}`.trim();
}
