/* eslint-disable max-len */
import React from 'react';
import { Alert, Typography } from '@mui/material';
import { useSelectedEnvironment } from '../../hooks/useSelectedEnvironment';
import { Section } from '../layout/Section';

export function PreProdHelpSection() {
  const [selectedEnv] = useSelectedEnvironment();
  const isPreProd = selectedEnv.key === 'preProd';

  if (!isPreProd) {
    return null;
  }

  return (
    <Section>
      <Alert severity="info">
        <Typography variant="body1">
          Every night data is copied from production to pre-production.
        </Typography>
      </Alert>
    </Section>
  );
}
