import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, TextField, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorMessage from '../../common/ErrorMessage';
import {
  useUserApplicationCreateMutation,
} from '../../../generated/graphql';

interface IFormInput {
  teamName: string;
  message: string;
  appName: string;
  appId: string;
}

export function ApplicationCreateForm() {
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>();

  const [call, { loading, error, data }] = useUserApplicationCreateMutation();

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    call({
      variables: {
        input: {
          teamName: formData.teamName,
          appName: formData.appName,
          appId: formData.appId,
          message: formData.message,
        },
      },
      refetchQueries: ['MyApplications', 'Applications'],
    }).then();

    return null;
  };

  if (data) {
    return (
      <Alert severity="success">
        A request to register an application has been submitted for review. <br/>
        Please come back when your request has been approved.
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage error={error}/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Application name"
            error={Boolean(errors.appName)}
            helperText={errors.appName && errors.appName.message}
            InputProps={{
              endAdornment: (
                <Tooltip title='Descriptive and unique name for this application'>
                      <InfoOutlinedIcon color='info'/>
                </Tooltip>),
            }}
            {...register('appName', {
              required: 'Application name is required',
              maxLength: { value: 60, message: 'Maximum length is 60 characters' },
              minLength: { value: 2, message: 'Minimum length is 2 characters' },
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Application ID (Alfabet)"
            type="number"
            error={Boolean(errors.appId)}
            helperText={errors.appId && errors.appId.message}
            InputProps={{
              endAdornment: (
                <Tooltip title='The organizational app id (Alfabet) of your team, e.g 123.'>
                  <InfoOutlinedIcon color='info'/>
                </Tooltip>),
            }}
            {...register('appId', {
              required: 'Application ID is required',
              maxLength: { value: 60, message: 'Maximum length is 60 characters' },
              minLength: { value: 2, message: 'Minimum length is 2 characters' },
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Team name"
            error={Boolean(errors.teamName)}
            helperText={errors.teamName && errors.teamName.message}
            InputProps={{
              endAdornment: (
                <Tooltip title='The name of your team'>
                      <InfoOutlinedIcon color='info'/>
                </Tooltip>),
            }}
            {...register('teamName', {
              required: 'Team name is required',
              maxLength: { value: 60, message: 'Maximum length is 60 characters' },
              minLength: { value: 2, message: 'Minimum length is 2 characters' },
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>

        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Describe your application (optional)"
            error={Boolean(errors.message)}
            helperText={errors.message && errors.message.message}
            multiline
            InputProps={{
              endAdornment: (
                <Tooltip title='Tell us why this application is needed and what it will be used for.'>
                      <InfoOutlinedIcon color='info'/>
                </Tooltip>),
            }}
            {...register('message', {
              maxLength: { value: 400, message: 'Maximum length is 400 characters' },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography align="right" component="div">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
            >Submit Request</LoadingButton>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
