import React from 'react';
import Typography from '@mui/material/Typography';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import Gql1 from './stored-cpv-set-options.graphql';
import Gql2 from './stored-cpv-set-options2.graphql';

export function StoredCpvSetCodeExamplePage() {
  return (
    <>
      <h1>Stored Cpv Set</h1>

      <Typography paragraph mt={2}>
        Stored CPV sets are a predefined sets of CPV codes, also known as features.
        These are only used by Cars produced by Geely or Polestar.
      </Typography>

      <Typography paragraph mt={4}>
        Note that when fetching stored cpv sets you should
        always use pagination as this data set can be very large.
        Therefor there is a hard limit on 10 000 items per page,
        even if no limit is set by the user.
      </Typography>

      <h2>Filtering using options</h2>

      <Typography paragraph>
        This example fetches a stored CPV set by its pno34 and returns the options.
        Note that options in this field are always sorted <strong>alphabetically</strong> and
        needs to be the sorted the same in the query.
      </Typography>

      <GraphQLCodeFile
        file={Gql1}
        variables={{
          pno34: '416EK650E14962600R5400000000000000',
          options: [
            '200001',
            '200002',
          ],
        }}
      />

      <Typography paragraph>
        If you want to fetch a StoredCpvSet using only a subset of options
        you can use a combination of ”AND” and ”options_include”
      </Typography>

      <GraphQLCodeFile
        file={Gql2}
        variables={{
          pno34: '416EK650E14962600R5400000000000000',
          where: [
            { options_INCLUDES: '200001' },
            { options_INCLUDES: '200002' },
          ],
        }}
      />

      <h2>PML IDs</h2>

      <Typography paragraph>
        the field <strong>pmlIds</strong> and
        <strong>pmlIdsWithTypeCode</strong> are dynamic fields that is generated
        from the cpvs and plant.
        This field is calculated in real time and is not stored in the database. Therefore it can
        not be used in filters or sorting.
      </Typography>

      <h2>TypeCode</h2>

      <Typography paragraph>
        The cpv set is tied to one typecode,
        however it can represent multiple typecodes for the same project.
        Therefore, PML IDs can belong to any typecode.
      </Typography>
    </>
  );
}
