/* eslint-disable max-len */
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  AdminWebhookDetailsFragment,
  ApplicationDetailsFragment,
  UserWebhookDetailsFragment,
} from '../../../generated/graphql';
import { RegisteredWebhooksTable } from '../Webhooks/RegisteredWebhooksTable';
import { WebhookCreateButton } from '../Webhooks/WebhookCreateButton';

interface Props {
  application: ApplicationDetailsFragment & {
    webhooks: UserWebhookDetailsFragment[] | AdminWebhookDetailsFragment[]
  },
  refresh: () => void,
}

export function ApplicationWebhooks(props: Props) {
  const { refresh, application } = props;

  return (
    <>
      <Box mb={4}>
        <RegisteredWebhooksTable webhooks={application.webhooks} refresh={refresh}/>
        <Stack spacing={2} direction="row" justifyContent="center" mt={2}>
          <WebhookCreateButton application={application}/>
        </Stack>
      </Box>
    </>
  );
}
