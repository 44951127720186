import React from 'react';
import Typography from '@mui/material/Typography';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { gql } from '@apollo/client';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import Gql1 from './bom-instance.graphql';
import { docsClient } from '../../../../../services/apollo';
import { Art } from '../../../../../generated/graphql';
import { downloadStringAsFile } from '../../../../../util/fileDownload';

const variables = {
  bomInstances: {
    OR: [
      { topNode: '200' },
      { topNode: '200KAR' },
    ],
  },
};

const bomQuery = `
query ExampleBomExport($where: ArtWhere) {
  arts(where: $where) {
    name
    number
    plmItemId
    functionGroups {
      name
      number
      plmItemId
      fgPos {
        name
        number
        plmItemId
        partAddresses(where: {releaseStatus: RELEASED}) {
          name
          number
          plmItemId
          partUsages {
            quantity
            part {
              partNumber
              aWeight
              iWeight
              weightType
            }
          }
        }
      }
    }
  }
}
`;

async function bomToCsv() {
  const result = await docsClient.query({
    query: gql(bomQuery),
    variables,
  });

  // Source: https://stackoverflow.com/questions/46637955/write-a-string-containing-commas-and-double-quotes-to-csv
  const toCsv = (table: string[][]) => {
    return table
      .map((row) => row
        .map((cell) => {
          // We remove blanks and check if the column contains
          // other whitespace,`,` or `"`.
          // In that case, we need to quote the column.
          if (cell.replace(/ /g, '').match(/[\s,"]/)) {
            return `"${cell.replace(/"/g, '""')}"`;
          }
          return cell;
        }).join(','))
      .join('\n');
  };

  // Loop through the result and create a multi level array of the data
  const csvLines: string[][] = [];
  result.data.arts.forEach((art: Art) => {
    art.functionGroups.forEach((fg) => {
      fg.fgPos.forEach((fgPos) => {
        fgPos.partAddresses.forEach((pa) => {
          pa.partUsages.forEach((pu) => {
            csvLines.push([
              art.name,
              art.number,
              art.plmItemId,
              fg.name,
              fg.number,
              fg.plmItemId,
              fgPos.name,
              fgPos.number,
              fgPos.plmItemId,
              pa.name,
              pa.number,
              pa.plmItemId,
              pu.quantity.toString(),
              pu.part?.partNumber || '',
              pu.part?.aWeight || '',
              pu.part?.iWeight || '',
              pu.part?.weightType || '',
            ]);
          });
        });
      });
    });
  });

  // Merge all cells and lines
  return toCsv(csvLines);
}

const tsExample = `
import { request, gql } from 'graphql-request';

// The GraphQL Query
const query = gql\`${bomQuery}\`;

const variables = {
  bomInstances: {
    OR: [
      { topNode: '200' },
      { topNode: '200KAR' },
    ],
  },
};

// Perform the request
const result = await request({
  url: prinsEndpoint,
  document: query,
  variables: variables,
  requestHeaders: {
    Authorization: 'AppKey XXXXXXXXXXXXXXXXXXXXXXXXX:XXXXXXXXXXXXXXXXXXXXXX'
  },
});

// Function to convert a multi level array to a csv string
const toCsv = (table: string[][]) => {
  return table
    .map((row) => row
      .map((cell) => {
        // We remove blanks and check if the column contains
        // other whitespace,\`,\` or \`"\`.
        // In that case, we need to quote the column.
        if (cell.replace(/ /g, '').match(/[\\s,"]/)) {
          return \`"\${cell.replace(/"/g, '""')}"\`;
        }
        return cell;
      }).join(','))
    .join('\\n');
};

// Loop through the result and create a multi level array of the data
const csvLines: string[][] = [];
result.data.arts.forEach((art: Art) => {
  art.functionGroups.forEach((fg) => {
    fg.fgPos.forEach((fgPos) => {
      fgPos.partAddresses.forEach((pa) => {
        pa.partUsages.forEach((pu) => {
          csvLines.push([
            art.name,
            art.number,
            art.plmItemId,
            fg.name,
            fg.number,
            fg.plmItemId,
            fgPos.name,
            fgPos.number,
            fgPos.plmItemId,
            pa.name,
            pa.number,
            pa.plmItemId,
            pu.quantity.toString(),
            pu.part?.partNumber || '',
            pu.part?.aWeight || '',
            pu.part?.iWeight || '',
            pu.part?.weightType || '',
          ]);
        });
      });
    });
  });
});

// Merge all cells and lines
return toCsv(csvLines);
`;

const jsExample = tsExample
  .replace(/: string\[\]\[\]/g, '')
  .replace(/: string\[\]/g, '')
  .replace(/: string/g, '')
  .replace(/: Art\)/g, ')');

export function BomLinesCodeExamples() {
  return (
    <>
      <h1>Bill of material</h1>

      <Typography paragraph>
        Here we can se an example of what the data structure
        of Arts, Function Groups, FG POSes and PartAddresses
        Tip: Try the button ”view as graph” to get a more visual representation of the data.
      </Typography>
      <GraphQLCodeFile file={Gql1} showGraph={true} showDagGraph={true} />

      <h2>Processing the data</h2>
      <Typography paragraph>
        You might want to modify the structure to fit your needs.
        Here is an example of how to convert the JSON returned into CSV (Comma Separated Values)
      </Typography>

      <JavaScriptExample/>
    </>
  );
}

function JavaScriptExample() {
  const [tab, setTab] = React.useState('1');
  const [codeResult, setCodeResult] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const onRun = async () => {
    setLoading(true);
    setCodeResult(await bomToCsv());
    setLoading(false);
  };

  return (
    <>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="TypeScript" value="1"/>
            <Tab label="JavaScript" value="2"/>
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <SyntaxHighlighter
            children={tsExample.trim()}
            language='typescript'
            style={materialLight}
            PreTag="div"
            customStyle={{ fontSize: '13px' }}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <SyntaxHighlighter
            children={jsExample.trim()}
            language='javascript'
            style={materialLight}
            PreTag="div"
            customStyle={{ fontSize: '13px' }}
          />
        </TabPanel>
      </TabContext>
      <Stack spacing={1} direction="row">
        <LoadingButton
          loading={loading}
          onClick={onRun}
          variant="contained"
        >Run code</LoadingButton>
        {codeResult && (
          <Button
            onClick={() => downloadStringAsFile(codeResult, 'bom.csv')}
            variant="contained"
          >Download as csv</Button>
        )}
      </Stack>
      {codeResult && <SyntaxHighlighter
          children={codeResult}
          language='csv'
          style={materialLight}
          PreTag="div"
          customStyle={{ fontSize: '11px' }}
      />}
    </>
  );
}
