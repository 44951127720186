/* eslint-disable max-len */
import Typography from '@mui/material/Typography';
import React from 'react';
import { Alert } from '@mui/material';

export function SIPlusCaseI00569() {
  return (
    <>
      <Typography variant="h3" mb={3}>Description of the existing KDP integration</Typography>
      <Typography paragraph>This file describes Delivery modules in KDP (see BL3).</Typography>

      <Typography paragraph>In KDP we use the consumptions to find out which parts that is used.</Typography>

      <Typography paragraph>We also know that this will be handled differently in Teamcenter. Probably you need to fetch this information from the M-BOM, please discuss with the architects for Teamcenter how to find the correct data.</Typography>

      <Alert severity="error">Can´t be completed at the moment because of delivery modules not beeing completely implemented in Teamcenter</Alert>
    </>
  );
}
