import { GridColDef, GridFilterModel } from '@mui/x-data-grid';

interface Where {
  [Key: string]: string | number | boolean | null | undefined;
}

export function convertDataGridFilterToGraphQLWhere(
  model: GridFilterModel,
  columns: GridColDef[],
): Where {
  const w: Where = {};

  // console.log(model);

  model.items.forEach((item) => {
    if (
      // Dont filter when there is no value ...
      item.value === undefined
      // ... unless filter is one of the following
      && !['isEmpty', 'isNotEmpty'].includes(item.operatorValue || '')
    ) {
      return;
    }

    const colDef = columns?.find((c) => item.columnField === c.field);

    let { value } = item;

    // Convert to number or array of numbers
    if (colDef?.type === 'number' && typeof value === 'string') {
      value = +value;
    }

    if (colDef?.type === 'number' && Array.isArray(value)) {
      value = value.map((v) => +v);
    }

    switch (item.operatorValue) {
      // string
      case 'contains':
        w[`${item.columnField}_CONTAINS`] = value;
        break;
      case 'equals':
        w[`${item.columnField}`] = value;
        break;
      case 'startsWith':
        w[`${item.columnField}_STARTS_WITH`] = value;
        break;
      case 'endsWith':
        w[`${item.columnField}_ENDS_WITH`] = value;
        break;
      case 'isEmpty':
        w[`${item.columnField}`] = null;
        break;
      case 'isNotEmpty':
        w[`${item.columnField}_NOT`] = null;
        break;
      case 'isAnyOf':
        if (item.value.length > 0) {
          w[`${item.columnField}_IN`] = value;
        }
        break;

      // date
      case 'is':
        w[`${item.columnField}`] = value;
        break;
      case 'after':
        w[`${item.columnField}_GT`] = value;
        break;
      case 'before':
        w[`${item.columnField}_LT`] = value;
        break;
      case 'onOrAfter':
        w[`${item.columnField}_GTE`] = value;
        break;
      case 'onOrBefore':
        w[`${item.columnField}_LTE`] = value;
        break;
      case 'not':
        w[`${item.columnField}_NOT`] = value;
        break;

      // number
      case '=':
        w[`${item.columnField}`] = value;
        break;
      case '>':
        w[`${item.columnField}_GT`] = value;
        break;
      case '<':
        w[`${item.columnField}_LT`] = value;
        break;
      case '>=':
        w[`${item.columnField}_GTE`] = value;
        break;
      case '<=':
        w[`${item.columnField}_LTE`] = value;
        break;
      case '!=':
        w[`${item.columnField}_NOT`] = value;
        break;

      default:
        // eslint-disable-next-line no-console
        console.log(`unknown filter type ${item.operatorValue}`);

        break;
    }
  });

  return w;
}
