import React, { PropsWithChildren } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import HelpIcon from '@mui/icons-material/Help';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CurrentUser } from '../layout/toolbar/CurrentUser';
import { Section } from '../layout/Section';
import { SectionCardHeader } from '../common/SectionCardHeader';
import { ReactMarkdownWithSyntaxHighlighting } from '../common/ReactMarkdownWithSyntaxHighlighting';

const md = `
# h1 Heading
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading

**This is bold text**
__This is bold text__
*This is italic text*
_This is italic text_

> Blockquotes

+ Create a list by starting a line with \`+\`, \`-\`, or \`*\`
+ Sub-lists are made by indenting 2 spaces:
  - Marker character change forces new list start:
    * Ac tristique libero volutpat at
    + Facilisis in pretium nisl aliquet
    - Nulla volutpat aliquam velit
+ Very easy!

1. Lorem ipsum dolor sit amet
2. Consectetur adipiscing elit

---

Inline \`code\`

\`\`\`
Sample text here...
\`\`\`

\`\`\` js
var foo = function (bar) {
  return bar++;
};

console.log(foo(5));
\`\`\`

[link with title](http://nodeca.github.io/pica/demo/ "title text!")

# This is a longer heading
The PRINS GraphQL API require an access token to use them, just add the obtained token
in the header while making the request. you can get the access token and help on
how to use them from the PRINS Control Panel

## Using Token in playground
Once you get the token from the PRINS Control Panel you can use it in the Apollo GraphQL
playground and in your application by simply adding the access token in the header

### Using Token in playground
Once you get the token from the PRINS Control Panel you can use it in the Apollo GraphQL
playground and in your application by simply adding the access token in the header


`;

export function ThemeTestPage() {
  return (
    <>
      <Section>
        <CurrentUser/>
      </Section>
      <Section>

        <Row>
          <Alert severity="info">Info</Alert>
          <Alert severity="warning">Warning</Alert>
          <Alert severity="error">Error</Alert>
          <Alert severity="success">Success</Alert>
        </Row>

        <Row>
          <Button color="primary">Primary</Button>
          <Button color="secondary">Secondary</Button>
          <Button color="info">Info</Button>
          <Button color="warning">Warning</Button>
          <Button color="error">Error</Button>
          <Button color="success">Success</Button>
        </Row>

        <Row>
          <Button color="primary" variant="outlined">Primary</Button>
          <Button color="secondary" variant="outlined">Secondary</Button>
          <Button color="info" variant="outlined">Info</Button>
          <Button color="warning" variant="outlined">Warning</Button>
          <Button color="error" variant="outlined">Error</Button>
          <Button color="success" variant="outlined">Success</Button>
        </Row>

        <Row>
          <Button color="primary" variant="contained">Primary</Button>
          <Button color="secondary" variant="contained">Secondary</Button>
          <Button color="info" variant="contained">Info</Button>
          <Button color="warning" variant="contained">Warning</Button>
          <Button color="error" variant="contained">Error</Button>
          <Button color="success" variant="contained">Success</Button>
        </Row>
      </Section>
      <Section>
        <Card>
          <SectionCardHeader
            avatar={<HelpIcon color="info"/>}
            title="Card"
          />
          <CardContent>
            Card content
          </CardContent>
        </Card>
      </Section>
      <Section>
        <ReactMarkdownWithSyntaxHighlighting content={md}/>
      </Section>
      <Section>
        <Typography variant="h1">Typography h1</Typography>
        <Typography variant="h2">Typography h2</Typography>
        <Typography variant="h3">Typography h3</Typography>
        <Typography variant="h4">Typography h4</Typography>
        <Typography variant="h5">Typography h5</Typography>
        <Typography variant="h6">Typography h6</Typography>
        <Typography variant="subtitle1">Typography subtitle1</Typography>
        <Typography variant="subtitle2">Typography subtitle2</Typography>
        <Typography variant="body1">Typography body1</Typography>
        <Typography variant="body2">Typography body2</Typography>
        <Typography variant="inherit">Typography inherit</Typography>
      </Section>
    </>
  );
}

function Row(props: PropsWithChildren) {
  return (
    <Stack direction="row" spacing={1} py={2}>
      {props.children}
    </Stack>
  );
}
