import React from 'react';
import Box from '@mui/material/Box';
import { FeatureBreakdownBatch } from '../../tools/feature-breakdown-batch/FeatureBreakdownBatch';

export function FeatureBreakdownBatchPage() {
  return (
    <Box mt={2}>
      <FeatureBreakdownBatch/>
    </Box>
  );
}
