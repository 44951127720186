import React from 'react';
import Box from '@mui/material/Box';
import { MarkdownFile } from '../../../../common/MarkdownFile';
import paginationText from './cursor-pagination-info.md';

export function CursorPaginationPage() {
  return (
    <Box maxWidth={1400}>
      <MarkdownFile file={paginationText}/>
    </Box>
  );
}
