import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { IntegrationSelect, SelectedApplication } from '../../common/forms/IntegrationSelect';
import { SelectedUser, UserSelect } from '../../common/forms/UserSelect';
import ErrorMessage from '../../common/ErrorMessage';
import { FormFooter } from '../../common/forms/FormFooter';
import { useAdminApplicationAddUserMutation } from '../../../generated/graphql';

export function AddUserToIntegration() {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<SelectedUser | null>(null);
  const [application, setIntegration] = useState<SelectedApplication | null>(null);
  const [call, { loading, error }] = useAdminApplicationAddUserMutation();

  const isValidRequest = !!(application && user);

  const submit = () => {
    if (application && user) {
      call({
        variables: {
          appId: application.appId,
          username: user.username,
        },
        refetchQueries: 'all',
      })
        .then(() => enqueueSnackbar(`
          user ${user.username} added to ${application?.label}
      `, { variant: 'success' }));
    }
  };

  return (
    <>
      <ErrorMessage error={error}/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <UserSelect onChange={setUser}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <IntegrationSelect onChange={setIntegration}/>
        </Grid>
      </Grid>
      <FormFooter>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!isValidRequest}
          onClick={submit}
        >Add</LoadingButton>
      </FormFooter>
    </>
  );
}
