import React, { PropsWithChildren } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

/**
 * Vertical table that displays a list of fields with their values.
 */
export function DenseFieldList(props: PropsWithChildren) {
  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {props.children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function DenseFieldListRow(props: PropsWithChildren<{ name: string }>) {
  return (
    <TableRow>
      <TableCell variant="head">{props.name}</TableCell>
      <TableCell>{props.children}</TableCell>
    </TableRow>
  );
}
