import React from 'react';
import { MbomInstancesTree } from '../../documentation/pages/browser/bom/mbom/MbomInstancesTree';

export function MbomBrowserPage() {
  return (
    <>
      <MbomInstancesTree/>
    </>
  );
}
