import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowseMfgChangeOrderIssuesQuery,
  MfgChangeOrderIssueWhere,
  SortDirection,
  useBrowseMfgChangeOrderIssuesQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn,
  dateTimeColumn, stringColumn, subFieldColumn, weekColumn,
} from '../../../../../util/dataGridColumns';
import { MfgChangeOrderIssueDialogButton } from './MfgChangeOrderIssueDialogButton';

type Row = BrowseMfgChangeOrderIssuesQuery['mfgChangeOrderIssues'][0];

const columns: GridEnrichedColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <MfgChangeOrderIssueDialogButton id={params.row.id} />,
  },
  {
    ...stringColumn('issue'),
    headerName: 'Issue',
    width: 140,
  },
  {
    ...weekColumn('introduction'),
    headerName: 'Introduction',
    width: 230,
  },
  {
    ...aggregateColumn('assemblyChangesAggregate'),
    headerName: 'Assemblies',
    width: 110,
  },
  {
    ...aggregateColumn('partUsageChangesAggregate'),
    headerName: 'PartUsages',
    width: 110,
  },
  {
    ...aggregateColumn('bopRoutingChangesAggregate'),
    headerName: 'Routings',
    width: 100,
  },
  {
    ...aggregateColumn('bopCompoundOperationChangesAggregate'),
    headerName: 'Comp Operations',
    width: 150,
  },
  {
    ...aggregateColumn('bopOperationChangesAggregate'),
    headerName: 'Operations',
    width: 110,
  },
  {
    ...aggregateColumn('bopMfgAssemblyTargetChangesAggregate'),
    headerName: 'Assembly Targets',
    width: 160,
  },
  {
    ...aggregateColumn('bopPartUsageChangesAggregate'),
    headerName: 'BopPartUsages',
    width: 120,
  },
  {
    ...aggregateColumn('effectivitiesAddAggregate'),
    headerName: 'Effectivities Add',
    width: 120,
  },
  {
    ...aggregateColumn('effectivitiesDelAggregate'),
    headerName: 'Effectivities Del',
    width: 120,
  },
  {
    ...stringColumn('endItem'),
    headerName: 'EndItem',
    width: 120,
  },
  {
    ...stringColumn('implementingCo'),
    headerName: 'ImplementingCo',
    width: 150,
  },
  {
    ...stringColumn('changeType'),
    headerName: 'ChangeType',
    width: 150,
  },
  {
    ...subFieldColumn('changeOrder.coNumber'),
    headerName: 'CO number',
    valueGetter: (v) => v.row.changeOrder?.coNumber,
    width: 140,
  },
  {
    ...subFieldColumn('changeOrder.name'),
    headerName: 'CO Name',
    valueGetter: (v) => v.row.changeOrder?.name,
    width: 160,
  },
  {
    ...subFieldColumn('changeOrder.description'),
    headerName: 'CO Description',
    valueGetter: (v) => v.row.changeOrder?.description,
    width: 360,
  },
  {
    ...stringColumn('name'),
    headerName: 'COI Name',
    width: 210,
  },
  {
    ...stringColumn('description'),
    headerName: 'COI Description',
    width: 360,
  },
  {
    ...stringColumn('coiNumber'),
    headerName: 'COI Number',
    width: 270,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'CreatedAt',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'LastModified',
  },
];

export function MfgChangeOrderIssuesTable() {
  const pageSize = 100;
  const [page, setPage] = useState<number>(0);
  const [where, setWhere] = useState<MfgChangeOrderIssueWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseMfgChangeOrderIssuesQuery({
    variables: {
      where,
      options: {
        limit: pageSize,
        offset: page * pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        pageSize={pageSize}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        rowsPerPageOptions={[]}
        onPageChange={(p) => setPage(p)}
        columns={columns}
        rowCount={(data || previousData)?.mfgChangeOrderIssuesAggregate.count || 0}
        rows={(data || previousData)?.mfgChangeOrderIssues || []}
      />
    </Box>
  );
}
