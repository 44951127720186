import { Tooltip } from '@mui/material';
import React from 'react';
import { FieldInteger, FieldString } from './FieldValues';

interface FieldCoisProps {
  coiNumber: string | null | undefined;
  coiNumberTooltip: string;
  week: number | null | undefined;
  weekTooltip: string;
}

export function FieldCoi(props: FieldCoisProps) {
  // Possible improvement: link to coi dialog

  // If the coi number is undefined it probably means that the the coi is missing
  // and the coi number is accessed as on the coi like changeOrderIssueAdd?.coiNumber
  // Int his case it will return undefined,
  // but we want to display this as null because changeOrderIssueAdd is null.

  return (
    <>
      <Tooltip title={props.coiNumberTooltip} placement="left">
        <div><FieldString value={props.coiNumber || null} /></div>
      </Tooltip>
      <Tooltip title={props.weekTooltip} placement="left">
        <div><FieldInteger value={props.week} /></div>
      </Tooltip>
    </>
  );
}
