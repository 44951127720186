import React from 'react';
import Box from '@mui/material/Box';
import { ReactMarkdownWithSyntaxHighlighting } from '../../../common/ReactMarkdownWithSyntaxHighlighting';

const Md = ReactMarkdownWithSyntaxHighlighting;

export function LinksPage() {
  return (
    <>
      <h1>Links</h1>

      <Box mb={5}>

        <Md content={`

* [PDM Glossary](https://intranet.volvocars.net/sites/VolvoMethods_qa/Developers/KDP%20Glossary/PDM%20Glossary%20(Use%20Ctrl%20+%20f%20to%20search).aspx)
* [Business concept glossary](https://intranet.volvocars.net/sites/Businessconceptglossary/Lists/Business%20concept%20glossary/AllItems.aspx?skipSignal=true)

        `} />

      </Box>
    </>
  );
}
