import React from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { AdminApplicationDetailsFragment } from '../../../generated/graphql';
import { dateTimeFormat } from '../../../util/date';

interface Props {
  application: AdminApplicationDetailsFragment
}

export function IntegrationAdminInfoTable(props: Props) {
  return (
    <Table size="small">
      <TableRow>
        <TableCell>Approved at: </TableCell>
        <TableCell>{dateTimeFormat(props.application.createdAt)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Last modified: </TableCell>
        <TableCell>{dateTimeFormat(props.application.lastModified)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Approved by: </TableCell>
        <TableCell>{props.application.approvedBy?.name || 'unknown'}</TableCell>
      </TableRow>
    </Table>
  );
}
