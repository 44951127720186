import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import { createDialog } from '../../../../../hooks/useDialogs';
import { SwCompleteBaselineWhere } from '../../../../../generated/graphql';
import { SwBaselineDialog } from './SwBaselineDialog';

interface Props {
  swCompleteBaselineWhere: SwCompleteBaselineWhere,
  size?: 'small' | 'medium' | 'large',
}

export function SwBaselineDialogButton(props: Props) {
  return (
    <IconButton
      size={props.size || 'medium'}
      color="primary"

      onClick={(e) => {
        e.stopPropagation();
        /* eslint-disable-next-line max-len */
        createDialog((close) => <SwBaselineDialog close={close} where={props.swCompleteBaselineWhere}/>);
      }}
    >
      <OpenInNewIcon />
    </IconButton>
  );
}
