import React from 'react';
import Box from '@mui/material/Box';
import { Mermaid } from '../../../../common/Mermaid';
import { appStructureDiagram } from './app-structure-diagram';
import { middlewareStructureDiagram } from './middleware-structure-diagram';
import aboutPageMd from './about-page.md';
import middlewareDocMd from './middleware-doc.md';
import { MarkdownFile } from '../../../../common/MarkdownFile';

export function AboutPage() {
  return (
    <>
      <h1>What is PRINS GraphHub?</h1>

      <Box sx={{
        width: { lg: '40%' },
        float: { lg: 'right' },
      }}>
        <Mermaid text={appStructureDiagram} />
      </Box>

      <MarkdownFile file={aboutPageMd} />

      <Box sx={{
        width: { lg: '40%' },
        float: { lg: 'right' },
      }}>
        <Mermaid text={middlewareStructureDiagram} />
      </Box>

      <MarkdownFile file={middlewareDocMd} />

    </>
  );
}
