/* eslint-disable @typescript-eslint/no-explicit-any */
import { InMemoryCache } from '@apollo/client';
import fragmentTypes from '../generated/fragmentTypes.json';

export default new InMemoryCache({
  possibleTypes: fragmentTypes.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        admin: {
          merge(existing: any, incoming, { mergeObjects }) {
            return mergeObjects(existing || {}, incoming);
          },
        },
        user: {
          merge(existing: any, incoming, { mergeObjects }) {
            return mergeObjects(existing || {}, incoming);
          },
        },
      },
    },
    AdminQuery: {
      keyFields: false, // Disable normalization to treat it as a root query
    },
    UserQuery: {
      keyFields: false, // Disable normalization to treat it as a root query
    },
  },
});
