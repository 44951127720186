/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import React, {
  useLayoutEffect, useRef, useState,
} from 'react';
import ForceGraph2D, { ForceGraphMethods, GraphData } from 'react-force-graph-2d';
import { gql, useQuery } from '@apollo/client';
import { useWindowSize } from '@react-hook/window-size';
import {
  forceLink, forceManyBody, forceCenter, forceCollide,
} from 'd3-force-3d';
import { Alert } from '@mui/material';
import { formatQueryResultForGraph, NodeObjectValue, NodeObjectWithValues } from './graphview.util';
import { Query } from '../../../../generated/graphql';
import { LoadingOverlay } from '../../LoadingOverlay';
import ErrorMessage from '../../ErrorMessage';
import { randomColorFromString } from '../../../../util/colors';
import { GraphOptions, GraphOptionsPanel } from './GraphOptionsPanel';
import { renderNode } from './renderNode';

interface Props {
  query: string;
  variables?: string;
  dagMode: boolean;
}

export default function GraphView(props: Props) {
  const { query, dagMode, variables } = props;
  const [width, height] = useWindowSize();
  const [graphData, setGraphData] = useState<GraphData>();

  let parsedVars: any = null;

  if (variables) {
    try {
      parsedVars = JSON.parse(variables);
    } catch (e) {
      return <Alert severity="warning">Invalid JSON for variables: {`${e}`}</Alert>;
    }
  }

  const { loading, error } = useQuery<Query>(gql(query), {
    variables: parsedVars,
    onCompleted: (data) => setGraphData(formatQueryResultForGraph(data)),
  });

  if (error) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  return (
    <Box position="relative">
      {loading && <LoadingOverlay/>}
      {!loading && graphData && graphData.nodes.length === 0 && <NoContent/>}
      <GraphViewResult
        graphData={graphData}
        dagMode={dagMode}
        width={width - 64}
        height={height - 128}
      />
    </Box>
  );
}

interface GraphViewResultProps {
  graphData?: GraphData,
  dagMode?: boolean
  width?: number,
  height?: number
  displayTypename?: boolean
}

export function GraphViewResult(props: GraphViewResultProps) {
  const {
    graphData, width, height, displayTypename,
  } = props;

  const [initialized, setInitialized] = useState(false);
  const [options, setOptions] = useState<GraphOptions>({
    displayTypename: !!displayTypename,
    fontSize: 5,
    dagMode: props.dagMode || false,
  });

  const { dagMode } = options;

  const ref = useRef<ForceGraphMethods>();

  useLayoutEffect(() => {
    if (initialized || !ref.current) {
      return () => {
      };
    }

    setInitialized(true);

    setForcesForGraph(ref.current);

    // if (!dagMode) {
    //   setForcesForGraph(ref.current);
    // } else {
    //   setForcesForDagGraph(ref.current);
    // }

    setTimeout(() => {
      ref.current?.zoomToFit(750);
    }, 500);

    return () => {
    };
  }, [ref.current]);

  const nodeSize = 2;

  return (
    <Box sx={{ position: 'relative' }}>
      <ForceGraph2D
        graphData={graphData}
        ref={ref}
        width={width}
        height={height}
        nodeLabel={nodeLabel}
        nodeAutoColorBy={'__typename'}
        // nodeColor={(d: any) => randomColorFromString(d.__typename, 60, 50, 20)}
        linkColor={(d: any) => randomColorFromString(d.linkText, 25, 80)}
        linkLabel={(d: any) => d.linkText}
        nodeRelSize={nodeSize}
        // Make nodes with no properties except for id smaller
        nodeVal={(n) => ((n as NodeObjectWithValues).values.length > 2 ? 1 : 0.25)}
        linkWidth={1}
        // d3AlphaDecay={0.0228}
        // d3VelocityDecay={0.1}
        warmupTicks={200}
        maxZoom={10}
        dagMode={dagMode ? 'lr' : undefined}
        dagLevelDistance={40} // A nice distance so that the labels will fit
        nodeCanvasObjectMode={() => 'after'}
        linkDirectionalArrowLength={2}
        linkDirectionalArrowRelPos={1}
        nodeCanvasObject={(node, ctx, zoom) => {
          renderNode(node as NodeObjectWithValues, ctx, options, nodeSize, zoom);
        }}
      />
      <GraphOptionsPanel options={options} setOptions={setOptions}/>
    </Box>
  );
}

function nodeLabel(node: NodeObjectWithValues | any) {
  const values = node.values
    .map((v: NodeObjectValue) => {
      if (v.key === '__typename') {
        return '';
      }

      return `${v.key}: ${v.value}`;
    })
    .filter((v: string) => !!v)
    .join('<br>');

  return `<b>${node.__typename}</b><br><br>${values}`;
}

function NoContent() {
  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      Your query returned no result or you have not selected “id” on any nodes
    </Box>
  );
}

function setForcesForGraph(methods: ForceGraphMethods) {
  methods
    // General spacing, needed when there are lots of nodes
    .d3Force('charge', forceManyBody().strength(-40).distanceMax(300))
    // // Separate nearby nodes
    // .d3Force("charge2", forceManyBody().strength(-90).distanceMax(20))
    .d3Force('link', forceLink()
      .strength(1)
      .distance((a: any) => {
        // Nodes with more relationships will get longer relationships
        // so they dont get clumped together.
        return 2 + Math.sqrt(a.source.relCount + a.target.relCount) * 8;
      }))
    .d3Force('center', forceCenter())
    .d3Force('collision', forceCollide(3).strength(0.8));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function setForcesForDagGraph(methods: ForceGraphMethods) {
  methods
    // General spacing, needed when there are lots of nodes
    .d3Force('charge', forceManyBody().strength(-2).distanceMax(400))
    .d3Force('link', forceLink().strength(0.5).distance(10))
    .d3Force('center', forceCenter().strength(1))
    .d3Force('collision', forceCollide(2).strength(0.8));
}
