import React from 'react';
import Box from '@mui/material/Box';
import aggregationsText from './aggregations-info.md';
import { MarkdownFile } from '../../../../common/MarkdownFile';

export function AggregationsPage() {
  return (
    <Box maxWidth={1400}>
      <MarkdownFile file={aggregationsText}/>
    </Box>
  );
}
