import React from 'react';
import {
  Stack,
  Tooltip,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import {
  AppRole,
} from '../../../generated/graphql';
import { EnvironmentOption } from '../../../util/envrionments';
import { config } from '../../../config/config';
import { envLabel } from '../../../util/environment-label';

interface Props {
  roles: AppRole[];
}

interface ApplicationPermissionsIconProps {
  env: EnvironmentOption;
  roles: AppRole[];
}

export function ApplicationPermissions(props: Props) {
  return (
    <Stack direction="row" spacing={1.5} flexWrap="wrap">
      {config.environments.map((env, index) => (
        <Stack direction="row" spacing={0.5} key={index}>
          <div>{envLabel(env.environment)}</div>
          <ApplicationPermissionsIcon env={env} roles={props.roles}/>
        </Stack>
      ))}
    </Stack>
  );
}

function ApplicationPermissionsIcon(props: ApplicationPermissionsIconProps) {
  const currentRole = props.roles.find((r) => {
    return r.environment === props.env.environment;
  });
  return (
  <>
  {currentRole?.role === 'upstream' && (
    <Tooltip title={`${props.env.label} | Upstream`}>
      <CheckCircleIcon color="success" />
    </Tooltip>
  )}
  {currentRole?.role === 'downstream' && (
    <Tooltip title={`${props.env.label} | Downstream`}>
      <CheckCircleIcon color="success" />
    </Tooltip>
  )}
  {currentRole?.role === 'disabled' && (
    <Tooltip title={`${props.env.label} | Disabled`}>
      <BlockRoundedIcon color="error"/>
    </Tooltip>
  )}
  {!currentRole && (
    <Tooltip title={`${props.env.label} | Not set`}>
      <BlockRoundedIcon color="error"/>
    </Tooltip>
  )}
</>);
}
