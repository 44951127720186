// Edit here: https://mermaid.live/edit

export const eventSequenceDiagramWebhooks = `
sequenceDiagram
participant TC as TeamCenter
participant GH as PRINS GraphHub
participant D as Downstreams <br>using webhook
TC->>GH: Sends data

TC->>GH: Triggers relevant event(s)
activate GH
GH->>D: Sends HTTP request
deactivate GH

activate D
D->>GH: GraphQL Query
GH-->>D: Updated data
deactivate D
`;

export const eventSequenceDiagramMq = `
sequenceDiagram
participant TC as TeamCenter
participant GH as PRINS GraphHub
participant S as Service Bus / MQ
participant D as Downstreams <br>subscribed to MQ
link S: https://vip.volvocars.net/docs/int/3807
TC->>GH: Sends data

TC->>GH: Triggers relevant event(s)
activate GH
GH->>S: Publishes message
deactivate GH

S->>D: Consumes messages
Note over S,D: See Volvo Cars Integration Portal <br> for more info
activate D
D->>GH: GraphQL Query
GH-->>D: Updated data
deactivate D
`;
