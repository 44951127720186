import {
  IntrospectionField,
  IntrospectionOutputTypeRef,
  IntrospectionSchema, IntrospectionType,
} from 'graphql/utilities/getIntrospectionQuery';

const hiddenTypes = [
  // Wiki
  'WikiEntry',

  // Metrics
  'KPIMetricQuery',
  'OperationStatusDistributionResult',
  'OperationTypeDistributionByTeamResult',
  'OperationTimeseriesResult',
  'OperationDistributionByTeamRoleResult',
  'TopUsedQueryResult',
  'TeamCenterImportResult',

  // Auth stuff
  'AdminQuery',
  'UserQuery',
  'Me',
  'RegisteredWebhook',
  'Application',
  'ApplicationSecret',
  'ApplicationConnectionRequest',
  'JwtAuthentication',
  'AppUser',

  // Other
  'StructureCheckSVC',
  'QuantityQualityCheckResult',
  'QuantityQualityCheckErrors',
  'partBreakupResultSet',
  'VariantBreakdownBatchResult',
  'VariantBreakdownResult',
  'VariantBreakdown2BatchResult',
  'VariantBreakdown2Result',
  'PreSeriesBreakdown2BatchResult',
  'PreSeriesBreakdown2Result',
  'PartBreakUpResult',
  'PartBreakUpResultSet',
  'FilteredCpvResult',
];

export function filterIntrospection(schema: IntrospectionSchema): IntrospectionSchema {
  const types = schema.types.map((type: IntrospectionType) => {
    if (!typeShouldBeVisible(type.name)) {
      return null;
    }

    if (type.kind === 'OBJECT' || type.kind === 'INTERFACE') {
      return {
        ...type,
        fields: type.fields.filter(fieldShouldBeVisible),
      };
    }

    return type;
  }).filter((type: IntrospectionType | null) => type !== null) as IntrospectionType[];

  return {
    ...schema,
    types,
  };
}

function typeShouldBeVisible(name: string) {
  return !name.endsWith('AggregationSelection')
    && !name.endsWith('AggregateSelection')
    && !name.endsWith('Edge')
    && !name.endsWith('Relationship')
    && !name.endsWith('Connection')
    && !name.endsWith('Response')
    && !hiddenTypes.includes(name);
}

function getRealType(type: IntrospectionOutputTypeRef) {
  let childType = type;
  while (childType.kind === 'NON_NULL' || childType.kind === 'LIST') {
    childType = childType.ofType;
  }

  return childType;
}

function fieldShouldBeVisible(field: IntrospectionField) {
  if (field.name.endsWith('Connection')) {
    return false;
  }

  const type = getRealType(field.type);

  if (type.kind === 'OBJECT') {
    return typeShouldBeVisible(type.name);
  }

  return true;
}
