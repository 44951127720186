import React, { useState } from 'react';
import {
  Box,
  Button, Dialog, DialogTitle, Stack, Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ProductFamilyDialogDocument, useProductFamilyDialogQuery } from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { PrettyJson } from '../../../../common/data/PrettyJson';
import { PrettyQuery } from '../../../../common/data/PrettyQuery';
import { ProductFamiliesDialogInfoPanel } from './ProductFamiliesDialogInfoPanel';
import { DialogLoader } from '../../../../common/dialog/DialogLoader';

interface Props {
  id: string,
  close: () => void
}

export function ProductFamiliesDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = useState('1');

  const { data, loading, error } = useProductFamilyDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const productFamily = data?.productFamilies[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  return (
    <Dialog
    onClose={handleClose}
    open={open}
    fullWidth={true}
    maxWidth="xl"
    sx={{
      transform: 'translateZ(0)',
    }}
    >
      <DialogTitle>
        <Stack justifyContent="space-between" alignItems="center" direction="row">
          <div>Product Family {productFamily?.pType}</div>
          <Button color="error" onClick={handleClose}>Close</Button>
        </Stack>
      </DialogTitle>
      {loading && <DialogLoader/>}
      <ErrorMessage error={error}/>

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Info" value="1" />
            <Tab label="Json" value="2" />
            <Tab label="Example Query" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <ProductFamiliesDialogInfoPanel productFamily={productFamily}/>
        </TabPanel>
        <TabPanel value="2">
          {data && <PrettyJson data={data}/>}
        </TabPanel>
        <TabPanel value="3">
          <PrettyQuery query={ProductFamilyDialogDocument.loc?.source.body.trim() || ''}/>
        </TabPanel>
      </TabContext>
    </Dialog>
  );
}
