import React, { useState } from 'react';
import Card from '@mui/material/Card';
import {
  Alert,
  Button, ButtonGroup, Dialog, DialogActions, DialogTitle,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ErrorMessage from '../../common/ErrorMessage';
import {
  AdminApplicationConnectionRequestsQuery,
  useAdminApplicationConnectionRequestsQuery,
  useAdminConnectionRequestApproveMutation,
  useAdminConnectionRequestDenyMutation,
} from '../../../generated/graphql';
import { CardRefreshAction } from '../../common/CardRefreshAction';
import { SimpleMessageDialog } from '../../common/SimpleMessageDialog';
import { dateFormat } from '../../../util/date';
import { LoadingCircularCentered } from '../../common/LoadingCircularCentered';
import { SectionCardHeader } from '../../common/SectionCardHeader';

type Row = AdminApplicationConnectionRequestsQuery['admin']['applicationConnectionRequests'][0];

export function PendingUserConnectionRequests() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data, loading, error, refetch,
  } = useAdminApplicationConnectionRequestsQuery();
  const [callConnect] = useAdminConnectionRequestApproveMutation();
  const [callDelete] = useAdminConnectionRequestDenyMutation();

  const rows = data?.admin.applicationConnectionRequests;

  async function approve({ id, application, user }: Row) {
    if (!application || !user) {
      return; // Can't be approved
    }

    await callConnect({
      variables: {
        connectionRequestId: id,
      },
    })
      .then(() => enqueueSnackbar(`
          ${user.name} <${user.username}> added to ${application.appName}
      `, { variant: 'warning' }))
      .finally(() => refetch());

    enqueueSnackbar(`
        ${user.name} <${user.username}> added to ${application.appName}
    `, { variant: 'success' });
  }

  function deny({ id, application, user }: Row) {
    if (!application || !user) {
      return;
    }

    callDelete({
      variables: {
        connectionRequestId: id,
      },
    })
      .then(() => enqueueSnackbar(`
          ${user.name} <${user.username}> was denied access to ${application.appName}
      `, { variant: 'warning' }))
      .finally(() => refetch());
  }

  return (
    <Card>
      <SectionCardHeader
        avatar={<AdminPanelSettingsIcon color='warning'/>}
        title="Pending user connection requests"
        action={<CardRefreshAction onClick={refetch}/>}
      />
      <ErrorMessage error={error}/>
      <LoadingCircularCentered visible={loading} />
      {rows && rows.length === 0 && <Alert severity="info">There are no pending requests</Alert>}
      {rows && rows.length > 0 && (
        <IntegrationUserConnectionRequestsTable rows={rows} approve={approve} deny={deny}/>
      )}
    </Card>
  );
}

interface TableProps {
  rows: Row[];
  approve: (row: Row) => void;
  deny: (row: Row) => void;
}

function IntegrationUserConnectionRequestsTable({ rows, approve, deny }: TableProps) {
  const [openDenyDialog, setOpenDenyDialog] = useState<boolean>(false);
  const [
    deniedConnection, setDeniedConnection,
  ] = useState<Row | undefined>();

  const handleDenyClick = (connection: Row) => {
    setDeniedConnection(connection);
    setOpenDenyDialog(true);
  };

  const handleDenyConfirmation = () => {
    if (deniedConnection) {
      deny(deniedConnection);
      setDeniedConnection(undefined);
    }
    setOpenDenyDialog(false);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Integration</TableCell>
            <TableCell>Date</TableCell>
            <TableCell padding="none" />
            <TableCell padding="none" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th">{row.user?.name}</TableCell>
              <TableCell>
                {row.application?.teamName} <br/>
              <em>{row.application?.appName}</em> ({row.application?.appId})
              </TableCell>
              <TableCell>{dateFormat(row.createdAt)}</TableCell>
              <TableCell padding='none'>
                {row.message && (
                  <SimpleMessageDialog message={row.message} />
                )}
              </TableCell>
              <TableCell padding='none' align='right'>
                <ButtonGroup variant="text" aria-label="text button group">
                  <Button color='success' onClick={() => approve(row)}>Approve</Button>
                  <Button color='error' onClick={() => handleDenyClick(row)}>Deny</Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog onClose={() => setOpenDenyDialog(false)} open={openDenyDialog}>
        <DialogTitle>
          Are you sure you want to reject the connection request
          for {deniedConnection?.user?.username} to {deniedConnection?.application?.appName}? <br />
          This action cannot be undone.
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenDenyDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleDenyConfirmation}>Yes</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
