import React from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { Alert, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { IntegrationSelect, SelectedApplication } from '../../common/forms/IntegrationSelect';
import {
  useUserConnectionRequestCreateMutation,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';

interface IFormInput {
  application: SelectedApplication;
  message: string;
}

export function ApplicationConnectionRequestForm() {
  const { register, handleSubmit, control } = useForm<IFormInput>();

  const [call, { loading, error, data }] = useUserConnectionRequestCreateMutation();

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    call({
      variables: {
        input: {
          appId: formData.application.appId,
          message: formData.message,
        },
      },
    });
    return null;
  };

  if (data) {
    return (
      <Alert severity="success">
        A request to add you to the <em>Application</em> has been submitted for review. <br/>
        Please come back when your request has been approved.
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage error={error}/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field: { ref, ...rest }, fieldState }) => (
              <IntegrationSelect
                {...rest}
                // excludesid={meData?.user?.me?.sid}
                state={fieldState}
                value={rest.value || null} // Forced controlled input
              />
            )}
            name={'application'}
            rules={{
              required: true,
            }}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Message (optional)"
            multiline
            rows={4}
            {...register('message', { required: false, maxLength: 400 })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography align="right" component="div">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
            >Submit Request</LoadingButton>
          </Typography>
        </Grid>
      </Grid>

    </form>
  );
}
