/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import {
  Button, FormControl, Grid, InputLabel, TextField, Typography, Paper,
} from '@mui/material';
import { Subject } from 'rxjs';
import {
  PlayArrow, CheckCircle, Error, AllInclusive, Timer,
} from '@mui/icons-material';
import { RunOperationsTable } from './RunOperationsTable';
import { CardRefreshAction } from '../../common/CardRefreshAction';

export function RunOperationsOverview() {
  const [totalErrors, setTotalErrors] = useState<number>(0);
  const [totalSuccessful, setTotalSuccessful] = useState<number>(0);
  const [completedRuns, setCompletedRuns] = useState<number>(0);
  const [totalOperations, setTotalOperations] = useState<number>(0);
  const [stressRuns, setStressRuns] = useState<number>();
  const [totalExecutionTime, setTotalExecutionTime] = useState<number>(0);

  const callAll = new Subject<null>();

  const incrementSuccessful = () => {
    setTotalSuccessful((prevSuccessful) => prevSuccessful + 1);
    setCompletedRuns((prevCount) => prevCount + 1);
  };

  const incrementErrors = () => {
    setTotalErrors((prevErrors) => prevErrors + 1);
    setCompletedRuns((prevCount) => prevCount + 1);
  };

  const calcTotalExecutionTime = (latestExecTime: number) => {
    setTotalExecutionTime((prevTime) => prevTime + latestExecTime);
  };

  const handleRefresh = () => {
    setCompletedRuns(0);
    setTotalSuccessful(0);
    setTotalErrors(0);
    setTotalExecutionTime(0);
    setStressRuns(10);
    setTotalOperations(0);
  };

  return (
    <>
      <Paper sx={{ p: 1, pr: 2 }}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item display='flex'>
            <CheckCircle color='success' />
            <Typography pl={1}>
              Total successful operations: {totalSuccessful}
            </Typography>
          </Grid>
          <Grid item display='flex'>
            <Error color='error' />
            <Typography pl={1}>
              Total failed operations: {totalErrors}
            </Typography>
          </Grid>
          <Grid item display='flex'>
            <AllInclusive />
            <Typography pl={1}>
              Total operations completed: {completedRuns} / {totalOperations}
            </Typography>
          </Grid>
          <Grid item display='flex'>
            <Timer />
            <Typography pl={1}>
              Total execution time: {totalExecutionTime > 0 && totalExecutionTime && `${Math.round(totalExecutionTime).toLocaleString('sv-SE')} ms`}
            </Typography>
          </Grid>
          <Grid item display='flex'>
            <CardRefreshAction onClick={() => handleRefresh()} />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 1, pr: 2, mt: 2 }}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item flexGrow={1}>
            <Button
              variant='outlined'
              onClick={() => {
                callAll.next(null);
              }}
              startIcon={<PlayArrow />}> Run all once</Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 1, pr: 2, mt: 2 }}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item flexGrow={1}>
            <Button
              variant='outlined'
              onClick={() => callAll.next(null)}
              startIcon={<PlayArrow />}> Run stresstest</Button>
          </Grid>
          <Grid sx={{ p: 1, pt: 3 }}>
            <FormControl>
              <InputLabel shrink>Runs</InputLabel>
              <TextField
                inputProps={{ type: 'number', min: 0 }}
                value={stressRuns}
                onChange={(e) => setStressRuns(e.target.value as unknown as number)} />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <RunOperationsTable
        callAll={callAll}
        incrementSuccessful={incrementSuccessful}
        incrementErrors={incrementErrors}
        totalExecutionTime={calcTotalExecutionTime}
        setTotalOperations={setTotalOperations}
        stressRuns={stressRuns} />
    </>
  );
}
