import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {
  ApplicationDetailsForOperationFragment,
  OperationDetailsFragment,
} from '../../../generated/graphql';
import { ApplicationOperationForm } from './ApplicationOperationForm';

interface Props {
  application: ApplicationDetailsForOperationFragment;
  operation: OperationDetailsFragment;
  onComplete: () => void;
}

export function ApplicationOperationEditButton(props: Props) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="primary">
        <EditIcon/>
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <ApplicationOperationForm
          operation={props.operation}
          application={props.application}
          onComplete={() => {
            setOpen(false);
            props.onComplete();
          }}
        />
      </Dialog>
    </>
  );
}
