/* eslint-disable max-len */
import React from 'react';
import Typography from '@mui/material/Typography';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';

import GqlPnoBreakdownVariants from './pno-breakdown-variants.graphql';
import GqlPnoBreakdownPmlIds from './pno-breakdown-pml-ids.graphql';
import GqlPnoBreakdownPmlIds2 from './pno-breakdown-pml-ids2.graphql';
import GqlPnoBreakdownConsumptions from './pno-breakdown-consumptions.graphql';
import GqlPnoBreakdownConsumptionIds from './pno-breakdown-consumption-ids.graphql';
import GqlPnoBreakdownConsumptionsById from './pno-breakdown-consumptions-by-id.graphql';
import { exampleOrder, exampleOrder2 } from './example-orders';

export function PnoBreakdownCodeExamplePage() {
  return (
    <>
      <h1>PNO breakdown</h1>

      <h2>Usage</h2>
      <Typography paragraph>
        Send a list of orders with <b>pno12, color, upholstery, plant, structureWeek</b> and <b>options</b>. <br/>
      </Typography>
      <Typography paragraph>
        The API will return a list of the same size as the input. <br/>
        You can select the output you want. Available outputs are:
      </Typography>
      <ul>
        <li><b>variants</b> - Typecodes and varcodes. This is a proxy for CPAM.</li>
        <li><b>pmlIds</b> - Using the typecodes and varcodes to get the PML IDs.</li>
        <li><b>pmlIds(prependTypeCode: true)</b> - Same as above but with each row containing the typecode (first 4 digits) and pmlId.</li>
        <li><b>partUsageIds</b> - PRINS ids for the part usages. See usage below.</li>
        <li><b>consumptionRecordIds</b> - PRINS ids for the part usages. See usage below.</li>
        <li><b>partUsages</b> - fetch information related to the part usages. Should not be used for large batches</li>
        <li><b>consumptionRecords</b> - fetch information related to the consumption records. Should not be used for large batches</li>
      </ul>

      <h2>From PNO to features</h2>

      <GraphQLCodeFile
        file={GqlPnoBreakdownVariants}
        variables={{
          inputs: [
            exampleOrder,
          ],
        }}
      />

      <h2>From PNO to PML IDs</h2>

      <GraphQLCodeFile
        file={GqlPnoBreakdownPmlIds}
        variables={{
          inputs: [
            exampleOrder2,
          ],
        }}
      />

      <h2>From PNO to Consumption Records</h2>

      <GraphQLCodeFile
        file={GqlPnoBreakdownConsumptions}
        variables={{
          inputs: [
            exampleOrder2,
          ],
        }}
      />

      <h2>From PNO to consumption record Ids</h2>
      <Typography paragraph>
        Multiple breakdowns can be performed at once for greater performance.
      </Typography>
      <Typography paragraph>
        When doing this it is better to select
        the <b>Consumption Record IDs</b> for the order. <br/>
        Then use the <b>distinct</b> IDs to fetch the consumption records. <br/>
        This avoids the same consumption records being repeated in the result when
        two or more orders have the same consumption records
      </Typography>

      <GraphQLCodeFile
        file={GqlPnoBreakdownConsumptionIds}
        variables={{
          inputs: [
            exampleOrder,
            exampleOrder2,
          ],
        }}
      />

      <Typography paragraph>
        Using the IDs in the previous result we fetch the consumption records
      </Typography>
      <GraphQLCodeFile
        file={GqlPnoBreakdownConsumptionsById}
        variables={{
          ids: ['ExampleID1', 'ExampleID2'],
        }}
      />

      <h2>From PNO to PML IDs including typeCodes</h2>

      <GraphQLCodeFile
        file={GqlPnoBreakdownPmlIds2}
        variables={{
          inputs: [
            exampleOrder2,
          ],
        }}
      />
    </>
  );
}
