import React from 'react';
import {
  Box, CircularProgress,
} from '@mui/material';
import { MfgAssemblyPlaceholderInfo, MfgAssemblyPlaceholder } from '../common/mfg/MfgAssemblyPlaceholderInfo';

interface Props {
  mfgAssemblyPlaceholder?: MfgAssemblyPlaceholder | null
}

export function MfgAssemblyPlaceholderInfoPanel(props: Props) {
  const { mfgAssemblyPlaceholder } = props;

  if (!mfgAssemblyPlaceholder) {
    return <Box py={10} justifyContent="center" display="flex"><CircularProgress/></Box>;
  }

  return (
    <>
      <Box p={2} mb={4} sx={{
        fontSize: '0.8rem',
      }}>
        <MfgAssemblyPlaceholderInfo mfgAssemblyPlaceholder={mfgAssemblyPlaceholder}/>
      </Box>
    </>
  );
}
