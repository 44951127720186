import React from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { MfgCoiBopMfgAssemblyTargetInfoFragment } from '../../../../../../generated/graphql';
import { EffectivityList } from '../../common/mfg/EffectivityList';

interface Props {
  rows: MfgCoiBopMfgAssemblyTargetInfoFragment[]
}

export function MfgCoiBopMfgAssemblyTargetTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>assembly.name</TableCell>
            <TableCell>assembly.productId</TableCell>
            <TableCell>routing.catalogueId</TableCell>
            <TableCell>effectivities</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow><TableCell colSpan={99} align="left">None</TableCell></TableRow>
          )}
          {rows.map((value, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, whiteSpace: 'nowrap' }}
            >
              <TableCell>{value.assembly.name}</TableCell>
              <TableCell>{value.assembly.productId}</TableCell>
              <TableCell>{value.routing.catalogueId}</TableCell>
              <TableCell>
                <EffectivityList effectivities={value.effectivities}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
