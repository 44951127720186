import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import React from 'react';

import {
  PartAddressInfoBoxFragment,
} from '../../../../../../generated/graphql';
import { FieldMiniTable } from '../../common/FieldMiniTable';

export function PartAddressInfoBox(props: { partAddress?: PartAddressInfoBoxFragment | null }) {
  const pa = props.partAddress;

  if (!pa) {
    return null;
  }

  return (
    <>
      <FieldMiniTable>
        <TableRow>
          <TableCell variant="head">PA:</TableCell>
          <TableCell>{pa?.number}</TableCell>
          <TableCell width="75%">{pa?.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">POS:</TableCell>
          <TableCell>{pa?.fgPos?.number}</TableCell>
          <TableCell width="75%">{pa?.fgPos?.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">FG:</TableCell>
          <TableCell>{pa?.fgPos?.functionGroup?.number}</TableCell>
          <TableCell width="75%">{pa?.fgPos?.functionGroup?.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">PC:</TableCell>
          <TableCell>{pa?.fgPos?.functionGroup?.bomInstance?.productContext?.typeCode}</TableCell>
          <TableCell width="75%">{pa?.fgPos?.functionGroup?.bomInstance?.productContext?.name}</TableCell>
        </TableRow>
      </FieldMiniTable>
      {/* <Box pt={1}> */}
      {/*  <FieldKeyValueList values={{ */}
      {/*    'PA Status': <ReleaseStatusChip releaseStatus={pa?.releaseStatus}/>, */}
      {/*  }}/> */}
      {/* </Box> */}
    </>
  );
}
