import React from 'react';
import { ChangeOrderIssuesTable } from '../../documentation/pages/browser/change-order-issues/ChangeOrderIssueTable';

export function ChangeOrderIssuesBrowserPage() {
  return (
    <>
      <ChangeOrderIssuesTable/>
    </>
  );
}
