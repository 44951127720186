import {
  ApolloClient, ApolloLink, createHttpLink, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { currentAuth } from '../hooks/useAuth';
import cache from './cache';
import { environmentSubject } from '../hooks/useSelectedEnvironment';

const httpLink: ApolloLink = createHttpLink({
  uri: () => environmentSubject.value.graphQLEndpoint,
});

const uploadLink: ApolloLink = createUploadLink({
  uri: () => environmentSubject.value.graphQLEndpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const key = getLocalStorage('appKey', '');
  const auth = currentAuth();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: auth ? `Bearer ${auth.idToken}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink, httpLink]),
  cache,
});

export const docsClient = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink, httpLink]),
  cache: new InMemoryCache({
    resultCaching: false,
    addTypename: false,
  }),
});

const uniqueRequestsLink = new ApolloLink((operation, forward) => {
  operation.setContext({ noAutoCombine: true, uniqueRequestId: Date.now() });
  return forward(operation);
});

export const uniqueRequestsClient = new ApolloClient({
  link: ApolloLink.from([authLink, uniqueRequestsLink, httpLink]),
  cache: new InMemoryCache({
    resultCaching: false,
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});
