import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  useVariantBreakdownPerformanceTestLazyQuery,
} from '../../../generated/graphql';
import { StoredCpvSetPreview } from './BreakdownResultPreview';
import ErrorMessage from '../../common/ErrorMessage';

interface Props {
  inputs: {
    features: string[],
    structureWeek: number,
    typeCode: string,
  }[];
}

export function BreakdownPerformanceTestGraphQl(props: Props) {
  const [call, {
    data, error, loading,
  }] = useVariantBreakdownPerformanceTestLazyQuery({
    variables: {
      inputs: props.inputs,
    },
    nextFetchPolicy: 'no-cache',
  });

  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => call()}
        >Breakdown of {props.inputs.length} collections feature sets using GraphQL</LoadingButton>

        <ErrorMessage error={error}/>

          {data && (
            <Box mt={2}>
              Took: {data.variantBreakdown2.took} ms
              to get {data.variantBreakdown2.results.length} breakdown results
            </Box>
          )}
      </Paper>

      {data && (
        <StoredCpvSetPreview rows={data.variantBreakdown2.results}/>
      )}

    </>
  );
}
