import { useEffect } from 'react';

export function EasterEgg() {
  const keyOrder = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a', 'Enter']; // move to const
  const audioFileURL = '/gameAudio.mp3';
  let counter = 0;

  function KeyHandler(event:KeyboardEvent) {
    if (event.key === keyOrder[counter]) {
      counter += 1;
    } else {
      counter = 0;
    }
    if (keyOrder.length === counter) {
      const audio = new Audio(audioFileURL);
      audio.play();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', KeyHandler);
  }, []);

  return null;
}
