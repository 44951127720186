import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { ProductFamilyDialogQuery } from '../../../../../generated/graphql';

type ProductFamily = ProductFamilyDialogQuery['productFamilies'][0];

interface Props {
  productFamily?: ProductFamily | null
}

export function ProductContextTable(props: Props) {
  const { productFamily } = props;

  if (!productFamily) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type Designation</TableCell>
            <TableCell>Type Code</TableCell>
            <TableCell>Last Modified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productFamily.productContexts.map((pc, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}
            >
              <TableCell>{pc.name}</TableCell>
              <TableCell>{pc.typeDesignation}</TableCell>
              <TableCell>{pc.typeCode}</TableCell>
              <TableCell>{pc.lastModified}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
