/* eslint-disable @typescript-eslint/no-explicit-any */
// https://mui.com/material-ui/guides/routing/
import React, { PropsWithChildren } from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';

import MuiLink from '@mui/material/Link';
import Button from '@mui/material/Button';

export function RoutedLink(props: PropsWithChildren<{ to: string }>) {
  return (
    <MuiLink component={RouterLink} {...props} />
  );
}
export function RoutedButton(props: PropsWithChildren<{ to: string }>) {
  return (
    <Button component={RouterLink} variant="outlined" {...props}></Button>
  );
}
