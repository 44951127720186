import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { ApolloError } from '@apollo/client';
import { OperationsTimeSeriesResult, UsageTimeseriesQuery } from '../../../generated/graphql';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { MultiLineGraph } from './Graph/MultiLineGraph';
import { Section } from '../../layout/Section';
import ErrorMessage from '../../common/ErrorMessage';

interface UseUsageProps {
  period: string
  useUsageProps: {
    data?: UsageTimeseriesQuery,
    previousData?: UsageTimeseriesQuery,
    loading: boolean,
    error?: ApolloError
  }
}

export function TeamUsage(props: UseUsageProps) {
  const result = props.useUsageProps.data || props.useUsageProps.previousData;

  const getDistinctTeams = (fetchedData: OperationsTimeSeriesResult[]) => {
    const distinctKeys: string[] = [];
    fetchedData.forEach((v) => {
      if (!distinctKeys.includes(v.teamName)) {
        distinctKeys.push(v.teamName);
      }
    });
    return distinctKeys;
  };

  const addKeyForEachTeam = (fetchedData: OperationsTimeSeriesResult[]) => {
    const distinctKeys = getDistinctTeams(fetchedData);
    return fetchedData.map((a) => {
      const t: { [name: string] : number | string } = { timestamp: a.timestamp };
      distinctKeys.forEach((k) => {
        if (a.teamName === k) {
          t[k] = a.count;
        } else {
          t[k] = 0;
        }
      });
      return t;
    });
  };

  const preprocess = (dataRows: OperationsTimeSeriesResult[]) => {
    if (!dataRows) {
      return [];
    }
    return addKeyForEachTeam(dataRows);
  };

  return (
    <Card {...props}>
      <SectionCardHeader
        avatar={<ArrowUpward color='secondary'/>}
        title='Team Usage'
      />
      <CardContent>
        <Section>
          <ErrorMessage error={props.useUsageProps.error}/>
          <MultiLineGraph
            period={props.period}
            data={preprocess(result?.kpiMetric.operationsTimeSeries || [])}
            error={props.useUsageProps.error}
            loading={props.useUsageProps.loading}
            xDataKey='timestamp'
            yDataKey={getDistinctTeams(result?.kpiMetric.operationsTimeSeries || [])}
          />
        </Section>
      </CardContent>
    </Card>
  );
}
