/* eslint-disable max-len */
import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';
import { GraphQLCodeFile } from '../../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import Gql1 from './delivery-units-for-factories.graphql';
import { subtractWeeks, yearWeekFormat } from '../../../../../../util/date';

export function NewcastCaseI00017() {
  return (
    <>
      <Typography variant="h3" mb={3}>Description of the existing KDP integration</Typography>
      <Typography paragraph>Detailed specification of file with effective dates on delivery units for factories.</Typography>
      <Typography paragraph>KDP sends files to many different factory instances and by using one parameter file per factory instance, KDP can send only relevant information to each factory.</Typography>
      <Typography paragraph>Therefore KDP receives a parameter file from Newcast containing information of which cars they want to have information about. The parameterfile only arrives to KDP when updated from Newcast, approximately once per week.</Typography>
      <Typography paragraph>This integration describes effective date for delivery units on factories. Effective date of the delivery module is from current week minus 36 weeks back in time.</Typography>
      <a href="https://intranet.volvocars.net/:w:/r/sites/SHLM/_layouts/15/Doc.aspx?sourcedoc=%7Be08167b8-7cce-4b08-8a89-1323a1f9528f%7D&action=view&wdAccPdf=0&wdparaid=7DC86B6F">Document</a>

      <Typography variant="h3" mt={4} mb={3}>PRINS GraphHub GraphQL query</Typography>
      <GraphQLCodeFile
        file={Gql1}
        variables={{
          plantCode: 21,
          pType: '41',
          week: yearWeekFormat(subtractWeeks(36)),
        }}
        showGraph={false}
      />
      <Typography paragraph>
        KULORTYP: <br/>
        I – Given for detailed parts, parttype = ‘D’<br/>
        S – Given for a complete part, parttype = starting with letter ‘K’, example ‘K’, ‘K1’, ‘K20’ etc<br/>
      </Typography>
      <Link
        href="https://intranet.volvocars.net/:w:/r/sites/SHLM/_layouts/15/Doc.aspx?sourcedoc=%7B974924A5-5E6E-40A9-BEF4-8F749B7130C8%7D&file=I00312%20-%20INT1057-%20Publish%20Part%20Coloured%20Info%20from%20KDP%20-%20MECCA%20spec.doc"
      >Source and read more</Link>

      <Typography paragraph>More property descriptions will be added in the future</Typography>

    </>
  );
}
