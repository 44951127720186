import React from 'react';
import Box from '@mui/material/Box';
import { QuantityCheck } from '../../tools/quantity-check/QuantityCheck';
import { yearWeekFormat } from '../../../util/date';

export function QuantityCheckPage() {
  return (
    <Box mt={2}>
      <QuantityCheck
        defaultValues={{
          structureWeek: yearWeekFormat(),
          typeCode: '',
          features: [],
        }}
      />
    </Box>
  );
}
