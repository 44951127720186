import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import React from 'react';

interface Props {
  visible: boolean,
  py?: number,
}

export function LoadingCircularCentered(props: Props) {
  const { visible, py } = props;

  if (!visible) {
    return null;
  }

  return (
    <Box py={py || 1}>
      <Typography align="center">
        <CircularProgress/>
      </Typography>
    </Box>
  );
}
