import React from 'react';
import {
  Grid, List, Paper, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ConsumptionRecordDialogQuery } from '../../../../../generated/graphql';
import { MBomInfoTable } from './cr-dialog/MBomInfoTable';
import { FieldListValue } from '../common/FieldListValue';
import { FieldMiniTable } from '../common/FieldMiniTable';

type ConsumptionRecordDialogRecord = ConsumptionRecordDialogQuery['consumptionRecords'][0];

interface Props {
  consumptionRecord?: ConsumptionRecordDialogRecord;
}

export function ConsumptionRecordDialogInfoPanel(props: Props) {
  const { consumptionRecord } = props;

  const part = consumptionRecord?.partUsage?.part || consumptionRecord?.partUsage?.softwarePart;

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12} sm={6} md={4}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Change Order Issue Added</Typography>
          <List disablePadding>
            <FieldListValue name="coiNumber" value={consumptionRecord?.changeOrderIssueAdd?.coiNumber} />
            <FieldListValue name="introductionWeek" value={consumptionRecord?.changeOrderIssueAdd?.introductionWeek} />
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Change Order Issue Deleted</Typography>
          <List disablePadding>
            <FieldListValue name="coiNumber" value={consumptionRecord?.changeOrderIssueDel?.coiNumber} />
            <FieldListValue name="introductionWeek" value={consumptionRecord?.changeOrderIssueDel?.introductionWeek} />
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>MBom</Typography>
          <MBomInfoTable consumptionRecord={consumptionRecord}/>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>PartUsage</Typography>
          <List disablePadding>
            <FieldListValue name="partAddress.number" value={consumptionRecord?.partUsage?.partAddress?.number} />
            <FieldListValue name="partAddress.name" value={consumptionRecord?.partUsage?.partAddress?.name} />
            <FieldListValue name="part.name" value={part?.name} />
            <FieldListValue name="part.number" value={part?.partNumber} />
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Paper variant="outlined">
        <Typography variant="h4" p={2}>UsageRules</Typography>
          {consumptionRecord?.partUsage.usageRules.map((ur) => (
            <Box key={ur.id} sx={{
              '&:not(:first-child)': { mt: 1, pt: 1, borderTop: '1px solid #ddd' },
              padding: 2,
            }}>
              <FieldMiniTable>
                <TableRow>
                  <TableCell variant="head">pmlId:</TableCell>
                  <TableCell>{ur.pmlId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">typeCode:</TableCell>
                  <TableCell>{ur.typeCode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">varCodes:</TableCell>
                  <TableCell>{ur.varCodes}</TableCell>
                </TableRow>
              </FieldMiniTable>
            </Box>
          ))}
      </Paper>
      </Grid>
    </Grid>
  );
}
