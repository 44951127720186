import React from 'react';
import Box from '@mui/material/Box';

interface Props {
  value: string | null | undefined
  color?: string
  mono?: boolean
}

export function Cell(props: Props) {
  return (
    <Box sx={{
      display: 'inline-block',
      color: props.color,
      textAlign: 'left',
      fontFamily: props.mono ? 'monospace' : 'inherit',
      fontWeight: props.mono ? 'bold' : 'inherit',
      paddingRight: '0.6em',
      minWidth: props.mono ? '40px' : 'auto',
    }}>
      {props.value}
    </Box>
  );
}
