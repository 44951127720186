import React, { ReactNode } from 'react';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';

export function TypeName(props: { color: string, children: ReactNode }) {
  return (
    <ListItem sx={{
      alignItems: 'flex-start',
      paddingLeft: 0,
      paddingRight: 0,
    }}>
      <Typography color={props.color} variant="h5" mb={1} mt="7px" minWidth={110}>
        {props.children}
      </Typography>
    </ListItem>
  );
}
