import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import React from 'react';
import { SparePartInfoDialogQuery } from '../../../../../../generated/graphql';
import { PartsTable } from './PartsTable';

type SparePartInfoDialogSparePartInfo = SparePartInfoDialogQuery['sparePartInfos'][0];

interface Props {
  sparePartInfo?: SparePartInfoDialogSparePartInfo
}

export function SparePartInfoDialogInfoPanel(props: Props) {
  const { sparePartInfo } = props;

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Defining Part</Typography>
          {sparePartInfo?.definitionOf?.partNumber ? (
            <PartsTable parts={[sparePartInfo.definitionOf]}/>
          ) : (
            <Alert severity="info">This has no Defining Part</Alert>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Use Instead</Typography>
          {sparePartInfo?.partUseInstead.length === 0 ? (
            <Alert severity="info">This has no Use Instead Parts</Alert>
          ) : (
          <PartsTable parts={sparePartInfo?.partUseInstead || []}/>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
