import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import {
  ApplicationDetailsFragment,
  OperationDetailsFragment,
} from '../../../generated/graphql';
import { ApplicationOperationsTable } from '../Operations/ApplicationOperationsTable';
import { ApplicationOperationCreateButton } from '../Operations/ApplicationOperationCreateButton';

interface Props {
  application: ApplicationDetailsFragment & {
    operations: OperationDetailsFragment[]
  },
  refresh: () => void,
}

export function ApplicationOperations(props: Props) {
  const { refresh, application } = props;

  return (
    <>
      <Box mb={4}>
        <Box mb={2} sx={{
          '& p': {
            mt: 0,
          },
        }}>
          <p>
            Here you must store queries that you are using or planning to use in production.
            Provide the details of your query, its trigger mechanism, frequency,
            and the potential impact on your application if our servers are inaccessible.
          </p>
          <p>
            The PRINS team can provide feedback for optimal performance
            and reliability before granting access to the production environment.
          </p>
          <p>
            The PRINS team will regularly test these queries for performance issues and to ensure
            that we don´t introduce breaking changes in the API.
            Therefore it is important that the queries matches the queries used or
            intended to be used and include all fields and only the fields that are needed.
          </p>
          <Alert severity="warning">
            It is your responsibility to ensure that the queries are correctly formatted and
            reflect your dependencies.
          </Alert>
        </Box>
        <ApplicationOperationsTable
          application={application}
          operations={application.operations}
          refresh={refresh}
        />
        <Stack spacing={2} direction="row" justifyContent="center" mt={2}>
          <ApplicationOperationCreateButton application={application} onComplete={refresh} />
        </Stack>
      </Box>
    </>
  );
}
