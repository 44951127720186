import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import {
  ApplicationDetailsForOperationFragment, OperationDetailsFragment, AdminOperationDetailsFragment,
  useAdminSaveApplicationOperationNotesMutation,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { dateTimeFormat } from '../../../util/date';

interface Props {
  application: ApplicationDetailsForOperationFragment;
  operation: OperationDetailsFragment & AdminOperationDetailsFragment;
  onComplete: () => void;
}

type IFormInput = {
  approve: string;
  adminNotes: string;
  adminNotesInternal: string;
};

export function ApplicationOperationNotesForm(props: Props) {
  const { application, operation } = props;
  const [call, { loading, error }] = useAdminSaveApplicationOperationNotesMutation();

  const {
    register, control, handleSubmit, reset,
  } = useForm<IFormInput>({
    defaultValues: {
      adminNotes: operation.adminNotes || '',
      adminNotesInternal: operation.adminNotesInternal || '',
      approve: 'undefined', // Default value for approval
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    let approvalValue: boolean | undefined;

    if (formData.approve === 'true') {
      approvalValue = true;
    } else if (formData.approve === 'false') {
      approvalValue = false;
    } else {
      approvalValue = undefined;
    }

    await call({
      variables: {
        id: operation.id,
        approve: approvalValue,
        adminNotes: formData.adminNotes,
        adminNotesInternal: formData.adminNotesInternal,
      },
    });

    props.onComplete();

    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Edit notes for operation for {application.appName}</DialogTitle>
      <DialogContent>
        <ErrorMessage error={error} />

        {/* Approval Select Dropdown */}
        <FormControl fullWidth margin="dense">
          <InputLabel id="approve-select-label">Action</InputLabel>
          <Controller
            name="approve"
            control={control}
            render={({ field }) => (
              <Select
                labelId="approve-select-label"
                label={'Action'}
                {...field}
              >
                <MenuItem value={'undefined'}>Do nothing</MenuItem>
                <MenuItem value={'true'}>Approve</MenuItem>
                <MenuItem value={'false'} disabled={operation.approvedAt === null}>
                  Remove Approval
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <TextField
          margin="dense"
          label="Admin Notes"
          fullWidth
          multiline
          minRows={2}
          {...register('adminNotes')}
        />

        <TextField
          margin="dense"
          label="Internal Admin Notes"
          fullWidth
          multiline
          minRows={2}
          {...register('adminNotesInternal')}
        />

        <Box pt={1}>
          {operation.approvedAt && (
            <>
              Approved by: <Box sx={{ display: 'inline', fontWeight: 500 }}>{operation.approvedByUser?.name}</Box> <br/>
              Approved at: <Box sx={{ display: 'inline', fontWeight: 500 }}>{dateTimeFormat(operation.approvedAt)}</Box>
            </>
          )}
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onComplete()}>Cancel</Button>
        <LoadingButton type="submit" variant="contained" loading={loading}>Save</LoadingButton>
      </DialogActions>
    </form>
  );
}
