import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  SortDirection,
  TestSeriesTableRowFragment,
  useBrowseTestSeriesConsumptionsQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import {
  booleanColumn, dateTimeColumn, numberColumn, stringColumn, subFieldColumn, weekColumn,
} from '../../../../../util/dataGridColumns';

type Row = TestSeriesTableRowFragment;

const columns: GridEnrichedColDef<Row>[] = [
  {
    ...subFieldColumn('plant.plantCode'),
    headerName: 'Plant',
    valueGetter: (v) => v.row.projectBuildEvent?.plant?.plantCode,
    width: 40,
  },
  {
    ...subFieldColumn('projectBuildEvent'),
    headerName: 'Project',
    valueGetter: (v) => {
      const e = v.row.projectBuildEvent;
      return e ? `${e.project} - ${e.event}` : '-';
    },
    width: 180,
  },
  {
    ...weekColumn('effectiveInWeek'),
    headerName: 'In Week',
    width: 100,
  },
  {
    ...weekColumn('effectiveOutWeek'),
    headerName: 'Out Week',
    width: 100,
  },
  {
    ...subFieldColumn('changeOrderIssueAdd.coiNumber'),
    headerName: 'Add',
    valueGetter: (v) => v.row.changeOrderIssueAdd?.coiNumber,
    width: 100,
  },
  {
    ...subFieldColumn('changeOrderIssueDel.coiNumber'),
    headerName: 'Del',
    valueGetter: (v) => v.row.changeOrderIssueDel?.coiNumber,
    width: 100,
  },
  {
    ...numberColumn('POST'),
    width: 100,
  },
  {
    ...numberColumn('quantity'),
    width: 100,
  },
  {
    ...stringColumn('deliveryUnitType'),
    headerName: 'Type',
    width: 100,
  },
  {
    ...stringColumn('duMarking'),
    headerName: 'DU-marking',
    width: 100,
  },
  {
    ...booleanColumn('isPartOfDeliveryModule'),
    headerName: 'isPartOfDeliveryModule',
    width: 100,
  },
  {
    ...subFieldColumn('pointsTo'),
    headerName: 'Part',
    valueGetter: (v) => {
      const part = v.row.part || v.row.softwarePart || v.row.deliveryModule;

      return part ? `${part.partNumber} - ${part.name}` : '-';
    },
    width: 500,
  },
  {
    ...subFieldColumn('pointsToType'),
    headerName: 'P/SW/DM',
    valueGetter: (v) => {
      const part = v.row.part || v.row.softwarePart || v.row.deliveryModule;

      return part ? part.__typename : '-';
    },
    width: 120,
  },
  {
    ...subFieldColumn('partAddress'),
    headerName: 'PartAddress',
    valueGetter: (v) => {
      const pa = v.row.partAddress;

      return pa ? `${pa.name} | ${pa.number} - ${pa.fgPos?.number} - ${pa.fgPos?.fgNumber}  - ${pa.topNode}` : '-';
    },
    width: 500,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'Created At',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified At',
  },
];

export function TestSeriesConsumptionsTable() {
  const pageSize = 100;
  const [page, setPage] = useState<number>(0);
  // const [where, setWhere] = useState<ConsumptionRecordWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseTestSeriesConsumptionsQuery({
    variables: {
      // where,
      options: {
        limit: pageSize,
        offset: page * pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        pageSize={pageSize}
        pagination
        // disableColumnMenu
        rowsPerPageOptions={[]}
        onPageChange={(p) => setPage(p)}
        columns={columns}
        rowCount={(data || previousData)?.testSeriesConsumptionsAggregate.count || 0}
        rows={(data || previousData)?.testSeriesConsumptions || []}
      />
    </Box>
  );
}
