/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { randomColor } from './graph.utils';

interface PieGraphProps {
  data?: any[];
  dataKey: string
  nameKey: string
  limit?: number;
  formatName: (name: string) => string,
}

const RADIAN = Math.PI / 180;

export function PieGraph(props: PieGraphProps) {
  const renderCustomizedLabel = (data:{
    cx : number,
    cy: number,
    midAngle: number,
    innerRadius: number,
    outerRadius: number,
    percent: number,
    name: string,
    percentage: number,
  }) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      name,
    } = data;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {props.formatName(name)} ({(percent * 100).toFixed(0)} %
      </text>
    );
  };

  if (!props.data) {
    return <div> error </div>;
  }

  return (
    <div>
      <ResponsiveContainer width='100%' aspect={1.0}>
        <PieChart data={props.data}>
          <Pie
            label={renderCustomizedLabel}
            labelLine={false}
            dataKey={props.dataKey} nameKey={props.nameKey}
            isAnimationActive={true}
            data={props.data}
          >
            {props.data.map((entry, index) => (
              <Cell key={index} fill={randomColor(index)}/>
            ))}
            <Tooltip></Tooltip>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
