import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Monospaced } from '../../common/text/Monospaced';
import { VariantBreakdown2Query } from '../../../generated/graphql';
import { PartDialogButton } from '../../documentation/pages/browser/parts/PartDialogButton';
import { colors } from '../../../services/mui-theme';

type PartUsageData = VariantBreakdown2Query['variantBreakdown2']['results'][0]['partUsages'][0];

export function FeatureBreakdownResultTable(props: { partUsages: PartUsageData[] }) {
  const partUsages = [...props.partUsages].sort(sortPartUsageData);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableCell>usageRule</TableCell>
          <TableCell align="right">quantity</TableCell>
          <TableCell>
            <Add value="coiNumberAdd"/>
            <Del value="coiNumberDel"/>
          </TableCell>
          <TableCell>
            <Add value="effectiveInWeek"/>
            <Del value="effectiveOutWeek"/>
          </TableCell>
          <TableCell>part</TableCell>
          <TableCell>partAddress</TableCell>
        </TableHead>
        {partUsages.map((pu, i) => (
          <TableRow key={i}>
            <TableCell>
              {pu.usageRule}
            </TableCell>
            <TableCell align="right">{pu.quantity}</TableCell>
            <TableCell>
              <Add value={pu.coiNumberAdd}/>
              <Del value={pu.coiNumberDel}/>
            </TableCell>
            <TableCell>
              <Add value={pu.effectiveInWeek}/>
              <Del value={pu.effectiveOutWeek}/>
            </TableCell>
            <TableCell>
              <Monospaced>{pu.part?.partNumber}</Monospaced>
              <PartDialogButton where={{ partNumber: pu.part?.partNumber }} size="small"/>
              <br/>
              {pu.part?.name}
            </TableCell>
            <TableCell>
              <Monospaced>{pu.partAddress?.number}</Monospaced> <br/>
              {pu.partAddress?.name}
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {partUsages.length === 0 && (
        <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
          No results. Try changing the input. Maybe the structure week is wrong?
        </Typography>
      )}
    </TableContainer>
  );
}

function Add({ value }: { value?: string | number | null }) {
  if (!value) {
    return <Typography variant="inherit" color={colors.brand.gray4}>-</Typography>;
  }

  return <Typography variant="inherit" color="info.dark">{value}</Typography>;
}

function Del({ value }: { value?: string | number | null }) {
  if (!value) {
    return <Typography variant="inherit" color={colors.brand.gray4}>-</Typography>;
  }

  return <Typography variant="inherit" color="error.dark">{value}</Typography>;
}

function sortPartUsageData(a: PartUsageData, b: PartUsageData) {
  if (!a.partAddress || !b.partAddress || !a.part || !b.part) {
    return 0;
  }

  // Sort by part address first
  if (a.partAddress.number < b.partAddress.number) {
    return -1;
  }
  if (a.partAddress.number > b.partAddress.number) {
    return 1;
  }

  // then by part number
  if (a.part.partNumber < b.part.partNumber) {
    return -1;
  }
  if (a.part.partNumber > b.part.partNumber) {
    return 1;
  }

  return 0;
}
