import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import { CoiPartConstituentInfoFragment } from '../../../../../../generated/graphql';

interface Props {
  rows: CoiPartConstituentInfoFragment[]
}

export function CoiPartConstituentTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>effectiveInWeek</TableCell>
            <TableCell>effectiveOutWeek</TableCell>
            <TableCell>POST</TableCell>
            <TableCell>part.partNumber</TableCell>
            <TableCell>softwarePart.partNumber</TableCell>
            <TableCell>assembly.partNumber</TableCell>
            <TableCell>createdAt</TableCell>
            <TableCell>lastModified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow><TableCell colSpan={99} align="left">None</TableCell></TableRow>
          )}
          {rows?.map((value, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, whiteSpace: 'nowrap' }}
            >
              <TableCell>{value.effectiveInWeek}</TableCell>
              <TableCell>{value.effectiveOutWeek}</TableCell>
              <TableCell>{value.POST}</TableCell>
              <TableCell>{value.part?.partNumber}</TableCell>
              <TableCell>{value.softwarePart?.partNumber}</TableCell>
              <TableCell>{value.assembly?.partNumber}</TableCell>
              <TableCell>{value.createdAt}</TableCell>
              <TableCell>{value.lastModified}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
