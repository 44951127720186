import React from 'react';
import Box from '@mui/material/Box';
import { ReactMarkdownWithSyntaxHighlighting } from '../../../common/ReactMarkdownWithSyntaxHighlighting';

const Md = ReactMarkdownWithSyntaxHighlighting;

export function PmlsPage() {
  return (
    <>
      <h1>Usage Rules/PMLs</h1>

      <Box mb={5}>

        <Md content={`

![PMLs in PRINS](/images/mbom-rules.png)

**Name**
 
Usage Rule

**Documentation**

Usage Rule represents a condition that is required to configure in (make valid) a usage in a product.
It comes in several flavors (types):

**Single PML:**

Represents just one CPV.
It must have a PML id.

**Combination PML:**

It is a set of Single PMLs / CPVs required (AND). Usage Rule must have PML id
Note: only one Combination PML, is allowed, via the "used through" per Usage(PartUsage, Consumption Record)

**Variant Combination:**

This is a more generic logic for creating backwards compatibility for the Pummel (PML) concept used in legacy systems. 
Each Variant Combination is a list of ANDs.
PML id on Usage rule is not mandatory, and once PRINS recieves PML ids, the Variant Combination is converted to either Single PML or Combination PML.

**Information on relations**

The "requires" relation to the CPV is a representation of a boolean AND like this:
CPV AND CPV AND CPV...
The "used through" relation allows many "CPV condition"s for one "Usage".
This together will be boolean logic like this example:
(CPV) OR (CPV AND CPV) OR (CPV AND CPV AND CPV) OR...
Uniqueness of usage Rule is identified in context with Dictionary from where CPVs are used along with product context it's used for e.g. typecode such as 4101, 4102.

*Last modified: 2024-09-04*


        `} />

      </Box>
    </>
  );
}
