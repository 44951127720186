import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import { createDialog } from '../../../../../hooks/useDialogs';
import { MfgAssemblyPlaceholderDialog } from './MfgAssemblyPlaceholderDialog';

interface Props {
  id: string,
  size?: 'small' | 'medium' | 'large',
}

export function MfgAssemblyPlaceholderDialogButton(props: Props) {
  return (
    <IconButton
      size={props.size || 'medium'}
      color="primary"

      onClick={(e) => {
        e.stopPropagation();
        createDialog((close) => <MfgAssemblyPlaceholderDialog close={close} id={props.id}/>);
      }}
    >
      <OpenInNewIcon />
    </IconButton>
  );
}
