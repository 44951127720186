import React, { useState } from 'react';
import { Alert } from '@mui/material';
import useInterval from '../../hooks/useInterval';
import { useAuth } from '../../hooks/useAuth';
import { Section } from '../layout/Section';

export function LoginState() {
  const { timeUntilExpire, login } = useAuth();
  const [hasExpired, setHasExpired] = useState(false);

  useInterval(() => {
    if (timeUntilExpire() <= 0) {
      login();
      setHasExpired(true);
    } else {
      setHasExpired(false);
    }
  }, 2000);

  if (hasExpired) {
    return (
      <Section>
        <Alert severity="warning" sx={{ mb: 2 }}>
          Your session has expired. Refresh the page.
        </Alert>
      </Section>
    );
  }

  return null;
}
