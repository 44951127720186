import React from 'react';
import Typography from '@mui/material/Typography';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import Gql1 from './parts.graphql';
import Gql2 from './part-assemblies.graphql';
import Gql3 from './part-assemblies-using-fragments.graphql';
import Gql4 from './part-assemblies-flat-constituents.graphql';
import Gql5 from './part-assemblies-leaf-parts.graphql';
import Gql6 from './part-exists-in-ebom.graphql';
import Gql7 from './parts-in-bom.graphql';
import { subtractWeeks, yearWeekFormat } from '../../../../../util/date';
import { Mermaid } from '../../../../common/Mermaid';
import { examplePartStructureGraph } from './mermaid/part-structure';

export function PartsCodeExamplePage() {
  return (
    <>
      <h1>Parts</h1>

      <Mermaid text={examplePartStructureGraph} />

      <Typography paragraph mt={2}>
        The part structure in GraphHub looks like this.
      </Typography>

      <Typography paragraph>
        <b>Part</b> has the part information and is uniquely identified by its part number <br/>
        <b>PartUsage</b>, sometimes called <em>delivery unit</em>, connects a Part to a PartAddress
        and has information about effectivity period (effectivityInWeek and effectivityOutWeek)
        and steering/variant condition. <br/>
        <b>PartConstituent</b>, similarly to PartUsage, connects a Part to another Part and also has
        information about effectivity period <br/>
      </Typography>

      <Typography paragraph>
        Other relevant types not shown in the graph are
        PartVersion which is connected to Part,
        FgPos which is connected to PartAddress and
        UsageRule, which contains the steering/variant condition, which is connected to PartUsage
      </Typography>

      <Typography paragraph mt={6}>
        This example fetches a Part using the part number
        and shows all Part Usages that does not have an Effective Out that is older than 36 weeks
      </Typography>

       <GraphQLCodeFile
        file={Gql1}
        variables={{
          partNumber: '80003380',
          usagesEffectiveOutAfter: yearWeekFormat(subtractWeeks(36)),
        }}
       />

      <Typography paragraph mt={4}>
        This example fetches some parts that have constituents (sub parts). <br/>
        Try the graph view for a more comprehensive view of the data.
      </Typography>

      <GraphQLCodeFile
        file={Gql2}
        showGraph
      />

      <Typography paragraph mt={4}>
        We can avoid having to repeating ourselves using fragments. <br/>
        Here is the same query using fragments.
      </Typography>

      <GraphQLCodeFile
        file={Gql3}
        showGraph
      />

      <Typography variant="h2">Ingoing Parts</Typography>

      <Typography paragraph mt={4}>
        Assemblies are parts with other parts on them.
        <ul>
          <li>A <b>Part Usage</b> has one <b>Part</b> or one <b>Software Part</b></li>
          <li>A <b>Part</b> can have multiple <b>Part Constituents</b> (assembledBy)</li>
          <li>A <b>Part Constituent</b> has one <b>Part</b> or one <b>Software Part</b></li>
        </ul>
        This means that parts can have parts that have parts and so on.
      </Typography>

      <Typography paragraph mt={4}>
        Because parts are in a hierarchy it can sometimes be difficult to traverse the result,
        and filtering on parts can be hard if you don´t know where in the hierarchy the part is.
      </Typography>
      <Typography paragraph mt={4}>
        So instead we can use some other fields that fetch all ingoing constituents no matter
        what level in the hierarchy it is in.
      </Typography>

      <GraphQLCodeFile
        file={Gql4}
      />

      <Typography paragraph mt={4}>
        We can also filter this field just like any other relationship field
      </Typography>

      <GraphQLCodeFile
        file={Gql5}
      />

      <Typography paragraph mt={4}>
        Using this field inside a filter allows us to easily find anything containing a part
        no matter if it is a part, sub-part och sub-sub-sub-part. <br/>
        Here we fetch all eboms that uses a part
      </Typography>

      <GraphQLCodeFile
        file={Gql6}
        variables={{
          partNumber: '80005418',
        }}
      />

      <Typography paragraph mt={4}>
        Or doing the opposite, using Part.rootPartUsage, we can traverse down to the part usage
        of the assembly and apply our condition. Resulting in all parts that are used directly
        on the bom or is part of an assembly that is used on the bom.
      </Typography>

      <GraphQLCodeFile
        file={Gql7}
        variables={{
          typeDesignation: '16',
        }}
      />
    </>
  );
}
