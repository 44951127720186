/* eslint-disable no-console */
import * as msal from '@azure/msal-browser';
import { Configuration } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { config } from '../config/config';

const { clientId, tenant } = config.authConf;

const msalConfig: Configuration = {
  cache: {
    cacheLocation: 'localStorage',
  },
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenant}`,
    redirectUri: `${window.location.origin}/`,
  },
};

let msalInstance: msal.PublicClientApplication;

export async function getAuthData(triggerLogin = false) {
  msalInstance = msalInstance || new msal.PublicClientApplication(msalConfig);
  let tokenResponse: AuthenticationResult | null;

  const scopes = [
    'openid',
    'profile',
    'User.Read',
    'email',
  ];

  try {
    tokenResponse = await msalInstance.handleRedirectPromise();
    if (tokenResponse) {
      return tokenResponse;
    }
    const accountObj = msalInstance.getAllAccounts()[0];

    if (accountObj) {
      // User has logged in, but no tokens.
      try {
        let token = await msalInstance.acquireTokenSilent({
          account: accountObj,
          scopes,
        });

        // Refresh if expired
        if (secondsLeftUntilTokenExpires(token) <= 0) {
          token = await msalInstance.acquireTokenSilent({
            account: accountObj,
            scopes,
            forceRefresh: true,
          });
        }

        return token;
      } catch (err) {
        await msalInstance.acquireTokenRedirect({ scopes });
      }
    }
    // No accountObject or tokenResponse present. User must now login.
    if (triggerLogin) {
      await msalInstance.loginRedirect({ scopes });
    }
  } catch (error) {
    console.error('Failed to handleRedirectPromise()', error);
  }

  return null;
}

export async function logout(homeAccountId: string) {
  await msalInstance.logoutRedirect({
    account: msalInstance.getAccountByHomeId(homeAccountId),
  });
}

export function secondsLeftUntilTokenExpires(token: AuthenticationResult) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (token.idTokenClaims as any).exp - Math.floor(Date.now() / 1000);
}
