import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { FeatureBreakdown } from '../../../../../tools/feature-breakdown/FeatureBreakdown';

interface Props {
  typeCode: string;
  features: string[];
  structureWeek: number;
}

export function FeatureBreakdownTab(props: Props) {
  const { typeCode, features, structureWeek } = props;

  if (!typeCode) {
    return <LinearProgress />; // In case it has not been loaded yet
  }

  return (
    <>
      <FeatureBreakdown
        defaultValues={{
          structureWeek,
          typeCode,
          features,
        }}
      />
    </>
  );
}
