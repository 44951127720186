import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React from 'react';
import { Stack, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import ErrorMessage from '../../common/ErrorMessage';
import { useDataOverviewQuery } from '../../../generated/graphql';
import { dateTimeFormat } from '../../../util/date';

export function EBOMWidget() {
  const { data, loading, error } = useDataOverviewQuery();

  return (
    <Card>

      <ErrorMessage error={error}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Last updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="BomInstances" aggregate={data?.bomInstancesAggregate}/>
          <Row name="Arts" aggregate={data?.artsAggregate}/>
          <Row name="FunctionGroups" aggregate={data?.functionGroupsAggregate}/>
          <Row name="FgPos" aggregate={data?.fgPosAggregate}/>
          <Row name="PartAddresses" aggregate={data?.partAddressesAggregate}/>
          <Row
            name="Released part addresses"
            aggregate={data?.partAddressesReleased}
            tooltip="Part addresses with status RELEASED"
          />
          <Row
            name="Preliminary part addresses"
            aggregate={data?.partAddressesPreliminary}
            tooltip="Part addresses with status PRELIMINARY"
          />
          <Row name="PartUsages" aggregate={data?.partUsagesAggregate}/>
          <Row
            name="Released part usages"
            aggregate={data?.partUsagesReleased}
            tooltip="Part usages where part address is RELEASED"
          />
          <Row
            name="Preliminary part usages"
            aggregate={data?.partUsagesPreliminary}
            tooltip="Part usages where part address is PRELIMINARY"
          />

        </TableBody>
      </Table>
      {loading && <LoadingOverlay/>}
    </Card>
  );
}

export function MBOMWidget() {
  const { data, loading, error } = useDataOverviewQuery();

  return (
    <Card>

      <ErrorMessage error={error}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Last updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="MbomInstances" aggregate={data?.mbomInstancesAggregate}/>
          <Row name="ConsumptionRecords" aggregate={data?.consumptionRecordsAggregate}/>
          <Row name="DeliveryModules" aggregate={data?.deliveryModulesAggregate}/>
          <Row name="DeliveryModuleConstituents" aggregate={data?.dModuleConstituentsAggregate}/>
        </TableBody>
      </Table>
      {loading && <LoadingOverlay/>}
    </Card>
  );
}

export function PartsWidget() {
  const { data, loading, error } = useDataOverviewQuery();

  return (
    <Card>

      <ErrorMessage error={error}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Last updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="Parts" aggregate={data?.partsAggregate}/>
          <Row name="SoftwareParts" aggregate={data?.softwarePartsAggregate}/>
          <Row name="DocumentRevisions" aggregate={data?.documentRevisionsAggregate}/>
          <Row name="PartVersions" aggregate={data?.partVersionsAggregate}/>
          <Row name="PartConstituents" aggregate={data?.partConstituentsAggregate}/>
          <Row name="PartFolderIssues" aggregate={data?.partFolderIssuesAggregate}/>
          <Row
            name="Released parts"
            aggregate={data?.partsReleased}
            tooltip="Parts that have at least one part address that is RELEASED"
          />
          <Row
            name="Preliminary parts"
            aggregate={data?.partsPreliminary}
            tooltip="Parts that have at least one part address that is PRELIMINARY"
          />
          <Row
            name="Main parts"
            aggregate={data?.partsMain}
            tooltip="Parts that are not a constituent of another part"
          />
          <Row
            name="Sub parts"
            aggregate={data?.partsSub}
            tooltip="Parts that are constituents of other parts"
          />
          <Row
            name="Unused parts"
            aggregate={data?.partsUnused}
            tooltip="Parts that have no usage or is not a constituent of another part"
          />
        </TableBody>
      </Table>
      {loading && <LoadingOverlay/>}
    </Card>
  );
}

export function ConfigurationsWidget() {
  const { data, loading, error } = useDataOverviewQuery();

  return (
    <Card>
      <ErrorMessage error={error}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Last updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="StoredCpvSets" aggregate={data?.storedCpvSetsAggregate}/>
          <Row name="UsageRules" aggregate={data?.usageRulesAggregate}/>
          <Row name="CommercialPackages" aggregate={data?.commercialPackagesAggregate}/>
          <Row name="ConfigurationRules" aggregate={data?.configurationRulesAggregate}/>
          <Row name="ConfigurationDictionaries" aggregate={data?.configurationDictionariesAggregate}/>
          <Row name="ConfigurationParameters" aggregate={data?.configurationParametersAggregate}/>
          <Row name="ConfigurationParameterValues" aggregate={data?.configurationParameterValuesAggregate}/>
          <Row name="variabilityDomains" aggregate={data?.variabilityDomainsAggregate}/>
        </TableBody>
      </Table>
      {loading && <LoadingOverlay/>}
    </Card>
  );
}

export function ProductPartitioningWidget() {
  const { data, loading, error } = useDataOverviewQuery();

  return (
    <Card>

      <ErrorMessage error={error}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Last updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="ProductLineMobilities" aggregate={data?.productLineMobilitiesAggregate}/>
          <Row name="ProductFamilies" aggregate={data?.productFamiliesAggregate}/>
          <Row name="ProductContexts" aggregate={data?.productContextsAggregate}/>
        </TableBody>
      </Table>
      {loading && <LoadingOverlay/>}
    </Card>
  );
}

export function ChangeHandlingWidget() {
  const { data, loading, error } = useDataOverviewQuery();

  return (
    <Card>

      <ErrorMessage error={error}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Last updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="ChangeOrders" aggregate={data?.changeOrdersAggregate}/>
          <Row name="ChangeOrderIssues" aggregate={data?.changeOrderIssuesAggregate}/>
          <Row name="MfgChangeOrders" aggregate={data?.mfgChangeOrdersAggregate}/>
          <Row name="MfgChangeOrderIssues" aggregate={data?.mfgChangeOrderIssuesAggregate}/>
        </TableBody>
      </Table>
      {loading && <LoadingOverlay/>}
    </Card>
  );
}

export function ManufacturingWidget() {
  const { data, loading, error } = useDataOverviewQuery();

  return (
    <Card>

      <ErrorMessage error={error}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Last updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="Plants" aggregate={data?.plantsAggregate}/>
          <Row name="BopRoutings" aggregate={data?.bopRoutingsAggregate}/>
          <Row name="BopCompoundOperations" aggregate={data?.bopCompoundOperationsAggregate}/>
          <Row name="BopOperations" aggregate={data?.bopOperationsAggregate}/>
          <Row name="BopMfgAssemblyTargets" aggregate={data?.bopMfgAssemblyTargetsAggregate}/>
          <Row name="BopPartUsages" aggregate={data?.bopPartUsagesAggregate}/>
          <Row name="MfgAssemblyPlaceholders" aggregate={data?.mfgAssemblyPlaceholdersAggregate}/>
          <Row name="MfgPartUsages" aggregate={data?.mfgPartUsagesAggregate}/>
        </TableBody>
      </Table>
      {loading && <LoadingOverlay/>}
    </Card>
  );
}

interface RowProps {
  name: string | JSX.Element,
  aggregate?: {
    count: number,
    lastModified?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      max?: any | null
    }
  }
  tooltip?: string,
}

function Row(props: RowProps) {
  const missing = <Box sx={{ opacity: 0.3 }}>-</Box>;

  return (
    <TableRow>
      <TableCell valign="middle">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box display="inline-block">
            {props.name}
          </Box>
          {props.tooltip && (
            <Tooltip title={props.tooltip} placement="right">
              <InfoOutlinedIcon color="info"/>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
      <TableCell align="right" sx={{ fontFamily: 'monospace' }}>{props.aggregate?.count || missing}</TableCell>
      <TableCell align="right" sx={{ fontFamily: 'monospace' }}>{dateTimeFormat(props.aggregate?.lastModified?.max, null) || missing}</TableCell>
    </TableRow>
  );
}
