import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import { CoiPartVersionInfoFragment } from '../../../../../../generated/graphql';

interface Props {
  rows: CoiPartVersionInfoFragment[]
}

export function CoiPartVersionTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>versionId</TableCell>
            <TableCell>partValidity</TableCell>
            <TableCell>preSeriesNumber</TableCell>
            <TableCell>sharedTechFlag</TableCell>
            <TableCell>status</TableCell>
            <TableCell>releaseDate</TableCell>
            <TableCell>part.name</TableCell>
            <TableCell>part.partNumber</TableCell>
            <TableCell>softwarePart.name</TableCell>
            <TableCell>softwarePart.partNumber</TableCell>
            <TableCell>partFolderIssue</TableCell>
            <TableCell>createdAt</TableCell>
            <TableCell>lastModified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow><TableCell colSpan={99} align="left">None</TableCell></TableRow>
          )}
          {rows?.map((value, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, whiteSpace: 'nowrap' }}
            >
              <TableCell>{value.versionId}</TableCell>
              <TableCell>{value.partValidity}</TableCell>
              <TableCell>{value.preSeriesNumber}</TableCell>
              <TableCell>{value.sharedTechFlag}</TableCell>
              <TableCell>{value.status}</TableCell>
              <TableCell>{value.releaseDate}</TableCell>
              <TableCell>{value.part?.name}</TableCell>
              <TableCell>{value.part?.partNumber}</TableCell>
              <TableCell>{value.softwarePart?.name}</TableCell>
              <TableCell>{value.softwarePart?.partNumber}</TableCell>
              <TableCell>
                <ul>
                  {value.partFolderIssue?.map((pfi, i) => (
                    <li key={i}>
                      issue: {pfi.issue} <br/>
                      status: {pfi.status} <br/>
                      createdAt: {pfi.createdAt} <br/>
                      lastModified: {pfi.lastModified} <br/>
                      auxDocsAggregate: {pfi.auxDocsAggregate} <br/>
                      primaryDocsAggregate: {pfi.primaryDocsAggregate}
                    </li>
                  ))}
                </ul>
              </TableCell>
              <TableCell>{value.createdAt}</TableCell>
              <TableCell>{value.lastModified}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
