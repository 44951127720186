/* eslint-disable max-len */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {
  ApplicationDetailsFragment,
} from '../../../generated/graphql';
import { IntegrationSecretsTable } from '../AppSecrets/IntegrationSecretsTable';
import { AppKeyPreview } from './AppKeyPreview';
import { CreateSecretDialog } from '../AppSecrets/CreateSecretDialog';

interface Props {
  application: ApplicationDetailsFragment,
  refresh: () => void,
}

export function ApplicationKeys(props: Props) {
  const { refresh, application } = props;
  const [newKey, setNewKey] = useState<string | null>(null);

  const onCreate = (key: string) => {
    setNewKey(key);
    refresh();
  };

  return (
    <>
      <Box mb={4}>
        <IntegrationSecretsTable secrets={application.secrets || []} refresh={refresh} />
        <Stack spacing={2} direction="row" justifyContent="center" mt={2}>
           <CreateSecretDialog application={application} onCreate={onCreate}/>
        </Stack>
      </Box>
      {newKey && (
        <Box mb={4}>
          <Alert severity="success">
            A new key has been generated! <br/>
            Copy and store this in a safe location as you will <b>not be able to see the full key here again</b>. <br/>
            For more info on how to use this key see “Using the key” in the help section. <br/>
            <AppKeyPreview appKey={newKey}/>
          </Alert>
        </Box>
      )}
    </>
  );
}
