import React from 'react';
import { SwBaselineTable } from '../../documentation/pages/browser/sw-baseline/SwBaselineTable';

export function SwBaselineBrowserPage() {
  return (
    <>
      <SwBaselineTable/>
    </>
  );
}
