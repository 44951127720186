/* eslint-disable guard-for-in,no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {
  FormControl,
  InputLabel, LinearProgress, MenuItem, OutlinedInput, Select,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  StoredCpvSetWhere,
  useCpvSetsOptionsQuery,
  CpvSetsOptionsQuery,
  ConfigParamValuesFragment,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { colors } from '../../../../../services/mui-theme';

type ConfigDictionary =
  CpvSetsOptionsQuery['configurationDictionaries'][0];

interface Props {
  onChange: (value: StoredCpvSetWhere) => void;
}

type SelectedValues = {
  [key: string]: ConfigParamValuesFragment;
};

export function StoredCpvSetFilter(props: Props) {
  const [selectedConfigDict, setSelectedConfigDict] = useState<ConfigDictionary>();
  const [selectedConfigParamValues, setSelectedConfigParamValues] = useState<SelectedValues>({});

  useEffect(() => {
    if (selectedConfigDict) {
      const wheres: StoredCpvSetWhere[] = [{
        configurationDictionary: { name: selectedConfigDict.name },
      }];

      for (const cpCode in selectedConfigParamValues) {
        wheres.push({
          // Note that SINGLE is a lot faster than SOME. Both work.
          values_SINGLE: {
            id: selectedConfigParamValues[cpCode].id || '',
            // Not using id:
            // altCode: selectedConfigParamValues[cpCode].altCode,
            // configurationParameter: { altCode: cpCode },
          },
        });
      }
      props.onChange({ AND: wheres });
    } else {
      props.onChange({});
    }
  }, [selectedConfigParamValues, selectedConfigDict]);

  const { data, loading, error } = useCpvSetsOptionsQuery();

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <LinearProgress />;
  }

  if (!data) {
    return <div>No StoredCPV. Try again</div>;
  }

  return (
    <Box>
      <Card>
        <Box p={2}>
          <Grid container spacing={1} mb={1.5}>
            <Grid item>
              <DictionarySelect
                options={data.configurationDictionaries}
                onChange={(value: ConfigDictionary | undefined) => {
                  setSelectedConfigParamValues({}); // reset selected values
                  setSelectedConfigDict(value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1.5}>
            {selectedConfigDict?.configurationParameters.map((cp) => (
              <Grid item key={cp.name} width={200}>
                <SelectValue
                  options={cp.configurationParameterValues}
                  label={cp.name || ''}
                  selectedValue={selectedConfigParamValues[cp.code]}
                  onChange={(option) => {
                    if (option) {
                      setSelectedConfigParamValues({
                        ...selectedConfigParamValues,
                        [cp.code]: option,
                      });
                    } else {
                      delete selectedConfigParamValues[cp.code];
                      setSelectedConfigParamValues({ ...selectedConfigParamValues });
                    }
                  }}
                ></SelectValue>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Card>
    </Box>
  );
}

function DictionarySelect(props: {
  options: ConfigDictionary[],
  onChange: (value: (ConfigDictionary | undefined)) => void
}) {
  const filteredOptions = props.options.filter((option) => option.name.length > 0);

  return <FormControl size="small" sx={{ minWidth: 260 }}>
    <InputLabel id='dic-select-label'>Configuration Dictionary</InputLabel>
    <Select
      labelId='dic-select-label'
      label='Configuration Dictionary'
      onChange={(e) => {
        const selected = filteredOptions.find((o) => o.name === e.target.value);

        props.onChange(selected);
      }}
      size="small"
      fullWidth
    >
      <MenuItem value="">-</MenuItem>
      {filteredOptions
        .map((option, index) => (
          <MenuItem key={index} value={option.name}>
            <Box
              className="font-monospace"
              sx={{ display: 'inline-block', mr: 2, fontFamily: 'monospace' }}
            >{option.name} </Box>
            <Box
              sx={{ display: 'inline-block', color: colors.brand.blue3 }}
            >{option.productContexts.map((pc) => pc.name).join(', ')} </Box>

          </MenuItem>
        ))}
    </Select>
  </FormControl>;
}

export function SelectValue(props: {
  label: string;
  options: ConfigParamValuesFragment[];
  selectedValue?: ConfigParamValuesFragment | undefined;
  onChange: (value: ConfigParamValuesFragment | undefined) => void;
}) {
  const { label, options } = props;
  return (
    <FormControl sx={{ width: '100%' }} size="small" variant="outlined">
      <InputLabel id={label} shrink={true}>{label}</InputLabel>
      <Select
        input={<OutlinedInput notched label={label} />}
        labelId={label}
        label={label}
        value={props.selectedValue?.id || ''}
        onChange={(e) => {
          const s = options.find((o) => o.id === e.target.value);
          props.onChange(s || undefined);
        }}
      >
        <MenuItem value="">-</MenuItem>
        {options
          .map((option, index) => (
            <MenuItem key={index} value={option.id}>
              <Box
                className="font-monospace"
                sx={{ display: 'inline', mr: 1, fontFamily: 'monospace' }}
              >{option.code}</Box>
              <Box
                className="font-monospace"
                sx={{ display: 'inline', color: colors.brand.orange2, mr: 1 }}
              >{option.altCode}</Box>
              <Box
                sx={{ display: 'inline' }}
              >{option.altName || option.name}</Box>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
