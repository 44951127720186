import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  ConsumptionRecordMbomDataFragment,
} from '../../../../../../generated/graphql';
import { ConsumptionRecordDialogButton } from '../../consumption-records/ConsumptionRecordDialogButton';
import { FieldInteger } from '../../common/FieldValues';
import { FieldCoi } from '../../common/FieldCoi';
import { FieldMiniTableVerticalDividers } from '../../common/FieldMiniTable';
import { LargePartDialogButton } from '../../common/dialog-buttons/PartDialogButton';

interface ConsumptionsRecordsTableProps {
  consumptionRecords: ConsumptionRecordMbomDataFragment[]
}

export function ConsumptionsRecordsTable(props: ConsumptionsRecordsTableProps) {
  const { consumptionRecords } = props;

  return (
    <Box mb={1}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell align="right">POST</TableCell>
              <TableCell align="right">quantity</TableCell>
              <TableCell>partNumber</TableCell>
              <TableCell>UsageRules</TableCell>
              <TableCell>Add</TableCell>
              <TableCell>Del</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consumptionRecords.map((cr, index) => (
              <TableRow
                key={index}
                className={cr.isRevoked ? 'revoked' : ''}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  // Strikethrough and change opacity when isRevoked
                  '&.revoked': {
                    opacity: 0.5,
                    textDecoration: 'line-through',
                  },
                }}
              >
                <TableCell padding="checkbox">
                  <ConsumptionRecordDialogButton id={cr.id} />
                </TableCell>
                <TableCell align="right">
                  <FieldInteger value={cr.POST} alignRight />
                </TableCell>
                <TableCell>
                  <FieldInteger value={cr.quantity} alignRight />
                </TableCell>
                <TableCell>
                  <LargePartDialogButton
                    part={cr.partUsage.hardwareOrSoftwarePart || cr.partUsage.deliveryModule}
                  />
                </TableCell>
                <TableCell>
                  <FieldMiniTableVerticalDividers>
                  {cr.usageRules.map((ur) => (
                    <TableRow key={ur.pmlId}>
                      <TableCell>{ur.pmlId}</TableCell>
                      <TableCell>{ur.varCodes}</TableCell>
                    </TableRow>
                  ))}
                  </FieldMiniTableVerticalDividers>
                </TableCell>
                <TableCell>
                  <FieldCoi
                    coiNumber={cr.changeOrderIssueAdd?.coiNumber}
                    coiNumberTooltip={'.changeOrderIssueAdd.coiNumber'}
                    week={cr.effectiveInWeek}
                    weekTooltip={'.effectiveInWeek'}
                  />
                </TableCell>
                <TableCell>
                  <FieldCoi
                    coiNumber={cr.changeOrderIssueDel?.coiNumber}
                    coiNumberTooltip={'.changeOrderIssueDel.coiNumber'}
                    week={cr.effectiveOutWeek}
                    weekTooltip={'.effectiveOutWeek'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
