import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React, { useState } from 'react';
import { UserNotFound } from '../../common/UserNotFound';
import { dateTimeFormat } from '../../../util/date';
import { ApplicationOperationEditButton } from '../../control-panel/Operations/ApplicationOperationEditButton';
import { ApplicationOperationDeleteButton } from '../../control-panel/Operations/ApplicationOperationDeleteButton';
import { OperationDetailsFragment, useAdminListOperationsQuery } from '../../../generated/graphql';
import { getComparator } from '../../common/sortTables/comparators';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';

export function AdminApplicationOperations() {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof OperationDetailsFragment>('lastModified');
  const { data, refetch } = useAdminListOperationsQuery();

  const operations = data?.admin.applicationOperations || [];

  const sortableRows = operations.slice().sort(
    getComparator<OperationDetailsFragment>(order, orderBy),
  );

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(property, direction) => {
              setOrder(direction);
              setOrderBy(property);
            }}
            refetch={refetch}
            headCells={headCells}
          />
          <TableBody>
            {sortableRows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  {row.application.teamName} <br/>
                  <em>{row.application.appName}</em> ({row.application.appId})
                </TableCell>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.impactLevel}
                </TableCell>
                <TableCell>
                  {row.modifiedByUser?.name || <UserNotFound/>}<br/>
                </TableCell>
                <TableCell>
                  {dateTimeFormat(row.lastModified)}
                </TableCell>
                <TableCell padding="checkbox">
                  <ApplicationOperationEditButton
                    application={row.application}
                    operation={row}
                    onComplete={refetch}
                  />
                  <ApplicationOperationDeleteButton id={row.id}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'application',
    label: 'Application',
    isSortable: true,
  },
  {
    id: 'name',
    label: 'Name',
    isSortable: true,
  },
  {
    id: 'impactLevel',
    label: 'Impact Level',
    isSortable: true,
  },
  {
    id: 'modifiedByUser.name',
    label: 'Modified by',
    isSortable: true,
  },
  {
    id: 'lastModified',
    label: 'Modified',
    isSortable: true,
  },
];
