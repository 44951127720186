/* eslint-disable @typescript-eslint/no-explicit-any */
export function safeParseJson(json: string | null | undefined, fallback: any): any {
  if (!json) {
    return fallback;
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    return fallback;
  }
}
