/* eslint-disable max-len */
import React, { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { DocumentNode } from 'graphql/language';
import { SlideUpTransition } from '../../parts/PartDialog/SlideUpTransition';
import ErrorMessage from '../../../../../common/ErrorMessage';
import {
  CpvSetDialogDocument,
  CpvSetDialogQuery,
  useCpvSetDialogQuery,
} from '../../../../../../generated/graphql';
import { colors } from '../../../../../../services/mui-theme';
import { FieldListValue } from '../../common/FieldListValue';
import { PrettyJson } from '../../../../../common/data/PrettyJson';
import { PrettyQuery } from '../../../../../common/data/PrettyQuery';
import { FeatureBreakdownTab } from './FeatureBreakdownTab';
import { DialogLoader } from '../../../../../common/dialog/DialogLoader';
import { QuantityQualityCheckTab } from './QuantityQualityCheckTab';

interface Props {
  id: string,
  close: () => void
}
export function CpvSetDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = React.useState('preview');

  const { data, loading, error } = useCpvSetDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const cpvSet = data?.storedCpvSets[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  const features = data?.storedCpvSets[0].values.map((v) => {
    return `${v.configurationParameter.code} = ${v.code}`;
  }).sort();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="lg"
      TransitionComponent={SlideUpTransition}
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogTitle>
        <Stack justifyContent="space-between" alignItems="center" direction="row">
          <div>Stored CPV Set {cpvSet?.name}</div>
          <Button color="error" onClick={handleClose}>Close</Button>
        </Stack>
      </DialogTitle>
      {loading && <DialogLoader/>}
      <ErrorMessage error={error} />

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Preview" value="preview" />
            <Tab label="EBOM Breakdown" value="breakdown" />
            <Tab label="Quantity Check" value="qc" />
            <Tab label="Json" value="json" />
            <Tab label="Example Query" value="query" />
          </TabList>
        </Box>
        <TabPanel value="preview"><DataTab data={data}></DataTab></TabPanel>
        <TabPanel value="breakdown">
          <FeatureBreakdownTab
            typeCode={cpvSet?.modelYearDetails?.typeCode.toString() || ''}
            structureWeek={cpvSet?.effectiveInWeek || 0}
            features={features || []}
          ></FeatureBreakdownTab>
        </TabPanel>
        <TabPanel value="qc">
          <QuantityQualityCheckTab
            typeCode={cpvSet?.modelYearDetails?.typeCode.toString() || ''}
            features={features || []}
          ></QuantityQualityCheckTab>
        </TabPanel>
        <TabPanel value="json"><JsonTab data={data}></JsonTab></TabPanel>
        <TabPanel value="query"><QueryTab query={CpvSetDialogDocument}></QueryTab></TabPanel>
      </TabContext>
    </Dialog>
  );
}

function DataTab(props: { data?: CpvSetDialogQuery }) {
  const cpvSet = props.data?.storedCpvSets[0];

  const values = cpvSet ? [...cpvSet.values].sort(sortCpvs) : [];

  return (
    <>
      <Box p={2}>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="name" value={cpvSet?.name} />
            <FieldListValue name="revision" value={cpvSet?.revision} />
            <FieldListValue name="pno" value={cpvSet?.pno} />
            <FieldListValue name="pno34" value={cpvSet?.pno34} />
            <FieldListValue name="externalPno" value={cpvSet?.externalPno} />
            <FieldListValue name="pno18" value={cpvSet?.pno18} />
            <FieldListValue name="description" value={cpvSet?.description} />
            <FieldListValue name="effectiveInWeek" value={cpvSet?.effectiveInWeek} />
            <FieldListValue name="effectiveOutWeek" value={cpvSet?.effectiveOutWeek} />
          </List>
        </Paper>
      </Box>

      <Box p={2}>
        <Typography variant="h3" mb={1} mx={2}>Model Year Details</Typography>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="type" value={cpvSet?.modelYearDetails?.type} />
            <FieldListValue name="typeCode" value={cpvSet?.modelYearDetails?.typeCode} />
            <FieldListValue name="year" value={cpvSet?.modelYearDetails?.year} />
          </List>
        </Paper>
      </Box>

      <Box p={2}>
        <Typography variant="h3" mb={1} mx={2}>Spec Markets</Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>code</TableCell>
                <TableCell>name</TableCell>
                <TableCell>description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cpvSet?.specMarkets?.map((value, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{value.code}</TableCell>
                  <TableCell>{value.name}</TableCell>
                  <TableCell>{value.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box p={2}>
        <Typography variant="h3" mb={1} mx={2}>Values (Configuration Parameter Values)</Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>cp.cd.name</TableCell>
                <TableCell>cp.code</TableCell>
                <TableCell>cp.altCode</TableCell>
                <TableCell>
                  cp.description & <br />
                  <i>cp.marketDescription</i>
                </TableCell>
                <TableCell>code</TableCell>
                <TableCell>altCode</TableCell>
                <TableCell>
                  description & <br />
                  <i>marketDescription</i>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.sort(sortCpvs).map((value, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{value.configurationParameter?.configurationDictionary?.name}</TableCell>
                  <TableCell sx={{ background: colors.lightUi.light4 }}>{value.configurationParameter?.code}</TableCell>
                  <TableCell sx={{ background: colors.lightUi.light4 }}>{value.configurationParameter?.altCode}</TableCell>
                  <TableCell sx={{ background: colors.lightUi.light4 }}>
                    {value.configurationParameter?.description} <br />
                    <i>{value.configurationParameter?.marketDescription}</i>
                  </TableCell>
                  <TableCell>{value.code}</TableCell>
                  <TableCell>{value.altCode}</TableCell>
                  <TableCell>
                    {value.description}
                    <i>{value.marketDescription}</i>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

function JsonTab(props: { data?: CpvSetDialogQuery }) {
  if (!props.data) {
    return null;
  }

  return (
    <PrettyJson data={props.data} />
  );
}

function QueryTab(props: { query: DocumentNode }) {
  return (
    <>
      <PrettyQuery query={props.query.loc?.source.body.trim() || ''} />
    </>
  );
}

type CpvData = CpvSetDialogQuery['storedCpvSets'][0]['values'][0];
function sortCpvs(a: CpvData, b: CpvData) {
  if (a.configurationParameter.code < b.configurationParameter.code) {
    return -1;
  }
  if (a.configurationParameter.code > b.configurationParameter.code) {
    return 1;
  }

  return 0;
}
