import React, { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  PartsWidget,
  EBOMWidget,
  MBOMWidget,
  ConfigurationsWidget,
  ProductPartitioningWidget, ChangeHandlingWidget, ManufacturingWidget,
} from '../control-panel/Widgets/TypesWidget';

export function DataDashboardPage() {
  return (
    <>
      <Box mt={2} sx={{
        columnGap: '20px',
        columnWidth: '460px',
      }}>
        <Item>
          <WidgetHeader>Product Partitioning</WidgetHeader>
          <ProductPartitioningWidget/>
        </Item>
        <Item>
          <WidgetHeader>EBOM</WidgetHeader>
          <EBOMWidget/>
        </Item>
        <Item>
          <WidgetHeader>MBOM</WidgetHeader>
          <MBOMWidget/>
        </Item>
        <Item>
          <WidgetHeader>Change Handling</WidgetHeader>
          <ChangeHandlingWidget/>
        </Item>
        <Item>
          <WidgetHeader>Parts & Documents</WidgetHeader>
          <PartsWidget/>
        </Item>
        <Item>
          <WidgetHeader>Configuration</WidgetHeader>
          <ConfigurationsWidget/>
        </Item>
        <Item>
          <WidgetHeader>Manufacturing</WidgetHeader>
          <ManufacturingWidget/>
        </Item>
      </Box>
    </>
  );
}

function Item(props: PropsWithChildren) {
  return (
    <Box display="inline-block" mb={3} width="100%">{props.children}</Box>
  );
}

function WidgetHeader(props: PropsWithChildren) {
  return (
    <Typography variant="h3" mt={0} mb={2}>{props.children}</Typography>
  );
}
