import { BehaviorSubject } from 'rxjs';
import { useBehaviorSubject } from './useSubject';

import { config } from '../config/config';

interface SelectedEnvironment {
  key: string,
  name: string,
  graphQLEndpoint: string,
  disabled: boolean,
}

const defaultEnv = config.endpoints
  .find((e) => e.key === config.defaultEnv)
  || config.endpoints[0];

export const environmentSubject = new BehaviorSubject<SelectedEnvironment>(defaultEnv);

export function useSelectedEnvironment() {
  return useBehaviorSubject(environmentSubject);
}
