import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import React from 'react';
import { SoftwarePartDialogQuery } from '../../../../../../generated/graphql';
import { FieldList } from './FieldList';
import { UsedInAssembliesTable } from '../../parts/PartDialog/UsedInAssembliesTable';
import { PartVersionsTable } from '../../parts/PartDialog/PartVersionsTable';
import { PartUsagesTable } from '../../parts/PartDialog/PartUsagesTable';
import { ConstituentTree } from '../../parts/PartDialog/ConstituentTree';

type SoftwarePartDialogSoftwarePart = SoftwarePartDialogQuery['softwareParts'][0];

interface Props {
  softwarePart?: SoftwarePartDialogSoftwarePart
}

export function SoftwarePartDialogInfoPanel(props: Props) {
  const { softwarePart } = props;

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Part Usages</Typography>
          {(softwarePart?.partUsages.length === 0) ? (
            <Alert severity="info">This has no Part usages</Alert>
          ) : (
            <PartUsagesTable part={softwarePart}/>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Part Versions</Typography>
          <PartVersionsTable partVersions={softwarePart?.partVersions || []}/>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper variant="outlined">
          <FieldList softwarePart={softwarePart}/>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>Assembled by</Typography>
          {(softwarePart?.assembledBy.length === 0) ? (
            <Alert severity="info">This softwarePart has no ingoing softwareParts</Alert>
          ) : (
            <ConstituentTree part={softwarePart} isTop={true}/>
          )}
        </Paper>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>Used in assemblies</Typography>
          {(softwarePart?.usedInAssemblies.length === 0) ? (
            <Alert severity="info">This softwarePart is not used in any other softwareParts</Alert>
          ) : (
            <UsedInAssembliesTable part={softwarePart}/>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
