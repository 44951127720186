import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MarkdownFile } from '../../../../common/MarkdownFile';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import PaginationQuery from '../../user-guide/best-practices-page/pagination.graphql';
import paginationText from './offset-pagination-info.md';

export function OffsetPaginationPage() {
  return (
    <Box maxWidth={1400}>
      <MarkdownFile file={paginationText}/>

      <Typography paragraph mt={2}>
        But of course you should use variables when reusing the same query with different inputs.
        Try changing the offset below.
      </Typography>

      <Box maxWidth={960}>
        <GraphQLCodeFile
          file={PaginationQuery}
          variables={{
            limit: 10,
            offset: 0,
          }}
        />
      </Box>
    </Box>
  );
}
