import React from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Link from '@mui/material/Link';
import { useAuth } from '../../hooks/useAuth';
import { SimpleCardLayout } from '../layout/SimpleCardLayout';
import { Section } from '../layout/Section';
import { BullMqOverview } from '../job-browser/BullMqJobOverview';

export function JobOverviewPage() {
  const { data: authData } = useAuth();

  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  return (
    <>
      <Section maxWidth={1200}>
        <BullMqOverview />
      </Section>
    </>
  );
}
