import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import { UsageRuleDialogFragment } from '../../../../../../generated/graphql';
import { PartUsagesTable } from './PartUsagesTable';
import { FieldList } from './FieldList';
import { CPVTable } from './CpvTable';

interface Props {
  usageRule?: UsageRuleDialogFragment;
}

export function UsageRuleDialogInfoPanel(props: Props) {
  const { usageRule } = props;

  return (
    <Grid container spacing={3} p={3}>
      {usageRule?.errors && (
        <Grid item xs={12}>
          <Alert severity="error">
            <Box sx={{ whiteSpace: 'pre' }}>{usageRule.errors}</Box>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} >
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>
            Part Usages
          </Typography>
          {usageRule?.partUsages.length === 0 ? (
            <Alert severity="info">This has no part usages</Alert>
          ) : (
            <PartUsagesTable partUsages={usageRule?.partUsages} />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={7}>
        <Paper variant="outlined">
          <FieldList usageRule={usageRule} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={5}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>
            CPV
          </Typography>
          {usageRule?.cpvs.length === 0 ? (
            <Alert severity="info">This has no cpvs</Alert>
          ) : (
            <CPVTable cpvs={usageRule?.cpvs} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
