export const appStructureDiagram = `

flowchart TD

subgraph Upstream
    direction TB
    TC(Teamcenter PLM)
    COMO(COMO)
end

PRINS[PRINS GraphHub]

subgraph Downstream
D1(App 1)
D2(App 2)
D3(App 3)
D4(Your App):::D4
end

TC -- PLM Data --> PRINS
COMO -- Configuration --> PRINS

PRINS -- HTTPS (GraphQL) --- D1
PRINS -- HTTPS (GraphQL) --- D2
PRINS -- HTTPS (GraphQL) --- D3
PRINS -- HTTPS (GraphQL) --- D4

classDef D4 stroke:#A85F02

`;
