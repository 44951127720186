import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import { useAuth } from '../../hooks/useAuth';
import { CrownSvg } from '../layout/branding/CrownSvg';
import { LoginLayout } from '../layout/LoginLayout';

/**
 * Initial login page.
 * Handles CDSID login.
 */
export function LoginPage() {
  const { data, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate('/control-panel');
    }
  }, [data]);

  return (
    <LoginLayout>
      <Box sx={{ width: 80, maxWidth: '50vw', margin: '0 auto' }}>
        <CrownSvg/>
      </Box>
      <Typography align="center" color="white" fontSize={54} component="h1" mb={1}>
        GraphHub
      </Typography>
      <Typography align="center" mb={3}>
        <Button
          size="large"
          variant='contained'
          endIcon={<LoginSharpIcon/>}
          onClick={() => {
            login();
          }}
        >Login</Button>
      </Typography>
    </LoginLayout>
  );
}
