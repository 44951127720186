import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { GraphExplorer } from '../../../common/GraphExplorer/GraphExplorer';

const sidebarWidth = 230;

export function GraphExplorerPage() {
  return (
    <Box mt={3}>
      <Card>
        <Box sx={{
          position: 'relative',
          height: 'calc(100vh - 136px)',
          '& .graphql-voyager': {
            fontSize: 12,
          },
          '& .type-doc': {
            marginTop: '3px',
          },
          '& .doc-navigation > .header': {
            // lineHeight: 1,
          },
          '& .graphql-voyager > .doc-panel': {
            width: sidebarWidth,
            minWidth: sidebarWidth,
          },
          '& .type-info-popover': {
            left: sidebarWidth,
          },
          '& .loading-box': {
            left: sidebarWidth,
          },
        }}>
          <GraphExplorer/>
        </Box>
      </Card>
    </Box>
  );
}
