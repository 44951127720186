import React from 'react';
import Stack from '@mui/material/Stack';
import { AssembledByInfoFragment, PartInfoFragment } from '../../../../../../generated/graphql';
import { DataTreeRow } from '../../../../../common/data/DataTree';
import { LargePartDialogButton } from '../../common/dialog-buttons/PartDialogButton';

interface Props {
  part?: PartInfoFragment & {
    assembledBy?: AssembledByInfoFragment['assembledBy']
  },
  isTop?: boolean
}

export function ConstituentTree({ part, isTop }: Props) {
  if (!part) {
    return null;
  }

  return (
    <DataTreeRow
      header={
        <>
          {!isTop && (
            <LargePartDialogButton
              part={part}
              showName
            />
          )}
          {isTop && (
            <>
              <b>{part.partNumber} - {part.name}</b>
            </>
          )}
        </>
      }
      suffix={part.__typename}
      defaultExpanded={true}
    >
    {part.assembledBy?.map((c, index) => (
        <DataTreeRow
          key={index}
          header={(
            <Stack direction="row" spacing={2} alignItems="center">
              <span>
                <b>Add:</b> {c.effectiveInWeek} {c.coiNumberAdd && `(${c.coiNumberAdd})`}
              </span>
              <span>
                <b>Del:</b> {c.effectiveOutWeek} {c.coiNumberDel && `(${c.coiNumberDel})`}
              </span>
            </Stack>
          )}
          suffix={c.__typename}
          defaultExpanded={true}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <ConstituentTree part={c.hardwareOrSoftwarePart as any}/>
        </DataTreeRow>
    ))}
    </DataTreeRow>
  );
}
