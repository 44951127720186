import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import { dateTimeFormat } from '../../../util/date';

interface Props {
  debug: string,
  lastTriggered: string,
  failed: boolean,
}

export function WebhookDebugButton(props: Props) {
  const { debug, lastTriggered, failed } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        color={failed ? 'error' : 'info'}
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        {dateTimeFormat(lastTriggered)} <br/>
      </Button>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Box p={2} sx={{
          fontFamily: 'Monospace',
          whiteSpace: 'pre',
          overflow: 'auto',
          maxWidth: 400,
          maxHeight: 500,
        }}>{debug}</Box>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
