import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import { PartDialog_ConsumptionRecordFragment } from '../../../../../../generated/graphql';
import { ValueText, ValueTooltip } from './valueText';

interface Props {
  consumptionRecords: PartDialog_ConsumptionRecordFragment[];
}

export function ConsumptionRecordsTable(props: Props) {
  const { consumptionRecords } = props;
  const [open, setOpen] = useState(true);
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type Code</TableCell>
            <TableCell>POST</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>DU-marking</TableCell>
            <TableCell>Plant</TableCell>
            <TableCell>Is revoked</TableCell>
            <TableCell padding="checkbox">
              <IconButton
                onClick={() => setOpen(!open)}
                aria-label={open ? 'Hide details' : 'Show details'}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        {open && (
          <TableBody>
            {consumptionRecords?.map((c) => (
              <TableRow
                key={c.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  ...(c.isRevoked ? {
                    opacity: 0.5,
                    textDecoration: 'line-through',
                  } : {}),
                }}
              >
                <TableCell>{ValueText(c.mbom?.ebom?.productContext?.typeCode)}</TableCell>
                <TableCell>{ValueText(c.POST)}</TableCell>
                <TableCell>{ValueText(c.partUsage?.part?.partNumber)}</TableCell>
                <TableCell>
                  <ValueTooltip
                    title="consumptionRecord.changeOrderIssueAdd.coiNumber"
                    placement="right"
                    value={c.changeOrderIssueAdd?.coiNumber || null}
                  /><br />
                  <ValueTooltip
                    title="consumptionRecord.effectiveInWeek"
                    placement="right"
                    value={c.effectiveInWeek}
                  />
                </TableCell>
                <TableCell>
                  <ValueTooltip
                    title="consumptionRecord.changeOrderIssueDel.coiNumber"
                    placement="right"
                    value={c.changeOrderIssueDel?.coiNumber || null}
                  /><br />
                  <ValueTooltip
                    title="consumptionRecord.effectiveOutWeek"
                    placement="right"
                    value={c.effectiveOutWeek} />
                </TableCell>
                <TableCell>{ValueText(c.duMarking)}</TableCell>
                <TableCell>{ValueText(c.plant?.plantCode)}</TableCell>
                <TableCell>{c.isRevoked ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer >
  );
}
