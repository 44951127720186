import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import {
  ApplicationDetailsForOperationFragment,
  OperationDetailsFragment,
} from '../../../generated/graphql';
import { ApplicationOperationForm } from './ApplicationOperationForm';

interface Props {
  application: ApplicationDetailsForOperationFragment;
  operation?: OperationDetailsFragment;
  onComplete: () => void;
}

export function ApplicationOperationCreateButton(props: Props) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startIcon={<AddIcon/>}
      >
        Register an operation
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <ApplicationOperationForm application={props.application} onComplete={() => {
          setOpen(false);
          props.onComplete();
        }} />
      </Dialog>
    </>
  );
}
