import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import PaginationQuery from './pagination.graphql';
import { Mermaid } from '../../../../common/Mermaid';
import { paginationSequenceDiagramWebhooks } from './pagination-sequence-diagram';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import GqlPartsUpdated from './parts-updated.graphql';
import { getSevenDaysAgo } from '../../../../../util/date';

export function BestPracticePage() {
  return (
    <>
      <h1>Pagination</h1>

      <Box sx={{
        width: { lg: '40%' },
        float: { lg: 'right' },
      }}>
        <Mermaid text={paginationSequenceDiagramWebhooks} />
      </Box>

      <Alert severity="info">
        PRINS GraphHub does not have currently have a restriction of how much data you can fetch.
        So it´s up to the client to set reasonable limits on the
        requests so that not too much data is fetch in one request.
      </Alert>

      <Link to="/query-filtering/offset-pagination">Read more about how to use pagination</Link>

      <Box sx={{ clear: 'right' }} />

      <Typography paragraph mt={2}>
        Here is an example of how you can use pagination in your GraphQL query.
      </Typography>

      <Box maxWidth={960}>
        <GraphQLCodeFile
          file={PaginationQuery}
          variables={{
            limit: 10,
            offset: 0,
          }}
        />
      </Box>

      <h1>Delta updates</h1>

      <h2>Fetching changed data</h2>

      <Typography paragraph>
        We could filter for any data that has been created/modified
        since last time we checked.
        All entities in PRINS GraphHub has a ”lastModified” property
        that can be used for filtering the data.
        This field is updated when PRINS receives updates from other sources.
      </Typography>

      <GraphQLCodeFile
        file={GqlPartsUpdated}
        variables={{
          date: getSevenDaysAgo().toISOString(),
        }}
      />

      <Typography paragraph>
        Note that this will only fetch entities that
        has been has changed directly and not if some related data has changed.
      </Typography>

      <Typography paragraph>
        These changes can happen multiple times per day.
        And there is no limit to how much data can be updated at once, so this might
        require that you implement pagination (see above) as well on this query.
      </Typography>

    </>
  );
}
