import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import React from 'react';
import { SwCompleteBaselineDialogQuery } from '../../../../../../generated/graphql';
import { DeploymentContextTable } from './SwDeploymentContextTable';
import { ExecutionEnvironmentTable } from './SwExecutionEnvironmentTable';
import { ColoredPaper } from '../../../../../common/style-elements/ColoredPaper';
import { colors } from '../../../../../../services/mui-theme';
import { SwBaselinePartUsageTable } from './SwBaselinePartUsageTable';

type SwBaselineDialogSwBaseline = SwCompleteBaselineDialogQuery['swCompleteBaselines'][0];

interface Props {
  swBaseline?: SwBaselineDialogSwBaseline
}

export function SwBaselineDialogInfoPanel(props: Props) {
  const { swBaseline } = props;

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <ColoredPaper borderColor={colors.brand.blue4} backgroundColor={'#f4f7fc'}>
          <Typography variant="h4" p={2}>Deployment Contexts</Typography>
          {swBaseline?.deploymentContexts ? (
            swBaseline.deploymentContexts.map((dc, index) => (
              <div key={index}>
                <DeploymentContextTable swDeploymentContexts={[dc]} />
                {dc.executionEnvironments && dc.executionEnvironments.length > 0 ? (
                  <ColoredPaper borderColor={colors.brand.orange4} backgroundColor={'#fffefc'}>
                    <Typography variant="h4" p={2}>Execution Environments</Typography>
                    {dc.executionEnvironments.map((ee, eeIndex) => (
                      <div key={eeIndex}>
                        <ExecutionEnvironmentTable swExecutionEnvironments={[ee]} />
                        {ee.baselinePartUsages && ee.baselinePartUsages.length > 0 ? (
                          <ColoredPaper borderColor={colors.brand.green4} backgroundColor={'#f2fff3'}>
                            <Typography variant="h4" p={2}>Sw Baseline Part Usages</Typography>
                              <SwBaselinePartUsageTable baselinePartUsages={ee.baselinePartUsages}/>
                          </ColoredPaper>
                        ) : (
                          <Alert severity="info" style={{ marginTop: '16px' }}>This Execution Environment has no Part Usage</Alert>
                        )
                        }
                      </div>
                    ))
                    }
                  </ColoredPaper>
                ) : (
                  <Alert severity="info" style={{ marginTop: '16px' }}>This Deployment Context has no Execution Environments</Alert>
                )}
              </div>
            ))
          ) : (
            <Alert severity="info">This Software Baseline has no Deployment Contexts</Alert>
          )}
        </ColoredPaper>
      </Grid>
    </Grid>
  );
}
