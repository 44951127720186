import React from 'react';
import Typography from '@mui/material/Typography';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { MicrosoftTeamsIcon } from '../../../common/icons/MicrosoftTeamsIcon';

export function SupportPage() {
  return (
    <>
      <h1>Support</h1>

      <Box mb={5}>
        <Typography variant="h2" mb={1}>Christina Wikström</Typography>
        <Typography paragraph mb={0.5}>
          Support with general questions regarding
          API access, planning, data model
        </Typography>

        <Typography paragraph>
          Preferred contact method: <b>Teams</b> or <b>e-mail</b>
        </Typography>
        <ContactLinks email="christina.wikstrom.2@volvocars.com"/>
      </Box>

      <Box mb={5}>
        <Typography variant="h2" mb={1}>Mikael Mattsson</Typography>
        <Typography paragraph mb={0.5}>
          Technical support with query creation, integration or documentation
        </Typography>

        <Typography paragraph>
          Preferred contact method: <b>Teams</b>
        </Typography>
        <ContactLinks email="mikael.mattsson.2@volvocars.com"/>
      </Box>
    </>
  );
}

function ContactLinks(props: { email: string }) {
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          startIcon={<EmailOutlinedIcon/>}
          href={`mailto: ${props.email}`}
        >
          Email
        </Button>

        <Button
          variant="outlined"
          startIcon={<MicrosoftTeamsIcon/>}
          href={`https://teams.microsoft.com/l/chat/0/0?users=${props.email}`}
          target="_blank"
        >
          Teams
        </Button>
      </Stack>
    </>
  );
}
