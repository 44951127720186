import { LoadingButton } from '@mui/lab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export function RunQueryButton(props: { onClick: () => void, loading: boolean }) {
  const { loading, onClick } = props;

  return (
    <Tooltip title="Run the query and show the json result" placement="top">
      <LoadingButton
        loading={loading}
        startIcon={<PlayArrowIcon/>}
        color="inherit"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >Run</LoadingButton>
    </Tooltip>

  );
}
