import React from 'react';
import { MobilitiesGraph } from '../../documentation/pages/browser/mobilities/MobilitiesGraph';

export function MobilityBrowserPage() {
  return (
    <>
    <MobilitiesGraph/>
    </>
  );
}
