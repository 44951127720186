import React from 'react';
import { UsageRuleTable } from '../../documentation/pages/browser/usageRules/UsageRuleTable';

export function UsageRuleBrowserPage() {
  return (
    <>
      <UsageRuleTable/>
    </>
  );
}
