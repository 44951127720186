import React from 'react';
import Typography from '@mui/material/Typography';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';

import Gql1 from './released-addresses.graphql';
import Gql2 from './released-partusages.graphql';
import Gql3 from './released-parts.graphql';
import Gql4 from './nested-partaddresses.graphql';

export function PreliminaryDataCodeExamplePage() {
  return (
    <>
      <h1>Released and preliminary data</h1>

      <Typography paragraph>
        <b>Part Addresses</b> can be preliminary or released.
        By default you will always get all part addresses, released and preliminary.
        If only the released addresses are desired then you need to add a “filter”.
      </Typography>
      <GraphQLCodeFile file={Gql1} />

      <Typography paragraph mt={6}>
        Because this property only exists on the part address
        we need to reference it with any related data.
      </Typography>
      <GraphQLCodeFile file={Gql2} />

      <Typography paragraph mt={6}>
        A part can be used in many different addresses.
        In this example we list all parts that
        have <b>at least one</b> part usage that is released.
      </Typography>
      <ul>
        <li>SOME: At least one must apply to this rule</li>
        <li>ALL: All related data must apply to this rule</li>
        <li>ONE: Exactly one related data node must apply to this rule</li>
        <li>NONE: No related data must apply to this rule</li>
      </ul>
      <GraphQLCodeFile file={Gql3} />

      <Typography paragraph mt={6}>
        This can also be used to filter nested/related data. <br/>
      </Typography>
      <GraphQLCodeFile file={Gql4} showGraph={true} />

    </>
  );
}
