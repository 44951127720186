/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  LinearProgress,
} from '@mui/material';
import {
  ReleaseStatusEnum,
  useBrowseMbomTreeQuery,
} from '../../../../../../generated/graphql';
import ErrorMessage from '../../../../../common/ErrorMessage';
import { DataTreeRow } from '../../../../../common/data/DataTree';
// import { PartUsageTable } from './PartUsageTable';
import { colors } from '../../../../../../services/mui-theme';
import { ReleaseStatusChip } from '../../common/ReleaseStatusChip';
import { ConsumptionsRecordsTable } from './ConsumptionsRecordsTable';
import { PlantCodeSelect } from '../common/PlantCodeSelect';
import { BomInstanceSelect } from '../common/BomInstanceSelect';

export function MbomInstancesTree() {
  const [selectedTypeDesignation, setSelectedTypeDesignation] = useState<string>();
  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <BomInstanceSelect onSelect={setSelectedTypeDesignation}/>
        </CardContent>
      </Card>
      {selectedTypeDesignation && (
        <Box mt={2}>
          <PlantCodeInstance typeDesignation={selectedTypeDesignation}/>
        </Box>
      )}
    </Box>
  );
}

function BomInstanceTree(props: { typeDesignation: string, plantCode: number }) {
  const { data, loading, error } = useBrowseMbomTreeQuery({
    variables: {
      typeDesignation: props.typeDesignation,
      plantCode: props.plantCode,
    },
    skip: !props.typeDesignation,
  });
  if (error) {
    return <ErrorMessage error={error}/>;
  }

  if (loading) {
    return <LinearProgress/>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {data.bomInstances.map((b, index) => (
        <DataTreeRow
          key={b.typeDesignation + index}
          header={`${b.typeDesignation} / ${b.typeCode}`}
          suffix={b.__typename}
          defaultExpanded={true}
        >
          {b.arts.map((art, index) => (
            <DataTreeRow
              key={index}
              header={<BomNodeHeader node={art}/>}
              suffix={art.__typename}
              defaultExpanded={true}
            >
              {art.functionGroups.map((fg, index) => (
                <DataTreeRow
                  key={index}
                  header={<BomNodeHeader node={fg}/>}
                  suffix={fg.__typename}
                >
                  {fg.fgPos.map((pos, index) => (
                    <DataTreeRow
                      key={index}
                      header={<BomNodeHeader node={pos}/>}
                      suffix={pos.__typename}
                    >
                      {pos.partAddresses.map((pa, index) => (
                        <DataTreeRow
                          key={index}
                          header={(<PartAddressHeader
                            partAddress={pa}
                            consumptionRecordsCount={pa.partUsages.reduce((acc, pu) => {
                              return acc + (pu.consumptionRecordsAggregate?.count || 0);
                            }, 0)}
                              />
                            )}
                          suffix={pa.__typename}
                        >
                          {pa.partUsages.flatMap((u) => u.consumptionRecords).length > 0 && (
                            <ConsumptionsRecordsTable key={index} consumptionRecords=
                              {pa.partUsages.flatMap((u) => u.consumptionRecords)}/>
                          )}
                        </DataTreeRow>
                      ))}
                    </DataTreeRow>
                  ))}
                </DataTreeRow>
              ))}
            </DataTreeRow>
          ))}
        </DataTreeRow>
      ))}
    </>
  );
}

interface BomNodeHeaderProps {
  node: {
    name: string;
    number: string;
    deletedAt?: string | null;
  }
}

function BomNodeHeader(props: BomNodeHeaderProps) {
  const { node } = props;
  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      sx={{
        '&.deleted': {
          opacity: 0.5,
          textDecoration: 'line-through',
        },
      }}
      className={node.deletedAt ? 'deleted' : undefined}
    >
      <Box color={colors.primary} pr={1}>{node.number}</Box>
      <Box>{node.name}</Box>
    </Box>
  );
}

interface PartAddressHeaderProps {
  partAddress: {
    name: string;
    number: string;
    releaseStatus: ReleaseStatusEnum;
  },
  consumptionRecordsCount?: number;
}

function PartAddressHeader(props: PartAddressHeaderProps) {
  const { partAddress, consumptionRecordsCount } = props;
  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box color={colors.primary} pr={1}>{partAddress.number}</Box>
      <Box pr={1}>{partAddress.name}</Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box px={1}>
        Consumption Record: {consumptionRecordsCount || 0}
      </Box>
      <ReleaseStatusChip releaseStatus={partAddress.releaseStatus} />
    </Box>
  );
}

function PlantCodeInstance(props: { typeDesignation: string }) {
  const [selectedPlantCode, setSelectedPlantCode] = useState<number>();
  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <PlantCodeSelect onSelect={setSelectedPlantCode}/>
        </CardContent>
      </Card>
      {selectedPlantCode && (
        <Box mt={2}>
          <Card variant="outlined">
            <CardContent>
              <BomInstanceTree plantCode={selectedPlantCode}
                               typeDesignation={props.typeDesignation}/>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}
