import React from 'react';
import Box from '@mui/material/Box';
import queriesText from './queries-info.md';
import { MarkdownFile } from '../../../../common/MarkdownFile';

export function QueryPage() {
  return (
    <Box maxWidth={1400}>
      <h1>Queries</h1>

      <MarkdownFile file={queriesText}/>

    </Box>
  );
}
