/* eslint-disable no-prototype-builtins */
import { ServerError } from '@apollo/client';
import { ApolloError, NetworkError } from '@apollo/client/errors';

/**
 * Similar to isApolloError
 */
export function isApolloServerError(err: NetworkError): err is ServerError {
  return !!err
    && err.hasOwnProperty('result')
    && err.hasOwnProperty('statusCode');
}

export function createErrorMessage(e: ApolloError) {
  const code = e.graphQLErrors[0]?.extensions?.code;
  // https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes

  if (code === 'GRAPHQL_VALIDATION_FAILED') {
    return "The GraphQL operation is not valid against the server's schema.";
  }

  if (code === 'UNAUTHENTICATED') {
    return 'Authentication missing. Try refreshing the page.';
  }

  if (code === 'FORBIDDEN') {
    return 'Forbidden. This user/application is not allowed to perform this action.';
  }

  if (code === 'INTERNAL_SERVER_ERROR' && e.graphQLErrors[0].message === 'Forbidden') {
    return 'Forbidden. This user/application is not allowed to perform this action.';
  }

  return `${e}`;
}
