import React from 'react';
import { SoftwarePartsTable } from '../../documentation/pages/browser/software-parts/SoftwarePartsTable';

export function SoftwarePartsBrowserPage() {
  return (
    <>
      <SoftwarePartsTable/>
    </>
  );
}
