import React, { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';
import { getComparator } from '../../common/sortTables/comparators';

interface Props {
  users: { username: string, name: string }[]
  refresh: () => void,
}

export function ApplicationUsersTable(props: Props) {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof User>('name');
  const userRow = props.users[0] || undefined;

  type User = typeof userRow;

  const sortedRows = props.users.slice().sort(
    getComparator<User>(
      order,
      orderBy,
    ),
  );

  const handleRequestSort = (
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as keyof User);
  };
  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            refetch={props.refresh}
            headCells={headCells}
          />
          <TableBody>
            {sortedRows.map((user) => (
              <TableRow
                key={user.username}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.username}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'User',
  },
  {
    id: 'username',
    label: 'Username',
  },
];
