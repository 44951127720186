import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Badge } from '@mui/material';
import {
  AdminOperationDetailsFragment,
  ApplicationDetailsForOperationFragment,
  OperationDetailsFragment,
} from '../../../generated/graphql';
import { ApprovalStatus } from './ApprovalStatus';
import { ApplicationOperationNotesForm } from './ApplicationOperationNotesForm';

interface Props {
  application: ApplicationDetailsForOperationFragment;
  operation: OperationDetailsFragment & AdminOperationDetailsFragment;
  onComplete: () => void;
}

export function ApplicationOperationEditNotesButton(props: Props) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Badge
          badgeContent={
            (props.operation.adminNotes ? 1 : 0)
            + (props.operation.adminNotesInternal ? 1 : 0)
          }
          color="secondary"
          showZero={false}
          sx={{
            '& .MuiBadge-badge': { color: 'white' },
          }}
        >
          <ApprovalStatus
            approvedAt={props.operation.approvedAt}
            lastModified={props.operation.lastModified}
          />
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <ApplicationOperationNotesForm
          operation={props.operation}
          application={props.application}
          onComplete={() => {
            setOpen(false);
            props.onComplete();
          }}
        />
      </Dialog>
    </>
  );
}
