import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Delete } from '@mui/icons-material';
import {
  Button, Dialog, DialogActions, DialogTitle, IconButton, Tooltip,
} from '@mui/material';
import { useAdminApplicationRemoveUserMutation } from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';

interface Props {
  appId: string,
  appName: string,
  username: string,
}

export function RemoveUserFromIntegration(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [call, { error }] = useAdminApplicationRemoveUserMutation();
  const { appId, appName, username } = props;

  const submit = () => {
    if (appId && username) {
      call({
        variables: {
          appId,
          username,
        },
        refetchQueries: 'all',
      })
        .then(() => enqueueSnackbar(`
          user ${username} removed from ${appName}
      `, { variant: 'success' }));
    }
  };

  return (
    <>
      <ErrorMessage error={error} />
      <Tooltip title={'Remove user from integration'}>
        <IconButton onClick={() => setOpen(true)} edge='start'>
          <Delete color='error' />
        </IconButton>
      </Tooltip>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          Are you sure you want to remove {username} from {appName}? <br />
          This action can not be undone.
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={submit}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
