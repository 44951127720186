import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

export function DocsFooter() {
  return (
    <Box px={3} py={2}>
      <Typography
        variant="body2"
        sx={{
          opacity: 0.65,
          textAlign: 'center',
          fontStyle: 'italic',
        }}>
        Found a problem with the documentation? <Link to='/documentation/support'>Contact Mikael</Link>
      </Typography>
    </Box>
  );
}
