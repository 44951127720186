import React from 'react';
import Stack from '@mui/material/Stack';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { FormControlLabel, Slider, Switch } from '@mui/material';
import Box from '@mui/material/Box';

export interface GraphOptions {
  fontSize: number,
  displayTypename: boolean,
  dagMode: boolean,
}

interface Props {
  options: GraphOptions,
  setOptions: (value: (((prevState: GraphOptions) => GraphOptions) | GraphOptions)) => void,
}

export function GraphOptionsPanel(props: Props) {
  const { options, setOptions } = props;

  return (
    <Box sx={{
      position: 'absolute',
      top: 2,
      right: 10,
      width: 200,
      background: 'rgba(255,255,255,0.80)',
      p: 2,
    }}>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        <FormatSizeIcon/>
        <Slider
          aria-label="Font size"
          step={0.01}
          min={0}
          max={15}
          value={options.fontSize}
          onChange={(e, v) => {
            setOptions({ ...options, fontSize: v as number });
          }}/>
      </Stack>
      <FormControlLabel
        label="Type names"
        sx={{ ml: 0, justifyContent: 'space-between', width: '100%' }}
        labelPlacement="start"
        control={<Switch
          checked={options.displayTypename}
          onChange={(e, v) => {
            setOptions({ ...options, displayTypename: v });
          }}
        />}
      />
      <FormControlLabel
        label="Directional"
        sx={{ ml: 0, justifyContent: 'space-between', width: '100%' }}
        labelPlacement="start"
        control={<Switch
          checked={options.dagMode}
          onChange={(e, v) => {
            setOptions({ ...options, dagMode: v });
          }}
        />}
      />
    </Box>
  );
}
