import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { PieGraph } from './Graph/PieGraph';
import { OperationDistributionByTeamRoleResult } from '../../../generated/graphql';

interface RoleUsageProps {
  operationDistributionByRole: OperationDistributionByTeamRoleResult[]
}

export function RoleUsage(props: RoleUsageProps) {
  return (
    <Card {...props}>
      <SectionCardHeader
        avatar={<ArrowUpward color='secondary'/>}
        title='Usage by role'
      />
      <CardContent>
        <PieGraph
          data={props.operationDistributionByRole}
          dataKey='count'
          nameKey='role'
          formatName={(name: string) => (name.includes('upstream') ? 'upstream' : 'downstream')}
        />
      </CardContent>
    </Card>
  );
}
