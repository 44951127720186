import React from 'react';
import Typography from '@mui/material/Typography';

export function NotFoundPage() {
  return (
    <>
      <Typography variant="h1" align="center">404</Typography>
      <Typography variant="h2" align="center" sx={{ marginTop: '0 !important' }}>Page not found</Typography>
    </>
  );
}
