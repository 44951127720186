import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import {
  Button, Tooltip, Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Card from '@mui/material/Card';

export function AppKeyPreview(props: { appKey: string }) {
  const [copied, setCopied] = useState(false);

  const textToCopy = `AppKey ${props.appKey}`;

  useEffect(() => {
    setCopied(false);
  }, [props.appKey]);

  return (
    <Card elevation={2} sx={{ mt: 2 }}>
      <Stack spacing={1} direction="row" alignItems="center">
          <Typography
          fontWeight="normal"
          fontSize="1rem"
          fontFamily="monospace"
          component="span"
          paddingLeft={1.5}
          paddingRight={0.5}
        >Authorization: <strong>{textToCopy}</strong></Typography>
        <Tooltip title={copied ? 'Header value copied!' : 'Copy to clipboard'} arrow={true} placement="top">
          <Button
            size="small"
            variant="contained"
            color={copied ? 'success' : 'primary'}
            sx={{ minWidth: 0, p: 1 }}
            onClick={() => {
              navigator.clipboard.writeText(textToCopy);
              setCopied(true);
            }}
          >
            {copied ? <CheckIcon/> : <ContentCopyIcon/>}
          </Button>
        </Tooltip>
      </Stack>
    </Card>
  );
}
