/* eslint-disable @typescript-eslint/no-explicit-any */
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import React from 'react';

interface Props {
  content: string
}

export function ReactMarkdownWithSyntaxHighlighting({ content }: Props) {
  return (
    <ReactMarkdown
      className={'react-markdown'}
      children={content}
      skipHtml={false}
      rehypePlugins={[rehypeRaw]}
      components={{
        code({
          node, inline, className, children, ...props
        }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              language={match[1]}
              style={{
                ...materialLight,
                'pre[class*="language-"]': {
                  ...materialLight['pre[class*="language-"]'],
                  background: '#f8f9f9',
                  fontSize: '0.85rem',
                  borderRadius: 6,
                },
                'code[class*="language-"]': {
                  ...materialLight['code[class*="language-"]'],
                },
              }}
              PreTag="div"
              {...props as unknown as any}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    />
  );
}
