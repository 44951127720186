/* eslint-disable max-len */
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { NewcastCaseI00017 } from './case-I00017/NewcastCaseI00017';
import { SectionCardHeader } from '../../../../common/SectionCardHeader';
import { CaseAccordionSummary } from '../common/CaseAccordionSummary';

export function NewCastCasePage() {
  const [expanded, setExpanded] = useState<number>();
  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  return (
    <>
      <Box my={3}>
        <Card>
          <CardContent>
            <Typography variant="h1" mb={1}>Newcast</Typography>
          </CardContent>
        </Card>
      </Box>
      <Card>
        <SectionCardHeader
          title="Existing KDP integrations"
        />
      </Card>
      <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
        <CaseAccordionSummary
          title="Effective date on delivery units for factories"
          sub="KDP name I00017"
          status="Implementing"
          statusColor="warning.main"
        />
        <AccordionDetails>
          <NewcastCaseI00017/>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
