/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  LinearProgress,
  Chip,
} from '@mui/material';
import {
  ReleaseStatusEnum,
  useBrowseBomTreeQuery,
} from '../../../../../../generated/graphql';
import ErrorMessage from '../../../../../common/ErrorMessage';
import { DataTreeRow } from '../../../../../common/data/DataTree';
import { PartUsageTable } from './PartUsageTable';
import { colors } from '../../../../../../services/mui-theme';
import { ReleaseStatusChip } from '../../common/ReleaseStatusChip';
import { BomInstanceSelect } from '../common/BomInstanceSelect';

export function BomInstancesTree() {
  const [selectedTypeDesignation, setSelectedTypeDesignation] = useState<string>();

  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <BomInstanceSelect onSelect={setSelectedTypeDesignation}/>
        </CardContent>
      </Card>
      {selectedTypeDesignation && (
        <Box mt={2}>
          <Card variant="outlined">
            <CardContent>
              <BomInstanceTree typeDesignation={selectedTypeDesignation}/>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}

function BomInstanceTree(props: { typeDesignation?: string }) {
  const { data, loading, error } = useBrowseBomTreeQuery({
    variables: {
      typeDesignation: props.typeDesignation || '',
    },
    skip: !props.typeDesignation,
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <LinearProgress/>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {data.bomInstances.map((b, index) => (
        <DataTreeRow
          key={b.typeDesignation + index}
          header={`${b.typeDesignation} / ${b.typeCode}`}
          suffix={b.__typename}
          defaultExpanded={true}
        >
          {b.arts.map((art, index) => (
            <DataTreeRow
              key={index}
              header={<BomNodeHeader node={art}/> }
              suffix={art.__typename}
              defaultExpanded={true}
            >
              {art.functionGroups.map((fg, index) => (
                <DataTreeRow
                  key={index}
                  header={<BomNodeHeader node={fg}/> }
                  suffix={fg.__typename}
                >
                  {fg.fgPos.map((pos, index) => (
                    <DataTreeRow
                      key={index}
                      header={<BomNodeHeader node={pos}/> }
                      suffix={pos.__typename}
                    >
                      {pos.partAddresses.map((pa, index) => (
                        <DataTreeRow
                          key={index}
                          header={<PartAddressHeader partAddress={pa}/>}
                          suffix={pa.__typename}
                        >
                          {pa.partUsages.length > 0 && (
                            <PartUsageTable key={index} partUsages={pa.partUsages}/>
                          )}
                        </DataTreeRow>
                      ))}
                    </DataTreeRow>
                  ))}
                </DataTreeRow>
              ))}
            </DataTreeRow>
          ))}
        </DataTreeRow>
      ))}
    </>
  );
}

interface BomNodeHeaderProps {
  node: {
    name: string;
    number: string;
    deletedAt?: string | null;
  }
}

function BomNodeHeader(props: BomNodeHeaderProps) {
  const { node } = props;
  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      sx={{
        '&.deleted': {
          opacity: 0.5,
          textDecoration: 'line-through',
        },
      }}
      className={node.deletedAt ? 'deleted' : undefined}
    >
      <Box color={colors.primary} pr={1}>{node.number}</Box>
      <Box>{node.name}</Box>
    </Box>
  );
}

interface PartAddressHeaderProps {
  partAddress: {
    name: string;
    number: string;
    releaseStatus: ReleaseStatusEnum;
    partUsagesAggregate?: { count: number } | null;
    isPlantPartAddress: boolean;
    isPsPartAddress: boolean;
  }
}

function PartAddressHeader(props: PartAddressHeaderProps) {
  const { partAddress } = props;
  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box color={colors.primary} pr={1}>{partAddress.number}</Box>
      <Box pr={1}>{partAddress.name}</Box>
      <PaSubTypeChip
        isPlantPartAddress={partAddress.isPlantPartAddress}
        isPsPartAddress={partAddress.isPsPartAddress}
      />
      <Box sx={{ flexGrow: 1 }}/>
      <Box px={1}>
        Part usages: {partAddress.partUsagesAggregate?.count || 0}
      </Box>
      <ReleaseStatusChip releaseStatus={partAddress.releaseStatus}/>
    </Box>
  );
}

export function PaSubTypeChip(props : {
  isPlantPartAddress: boolean
  isPsPartAddress: boolean,
}) {
  if (props.isPlantPartAddress) {
    return (
      <Chip
        label="Plant PA"
        variant="outlined"
        color="primary"
        size="small"
      />
    );
  }

  if (props.isPsPartAddress) {
    return (
      <Chip
        label="Pre-series PA"
        variant="outlined"
        color="secondary"
        size="small"
      />
    );
  }

  return null;
}
