import React from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { MfgCoiPartUsageInfoFragment } from '../../../../../../generated/graphql';
import { TableCellWithValue } from '../../parts/PartDialog/valueText';
import { EffectivityList } from '../../common/mfg/EffectivityList';

interface Props {
  rows: MfgCoiPartUsageInfoFragment[]
}

export function MfgCoiPartUsageTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>type</TableCell>
            <TableCell>partNumber / productId</TableCell>
            <TableCell>effectivitites</TableCell>
            <TableCell>isSerialized</TableCell>
            <TableCell>isReusable</TableCell>
            <TableCell>propability</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow><TableCell colSpan={99} align="left">None</TableCell></TableRow>
          )}
          {rows?.map((value, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, whiteSpace: 'nowrap' }}
            >
              <TableCell>
                {value.part && (
                  value.part.__typename
                )}
                {value.assembly && (
                  value.assembly.__typename
                )}
                {value.mfgPart && (
                  value.mfgPart.__typename
                )}
              </TableCell>
              <TableCell>
                {value.part?.partNumber || value.assembly?.productId || value.mfgPart?.productId}
              </TableCell>
              <TableCell>
                <EffectivityList effectivities={value.effectivities}/>
              </TableCell>
              <TableCellWithValue value={value.isSerialized} />
              <TableCellWithValue value={value.isReusable} />
              <TableCellWithValue value={value.probability} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
