import React from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { webhooks } from '../../../../control-panel/Webhooks/webhook.consts';
import { Mermaid } from '../../../../common/Mermaid';
import { eventSequenceDiagramMq, eventSequenceDiagramWebhooks } from './event-sequence-diagram';

export function EventsPage() {
  return (
    <>
      <h1>Events</h1>
      <TableContainer component={Paper} sx={{ maxWidth: 960 }}>
        <Table>
          <TableBody>
            {webhooks.map((e, i) => (
              <TableRow key={i}>
                <TableCell sx={{ fontWeight: '500' }}>{e.name}</TableCell>
                <TableCell>{e.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h2" mb={1} mt={4}>Webhooks</Typography>
      <Typography paragraph>
        PRINS GraphHub will make an HTTP POST request to the given URL
        when the selected event is triggered.
        The request will originate from within the Volvo private network on azure.
      </Typography>
      <Typography paragraph>
        You can register your URL on you Application under “My Applications”
      </Typography>
      <Mermaid text={eventSequenceDiagramWebhooks} />

      <Typography variant="h2" mb={1} mt={4}>MQ</Typography>
      <Box mb={2}>
        <Alert severity="warning">Production and QA is still work in progress</Alert>
      </Box>
      <Typography paragraph>
        All events are sent to the same queue.
        All messages have a parameter for eventType with one of the values listed above.
        Some events also contain extra parameters. Contact the PRINS team for more info.
        The message body is in JSON format and contain an object with all the parameters as well.
      </Typography>
      <Typography paragraph>
        Volvo Cars Integration Portal: <a href="https://vip.volvocars.net/docs/int/3807">https://vip.volvocars.net/docs/int/3807</a>
      </Typography>
      <Mermaid text={eventSequenceDiagramMq} />
    </>
  );
}
