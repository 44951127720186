/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  JsonView, allExpanded, darkStyles,
} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

export function GraphQLResultArea(props: { data: any }) {
  return (
    <JsonView data={props.data} shouldExpandNode={allExpanded} style={darkStyles} />
  );
}
