import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

interface Props {
  title: string
  sub: string
  status: string
  statusColor: string
}

export function CaseAccordionSummary(props: Props) {
  const {
    title, sub, status, statusColor,
  } = props;

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
      <Box sx={{ width: '40%', mr: 1 }}>
        <Typography>{title}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{sub}</Typography>
      </Box>
      <Box>
        <Typography sx={{ color: statusColor }}>{status}</Typography>
      </Box>
    </AccordionSummary>
  );
}
