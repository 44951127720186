import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

export function CodeWrapper(props: PropsWithChildren<object>) {
  return (
    <Box component="span" sx={{
      overflowX: 'auto',
      p: 1,
      my: 1,
      background: '#00000006',
      fontFamily: 'Monospace',
      display: 'block',
      whiteSpace: 'pre',
      fontSize: 13,
    }}>{props.children}</Box>
  );
}
