import React from 'react';
import { ProductFamiliesTable } from '../../documentation/pages/browser/product-families/ProductFamiliesTable';

export function ProductFamiliesBrowserPage() {
  return (
    <>
      <ProductFamiliesTable/>
    </>
  );
}
