import React from 'react';
import Box from '@mui/material/Box';
import { yearWeekFormat } from '../../../util/date';
import { FeatureBreakdown } from '../../tools/feature-breakdown/FeatureBreakdown';

export function FeatureBreakdownPage() {
  return (
    <Box mt={2}>
      <FeatureBreakdown
        defaultValues={{
          structureWeek: yearWeekFormat(),
          typeCode: '',
          features: [],
        }}
      />
    </Box>
  );
}
