import React from 'react';
import {
  BopRoutingTable,
} from '../../documentation/pages/browser/bop-routings/BopRoutingTable';

export function BopRoutingBrowserPage() {
  return (
    <>
      <BopRoutingTable/>
    </>
  );
}
