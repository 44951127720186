import React from 'react';
import { SparePartInfoTable } from '../../documentation/pages/browser/spare-part-info/SparePartInfoTable';

export function SparePartInfoBrowserPage() {
  return (
    <>
      <SparePartInfoTable/>
    </>
  );
}
