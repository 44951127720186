import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { fetchBackendText } from '../../../services/restClient';
import { defaultInput } from './defaultInput';

export function FeatureBreakdownBatch() {
  // State for the result
  const [result, setResult] = useState<string | null>(null);

  const [input, setInput] = useState<string>(defaultInput.trim());

  // is loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Error
  const [error, setError] = useState<string | null>(null);

  const [fileSize, setFileSize] = useState<number | null>(null);

  const onClick = async () => {
    setIsLoading(true);
    fetchBackendText('variant-breakdown', 'POST', input, (bytes) => {
      setFileSize(bytes);
    })
      .then((response) => {
        setError(null);
        setResult(response);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setIsLoading(false));

    // Save result into a state
  };

  return (
    <Box>
      <Paper>
        <Box p={2}>
          <TextField
            fullWidth
            multiline
            rows={30}
            margin="normal"
            label="Orders"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': { fontSize: '9px !important' },
            }}
            defaultValue={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
        </Box>
      </Paper>
      <LoadingButton
        onClick={onClick}
        loading={isLoading}
        variant="contained"
        color="primary"
      > Send breakdown </LoadingButton>
      {fileSize && (
        <Alert severity="info">Response size: {Math.round(fileSize / 1000)} kB</Alert>
      )}
      <Box mt={2}>
        <Paper>
          {error && (
            <Alert severity="error">{error}</Alert>
          )}
          {result && (
            <Box sx={{
              overflow: 'auto',
            }}>
              <Box p={2} sx={{
                fontSize: '9px',
              }}>{result}</Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
