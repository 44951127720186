import React, { ReactElement } from 'react';
import { Badge } from '@mui/material';

interface Props {
  content: number,
  icon: ReactElement,
}

export function IntegrationBadge(props: Props) {
  return (
    <Badge
      badgeContent={props.content}
      color="secondary"
      showZero={false}
      sx={{
        '& .MuiBadge-badge': { color: 'white' },
      }}
    >{props.icon}</Badge>
  );
}
