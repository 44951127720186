import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React from 'react';

export function PrettyQuery(props: { query: string }) {
  return (
    <SyntaxHighlighter
      children={preProcessQuery(props.query)}
      language="graphql"
      style={materialLight}
      PreTag="div"
    />
  );
}

function preProcessQuery(query: string) {
  return query
    .trim() // Remove spaces and newlines before and after
    .replaceAll('    fragment ', 'fragment '); // Remove indentation on fragment
}
