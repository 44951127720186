import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';
import { useAdminListWebhooksQuery } from '../../../generated/graphql';
import { RegisteredWebhooksTable } from '../../control-panel/Webhooks/RegisteredWebhooksTable';

export function AdminWebhooks() {
  const {
    data, loading, refetch,
  } = useAdminListWebhooksQuery({});

  const rows = data?.admin.webhooks;

  if (loading) {
    return <Paper variant="outlined"><CircularProgress/></Paper>;
  }

  if (!rows?.length) {
    return (
      <Alert severity="warning">
        There are no registered webhooks
      </Alert>
    );
  }

  return (
    <Paper variant="outlined">
      <RegisteredWebhooksTable webhooks={rows} refresh={refetch} />
    </Paper>
  );
}
