import React, { useState } from 'react';
import {
  IconButton, Dialog, DialogTitle, DialogActions, Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUserApplicationSecretDeleteMutation } from '../../../generated/graphql';

export function DeleteSecretDialog(props: { id: string }) {
  const { id } = props;
  const [deleteSecret] = useUserApplicationSecretDeleteMutation();
  const [open, setOpen] = useState<boolean>(false);
  const handleYes = () => {
    deleteSecret({
      variables: {
        applicationSecretId: id,
      },
      refetchQueries: ['MyApplications', 'Applications'],
    }).then();

    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="error">
        <DeleteIcon />
      </IconButton>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          Are you sure you want to delete this secret key? <br />
          This action can not be undone.
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
