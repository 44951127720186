import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import { BreakdownResultRow } from './breakdown-performance-types';

export function StoredCpvSetPreview(props: { rows: BreakdownResultRow[] }) {
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        Total: {props.rows.length}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ overflowX: 'scroll', whiteSpace: 'nowrap', fontSize: '7px' }}>
          {props.rows.map((row, i) => (
            <div key={i}>
              <b>Part usages: {row.partUsageIds.length}</b>&nbsp;
              tcIds: {row.partUsageIds.join(', ')}
            </div>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
