/* eslint-disable max-len */
import React from 'react';
import { Alert } from '@mui/material';
import { useSelectedEnvironment } from '../../hooks/useSelectedEnvironment';

export function LocalEnvAlert() {
  const [selectedEnv] = useSelectedEnvironment();
  const isLocal = selectedEnv.key === 'local';

  if (!isLocal) {
    return null;
  }

  return (
    <Alert variant="filled" severity="error">
      Local Environment.
    </Alert>
  );
}
