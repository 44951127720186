import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

interface IFormInput {
  offset: number;
  limit: number;
  plantCode: number;
  effectiveWeek: number;
}

interface Props {
  onSubmit : (input: IFormInput) => void
  loading: boolean
}

export function StoredCpvSetFetcherForm(props: Props) {
  const { register, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      offset: 0,
      limit: 100,
      plantCode: 22,
      effectiveWeek: 202547,
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    props.onSubmit(formData);

    return null;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fullWidth
            label="Offset"
            {...register('offset', {
              valueAsNumber: true,
            })}
            type="number"

            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fullWidth
            label="Limit"
            {...register('limit', {
              valueAsNumber: true,
            })}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fullWidth
            label="Plant code"
            {...register('plantCode', {
              valueAsNumber: true,
            })}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fullWidth
            label="EffectiveWeek"
            {...register('effectiveWeek', {
              valueAsNumber: true,
            })}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography align="right" component="div">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={props.loading}
            >Load StoredCpvSets</LoadingButton>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
