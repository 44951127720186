import React from 'react';
import { CardHeader, CardHeaderProps } from '@mui/material';

export function SectionCardHeader(props: CardHeaderProps) {
  return (
    <CardHeader
      titleTypographyProps={{ fontSize: 16 }}
      {...props}
    />
  );
}
