import React from 'react';
import Card from '@mui/material/Card';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApplicationCreateForm } from './NewIntegrationForms/ApplicationCreateForm';
import { ApplicationConnectionRequestForm } from './NewIntegrationForms/ApplicationConnectionRequestForm';
import { SectionCardHeader } from '../common/SectionCardHeader';

export function IntegrationTabs() {
  return (
    <>
      <Card>
        <SectionCardHeader
          avatar={<BookmarkAddIcon color="secondary"/>}
          title="Register Application"
        />
      </Card>
      <Accordion>
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          Register your Application
        </AccordionSummary>
        <AccordionDetails>
          <ApplicationCreateForm/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          Add me to an already registered Application
        </AccordionSummary>
        <AccordionDetails>
          <ApplicationConnectionRequestForm/>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
