import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import React from 'react';
import { PartDialogQuery } from '../../../../../../generated/graphql';
import { PartVersionsTable } from './PartVersionsTable';
import { FieldList } from './FieldList';
import { ConstituentTree } from './ConstituentTree';
import { UsedInAssembliesTable } from './UsedInAssembliesTable';
import { LabelDefinitionTree } from './LabelDefinitionTree';

type PartDialogPart = PartDialogQuery['parts'][0];

interface Props {
  part?: PartDialogPart;
}

export function PartDialogInfoPanel(props: Props) {
  const { part } = props;

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>
            Part Versions
          </Typography>
          <PartVersionsTable partVersions={part?.partVersions || []} />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper variant="outlined">
          <FieldList part={part} />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>
            Assembled by
          </Typography>
          {part?.assembledBy.length === 0 ? (
            <Alert severity="info">This part has no ingoing parts</Alert>
          ) : (
            <ConstituentTree part={part} isTop={true} />
          )}
        </Paper>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>
            Used in assemblies
          </Typography>
          {part?.usedInAssemblies.length === 0 ? (
            <Alert severity="info">
              This part is not used in any other parts
            </Alert>
          ) : (
            <UsedInAssembliesTable part={part} />
          )}
        </Paper>
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="h4" p={2}>
            Label Definition
          </Typography>
          {part?.labelDefinition === null ? (
            <Alert severity="info">
              This part has no label definition
            </Alert>
          ) : (
            <LabelDefinitionTree config={part?.labelDefinition} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
