/* eslint-disable max-len */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { UsageRuleDialog_PartUsageFragment } from '../../../../../../generated/graphql';
import { PartAddressInfoBox } from '../../bom/common/PartAddressInfoBox';
import { FieldInteger } from '../../common/FieldValues';
import { FieldCoi } from '../../common/FieldCoi';
import { LargePartDialogButton } from '../../common/dialog-buttons/PartDialogButton';

interface Props {
  partUsages?: UsageRuleDialog_PartUsageFragment[]
}

export function PartUsagesTable(props: Props) {
  const { partUsages } = props;

  if (!partUsages) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">POST</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>Part / LMOD</TableCell>
            <TableCell>Bom Line</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partUsages.map((pu, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}
            >
              <TableCell>
                <FieldInteger alignRight value={pu.POST}/>
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={pu.coiNumberAdd}
                  coiNumberTooltip="partUsage.coiNumberAdd"
                  week={pu.effectiveInWeek}
                  weekTooltip="partUsage.effectiveInWeek"
                />
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={pu.coiNumberDel}
                  coiNumberTooltip="partUsage.coiNumberDel"
                  week={pu.effectiveOutWeek}
                  weekTooltip="partUsage.effectiveOutWeek"
                />
              </TableCell>
              <TableCell>
                <LargePartDialogButton part={pu.hardwareOrSoftwarePart || pu.deliveryModule} />
              </TableCell>
              <TableCell>
                <PartAddressInfoBox partAddress={pu.partAddress}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
