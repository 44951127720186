// Edit here: https://mermaid.live/edit

export const examplePartStructureGraph = `
flowchart LR

PA1[PartAddress]:::PA
PU1(PartUsage 1):::PU
PU2(PartUsage 2):::PU

PC1(PartConstituent 1):::PC
PC2(PartConstituent 2):::PC
PC3(PartConstituent 3):::PC
PC4(PartConstituent 4):::PC

P1[Part 1]:::P
P2[Part 2]:::P
P3[Part 3]:::P
P4[Part 4]:::P
P5[Part 5]:::P

PA1 --- PU1 --- P1
PA1 --- PU2 --- P2

P1 -- .assembledBy --> PC1 -- .part --> P3
P1 -- .assembledBy --> PC2 -- .part --> P4
P2 -- .assembledBy --> PC3 -- .part --> P4
P2 -- .assembledBy --> PC4 -- .part --> P5

P3 -. ".usedInAssemblies" .-> PC1 -. ".assembly" .-> P1

classDef PA stroke:#202A44,fill:#FFF
classDef PU stroke:#A85F02,fill:#FFF
classDef PC stroke:#BF834B,fill:#FFF
classDef P stroke:#1B365D,fill:#FFF
`;
