import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import {
  ApplicationDetailsFragment,
  useUserApplicationSecretCreateMutation,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { EnvironmentSelect } from '../../common/forms/EnvironmentSelect';
import { EnvironmentOption } from '../../../util/envrionments';

interface Props {
  application: ApplicationDetailsFragment;
  onCreate: (key: string) => void;
}

interface IFormInput {
  description: string;
  environment: EnvironmentOption;
}

export function CreateSecretDialog(props: Props) {
  const { application } = props;
  const [call, { loading, error }] = useUserApplicationSecretCreateMutation();
  const [open, setOpen] = React.useState(false);

  const { register, handleSubmit, control } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    const result = await call({
      variables: {
        input: {
          description: formData.description,
          appId: application.appId,
          environment: formData.environment.environment,
        },
      },
      refetchQueries: 'all',
    });

    props.onCreate(result.data?.user.applicationSecretCreate || '');
    setOpen(false);

    return null;
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startIcon={<AddIcon/>}
      >
        Create a new App Key
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'visible', // or else the select menu gets hidden
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>New API key for {application.appName}</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Create a new secret api key to be used to access PRINS. <br />
              You will only see this secret once so make sure you save it.
            </DialogContentText>
            <ErrorMessage error={error}/>
            <TextField
              autoFocus
              margin="normal"
              label="Key name"
              fullWidth
              sx={{ mb: 2 }}
              {...register('description', { required: true, maxLength: 200 })}
            />
            <Controller
              render={({ field: { ref, ...rest }, fieldState }) => (
                <EnvironmentSelect
                  {...rest}
                  state={fieldState}

                  value={rest.value || null} // Forced controlled input
                />
              )}
              name={'environment'}
              rules={{
                required: true,
              }}
              control={control}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>Create</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
