import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Alert from '@mui/material/Alert';
import { dateFormat } from '../../../util/date';
import { MyApplicationsQuery, SecretDetailsFragment } from '../../../generated/graphql';
import { UserNotFound } from '../../common/UserNotFound';
import { envLabel } from '../../../util/environment-label';
import { DeleteSecretDialog } from './DeleteSecretDialog';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';
import { getComparator } from '../../common/sortTables/comparators';

interface Props {
  secrets: MyApplicationsQuery['user']['myApplications'][0]['secrets'],
  refresh: () => void,
}

export function IntegrationSecretsTable(props: Props) {
  const { secrets, refresh } = props;
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof SecretDetailsFragment>('description');

  if (secrets.length === 0) {
    return (
      <Alert severity="warning">
        You have not created any app secrets yet
      </Alert>
    );
  }

  const sortedRows = secrets.slice().sort(
    getComparator<SecretDetailsFragment>(
      order,
      orderBy,
    ),
  );

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(property, direction) => {
              setOrder(direction);
              setOrderBy(property);
            }}
            refetch={refresh}
            headCells={headCells}
          />
          <TableBody>
            {sortedRows.map((row) => (
              <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{envLabel(row.environment)}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.secretId}</TableCell>
                <TableCell>
                  {row.createdByUser?.name || <UserNotFound/>} <br/>
                  {dateFormat(row.createdAt)}
                </TableCell>
                <TableCell padding="none" align="right">
                  <DeleteSecretDialog id={row.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'environment',
    label: 'Environment',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'secretId',
    label: 'Key',
  },
  {
    id: 'createdAt',
    label: 'Created by',
  },
];
