export const middlewareStructureDiagram = `

flowchart TD

PRINS[PRINS GraphHub]

M1(Middleware 1)
M2(Middleware 2)
M3(Middleware 3)

A1(App 1)
A2(App 2)
A3(App 3)

PRINS -- HTTPS (GraphQL) --- M1 -- HTTPS (REST) --- A1
PRINS -- HTTPS (GraphQL) --- M2 -- MQ Topic --- A2
PRINS -- HTTPS (GraphQL) --- M3 -- CSV in a blob storage --- A3

`;
