import React, { useState } from 'react';
import Card from '@mui/material/Card';
import {
  Alert,
  Button, ButtonGroup, Dialog, DialogActions, DialogTitle,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ErrorMessage from '../../common/ErrorMessage';
import {
  AdminPendingApplicationsQuery,
  ValidAppEnvironment,
  ValidAppRole,
  useAdminApplicationApproveMutation, useAdminApplicationDeleteMutation,
  useAdminApplicationSetRoleMutation,
  useAdminPendingApplicationsQuery,
} from '../../../generated/graphql';
import { CardRefreshAction } from '../../common/CardRefreshAction';
import { SimpleMessageDialog } from '../../common/SimpleMessageDialog';
import { dateTimeFormat } from '../../../util/date';
import { LoadingCircularCentered } from '../../common/LoadingCircularCentered';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { config } from '../../../config/config';
import { ToggleRole } from '../../control-panel/IntegrationInfo/ToggleRole';

type PendingApplication = AdminPendingApplicationsQuery['admin']['pendingApplications'][0];

export function PendingApplicationCreationRequests() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data, loading, error, refetch,
  } = useAdminPendingApplicationsQuery();

  const [approveApp] = useAdminApplicationApproveMutation();
  const [deleteApp] = useAdminApplicationDeleteMutation();
  const [update] = useAdminApplicationSetRoleMutation();

  const rows = data?.admin.pendingApplications;

  async function approve(app: PendingApplication) {
    await approveApp({
      variables: {
        appId: app.appId,
      },
      refetchQueries: ['AdminPendingApplications', 'AdminApprovedApplications'],
    });

    // If no role has been set for a non-prod env, set it to downstream.
    const nonProdEnvs = [
      ValidAppEnvironment.Qa,
      ValidAppEnvironment.Nr,
      ValidAppEnvironment.Test,
      ValidAppEnvironment.Development,
      ValidAppEnvironment.Local,
    ];

    const envsWithNoRoles = nonProdEnvs.filter((env) => {
      return !app.roles.some((role) => role.environment === env);
    });

    envsWithNoRoles.forEach((env) => {
      update({
        variables: {
          appId: app.appId,
          role: ValidAppRole.Downstream,
          environment: env,
        },
      });
    });

    enqueueSnackbar(` Approved ${app.appName}`, { variant: 'success' });
  }

  function deny(app: PendingApplication) {
    deleteApp({
      variables: {
        appId: app.appId,
      },
      refetchQueries: ['AdminPendingApplications'],
    })
      .then(() => enqueueSnackbar(`
         ${app.appName} Deleted
      `, { variant: 'error' }));
  }

  return (
    <Card>
      <SectionCardHeader
        avatar={<AdminPanelSettingsIcon color='warning'/>}
        title="Pending new integrations"
        action={<CardRefreshAction onClick={refetch}/>}
      />
      <ErrorMessage error={error}/>
      <LoadingCircularCentered visible={loading} />
      {rows && rows.length === 0 && <Alert severity="info">There are no pending requests</Alert>}
      {rows && rows.length > 0 && (
        <PendingApplicationsTable rows={rows} approve={approve} deny={deny} onChange={refetch}/>
      )}
    </Card>
  );
}

interface TableProps {
  rows: PendingApplication[];
  approve: (row: PendingApplication) => void;
  deny: (row: PendingApplication) => void;
  onChange: () => void;
}

function PendingApplicationsTable(props: TableProps) {
  const {
    rows, approve, deny, onChange,
  } = props;

  const [openDenyDialog, setOpenDenyDialog] = useState<boolean>(false);
  const [deniedApplication, setDeniedApplication] = useState<PendingApplication | null>(null);

  const handleDenyClick = (app: PendingApplication) => {
    setDeniedApplication(app);
    setOpenDenyDialog(true);
  };

  const handleDenyConfirmation = () => {
    if (deniedApplication) {
      deny(deniedApplication);
      setDeniedApplication(null);
    }
    setOpenDenyDialog(false);
  };

  return (
    <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Integration</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Date</TableCell>
              <TableCell padding="none"/>
              {config.environments.map((env, i) => (
                <TableCell key={i} padding="none" align="center">
                  {env.label}
                </TableCell>
              ))}
              <TableCell padding="none"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th">
                  {row.teamName} <br/>
                  <em>{row.appName}</em> ({row.appId})
                </TableCell>
                <TableCell>
                  {row.createdBy?.name} <br/>
                  <em>{row.createdBy?.username}</em>
                </TableCell>
                <TableCell>{dateTimeFormat(row.createdAt as string)}</TableCell>
                <TableCell padding='none'>
                  {row.notes && (
                    <SimpleMessageDialog message={row.notes}/>
                  )}
                </TableCell>
                {config.environments.map((env, i) => (
                  <TableCell key={i} padding="none" align="center">
                    <ToggleRole application={row} env={env} onComplete={onChange}/>
                  </TableCell>
                ))}
                <TableCell padding='none' align='right'>
                  <ButtonGroup variant="text" aria-label="text button group">
                    <Button color='success' onClick={() => approve(row)}>Approve</Button>
                    <Button color='error' onClick={() => handleDenyClick(row)}>Deny</Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog onClose={() => setOpenDenyDialog(false)} open={openDenyDialog}>
        <DialogTitle>
          Are you sure you want to deny access to {deniedApplication?.appName}? <br />
          This action cannot be undone.
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenDenyDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleDenyConfirmation}>Yes</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
