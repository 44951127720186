import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {
  Button, LinearProgress, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { ApolloError } from '@apollo/client';
import { useAuth } from '../../hooks/useAuth';
import { SimpleCardLayout } from '../layout/SimpleCardLayout';
import { Section } from '../layout/Section';
import { TeamUsage } from '../control-panel/Metrics/TeamUsage';
import { QueryUsage } from '../control-panel/Metrics/QueryUsage';
import { TopQuery } from '../control-panel/Metrics/TopQuery';
import UsageMetric from '../control-panel/Metrics/UsageMetrics';
import { RoleUsage } from '../control-panel/Metrics/RoleUsage';
import {
  MetricsQuery, UsageTimeseriesQuery, useMetricsQuery, useUsageTimeseriesQuery,
} from '../../generated/graphql';
import ErrorMessage from '../common/ErrorMessage';

export function Metrics() {
  const { data: authData } = useAuth();
  const [period, setPeriod] = useState<{ l: string, v:string }>({ l: '5 days', v: 'P5D' });
  const metricsProps: {
    data?: MetricsQuery, loading: boolean, error?: ApolloError,
  } = useMetricsQuery({
    variables: { period: period.v },
  });
  const uProps: {
    data?: UsageTimeseriesQuery,
    previousData?: UsageTimeseriesQuery,
    loading: boolean,
    error?: ApolloError,
  } = useUsageTimeseriesQuery({
    variables: { period: period.v },
  });
  const periodOptions = [
    { l: '5 days', v: 'P5D' },
    { l: '72 hours', v: 'PT72H' },
    { l: '48 hours', v: 'PT48H' },
    { l: '24 hours', v: 'PT24H' },
  ];
  const handleChange = async (e: SelectChangeEvent) => {
    const selected = periodOptions
      .find((option) => option.v === e.target.value);
    if (!selected) {
      return;
    }
    setPeriod(selected);
  };

  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  if (metricsProps.error) {
    return <ErrorMessage error={metricsProps.error}></ErrorMessage>;
  }

  if (metricsProps.loading || !metricsProps.data) {
    return <LinearProgress></LinearProgress>;
  }

  return (
    <Section>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={handleChange}
        value={period.v}
        sx={{
          color: 'white',
        }}
      >
        {periodOptions.map(({ l, v }) => (
          <MenuItem key={v} value={v}>{l}</MenuItem>
        ))}
      </Select>
      <Section>
        <UsageMetric
          period={period}
          teamOperationDistribution={metricsProps.data.kpiMetric.operationTypeDistributionByTeam}
          operationStatusDistribution={metricsProps.data.kpiMetric.operationStatusDistribution}
        />
      </Section>
      <Section>
        <TeamUsage
        period={period.v}
        useUsageProps={uProps}
        />
      </Section>
      <Section>
        <QueryUsage
          operationDistribution={metricsProps.data.kpiMetric.operationTypeDistributionByTeam}
        />
      </Section>
      <Section>
        <RoleUsage
          operationDistributionByRole={metricsProps.data.kpiMetric.operationDistributionByTeamRole}
        />
      </Section>
      <Section>
        <TopQuery
          recentTeams={metricsProps.data.kpiMetric.operationTypeDistributionByTeam}
        />
      </Section>
    </Section>
  );
}
