import React from 'react';
import Box from '@mui/material/Box';
import { ReactMarkdownWithSyntaxHighlighting } from '../../../common/ReactMarkdownWithSyntaxHighlighting';

const Md = ReactMarkdownWithSyntaxHighlighting;

export function PreliminaryDataPage() {
  return (
    <>
      <h1>Preliminary Data</h1>

      <Box mb={5}>

        <Md content={`

## Todo

*Last modified: 2024-01-15*

        `} />

      </Box>
    </>
  );
}
