import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { ConsumptionRecordDialogQuery } from '../../../../../../generated/graphql';

type ConsumptionRecord = ConsumptionRecordDialogQuery['consumptionRecords'][0];

interface Props {
  consumptionRecord?: ConsumptionRecord | null
}

export function MBomInfoTable(props: Props) {
  const { consumptionRecord } = props;

  if (!consumptionRecord) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type Code</TableCell>
            <TableCell>Type Designation</TableCell>
            <TableCell>Plant</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}>
            <TableCell>
              {consumptionRecord.mbom?.ebom?.productContext?.typeCode}
            </TableCell>
            <TableCell>
              {consumptionRecord.mbom?.typeDesignation}
            </TableCell>
            <TableCell>
              {consumptionRecord.mbom?.plant?.plantCode}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
