import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PartVersionInfoFragment } from '../../../../../../generated/graphql';
import {
  FieldBoolean, FieldDatetime, FieldEnum, FieldInteger, FieldString,
} from '../../common/FieldValues';

interface Props {
  partVersions: PartVersionInfoFragment[]
}

export function PartVersionsTable(props: Props) {
  const { partVersions } = props;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>versionId</TableCell>
            <TableCell>partValidity</TableCell>
            <TableCell>preSeriesNumber</TableCell>
            <TableCell>sharedTechFlag</TableCell>
            <TableCell>status</TableCell>
            <TableCell>createdAt</TableCell>
            <TableCell>lastModified</TableCell>
            <TableCell>releaseDate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partVersions.map((version, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><FieldString value={version.versionId} /></TableCell>
              <TableCell><FieldString value={version.partValidity} /></TableCell>
              <TableCell><FieldInteger value={version.preSeriesNumber} /></TableCell>
              <TableCell><FieldBoolean value={version.sharedTechFlag} /></TableCell>
              <TableCell><FieldEnum value={version.status} /></TableCell>
              <TableCell><FieldDatetime value={version.createdAt} /></TableCell>
              <TableCell><FieldDatetime value={version.lastModified} /></TableCell>
              <TableCell><FieldDatetime value={version.releaseDate} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
