/* eslint-disable max-len */
import Typography from '@mui/material/Typography';
import React from 'react';
import { GraphQLCodeFile } from '../../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import Gql1 from './case-query-parts.graphql';

export function SIPlusCaseI00570() {
  return (
    <>
      <Typography variant="h3" mb={3}>Description of the existing KDP integration</Typography>
      <Typography paragraph>This file describes parts in KDP (see BL2).</Typography>
      <Typography paragraph>In KDP we use the consumptions to find out which parts that is used.</Typography>
      <Typography paragraph>This file also includes spare parts (POS 800-series) and accessories (POS 900-series).</Typography>

      <Typography variant="h3" mt={4} mb={3}>PRINS GraphHub GraphQL query</Typography>

      <GraphQLCodeFile
        file={Gql1}
        variables={{
          pType: '41',
          offset: 0,
          limit: 100,
        }}
      />

    </>
  );
}
