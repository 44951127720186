import React from 'react';
import { LmodTable } from '../../documentation/pages/browser/lmod/LmodTable';

export function LmodBrowserPage() {
  return (
    <>
      <LmodTable/>
    </>
  );
}
