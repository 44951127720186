/* eslint-disable max-len */
import React from 'react';
import { SoftwarePartDialogQuery } from '../../../../../../generated/graphql';
import { DenseFieldList, DenseFieldListRow } from '../../common/DenseFieldList';
import { FieldDatetime, FieldString } from '../../common/FieldValues';

type SoftwarePart = SoftwarePartDialogQuery['softwareParts'][0];

interface Props {
  softwarePart?: SoftwarePart
}

export function FieldList({ softwarePart }: Props) {
  return (
    <DenseFieldList>
      <DenseFieldListRow name="partNumber">
        <FieldString value={softwarePart?.partNumber}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="name">
        <FieldString value={softwarePart?.name}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="assignmentNumber">
        <FieldString value={softwarePart?.assignmentNumber}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="project">
        <FieldString value={softwarePart?.project}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="partType">
        <FieldString value={softwarePart?.partType}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="partTypeDescription">
        <FieldString value={softwarePart?.partTypeDescription}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="kuResponsible">
        <FieldString value={softwarePart?.kuResponsible}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="taskNumber">
        <FieldString value={softwarePart?.taskNumber}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="taskStatus">
        <FieldString value={softwarePart?.taskStatus}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="costCarrier">
        <FieldString value={softwarePart?.costCarrier}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="designOwner">
        <FieldString value={softwarePart?.designOwner}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="description">
        <FieldString value={softwarePart?.description}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="masteredBy">
        <FieldString value={softwarePart?.masteredBy}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="createdAt">
        <FieldDatetime value={softwarePart?.createdAt}/>
      </DenseFieldListRow>
      <DenseFieldListRow name="lastModified">
        <FieldDatetime value={softwarePart?.lastModified}/>
      </DenseFieldListRow>
    </DenseFieldList>
  );
}
