import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import { UsageRuleWhere } from '../../../../../generated/graphql';
import { createDialog } from '../../../../../hooks/useDialogs';
import { UsageRuleDialog } from './UsageRuleDialog';

interface Props {
  where: UsageRuleWhere,
  size?: 'small' | 'medium' | 'large',
}

export function UsageRuleDialogButton(props: Props) {
  return (
    <IconButton
      size={props.size || 'medium'}
      color="primary"

      onClick={() => {
        createDialog((close) => <UsageRuleDialog close={close} where={props.where}/>);
      }}
    >
      <OpenInNewIcon />
    </IconButton>
  );
}
