import React from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { MfgCoiBopPartUsageInfoFragment } from '../../../../../../generated/graphql';

interface Props {
  rows: MfgCoiBopPartUsageInfoFragment[]
}

export function MfgCoiBopPartUsageTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* <TableCell>effectiveInWeek</TableCell> */}
            {/* <TableCell>effectiveOutWeek</TableCell> */}
            <TableCell>quantity</TableCell>
            <TableCell>psa</TableCell>
            <TableCell>variantFormula</TableCell>
            <TableCell>partPosition</TableCell>
            <TableCell>partMagazine</TableCell>
            <TableCell>partDelayTime</TableCell>
            <TableCell>occurrenceType</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow><TableCell colSpan={99} align="left">None</TableCell></TableRow>
          )}
          {rows?.map((value, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, whiteSpace: 'nowrap' }}
            >
              {/* <TableCell>{value.effectiveInWeek}</TableCell> */}
              {/* <TableCell>{value.effectiveOutWeek}</TableCell> */}
              <TableCell>{value.quantity}</TableCell>
              <TableCell>{value.psa}</TableCell>
              <TableCell>{value.variantFormula}</TableCell>
              <TableCell>{value.partPosition}</TableCell>
              <TableCell>{value.partMagazine}</TableCell>
              <TableCell>{value.partDelayTime}</TableCell>
              <TableCell>{value.occurrenceType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
