import List from '@mui/material/List';
import React from 'react';
import { UsageRuleDialogQuery } from '../../../../../../generated/graphql';
import { FieldListValue } from '../../common/FieldListValue';

type UsageRule = UsageRuleDialogQuery['usageRules'][0];

interface Props {
  usageRule?: UsageRule
}

export function FieldList({ usageRule }: Props) {
  return (
    <List disablePadding>
      <FieldListValue name="pmlID" value={usageRule?.pmlId}/>
      <FieldListValue name="typeCode" value={usageRule?.typeCode}/>
      <FieldListValue name="subType" value={usageRule?.subType}/>
      <FieldListValue name="Var Codes" value={usageRule?.varCodes}/>
      <FieldListValue name="Alt Var Codes" value={usageRule?.altVarCodes}/>
      <FieldListValue name="lastModified" value={usageRule?.lastModified}/>
      <FieldListValue name="createdAt" value={usageRule?.createdAt}/>
    </List>
  );
}
