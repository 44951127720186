import React from 'react';
import Typography from '@mui/material/Typography';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';

import GqlConfigurationDictionary from './configuration-dictionary.graphql';

export function ConfigurationDictionaryCodeExamplePage() {
  return (
    <>
      <h1>Configuration Dictionary</h1>

      <Typography paragraph>
        Here we can se an example of what the data structure
        of Configuration Dictionary, Parameter and Parameter values. <br/>
        Tip: Try the button ”view as graph” to get a more visual representation of the data.
      </Typography>
      <GraphQLCodeFile file={GqlConfigurationDictionary} showGraph={true} showDagGraph={true} />
    </>
  );
}
