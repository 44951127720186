import React from 'react';
import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material';
import { CardRefreshAction } from '../CardRefreshAction';

export interface HeadCell {
  id: string;
  label: string;
  isSortable?: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

type Direction = 'asc' | 'desc';

interface TableHeadProps<T> {
  order: 'asc' | 'desc';
  orderBy: keyof T;
  onRequestSort: (property: keyof T, direction: Direction) => void;
  refetch: () => void;
  headCells: HeadCell[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SortableTableHead<T = any>(props: TableHeadProps<T>) {
  const {
    refetch, headCells, orderBy, order,
  } = props;

  const handleRequestSort = (
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const newDirection = isAsc ? 'desc' : 'asc';
    props.onRequestSort(property as keyof T, newDirection);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.isSortable) {
            return (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          }
          return (
            <TableCell key={headCell.id} align={headCell.align}>
              {headCell.label}
            </TableCell>
          );
        })}
        <TableCell padding="none" align="right">
          <CardRefreshAction onClick={refetch} />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
