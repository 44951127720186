import { Button } from '@mui/material';
import React, { useState } from 'react';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import Box from '@mui/material/Box';
import { ConfigParamValuesFragment, useFeatureBreakdownOptionsQuery } from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { SelectValue } from '../../documentation/pages/browser/cpv-sets/StoredCpvSetsFilter';

interface Props {
  typeCode: string;
  value: string,
  onChange: (v: string[]) => void,
}

type SelectedValues = {
  [key: string]: ConfigParamValuesFragment;
};

export function FeatureBuilder(props: Props) {
  const [selectedConfigParamValues, setSelectedConfigParamValues] = useState<SelectedValues>({});

  const { data, loading, error } = useFeatureBreakdownOptionsQuery({
    variables: {
      typeCode: props.typeCode,
    },
    onCompleted: (result) => {
      // set selectedConfigParamValues to default values
      // Take a props.value that looks like this
      // '2JSS=62U\n4T9Q=SN0\n75X3=U5U'
      // and turn it into an object that looks like this
      // {
      //   '2JSS': { code: '62U', name: 'Name 1' },
      //   '4T9Q': { code: 'SN0', name: 'Name 2' },
      //   '75X3': { code: 'U5U', name: 'Name 3' },
      // }
      const selected: SelectedValues = {};
      props.value.split('\n').forEach((line) => {
        const [key, value] = line.split('=').map((s) => s.trim());
        const cpv = result.configurationDictionaries[0].configurationParameters
          .find((cp) => cp.code === key)?.configurationParameterValues
          .find((cpv2) => cpv2.code === value);
        if (cpv) {
          selected[key] = cpv;
        }
      });
      setSelectedConfigParamValues(selected);
    },
    skip: props.typeCode.length !== 4,
  });

  const update = (values: SelectedValues) => {
    setSelectedConfigParamValues(values);
    const lines = Object.entries(values).map(([key, value]) => `${key}=${value.code}`);
    props.onChange(lines);
  };

  const randomize = () => {
    if (data) {
      const selected: SelectedValues = {};
      data.configurationDictionaries[0].configurationParameters.forEach((cp) => {
        const randomIndex = Math.floor(Math.random() * cp.configurationParameterValues.length);
        selected[cp.code] = cp.configurationParameterValues[randomIndex];
      });
      update(selected);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const selectedConfigDict = data?.configurationDictionaries[0];

  return (
    <div>
      {props.typeCode.length !== 4 && (
        <div>Typecode must be 4 characters</div>
      )}
      {data?.configurationDictionaries.length === 0 && (
        <div>No configuration dictionary found</div>
      )}
      {data?.configurationDictionaries.length && (
        <Box mb={1} sx={{ textAlign: 'right' }}>
          <Button startIcon={<ShuffleIcon />} onClick={() => randomize()} size="small">Randomize</Button>
        </Box>
      )}
      {selectedConfigDict?.configurationParameters.map((cp) => (
        <Box key={cp.name} mb={1}>
          <SelectValue
            options={cp.configurationParameterValues}
            label={`${cp.code} - ${cp.name}` }
            selectedValue={selectedConfigParamValues[cp.code]}
            onChange={(option) => {
              if (option) {
                update({
                  ...selectedConfigParamValues,
                  [cp.code]: option,
                });
              } else {
                delete selectedConfigParamValues[cp.code];
                update({ ...selectedConfigParamValues });
              }
            }}
          ></SelectValue>
        </Box>
      ))}
    </div>
  );
}
