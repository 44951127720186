import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { fetchBackendJson } from '../../../services/restClient';

interface Result {
  result: Row[];
}

interface Row {
  svcName: string,
  svcTcId: string,
  pa: string,
  pos: string,
  fg: string,
  art: string,
  usageCount: number,
  expectedQty: number,
  rule: string | null
}

export function QuantityCheckSvc() {
  // State for the result
  const [rows, setRows] = useState<Row[] | null>(null);

  // is loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Error
  const [error, setError] = useState<string | null>(null);

  const [fileSize, setFileSize] = useState<number | null>(null);

  const onClick = async () => {
    setIsLoading(true);
    fetchBackendJson<Result>('structure-check', 'GET', (bytes) => {
      setFileSize(bytes);
    })
      .then((response) => {
        setError(null);
        setRows(response.result);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setIsLoading(false));

    // Save result into a state
  };

  return (
    <Box>
      <Stack spacing={2} direction="row">
        <LoadingButton
          onClick={onClick}
          loading={isLoading}
          variant="contained"
          color="primary"
        > Check Structures </LoadingButton>
        {fileSize && (
          <Alert severity="info">Response size: {Math.round(fileSize / 1000)} kB</Alert>
        )}
      </Stack>
      <Box mt={2}>
        <Paper>
          {error && (
            <Alert severity="error">Error: {error}</Alert>
          )}

          {rows && (
            <QuantityCheckSvcTable rows={rows}/>
          )}
        </Paper>
      </Box>
    </Box>
  );
}

function QuantityCheckSvcTable(props: { rows: Row[] }) {
  if (props.rows.length === 0) {
    return (
      <Alert severity="success">No issues found</Alert>
    );
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>SVC Name</TableCell>
          <TableCell>SVC TC ID</TableCell>
          <TableCell>PA</TableCell>
          <TableCell>POS</TableCell>
          <TableCell>FG</TableCell>
          <TableCell>ART</TableCell>
          <TableCell>Usage Count</TableCell>
          <TableCell>Expected Qty</TableCell>
          <TableCell>Rule</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row, i) => (
          <TableRow key={i}>
            <TableCell>{row.svcName}</TableCell>
            <TableCell>{row.svcTcId}</TableCell>
            <TableCell>{row.pa}</TableCell>
            <TableCell>{row.pos}</TableCell>
            <TableCell>{row.fg}</TableCell>
            <TableCell>{row.art}</TableCell>
            <TableCell>{row.usageCount}</TableCell>
            <TableCell>{row.expectedQty}</TableCell>
            <TableCell>{row.rule}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
