import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Outlet } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { NavigationMenu } from './NavigationMenu';
import { VolvoLogo } from './branding/VolvoLogo';
import { EnvironmentSelector } from '../common/forms/EnvironmentSelector';
import { CurrentUser } from './toolbar/CurrentUser';
import { Title } from './toolbar/Title';
import ErrorBoundary from '../common/ErrorBoundry';
import { LoginState } from '../control-panel/LoginState';
import { useAuth } from '../../hooks/useAuth';
import { DocsFooter } from './main/DocsFooter';
import { LocalEnvAlert } from './LocalEnvAlert';

const drawerWidth = 265;

interface Props {
  background?: string
  footer?: boolean,
}

export function ControlPanelLayout(props: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { data: authData } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      {/* <Toolbar /> */}
      {/* <Divider /> */}
      <Box sx={{
        display: { md: 'none' },
      }}>
        <Box p={2}>
          <EnvironmentSelector />
        </Box>
        <Divider />
      </Box>
      <NavigationMenu />
      <Box flexGrow={1}></Box>
      <VolvoLogo />
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Title />
          <Box flexGrow={1} />
          <LocalEnvAlert />
          <CurrentUser />
          <Box sx={{ ml: 2, width: 200, display: { xs: 'none', md: 'block' } }}>
            <EnvironmentSelector />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={window.document.body}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        className="control-panel-main"
        sx={{
          flexGrow: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          minHeight: '100vh',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: props.background,
        }}
      >
        <Toolbar />
        <Box sx={{ px: 3, pb: 6, flexGrow: 1 }}>
          <ErrorBoundary>
            <LoginState /> {/* Redirect to the login page */}
            {authData && <Outlet />}
          </ErrorBoundary>
        </Box>
        {props.footer && <DocsFooter />}
      </Box>
    </Box>
  );
}
