/* eslint-disable max-len */
import Typography from '@mui/material/Typography';
import React from 'react';
import { GraphQLCodeFile } from '../../../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import Gql2 from './case-query-usages.graphql';
import { subtractWeeks, yearWeekFormat } from '../../../../../../util/date';
import caseText from './case-text.md';
import { MarkdownFile } from '../../../../../common/MarkdownFile';

export function SIPlusCaseI00568() {
  return (
    <>
      <Typography variant="h3" mb={3}>Description of the existing KDP integration</Typography>
      <Typography paragraph>
        This file describes delivery units in KDP (see BL3).
      </Typography>
      <Typography paragraph>
        In KDP we use the consumptions to find out which parts that is used.
      </Typography>
      <Typography paragraph>
        We also know that this will be handled differently in Teamcenter.
        Probably you need to fetch this information from the M-BOM,
        please discuss with the architects for Teamcenter how to find the correct data
      </Typography>

      <Typography variant="h3" mt={4} mb={3}>PRINS GraphHub GraphQL query</Typography>
      <GraphQLCodeFile
        file={Gql2}
        variables={{
          pType: '41',
          week: yearWeekFormat(subtractWeeks(36)),
          offset: 0,
          limit: 10,
          consumptionRecord: {
            plant: { plantCode: 21 },
          },
        }}
        showGraph={true}
      />

      <Typography variant="h3" mt={4} mb={3}>Explanation</Typography>
      <MarkdownFile file={caseText}/>

      <Typography variant="h3" mt={4} mb={3}>For v216</Typography>
      <Typography paragraph mt={3}>
        As mentioned before we don´t have consumption records for v216 but since we are not selecting any
        data from the consumption records we can reuse the same query and just change the variables.
        We just remove the consumption record filter and change the pType
      </Typography>

      <GraphQLCodeFile
        file={Gql2}
        variables={{
          pType: '16',
          week: yearWeekFormat(subtractWeeks(36)),
          offset: 0,
          limit: 10,
        }}
        showGraph={true}
      />

    </>
  );
}
