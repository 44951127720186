export const dateFormat = (date: string | null) => {
  if (!date) {
    return '-';
  }

  return (new Date(date)).toISOString().split('T')[0];
};

export const dateTimeFormat = (date?: string | null, def: string | null = '-') => {
  if (!date) {
    return def;
  }

  const iso = (new Date(date)).toISOString();

  return `${iso.substr(0, 10)} ${iso.substr(11, 5)}`;
};

export const dateTimeFormatWithSeconds = (date?: string | null, def: string | null = '-') => {
  if (!date) {
    return def;
  }

  const iso = (new Date(date)).toISOString();

  return `${iso.substr(0, 10)} ${iso.substr(11, 12)}`;
};

export const timeStampToDate = (timestamp: string | undefined, def: string | null = '-') => {
  const timestampEpoch = parseInt(timestamp || '', 10);
  if (!timestampEpoch || timestampEpoch <= 0) {
    return def;
  }

  return new Date(timestampEpoch);
};

export function weekNumber(date = new Date()) {
  const startDate = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));

  return Math.ceil(days / 7);
}

export function weekNumberAsString(date = new Date()) {
  return weekNumber(date).toString().padStart(2, '0');
}

export function subtractWeeks(numOfWeeks: number, date = new Date()) {
  date.setDate(date.getDate() - numOfWeeks * 7);

  return date;
}

export function yearWeekFormat(date: Date = new Date()) {
  return parseInt(`${date.getFullYear()}${weekNumberAsString(date)}`, 10);
}

export function getSevenDaysAgo() {
  const previous = new Date();
  previous.setDate((new Date()).getDate() - 7);

  return previous;
}
