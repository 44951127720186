import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { FieldValues } from 'react-hook-form/dist/types';
import {
  AdminApplicationDetailsFragment,
  useAdminApplicationUpdateMutation,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { IntegrationAdminInfoTable } from '../admin-overview/IntegrationAdminInfoTable';

interface Props {
  close: () => void,
  application: AdminApplicationDetailsFragment,
}

interface FormInput extends FieldValues {
  teamName: string;
  appName: string;
  appId: string;
  notes: string;
  isDisabled: boolean;
}

export function EditIntegrationForm(props: Props) {
  const { register, handleSubmit } = useForm<FormInput>({
    defaultValues: {
      id: props.application.id,
      teamName: props.application.teamName,
      appName: props.application.appName || '',
      appId: props.application.appId || '',
      notes: props.application.notes || '',
    },
  });

  const [call, { loading, error }] = useAdminApplicationUpdateMutation();

  const onSubmit: SubmitHandler<FormInput> = async (values) => {
    await call({
      variables: {
        input: {
          id: props.application.id,
          ...values,
        },
      },
      refetchQueries: 'all',
    });

    props.close();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage error={error}/>
      <TextField
        fullWidth
        margin="normal"
        label="Team name"
        {...register('teamName', { required: true, maxLength: 60, minLength: 2 })}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Application name"
        {...register('appName', { required: true, maxLength: 60, minLength: 2 })}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Application ID (Alfabet)"
        {...register('appId', { required: true, maxLength: 30, minLength: 2 })}
      />
      <TextField
        fullWidth
        multiline
        minRows={2}
        maxRows={10}
        margin="normal"
        label="Notes"
        {...register('notes')}
      />
      <IntegrationAdminInfoTable application={props.application}/>
      <Stack spacing={2} direction="row" justifyContent="right" mt={3}>
        <LoadingButton color="primary" type="submit" variant="contained" loading={loading}>Save</LoadingButton>
      </Stack>
    </form>
  );
}
