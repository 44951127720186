import React from 'react';
import {
  MfgChangeOrderIssuesTable,
} from '../../documentation/pages/browser/mfg-change-order-issues/MfgChangeOrderIssuesTable';

export function MfgChangeOrderIssuesBrowserPage() {
  return (
    <>
      <MfgChangeOrderIssuesTable/>
    </>
  );
}
