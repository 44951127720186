import React from 'react';
import Box from '@mui/material/Box';
import { MarkdownFile } from '../../../../common/MarkdownFile';
import filteringText from './filtering-info.md';

export function FilteringPage() {
  return (
    <Box maxWidth={1400}>
      <MarkdownFile file={filteringText}/>
    </Box>
  );
}
