import React from 'react';
import Box from '@mui/material/Box';
import { MarkdownFile } from '../../../../common/MarkdownFile';
import sortingText from './sorting-info.md';

export function SortingPage() {
  return (
    <Box maxWidth={1400}>
      <MarkdownFile file={sortingText}/>
    </Box>
  );
}
