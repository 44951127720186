/* eslint-disable max-len */
import React from 'react';
import Typography from '@mui/material/Typography';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';

import GqlPartBreakdownPmlIds from './variant-breakdown-pmlIds.graphql';
import GqlPartBreakdownPartUsageIds from './variant-breakdown-partUsageIds.graphql';
import GqlPartBreakdownPartUsagesSeparately from './variant-breakdown-partUsages-separately.graphql';
import GqlPartBreakdown2 from './variant-breakdown2-partUsageIds.graphql';
import GqlPartBreakdownConsumptionRecordsSeparately from './variant-breakdown-crs-separately.graphql';

const features1 = [
  // 4101
  'MC06',
  'NJ02',
  'NK01',
  'NN02',
  'NO02',
  'NP01',
  'NQ02',
  'N901',
  'PF02',
  'PU02',
  // 4102
  'FK3X',
  'XE0A',
];

const features2 = [
  'AA00 = AA48',
  'AB00 = AB02',
  'AC00 = AC02',
  'AE00 = AE01',
  'AL00 = AL05',
  'AP00 = AP01',
  'AR00 = AR01',
  'AS00 = AS01',
  'AT00 = AT02',
  'AW00 = AW01',
  'AX00 = AX03',
  'AY00 = AY06',
];

export function BreakdownCodeExamplePage() {
  return (
    <>
      <h2>Variant breakdown</h2>

      <h3>PML IDs</h3>
      <Typography paragraph>
        Using KDP features to fetch PML IDs
      </Typography>

      <GraphQLCodeFile
        file={GqlPartBreakdownPmlIds}
        variables={{
          inputs: [{
            typeCode: '4101',
            features: features1,
            includeAssociatedTypeCodes: true,
          }],
        }}
      />
      <Typography paragraph>
        Additionally you can add plantCode to the input to only show pmlIds authorized for that plant.
      </Typography>
      <Typography paragraph>
        Adding includeAssociatedTypeCodes to the input will change the format of pmlIds to include the typeCode as well.
      </Typography>

      <h3>PartUsages (a.k.a delivery units)</h3>
      <Typography paragraph>
        Using the same input we can instead fetch part usages.
      </Typography>
      <ul>
        <li>It finds the usage rules matching the features.</li>
        <li>If includeAssociatedTypeCodes is enabled it will include those as well</li>
        <li>Finds the part usages connected to those rules</li>
        <li>Compare structure week, only including those{' '}
          <strong>effectiveIn &lt;= structureWeek</strong> and <strong>effectiveOut &gt; structure week</strong></li>
      </ul>

      <GraphQLCodeFile
        file={GqlPartBreakdownPartUsageIds}
        variables={{
          inputs: [{
            typeCode: '4101',
            features: features1,
            structureWeek: 202846,
            includeAssociatedTypeCodes: true,
          }],
        }}
      />

      <Typography paragraph>
        Then you can fetch the part usages data separately. (See below)
      </Typography>

      <h2>Variant breakdown 2 (TC Format)</h2>

      <Typography paragraph>
        You can also do the same with the new format
      </Typography>

      <GraphQLCodeFile
        file={GqlPartBreakdown2}
        variables={{
          inputs: [
            {
              typeCode: '1616',
              features: features2,
              structureWeek: 202846,
              plantCode: 25,
              includeAssociatedTypeCodes: true,
            },
          ],
        }}
      />

      <h2>Part Usage details</h2>

      <Typography paragraph>
        To get details for each part usage you can fetch them separately. <br />

        This way you don´t have to fetch part usages details for each breakdown.
      </Typography>

      <GraphQLCodeFile
        file={GqlPartBreakdownPartUsagesSeparately}
        variables={{ typeCode: '1616' }}
      />

      <h2>Consumption Record details</h2>

      <GraphQLCodeFile
        file={GqlPartBreakdownConsumptionRecordsSeparately}
        variables={{ typeCode: '1616', plantCode: 25 }}
      />

    </>
  );
}
