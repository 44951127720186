import React from 'react';
import Box from '@mui/material/Box';
import { QuantityCheckSvc } from '../../tools/quantity-check-svc/QuantityCheckSvc';

export function QuantityCheckSvcPage() {
  return (
    <Box mt={2}>
      <QuantityCheckSvc/>
    </Box>
  );
}
