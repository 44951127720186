/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ApolloQueryResult, gql, isApolloError } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { Alert } from '@mui/material';
import { Subject } from 'rxjs';
import { uniqueRequestsClient, docsClient } from '../../../services/apollo';
import { LoadingOverlay } from '../LoadingOverlay';
import { GraphQLResultArea } from './GraphQLResultArea';
import './GraphqlCode.scss';
import { codeBlockColors } from './colors';
import { GraphQLEditor } from './GraphQLEditor';
import { GraphViewButton } from './GraphView/GraphViewButton';
import { RunQueryButton } from './GraphView/RunQueryButton';
import { createErrorMessage } from './apollo.util';

interface Props {
  code: string;
  variables?: any;
  showGraph?: boolean;
  showDagGraph?: boolean;
  callSubject?: Subject<null>;
  onCompleted?: (data: { success: boolean, executionTime: number }) => void;
  onChange?: (state: { loading: boolean }) => void;
  stressRuns?: number;
  skipRender?: boolean;
}

const prettyJson = (v: any) => JSON.stringify(v, null, 2);

export function GraphQLCode(props: Props) {
  const {
    code, showGraph, showDagGraph, variables, callSubject, onCompleted, onChange,
    stressRuns,
  } = props;
  const [result, setResult] = useState<ApolloQueryResult<any> | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentCode, setCurrentCode] = useState<string>(code.trim());
  const [currentVariables, setCurrentVariables] = useState<string>(prettyJson(variables));
  const timesToRun = stressRuns || 1;

  useEffect(() => {
    if (!callSubject) {
      return () => { };
    }

    const subscription = callSubject.subscribe(() => executeQuery());
    return () => subscription.unsubscribe();
  }, [callSubject]);

  const executeQuery = async () => {
    let vars: any = null;
    let executionTime: number;
    if (currentVariables) {
      try {
        vars = JSON.parse(currentVariables);
      } catch (e) {
        setErrorMessage(`Invalid JSON for variables: ${e}`);
        return;
      }
    }
    setLoading(true);
    if (onChange) {
      onChange({ loading: true });
    }
    setErrorMessage(null);
    const startTime = performance.now();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < timesToRun; i++) {
      try {
        const result2 = await uniqueRequestsClient.query({
          query: gql(currentCode),
          variables: { ...vars, iteration: i },
          fetchPolicy: 'network-only',
        });

        // Clear the cached data for this query by specifying a cache key
        const cacheKey = docsClient.cache.identify({ __ref: 'ROOT_QUERY', query: gql(currentCode), va: vars });
        docsClient.cache.evict({ id: cacheKey });

        executionTime = performance.now() - startTime;
        setResult(result2);
        setError(null);
        if (onCompleted) {
          onCompleted({ success: true, executionTime });
        }
      } catch (e: any) {
        setResult(null);
        // eslint-disable-next-line no-console
        console.error(e);
        setError(e);

        if (isApolloError(e)) {
          setErrorMessage(createErrorMessage(e));
        }
        if (onCompleted) {
          executionTime = performance.now() - startTime;
          onCompleted({ success: false, executionTime });
        }
      } finally {
        setLoading(false);
        if (onChange) {
          onChange({ loading: false });
        }
      }
    }
  };

  if (props.skipRender) {
    // We use this to avoid rendering the component when it's not visible
    // But we still want it active so it can execute the query
    return null;
  }

  return (
    <Card variant="outlined" sx={{
      overflow: 'visible', // fix for tooltip popovers
      mb: 4,
    }}>
      <Grid container sx={{ fontSize: '14px' }}>
        <Grid item xs={12} md={6} xl={5}>
          <Box p={0}>
            <GraphQLEditor
              initialQuery={code.trim()}
              initialVariables={prettyJson(variables)}
              onEdit={setCurrentCode}
              onVariablesEdit={setCurrentVariables}
            />
          </Box>
          <Box sx={{ background: codeBlockColors.actionBarBackground, color: '#fff' }}>
            <Stack spacing={1} direction="row">
              <RunQueryButton
                loading={loading}
                onClick={() => executeQuery()}
              />
              {showGraph && <GraphViewButton
                query={currentCode}
                variables={currentVariables}
                dagMode={false}
                label="Graph view"
                toolTip="View the result as a graph (nodes and relationships). Uses the IDs in the result to generate a visual representation of the data"
              />
              }
              {showDagGraph && <GraphViewButton
                query={currentCode}
                variables={currentVariables}
                dagMode={true}
                label="DAG graph view"
                toolTip="View the result as a tree. Only works correctly for DAG graph structures (without cycles)."
              />
              }
              {/* Removed because we don't need auth here anymore.
                Might use for something else later. */}
              {/* <SettingsButton/> */}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} xl={7} sx={{ position: 'relative' }}>
          <Box p={1} sx={{
            top: 0,
            left: 0,
            position: { md: 'absolute' },
            background: codeBlockColors.resultBackground,
            color: '#fff',
            height: { xs: '350px', md: '100%' },
            width: '100%',
            overflowY: 'scroll',
          }}>
            {loading && <LoadingOverlay />}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {error && <Alert severity="error">{error.message}</Alert>}
            {result?.data && <GraphQLResultArea data={result.data} />}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
