/* eslint-disable max-len */
import React, { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { useWindowSize } from '@react-hook/window-size';
import { SlideUpTransition } from '../parts/PartDialog/SlideUpTransition';
import ErrorMessage from '../../../../common/ErrorMessage';
import {
  MfgChangeOrderIssueDialogDocument, useMfgChangeOrderIssueBopDialogQuery,
  useMfgChangeOrderIssueDialogQuery, useMfgChangeOrderIssueMfgDialogQuery,
} from '../../../../../generated/graphql';
import { GraphViewResult } from '../../../../common/GraphqlCodeBlock/GraphView/GraphView';
import { formatQueryResultForGraph } from '../../../../common/GraphqlCodeBlock/GraphView/graphview.util';
import { PrettyJson } from '../../../../common/data/PrettyJson';
import { PrettyQuery } from '../../../../common/data/PrettyQuery';
import { MfgChangeOrderIssueInfoPanel } from './MfgChangeOrderIssueInfoPanel';
import { DialogLoader } from '../../../../common/dialog/DialogLoader';

interface Props {
  id: string,
  close: () => void
}

export function MfgChangeOrderIssueDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = React.useState('1');
  const [width, height] = useWindowSize();

  const { data, loading, error } = useMfgChangeOrderIssueDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const changeOrderIssue = data?.mfgChangeOrderIssues[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      TransitionComponent={SlideUpTransition}
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogTitle>
        <Stack justifyContent="space-between" alignItems="center" direction="row">
          <div>Change Order Issue {changeOrderIssue?.coiNumber}</div>
          <Button color="error" onClick={handleClose}>Close</Button>
        </Stack>
      </DialogTitle>
      {loading && <DialogLoader/>}
      <ErrorMessage error={error}/>

      <TabContext value={tab} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Info" value="1" />
            <Tab label="Graph" value="2" />
            <Tab label="Json" value="3" />
            <Tab label="Example Query" value="4" />
            <Tab label="Graph (MFG only)" value="5" />
            <Tab label="Graph (BOP only)" value="6" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <MfgChangeOrderIssueInfoPanel mfgChangeOrderIssue={changeOrderIssue}/>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          {data && <GraphViewResult
            graphData={formatQueryResultForGraph(data)}
            width={Math.min(width - 64, 1536)}
            height={height - 32 - 156}
            dagMode
            displayTypename
          />}
        </TabPanel>
        <TabPanel value="3">
          {data && <PrettyJson data={data} />}
        </TabPanel>
        <TabPanel value="4">
          <PrettyQuery query={MfgChangeOrderIssueDialogDocument.loc?.source.body.trim() || ''}/>
        </TabPanel>
        <TabPanel value="5">
          <MfgGraph id={props.id}/>
        </TabPanel>
        <TabPanel value="6">
          <BopGraph id={props.id}/>
        </TabPanel>
      </TabContext>

    </Dialog>
  );
}

function BopGraph(props: { id: string }) {
  const [width, height] = useWindowSize();

  const { data, loading, error } = useMfgChangeOrderIssueBopDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <LinearProgress/>}
      <ErrorMessage error={error}/>
      <GraphViewResult
        graphData={formatQueryResultForGraph(data)}
        width={Math.min(width - 64, 1536)}
        height={height - 32 - 156}
        dagMode
        displayTypename
      />
    </>
  );
}

function MfgGraph(props: { id: string }) {
  const [width, height] = useWindowSize();

  const { data, loading, error } = useMfgChangeOrderIssueMfgDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <LinearProgress/>}
      <ErrorMessage error={error}/>
      <GraphViewResult
        graphData={formatQueryResultForGraph(data)}
        width={Math.min(width - 64, 1536)}
        height={height - 32 - 156}
        dagMode
        displayTypename
      />
    </>
  );
}
