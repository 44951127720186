import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CrownSvg } from '../branding/CrownSvg';
import './title.scss';

export function Title() {
  return (
    <Box position="relative">
      <Box sx={{
        position: 'absolute',
        top: -13,
        left: -6,
        width: 18,
        height: 18,
        // transform: 'rotate(-15deg)',
      }} className="mini-crown">
        <CrownSvg fill={'#ecad38'}/>
      </Box>
      <Typography variant="h6" noWrap component="div" sx={{ lineHeight: 1.2 }}>
        GraphHub <Box component="small" sx={{
        fontWeight: '400',
        fontSize: '0.8em',
      }}>by PRINS</Box>
      </Typography>
    </Box>
  );
}
