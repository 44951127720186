import React from 'react';
import './App.scss';
import { Router } from './components/layout/Router';
import { useDialogSubject } from './hooks/useDialogs';

function App() {
  const [dialogs] = useDialogSubject();

  return (
    <div className="App">
      <Router/>
      <>
        {dialogs.map((dialog, i) => {
          return <div key={i}>{dialog}</div>;
        })}
      </>
    </div>
  );
}

export default App;
