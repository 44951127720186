import { Chip } from '@mui/material';
import React from 'react';

export function ReleaseStatusChip({ releaseStatus } : { releaseStatus?: string | null }) {
  if (!releaseStatus) {
    return null;
  }

  return (
    <Chip
      label={releaseStatus}
      variant="outlined"
      color={releaseStatus === 'RELEASED' ? 'info' : 'warning'}
      size="small"
    />
  );
}
