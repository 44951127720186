import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  checked: boolean;
  setVisibility: (checked: boolean) => void;
}

export function ToggleAltCodes(props: Props) {
  return (
    <FormControlLabel
      control={<Checkbox
        checked={props.checked}
        onChange={(e) => props.setVisibility(e.target.checked)}
      />}
      label="Show alt codes and names"
    />
  );
}
