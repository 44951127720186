import React from 'react';
import Card from '@mui/material/Card';
import {
  CardHeaderProps,
  LinearProgress,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { SectionCardHeader } from '../common/SectionCardHeader';
import { CleansingJobResultTableRowFragment, useCleansingJobResultTableQuery } from '../../generated/graphql';
import ErrorMessage from '../common/ErrorMessage';
import { Section } from '../layout/Section';
import { CardRefreshAction } from '../common/CardRefreshAction';
import { CleansingJobResultTable } from './RepeatableJobResultTable';

export function RepeatableBullMqOverview() {
  return (
    <Section maxWidth={2300}>
      <CleansingJobResults
      avatar={<CleaningServicesIcon color='primary' />}
      state={['data cleansed']}
      />
    </Section>
  );
}

function CleansingJobResults({ state, avatar }: {
  state: string[],
  avatar: CardHeaderProps['avatar'],
}) {
  const {
    data,
    loading,
    error,
    refetch,
  } = useCleansingJobResultTableQuery();

  // Make sure there are no null values in the array
  const jobResults = data?.user?.allCleansingJobResults
    ?.filter((jobResult): jobResult is CleansingJobResultTableRowFragment => jobResult !== null)
    ?.map((jobResult) => ({
      ...jobResult,
    })) ?? [];

  return (
    <Card sx={{ mb: 2 }}>
      <SectionCardHeader
        avatar={avatar}
        title={`Jobs - ${state.join(', ')}`}
        action={<CardRefreshAction onClick={refetch} />}
      />
      <ErrorMessage error={error}></ErrorMessage>
      {loading && <LinearProgress></LinearProgress>}
      {jobResults && jobResults.length === 0 && !loading && (
        <Alert severity="info">No Jobs Found</Alert>
      )}
      {jobResults && jobResults.length > 0 && (
        <CleansingJobResultTable jobResultList={jobResults}></CleansingJobResultTable>
      )}
    </Card>
  );
}
