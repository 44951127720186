import React from 'react';
import Box from '@mui/material/Box';
import { ReactMarkdownWithSyntaxHighlighting } from '../../../common/ReactMarkdownWithSyntaxHighlighting';
import { ModelImage } from '../../../pages/DataModelPage';
import { GraphQLCodeFile } from '../../../common/GraphqlCodeBlock/GraphQLCodeFile';
import Gql1 from './graphql/example-mfg-runtime-children.graphql';

const Md = ReactMarkdownWithSyntaxHighlighting;

export function ComponentMbomPage() {
  return (
    <>
      <h1>MBOM</h1>

      <Box mb={5}>
        <Md content={`
## Component MBOM
This is handled separately from the other EBOM and MBOM in Teamcenter and works very differently.
If you think you are interested in this data then make sure to contact the PRINS team or the Teamcenter team
first.

In PRINS the data is stored in a similar structture as in Teamcenter.
One structure can contain the structure for multiple similar enditems and the structure for each individual
enditem is by the effectivity nodes on each partUsage.
 
To deal with this you can query runtimeChildren on mfgPartUsage to get a breakdown list of the substructure is for that mfgPartUsage
        `} />
      </Box>

      <GraphQLCodeFile
        file={Gql1}
        variables={{
          endItem: '01216735',
        }}
      />

      <ModelImage src="/images/mep5-structure-2024-09-04.png" label="mep5 example strucutre" width={600} />
    </>
  );
}
