import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ControllerFieldState, Noop, RefCallBack } from 'react-hook-form/dist/types';
import { useApplicationsQuery } from '../../../generated/graphql';
import ErrorMessage from '../ErrorMessage';

export interface SelectedApplication {
  label: string
  appId: string
}

interface Props {
  onChange: (value: SelectedApplication) => void,
  excludesid?: string,
  inputValue?: string,
  onBlur?: Noop; // From a controller
  value?: SelectedApplication; // From a controller
  name?: string; // From a controller
  ref?: RefCallBack; // From a controller
  state?: ControllerFieldState
}

export function IntegrationSelect(props: Props) {
  const { data, loading, error } = useApplicationsQuery({});

  if (error) {
    return <ErrorMessage error={error}/>;
  }

  const options = data?.user.applications.map((app) => ({
    label: `${app.teamName} | ${app.appName} (${app.appId})`,
    appId: app.appId,
  })) || [];

  return (
    <>
      <Autocomplete
        isOptionEqualToValue={(option, value) => option.appId === value.appId}
        {...props}
        onChange={(e, v) => props.onChange(v as SelectedApplication)}
        loading={loading}
        disablePortal
        options={options}
        renderInput={(params) => <TextField
          error={!!props.state?.error || false}
          {...params}
          label="Team | Application"/>
        }
      />
    </>
  );
}
