import React from 'react';
import Card from '@mui/material/Card';
import Info from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  useUsageRulesWithErrorsQuery,
  useTypeCodesWithEbomsQuery,
  usePmlOverviewQuery,
} from '../../../generated/graphql';
import { Section } from '../../layout/Section';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { dateTimeFormat } from '../../../util/date';
import ErrorMessage from '../../common/ErrorMessage';

export function UpstreamDashboard() {
  return (
    <>
      <Section>
        <Card>
          <SectionCardHeader
            avatar={<Info color="error"/>}
            title="Usage rules errors"
          />
          <UsageRulesWithErrors/>
        </Card>
      </Section>
      <Section>
        <Card>
          <SectionCardHeader
            avatar={<Info color="info"/>}
            title="Usage rules overview"
          />
          <UsageRulesOverview/>
        </Card>
      </Section>
    </>
  );
}

function UsageRulesWithErrors() {
  const { data, loading, error } = useUsageRulesWithErrorsQuery();

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <ErrorMessage error={error}/>}
      {(data && data.usageRules.length > 0) && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Errors</TableCell>
                <TableCell>Type Code</TableCell>
                <TableCell>Sub Type</TableCell>
                <TableCell>Plants</TableCell>
                <TableCell>PartUsages</TableCell>
                <TableCell>VarCodes</TableCell>
                <TableCell>AltVarCodes</TableCell>
                <TableCell>CreatedAt</TableCell>
                <TableCell>Last Modified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.usageRules.map((rule, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell width={400}>{rule.errors}</TableCell>
                  <TableCell>{rule.typeCode}</TableCell>
                  <TableCell>{rule.subType}</TableCell>
                  <TableCell>{rule.plants.map((p) => p.plantCode).join(', ')}</TableCell>
                  <TableCell>{rule.partUsagesAggregate?.count}</TableCell>
                  <TableCell>{rule.varCodes}</TableCell>
                  <TableCell>{rule.altVarCodes}</TableCell>
                  <TableCell>{dateTimeFormat(rule.createdAt)}</TableCell>
                  <TableCell>{dateTimeFormat(rule.lastModified)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {(data && data.usageRules.length === 0) && (
        <p>No errors</p>
      )}
    </>
  );
}

function UsageRulesOverview() {
  const { data, loading, error } = useTypeCodesWithEbomsQuery();

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <ErrorMessage error={error}/>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>TypeCode</TableCell>
            <TableCell>Single PMLs</TableCell>
            <TableCell>Combination PMLs</TableCell>
            <TableCell>Variant Combinations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.productContexts.map((pc) => (
            <UsageRulesOverviewRow typeCode={pc.typeCode} key={pc.typeCode}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function UsageRulesOverviewRow(props: { typeCode: string }) {
  const { typeCode } = props;
  const { data, loading, error } = usePmlOverviewQuery({
    variables: {
      typeCode,
    },
  });

  if (loading) {
    return (
      <TableRow>
        <TableCell>{typeCode}</TableCell>
        <TableCell>Loading...</TableCell>
        <TableCell>Loading...</TableCell>
      </TableRow>
    );
  }

  if (error) {
    return (
      <TableRow>
        <TableCell colSpan={3}>{typeCode}</TableCell>
        <TableCell>
          <ErrorMessage error={error}/>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{typeCode}</TableCell>
      <TableCell>{data && data.singlePmls.count}</TableCell>
      <TableCell>{data && data.combinationPmls.count}</TableCell>
      <TableCell>{data && data.variantCombinations.count}</TableCell>
    </TableRow>
  );
}
