import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { client } from '../../../services/apollo';
import { useSelectedEnvironment } from '../../../hooks/useSelectedEnvironment';
import { config } from '../../../config/config';

interface Props {
  size?: 'small' | 'medium'
}

/**
 * Select which backend enpoint to use
 */
export function EnvironmentSelector(props: Props) {
  const [env, setEnv] = useSelectedEnvironment();
  const { size = 'small' } = props;

  const handleChange = async (event: SelectChangeEvent) => {
    const selected = config.endpoints
      .find((e) => e.name === event.target.value);

    if (!selected) {
      return; // can't happen
    }

    setEnv(selected);
    client.refetchQueries({
      include: 'active',
      updateCache: (c) => c.reset(),
    });
  };

  return (
    <FormControl fullWidth size={size}>
      <InputLabel>Environment</InputLabel>
      <Select
        value={env.name}
        label="Environment"
        onChange={handleChange}
      >
        {config.endpoints.map((e, i) => (
          <MenuItem key={i} value={e.name} disabled={e.disabled}>{e.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
