import React, { PropsWithChildren } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

export function FieldMiniTable({ children }: PropsWithChildren) {
  return (
    <Table padding="none" sx={{
      width: 'auto',
      td: {
        border: 0,
        fontSize: 12,
        lineHeight: 1.5,
      },
      'td + td': {
        paddingLeft: 1,
      },
    }}>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
}

export function FieldMiniTableVerticalDividers({ children }: PropsWithChildren) {
  return (
    <Table padding="none" sx={{
      width: 'auto',
      td: {
        border: 0,
        fontSize: 12,
        lineHeight: 1.5,
        position: 'relative',
      },
      'td + td': {
        paddingLeft: 2,
      },
      'td + td:before': {
        content: '""',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        position: 'absolute',
        top: 2,
        bottom: 2,
        left: 8,
      },
    }}>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
}
