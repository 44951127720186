import React from 'react';
import Typography from '@mui/material/Typography';
import { GraphQLCodeFile } from '../../../../common/GraphqlCodeBlock/GraphQLCodeFile';

import Gql1 from './effectivity-weeks.graphql';

export function EffectivityWeeksCodeExamplePage() {
  return (
    <>
      <h1>Effectivity weeks</h1>

      <Typography paragraph>
        In this example we only include part usages that are in effect a given week.
      </Typography>
      <Typography paragraph>
        <b>effectiveInWeek_LTE: 202517</b> means return all part usages that has
        an introduction week less (earlier) than or equal to 202517
      </Typography>
      <Typography paragraph>
        <b>effectiveOutWeek_GT: 202517</b> means return all part usages that has
        an out week more than (after) to 202517. <br/>
        Exact matches will be excluded here since
        this value represents the first week where it will no longer be in effect.
      </Typography>
      <Typography paragraph>
        This can also be used on <b>Part Constituents</b>
      </Typography>

      <GraphQLCodeFile
        file={Gql1}
        showGraph={true}
        variables={{
          week: 202517,
        }}
      />
    </>
  );
}
