import React from 'react';
import Box from '@mui/material/Box';
import { ReactMarkdownWithSyntaxHighlighting } from '../../../common/ReactMarkdownWithSyntaxHighlighting';

const Md = ReactMarkdownWithSyntaxHighlighting;

export function DataCleansingPage() {
  return (
    <>
      <h1>Data cleansing in PRINS</h1>

      <Box mb={5}>

        <Md content={`
The data cleansing process in PRINS is a scheduled job that runs every Monday at midnight CET. The job is responsible for cleaning up the following data:

**Part Usages**
 
 Part Usages that have been marked as revoked and have had no consumption records for more than 4 weeks will be deleted.

 **Consumption Records**
 
 Consumption Records that have been marked as revoked for more than 4 weeks will be deleted.

 **Part Constituents**
 
 Part Usages that have been marked as revoked for more than 4 weeks will be deleted.


*Last modified: 2024-09-04*

        `} />

      </Box>
    </>
  );
}
