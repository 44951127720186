import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

export function DialogLoader() {
  return <Box sx={{
    top: 0,
    left: 0,
    position: { md: 'absolute' },
    width: '100%',
    zIndex: 1,
  }}>
    <LinearProgress/>
  </Box>;
}
