import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { SimpleCardLayout } from '../layout/SimpleCardLayout';
import { UploadBom } from '../control-panel/Upstream/UploadBom';

export function UpstreamPage() {
  const { data: authData } = useAuth();

  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  return (
    <>
      <UploadBom/>
    </>
  );
}
