import React from 'react';
import {
  Alert, Grid, Paper, Typography,
} from '@mui/material';
import { ProductFamilyDialogQuery } from '../../../../../generated/graphql';
import { ProductContextTable } from './ProductContextTable';

type ProductFamilyDialog = ProductFamilyDialogQuery['productFamilies'][0];

interface Props {
  productFamily?: ProductFamilyDialog;
}

export function ProductFamiliesDialogInfoPanel(props: Props) {
  const { productFamily } = props;

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>
            Product Contexts
          </Typography>
          {productFamily?.productContexts.length === 0 ? (
            <Alert severity="info">This has no product contexts</Alert>
          ) : (
            <ProductContextTable productFamily={productFamily} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
