import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { colors } from '../../services/mui-theme';

interface Props {
  children: ReactNode,
}

export function LoginLayout(props: Props) {
  return <Box sx={{
    height: '100vh',
    minWidth: 280,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    background: colors.primary,
  }}>
    <Box sx={{
      width: 280,
      height: 280,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}>
      <Box sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        transform: 'rotate(45deg)',
        border: '3px solid #fff',
      }}/>
      {props.children}
    </Box>
  </Box>;
}
