/* eslint-disable max-len */
import React, { PropsWithChildren, ReactElement, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ConstructionIcon from '@mui/icons-material/Construction';
import Stack from '@mui/material/Stack';
import SchemaIcon from '@mui/icons-material/Schema';
import { Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { useAuth } from '../../hooks/useAuth';
import { useMeQuery } from '../../generated/graphql';
import { colors } from '../../services/mui-theme';

interface IMenuSection {
  sectionTitle: string
  icon: ReactElement
  alwaysShow?: boolean
  items: { to: string, title: string, if?: () => boolean | undefined }[]
}

export function NavigationMenu() {
  const { data: authData } = useAuth();
  const location = useLocation();
  const {
    data: userData,
  } = useMeQuery({ skip: !authData });
  const isUpstream = userData?.user.me.roles.includes('upstream');
  const isAdmin = userData?.user.me.roles.includes('admin');

  const menu: IMenuSection[] = [
    {
      sectionTitle: 'Control Panel',
      icon: <HomeIcon />,
      alwaysShow: true,
      items: [
        { to: '/control-panel/', title: 'My Applications' },
      ],
    },
    {
      sectionTitle: 'User Guide',
      icon: <HelpCenterIcon />,
      items: [
        { to: '/documentation/', title: 'Getting started' },
        { to: '/documentation/graphql', title: 'Learn GraphQL' },
        { to: '/documentation/graphql-ui', title: 'GraphQL Sandbox' },
        { to: '/documentation/authentication', title: 'Authentication' },
        { to: '/documentation/best-practice', title: 'Best Practice' },
        { to: '/documentation/environments', title: 'Environments & Networking' },
        { to: '/documentation/events', title: 'Webhooks & MQ Events' },
        { to: '/documentation/support', title: 'Support' },
      ],
    },
    {
      sectionTitle: 'PRINS GraphHub model',
      icon: <SchemaIcon />,
      items: [
        { to: '/graph-navigator', title: 'Graph navigator' },
        { to: '/data-model/field-list', title: 'Field list' },
        { to: '/data-model', title: 'Data model' },
      ],
    },
    {
      sectionTitle: 'API & Filters',
      icon: <DataObjectIcon />,
      items: [
        { to: '/query-filtering/queries', title: 'Queries' },
        { to: '/query-filtering/filtering', title: 'Filtering' },
        { to: '/query-filtering/aggregations', title: 'Aggregations' },
        { to: '/query-filtering/sorting', title: 'Sorting' },
        { to: '/query-filtering/offset-pagination', title: 'Offset Pagination' },
        { to: '/query-filtering/cursor-pagination', title: 'Cursor Pagination' },
      ],
    },
    {
      sectionTitle: 'Example queries',
      icon: <PlayCircleIcon />,
      items: [
        { to: '/documentation/parts-code-example', title: 'Parts' },
        { to: '/documentation/breakdown-code-example', title: 'Variant Breakdown' },
        // Not sure if we should even have this in prins.
        // { to: '/documentation/pno-breakdown-code-example', title: 'PNO Breakdown' },
        // { to: '/documentation/delta-updates-code-example', title: 'Delta updates' },
        // { to: '/documentation/preliminary-data-code-example', title: 'Released/Preliminary data' },
        { to: '/documentation/effectivity-weeks-code-example', title: 'Effectivity weeks' },
        { to: '/documentation/bom-lines-code-example', title: 'Bom Lines' },
        { to: '/documentation/configuration-dictionary-code-example', title: 'Configuration Dictionary' },
        { to: '/documentation/stored-cpv-set-code-example', title: 'Stored CPV sets' },
      ],
    },
    {
      sectionTitle: 'Browse data',
      icon: <ManageSearchIcon />,
      items: [
        { to: '/data-model/data', title: 'Data overview' },
        { to: '/browser/ebom', title: 'EBOM' },
        { to: '/browser/mbom', title: 'MBOM' },
        { to: '/browser/parts', title: 'Parts' },
        { to: '/browser/software-parts', title: 'Software Parts' },
        { to: '/browser/lmod', title: 'LMOD' },
        { to: '/browser/change-order-issues', title: 'Change Order Issues' },
        { to: '/browser/configuration-dictionaries', title: 'Configuration Dictionaries' },
        { to: '/browser/usage-rules', title: 'Usage Rules' },
        { to: '/browser/consumption-records', title: 'Consumption Records' },
        { to: '/browser/test-series-consumptions', title: 'Test Series Consumptions' },
        { to: '/browser/stored-cpv-sets', title: 'Stored CPV Sets' },
        { to: '/browser/mobility', title: 'Mobility' },
        { to: '/browser/product-families', title: 'Product Families' },
        { to: '/browser/mfg-change-order-issues', title: 'Mfg Change Order Issues' },
        { to: '/browser/mfg-assembly-placeholders', title: 'Mfg Assembly Placeholders' },
        { to: '/browser/bop-routings', title: 'Bop Routings' },
        { to: '/browser/spare-part-info', title: 'Spare Parts Info' },
        { to: '/browser/sw-baseline', title: 'Software Baselines' },
      ],
    },
    {
      sectionTitle: 'Concepts & explanations',
      icon: <MenuBookIcon />,
      items: [
        { to: '/documentation/links', title: 'Links' },
        { to: '/documentation/mbom', title: 'MBOM' },
        { to: '/documentation/component-mbom', title: 'Component MBOM' },
        { to: '/documentation/revoked-part-usages', title: 'Revoked Part usages', if: () => (isAdmin || isUpstream) },
        { to: '/documentation/released-preliminary-data', title: 'Released/Preliminary Data', if: () => (isAdmin || isUpstream) },
        { to: '/documentation/pmls', title: 'Usage Rules/PMLs' },
        { to: '/documentation/data-cleansing-docs', title: 'Cleansing data' },
      ],
    },
    /*
    {
      sectionTitle: 'Case studies',
      icon: <WorkHistoryIcon/>,
      items: [
        { to: '/cases/siplus', title: 'SI+', if: () => (isAdmin || isUpstream) },
        { to: '/cases/newcast', title: 'Newcast', if: () => (isAdmin || isUpstream) },
      ],
    },
    */
    {
      sectionTitle: 'Tools',
      icon: <ConstructionIcon />,
      items: [
        { to: '/tools/breakdown-performance', title: 'Breakdown performance', if: () => (isAdmin || isUpstream) },
        { to: '/tools/feature-breakdown', title: 'Feature Breakdown', if: () => (isAdmin || isUpstream) },
        { to: '/tools/feature-breakdown-batch', title: 'Feature Breakdown Batch', if: () => (isAdmin || isUpstream) },
        { to: '/tools/quantity-check', title: 'Quantity Check Custom', if: () => (isAdmin || isUpstream) },
        { to: '/tools/quantity-check-svc', title: 'Quantity Check SVC', if: () => (isAdmin || isUpstream) },
        { to: '/tools/cmp-consumptions-counter', title: 'Cmp Consumptions counter', if: () => isAdmin },
      ],
    },
    {
      sectionTitle: 'Upstream',
      icon: <AdminPanelSettingsIcon />,
      items: [
        { to: '/control-panel/upstream-dashboard', title: 'Upstream Dashboard', if: () => isUpstream },
        { to: '/control-panel/upstream', title: 'Upstream Upload', if: () => isUpstream },
        { to: '/control-panel/data-quality', title: 'Data Quality', if: () => (isAdmin || isUpstream) },
        { to: '/control-panel/jobs', title: 'Jobs', if: () => (isAdmin || isUpstream) },
        { to: '/control-panel/data-cleansing', title: 'Data Cleansing', if: () => (isAdmin || isUpstream) },
      ],
    },
    {
      sectionTitle: 'Administration',
      icon: <AdminPanelSettingsIcon />,
      items: [
        { to: '/control-panel/administration', title: 'Applications', if: () => isAdmin },
        { to: '/control-panel/run-operations', title: 'Run Operations', if: () => isAdmin },
        { to: '/control-panel/metrics', title: 'Metrics', if: () => isAdmin },
      ],
    },
  ];

  return (
    <>
      <List sx={{ p: 0 }}>
        {menu.map((section, index) => {
          const items = section.items.filter((item) => !item.if || item.if());

          if (items.length === 0) {
            return null;
          }

          return (
            <MenuSection
              text={section.sectionTitle}
              icon={section.icon}
              key={index}
              // Make sure that the menu section is open when visiting from a link
              defaultShow={!!items.find((item) => item.to === location.pathname)}
              // Force show and remove the expand button
              alwaysShow={!!section.alwaysShow}
            >
              {items.map((item, index2) => (
                <MenuItem to={item.to} key={index2}>{item.title}</MenuItem>
              ))}
            </MenuSection>
          );
        })}

      </List>
    </>
  );
}

interface MenuItemProps {
  to: string;
  disabled?: boolean;
}

function MenuItem(props: PropsWithChildren<MenuItemProps>) {
  const navigate = useNavigate();

  return (
    <ListItem disablePadding>
      <ListItemButton
        href={props.to}
        onClick={(e) => {
          e.preventDefault();
          navigate(props.to);
        }}
        selected={window.location.pathname === props.to}
        sx={{ px: 2, py: 1.25, fontSize: '0.9375rem' }}
        disabled={props.disabled}
      >
        {props.children}
      </ListItemButton>
    </ListItem>
  );
}

interface MenuSectionProps {
  text: string
  icon?: ReactElement
  alwaysShow?: boolean
  defaultShow: boolean
}

function MenuSection(props: PropsWithChildren<MenuSectionProps>) {
  const {
    text, icon, alwaysShow, defaultShow, children,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(defaultShow || !!alwaysShow);

  const handleExpand = () => {
    if (!alwaysShow) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <Box py={1}>
        <Stack
          direction="row"
          color={colors.lightUi.dark2}
          alignItems="center"
          onClick={handleExpand}
          sx={{ cursor: alwaysShow ? 'default' : 'pointer' }}
          px={1}
        >
          {icon}
          <Typography
            pl={1}
            variant="overline"
            display="block"
          >{text}</Typography>
          <Box flexGrow={1} />
          {!alwaysShow && <ExpandIcon isOpen={isOpen} />}
        </Stack>
        <Collapse in={isOpen} timeout="auto">
          {children}
        </Collapse>
      </Box>
      <Divider />
    </>
  );
}

function ExpandIcon(props: { isOpen: boolean }) {
  return props.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;
}
