import React from 'react';
import { Alert } from '@mui/material';
import { GraphQLCode } from './GraphQLCode';
import { useStaticFile } from '../../../hooks/useStaticFile';

interface Props {
  file: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables?: any;
  showGraph?: boolean;
  showDagGraph?: boolean;
}

/**
 * Loads a text file containing graphql code using http
 */
export function GraphQLCodeFile({ file, ...rest }: Props) {
  const { loading, error, content } = useStaticFile(file);

  if (loading) {
    return <Alert severity="info">Loading</Alert>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return <GraphQLCode code={content} {...rest}/>;
}
