/* eslint-disable no-continue,no-restricted-syntax,@typescript-eslint/no-non-null-assertion */
import { NodeObjectWithValues } from './graphview.util';
import { GraphOptions } from './GraphOptionsPanel';

export function renderNode(
  node: NodeObjectWithValues,
  ctx: CanvasRenderingContext2D,
  options: GraphOptions,
  nodeSize: number,
  zoom: number,
) {
  if (zoom < 0.8) {
    return; // Text will be to small to display
  }

  const textLeft = 2;
  const fontSize = (options.fontSize / (1 + zoom / 2)) * 1.5;
  const lineHeight = fontSize * 1.2;
  const y = node.y!;
  const x = node.x! + nodeSize + textLeft;

  ctx.textAlign = 'left';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = 'black';

  if (options.displayTypename) {
    const typeName = node.values.find(({ key }) => key === '__typename');
    ctx.font = `bold ${fontSize}px Sans-Serif`;
    ctx.fillText(`${typeName?.value}`, x, y - lineHeight / 2);
  }

  ctx.font = `${fontSize}px Sans-Serif`;
  const text = firstValue(node);
  ctx.fillText(text, x, options.displayTypename ? y + lineHeight / 2 : y);
}

/**
 * First value of the node that is not id or typename
 */
function firstValue(node: NodeObjectWithValues) {
  for (const v of node.values) {
    if (['__typename', 'id'].includes(v.key)) {
      continue;
    }

    return `${v.value}`;
  }

  return '';
}
