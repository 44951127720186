import React from 'react';
import { PartsTable } from '../../documentation/pages/browser/parts/PartsTable';

export function PartsBrowserPage() {
  return (
    <>
      <PartsTable/>
    </>
  );
}
