import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import React from 'react';
import { StoredCpvSetRow } from './breakdown-performance-types';

export function StoredCpvSetPreview(props: { result: StoredCpvSetRow[] }) {
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        Total: {props.result.length}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ overflowX: 'scroll', whiteSpace: 'nowrap', fontSize: '7px' }}>
          {props.result.map((row, i) => (
            <div key={i}>
              <b>{row.modelYearDetails?.typeCode}</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {row.cpAndCpvCodes.join(', ')}
            </div>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
