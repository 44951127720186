import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Alert from '@mui/material/Alert';
import {
  CircularProgress, List, ListItem, ListItemText,
} from '@mui/material';
import { UserDetailsFragment, useAdminListApplicationsAndUsersQuery, useAdminListUsersQuery } from '../../../generated/graphql';
import { RemoveUserFromIntegration } from './RemoveUserFromIntegration';
import { RemoveUser } from './RemoveUser';
import { getComparator } from '../../common/sortTables/comparators';
import { SortableTableHead, HeadCell } from '../../common/sortTables/SortableTableHead';

export function UsersTable() {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserDetailsFragment>('name');

  const { data, refetch, loading } = useAdminListUsersQuery();
  const appsData = useAdminListApplicationsAndUsersQuery();

  const userRows = data?.admin.users;
  const appRows = appsData.data?.admin.approvedApplications;

  const getAppsByUserId = (userId: string) => {
    return appRows?.filter((app) => app.users.some((user) => user.id === userId)) || [];
  };

  if (loading || appsData.loading) {
    return <Paper variant="outlined"><CircularProgress /></Paper>;
  }

  if (!userRows?.length) {
    return (
      <Alert severity="warning">
        There are no users
      </Alert>
    );
  }

  const sortableRows = [...userRows].sort(
    getComparator<UserDetailsFragment>(
      order,
      orderBy,
    ),
  );

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size='small'>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(property, direction) => {
              setOrder(direction);
              setOrderBy(property);
            }}
            refetch={refetch}
            headCells={headCells}
          />
          <TableBody>
            {sortableRows?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>
                  <List dense>
                    {getAppsByUserId(row.id).map((app) => (
                      <ListItem key={app.appId} sx={{ border: 1, margin: 1 }}>
                        <ListItemText primary={`${app.appName} | ${app.appId}`} />
                        <RemoveUserFromIntegration
                          appId={app.appId}
                          appName={app.appName}
                          username={row.username} />
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell padding="checkbox">
                  <RemoveUser username={row.username} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'User',
    isSortable: true,
  },
  {
    id: 'username',
    label: 'Username',
    isSortable: true,
  },
  {
    id: 'a1',
    label: 'Applications',
  },
];
