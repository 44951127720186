/* eslint-disable no-plusplus,no-bitwise */

export function randomColorFromString(
  seed: string,
  saturation = 90,
  lightness = 75,
  saturationVariation = 10,
) {
  const randomNumber = hashCode(seed);
  const randomSaturation = saturation + (randomNumber % saturationVariation);

  return randomColorFromNumber(randomNumber, randomSaturation, lightness);
}

export function randomColorFromNumber(seed: number, saturation = 100, lightness = 75) {
  return `hsl(${(seed % 359)}, ${saturation}%, ${lightness}%)`;
}

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // return Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216);
  return Math.abs(hash);
}
