import React from 'react';
import {
  MfgAssemblyPlaceholderTable,
} from '../../documentation/pages/browser/mfg-placeholders/MfgAssemblyPlaceholderTable';

export function MfgAssemblyPlaceholderBrowserPage() {
  return (
    <>
      <MfgAssemblyPlaceholderTable/>
    </>
  );
}
