/* eslint-disable @typescript-eslint/naming-convention */
import { Tooltip } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import { createDialog } from '../../../../../../hooks/useDialogs';
import { PartDialog } from '../../parts/PartDialog';
import { LmodDialog } from '../../lmod/LmodDialog';
import { SoftwarePartDialog } from '../../software-parts/SoftwarePartDialog';

interface Props {
  part: {
    __typename?: string;
    partNumber?: string;
    name?: null | string;
  } | null | undefined;
  showName?: boolean;
}

/**
 * A larger button for the part dialog.
 */
export function LargePartDialogButton(props: Props) {
  const { part } = props;

  if (!part?.__typename) {
    return <>Missing part</>;
  }

  const {
    __typename, name, partNumber,
  } = part;

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (__typename === 'Part') {
      createDialog((close) => <PartDialog close={close} where={{ partNumber }}/>);
    }
    if (__typename === 'SoftwarePart') {
      createDialog((close) => <SoftwarePartDialog close={close} where={{ partNumber }}/>);
    }
    if (__typename === 'DeliveryModule') {
      createDialog((close) => <LmodDialog close={close} where={{ partNumber }}/>);
    }
  };

  if (props.showName) {
    return (
      <Button
        size="small"
        variant="outlined"
        onClick={onClick}
        sx={{
          textTransform: 'none',
        }}
      >
        {shortTypeName(__typename)}:&nbsp;{partNumber} - {name}
      </Button>
    );
  }

  return (
    <Tooltip title={name || ''}>
      <Button
        size="small"
        variant="outlined"
        onClick={onClick}
        sx={{
          textTransform: 'none',
        }}
      >
        {shortTypeName(__typename)}:&nbsp;{partNumber}
      </Button>
    </Tooltip>
  );
}

function shortTypeName(name: string) {
  if (name === 'Part') {
    return 'Part';
  }

  if (name === 'SoftwarePart') {
    return 'SwPart';
  }

  if (name === 'DeliveryModule') {
    return 'LMOD';
  }

  return '';
}
