import React from 'react';
import {
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import {
  useApplicationSecretsQuery,
  useAdminCheckOffboardingMutation, useAdminListUsersQuery,
  useAdminSecretsByUserQuery,
} from '../../../generated/graphql';
import { CardRefreshAction } from '../../common/CardRefreshAction';
import { dateFormat } from '../../../util/date';
import { DeleteSecretDialog } from '../../control-panel/AppSecrets/DeleteSecretDialog';
import ErrorMessage from '../../common/ErrorMessage';
import { RemoveUser } from './RemoveUser';

export function Offboarding() {
  useAdminCheckOffboardingMutation();
  const { data, refetch, loading } = useAdminListUsersQuery();

  const rows = data?.admin.users.filter((user) => user.isRemoved === true);

  if (loading) {
    return <Paper variant="outlined"><CircularProgress /></Paper>;
  }

  if (!rows?.length) {
    return (
      <Alert severity="warning">
        There are no offboarded users
      </Alert>
    );
  }
  return (
    <Paper variant='outlined'>
      <Typography sx={{ padding: 2 }}>Offboarded Users</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Secrets</TableCell>
              <TableCell>Remove User</TableCell>
              <TableCell padding="checkbox">
                <CardRefreshAction onClick={refetch} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>
                  <UserSecrets username={row.username} />
                </TableCell>
                <TableCell>
                  <RemoveUser username={row.username} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function UserSecrets(user: { username: string }) {
  const { data, loading, error } = useAdminSecretsByUserQuery({
    variables: {
      username: user.username,
    },
  });

  return (
    <>
      {loading && <CircularProgress />}
      <ErrorMessage error={error} />
      <List dense>
        {data?.admin.secretsByUser.map((secret) => (
          <ListItem key={secret.id}>
            <ListItemText primary={secret.secretId} />
            <DeleteSecretDialog id={secret.id} />
          </ListItem>
        ))}
      </List>
    </>
  );
}

export function OrphanedSecretsTable() {
  const {
    data, refetch, loading,
  } = useApplicationSecretsQuery({});

  const rows = data?.admin.approvedApplications;

  if (loading) {
    return <Paper variant="outlined"><CircularProgress /></Paper>;
  }

  if (rows?.every((application) => application.secrets
    .every((secret) => secret.createdByUser))) {
    return (
      <Alert severity="warning">
        There are no orphaned secrets
      </Alert>
    );
  }

  return (
    <Paper variant="outlined">
      <Typography sx={{ padding: 2 }}>Orphaned Secrets</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Env</TableCell>
              <TableCell>Key</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Application</TableCell>
              <TableCell>Created</TableCell>
              <TableCell padding="checkbox">
                <CardRefreshAction onClick={refetch} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              row.secrets
                .filter((secret) => !secret.createdByUser)
                .map((secret) => (
                  <TableRow
                    key={secret.secretId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{secret.environment}</TableCell>
                    <TableCell>{secret.secretId}</TableCell>
                    <TableCell>{secret.description}</TableCell>
                    <TableCell>{row.appName}</TableCell>
                    <TableCell>{dateFormat(secret.createdAt)}</TableCell>
                    <TableCell padding="checkbox">
                      <DeleteSecretDialog id={secret.id} />
                    </TableCell>
                  </TableRow>
                ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
