import React from 'react';
import Card from '@mui/material/Card';
import HelpIcon from '@mui/icons-material/Help';
import { Typography, TypographyProps } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  DocsLink,
  EnvironmentsText,
  GettingStartedText,
  ManageYourKeysText,
  UseYourKeyText,
} from '../texts/guide';
import { SectionCardHeader } from '../common/SectionCardHeader';

export function HelpSection() {
  const SummaryText = (props: TypographyProps) => (
    <Typography {...props} />
  );
  const DetailsText = (props: TypographyProps) => (
    <Typography variant="body2" mb={1.5} {...props} />
  );

  return (
    <>
      <Card>
        <SectionCardHeader
          avatar={<HelpIcon color="info"/>}
          title="Help"
          // action={<DocumentationLinkButton/>}
        />
      </Card>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <SummaryText>Getting started</SummaryText>
        </AccordionSummary>
        <AccordionDetails>
          <DetailsText>
            <GettingStartedText/>
          </DetailsText>
          <DetailsText>
            <EnvironmentsText/>
          </DetailsText>
          <DetailsText>
            <DocsLink/>
          </DetailsText>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <SummaryText>Managing your keys</SummaryText>
        </AccordionSummary>
        <AccordionDetails>
          <DetailsText>
            <ManageYourKeysText/>
          </DetailsText>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <SummaryText>Using the key</SummaryText>
        </AccordionSummary>
        <AccordionDetails>
          <DetailsText>
            <UseYourKeyText/>
          </DetailsText>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
